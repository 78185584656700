import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CriticalInstrumentationExecution } from 'src/app/model/criticalInstrumentation';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataEquipmentEdit {
  criticalInstr: CriticalInstrumentationExecution;
}

@Component({
  selector: 'app-execution-edit-critical-instrumentation-edit',
  templateUrl: './execution-edit-critical-instrumentation-edit.component.html'
})
export class ExecutionEditCriticalInstrumentationEditComponent implements OnInit {
  criticalInstrOriginal: CriticalInstrumentationExecution;

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditCriticalInstrumentationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentEdit,
    private translate: TranslateService,
    public snackBarService: SnackBarService) {
  }
  ngOnInit(): void {
    this.criticalInstrOriginal =  _.cloneDeep(this.data.criticalInstr);
  }

  onOkClick(): void {
    const errs = [];

    if (!this.requiredReason()) {
      this.data.criticalInstr.reasonChange = null;
    } else if (this.data.criticalInstr.reasonChange == null || this.data.criticalInstr.reasonChange === '') {
      errs.push(this.translate.instant('common.reasonChangeError'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.criticalInstr);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }
  requiredReason(): boolean {
    let res = false;

    if (this.data.criticalInstr.fromProtocol) {
      res = res || this.data.criticalInstr.equipment !== this.data.criticalInstr.equipmentProtocol;
      res = res || this.data.criticalInstr.procediment !== this.data.criticalInstr.procedimentProtocol;
    } else {
      res = res || this.data.criticalInstr.equipment !== this.criticalInstrOriginal.equipmentProtocol;
      res = res || this.data.criticalInstr.procediment !== this.criticalInstrOriginal.procedimentProtocol;
    }

    return res;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
