import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import {
  CalibrationPattern, CalibrationPatternValue, CalibrationUncertaintiesValues, CalibrationVariable
} from 'src/app/model/calibration';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { VariableTypeEnum, VariableUnit, VariableUnitEnum } from 'src/app/model/variable';
import { CalibrationService } from 'src/app/services/calibration.service';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

export interface DialogDataCalibrationAsFound {
  variable: CalibrationVariable;
  item: CalibrationPattern;
  points: number[];
  units: VariableUnit[];
  unitsExtra: VariableUnit[];
}

@Component({
  selector: 'app-calibration-edit-patterns-dialog',
  templateUrl: './calibration-edit-patterns-dialog.component.html'
})
export class CalibrationEditPatternsDialogComponent implements OnInit, OnDestroy {

  public equipmentAutoComplete: InternalEquipment[];
  public equipmentName: string;
  isNotUncertainty = false;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCalibrationAsFound,
    public dialogRef: MatDialogRef<CalibrationEditPatternsDialogComponent>,
    private calibrationService: CalibrationService,
    private internalEquipmentService: InternalEquipmentService,
    private variableTypeService: VariableTypeService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    if (this.data.item.equipment == null) {
      this.data.item.equipment = new InternalEquipment();
    }

    if (this.data.item.values == null) {
      this.data.item.values = [];
    }

    if (this.data.points == null) {
      this.data.points = [];
    }

    this.data.item.values.forEach(v => {
      v.include = true;
    });

    const autoinclude = ArrayUtils.isEmpty(this.data.item.values);

    this.data.points.forEach(p => {
      const point = this.data.item.values.find(va => va.value === p);

      if (point == null) {
        const value = new CalibrationPatternValue();
        value.value = p;
        value.include = autoinclude;
        this.data.item.values.push(value);
      }
    });

    if (!ArrayUtils.isEmpty(this.data.points)) {
      this.data.item.values = this.data.item.values.filter(v => this.data.points.includes(v.value));
    }

    this.data.item.values = this.data.item.values.sort((v1, v2) => v1.value - v2.value);

    this.equipmentName = this.data.item.equipment.name;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  lookupEquipment($event): void {
    let results = this.equipmentAutoComplete;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forCalibrationPattern = true;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      results = item.content;
      this.equipmentAutoComplete = results;
    }, () => {
      results = [];
      this.equipmentAutoComplete = results;
    });
  }

  onSensorChange($event: any, trigger: MatAutocompleteTrigger): void {
    const equipmentSelected = $event.option.value as InternalEquipment;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {

      this.data.item.equipment = equipmentSelected;
      this.data.item.idEquipment = equipmentSelected.id;
      this.equipmentName = equipmentSelected.name;

      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').replace('./', '/').toUpperCase();

      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {
          this.data.item.equipment = equipmentSelected;
          this.data.item.idEquipment = equipmentSelected.id;
          this.equipmentName = equipmentSelected.name;
          if (!equipmentSelected.name.includes('QDE-')) {
            this.findUncertaintyFromEquipment(equipmentSelected.id, trigger);
          } else {
            this.isNotUncertainty = true;
          }
        } else {
          this.data.item.equipment = null;
          this.equipmentName = null;
          if (!equipmentSelected.name.includes('QDE-')) {
            this.findUncertaintyFromEquipment(equipmentSelected.id, trigger);
          } else {
            this.isNotUncertainty = true;
          }
        }
      });
    } else {
      this.data.item.equipment = equipmentSelected;
      this.data.item.idEquipment = equipmentSelected.id;
      this.equipmentName = equipmentSelected.name;
      if (!equipmentSelected.name.includes('QDE-')) {
        this.findUncertaintyFromEquipment(equipmentSelected.id, trigger);
      } else {
        this.isNotUncertainty = true;
      }
    }
  }

  public setValue(index: number, value: number | string | boolean | MatCheckboxChange, field: string): void {

    if (value == null || value === '') {
      value = null;
    } else if (value instanceof MatCheckboxChange) {
      value = value.checked;
    } else if (typeof value === 'string') {
      value = +value;
    }

    this.data.item.values[index][field] = value;
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }

  onOkClick(): void {
    const errs = [];

    this.data.item.values.forEach(v => {
      if (v.include && (v.uncertainty == null || v.uncertainty === 0) && this.data.item.equipment.equipment?.includes('QDE-')) {
        errs.push(`Debe incluir la incertidumbre para el punto ${v.value}`);
      }
    });

    if (errs.length === 0) {
      this.data.item.values = this.data.item.values.filter(v => v.include);
      this.dialogRef.close(this.data.item);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  downloadPdf(id: number = this.data.item.idEquipment, name: string = this.equipmentName): void {
    this.spinnerService.show();

    this.internalEquipmentService.downloadPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, name + '.pdf');
    }, error => {
      console.error(error);
    }, () => {
      this.spinnerService.hide();
    });
  }

  showExtraField(variable: CalibrationVariable): boolean {
    return CalibrationUtils.showExtraField(variable);
  }

  getExtraFieldName(variable: CalibrationVariable): string {
    return CalibrationUtils.getExtraFieldName(variable);
  }

  private findUncertaintyFromEquipment(idEquipment: number, trigger: MatAutocompleteTrigger): void {
    this.spinnerService.show();

    if (idEquipment == null) {
      this.spinnerService.hide();
      return;
    }

    const idVariable = this.data.variable.idVariable;

    this.calibrationService.findUncertaintyFromEquipment(idEquipment, idVariable).pipe(takeUntil(this.destroy$))
      .subscribe((res: CalibrationUncertaintiesValues) => {

        if (res == null) {
          this.showErrorNoUncertainties();
          this.spinnerService.hide();

          return;
        }

        const idUnitFrom = res.idUnit;
        const idUnitTo = this.data.variable.idUnit;

        res.unit = this.data.units.find(u => u.id === idUnitFrom)?.unit;

        if (idUnitFrom !== idUnitTo) {

          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              message: this.translate.instant('calibrateEquipmentEdit.uncertainties.form.ask',
                { origin: res.unit, destination: this.data.variable.unitName }) as string
            }
          });

          const isTemp = idVariable === VariableTypeEnum.TEMPERATURE;

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
            if (result === true) {
              this.spinnerService.show();
              const promises = res.values.map(u => this.convertPattern(u, idUnitFrom, idUnitTo, !isTemp));

              void Promise.all(promises).then(u => {
                this.spinnerService.hide();
                void this.setUncertainties(idEquipment, idVariable, u, idUnitFrom, trigger);
              });
            } else {
              const uncertainties = res.values;
              void this.setUncertainties(idEquipment, idVariable, uncertainties, idUnitFrom, trigger);
            }
          });

        } else {
          const uncertainties = res.values;
          void this.setUncertainties(idEquipment, idVariable, uncertainties, idUnitFrom, trigger);
        }

        this.spinnerService.hide();
      }, () => {
        this.showErrorNoUncertainties();
        this.spinnerService.hide();
      });
  }

  private async convertPattern(u: CalibrationPatternValue, idUnitFrom: number, idUnitTo: number, convertUncertainty = true):
    Promise<CalibrationPatternValue> {

    const variable = this.data.variable;

    let radius = variable.extraField;

    if (this.showExtraField(variable)) {
      radius = await firstValueFrom(
        this.variableTypeService.convert(variable.extraField, variable.idUnitExtra, VariableUnitEnum.LENGHT_M)) as number;
      u.value = await firstValueFrom(this.variableTypeService.convertWithRadius(u.value, radius, idUnitFrom, idUnitTo)) as number;
    } else {
      u.value = await firstValueFrom(this.variableTypeService.convert(u.value, idUnitFrom, idUnitTo)) as number;
    }

    if (convertUncertainty) {
      if (this.showExtraField(variable)) {
        u.uncertainty = await firstValueFrom(
          this.variableTypeService.convertWithRadius(u.uncertainty, radius, idUnitFrom, idUnitTo)) as number;
      } else {
        u.uncertainty = await firstValueFrom(this.variableTypeService.convert(u.uncertainty, idUnitFrom, idUnitTo)) as number;
      }
    }

    return u;
  }

  private async setUncertainties(idPattern: number, idVariable: number, uncertainties: CalibrationPatternValue[], idUnitFrom: number,
    trigger: MatAutocompleteTrigger): Promise<void> {

    if (!ArrayUtils.isEmpty(uncertainties)) {
      uncertainties.forEach(u => {
        u.value = NumberUtils.fixPrecision(u.value);
        u.uncertainty = NumberUtils.fixPrecision(u.uncertainty);
      });

      const emptyList: CalibrationPatternValue[] = [];
      const sorted = emptyList.concat(...uncertainties).sort((u1, u2) => u1.value - u2.value);
      const reversed = emptyList.concat(...uncertainties).sort((u1, u2) => u1.value - u2.value).reverse();

      const pointsErrs: number[] = [];

      for (let indexVal = 0; indexVal < this.data.item.values.length; indexVal++) {
        const val = this.data.item.values[indexVal];

        val.include = true;

        const point = val.value;

        const exact = uncertainties.find(u => u.value === point);

        if (exact != null) {
          val.uncertainty = exact.uncertainty;
        } else {
          let high = new CalibrationPatternValue();
          for (let i = 0; i < sorted.length - 1; i++) {
            // Verificar si el número está entre dos registros consecutivos
            if (point >= sorted[i].value && point <= sorted[i + 1].value) {
              if (sorted[i]?.uncertainty > sorted[i + 1]?.uncertainty) {
                high = sorted[i];
              } else {
                high = sorted[i + 1];
              }
            }
          }
          const low = reversed.find(u => u.value < point);

          if (high != null && low != null) {
            if (high.uncertainty === null || !high.uncertainty) {
              val.uncertainty = await firstValueFrom(this.calibrationService.interpolateValuePattern(idPattern, idVariable,
                point)) as number;
              const idUnitTo = this.data.variable.idUnit;
              val.uncertainty = NumberUtils.fixPrecision(
                await firstValueFrom(this.variableTypeService.convert(val.uncertainty, idUnitFrom, idUnitTo)) as number);
            } else {
              val.uncertainty = high.uncertainty;
            }
          } else if ((high != null && low == null) || (high == null && low != null)) {
            val.include = false;
            pointsErrs.push(indexVal + 1);
          }
        }

      }

      if (ArrayUtils.isNotEmpty(pointsErrs)) {
        const minUncertainty = Math.min(...uncertainties.map(u => u.value));
        const maxUncertainty = Math.max(...uncertainties.map(u => u.value));

        this.showErrorNotCalibrated(pointsErrs, minUncertainty, maxUncertainty);
      }

      event.stopPropagation();
      trigger.closePanel();
    } else {
      this.showErrorNoUncertainties();
    }
  }

  private showErrorNoUncertainties() {
    const message = this.translate.instant('calibrateEquipmentEdit.uncertainties.form.noUncertainties') as string;
    const messageCancel = this.translate.instant('button.accept') as string;

    this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '20%',
      data: { message, canOk: false, messageCancel }
    });

    this.data.item.equipment = null;
    this.equipmentName = null;

    this.data.item.values.forEach(val => {
      val.uncertainty = null;
    });
  }

  private showErrorNotCalibrated(pointsErrs: number[], minPoint: number, maxPoint: number): void {
    const points = pointsErrs.join(', ');

    const message = this.translate.instant('calibrateEquipmentEdit.uncertainties.form.patternNotValid',
      { points, minPoint, maxPoint }) as string;
    const messageCancel = this.translate.instant('button.accept') as string;

    this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '20%',
      data: { message, canOk: false, messageCancel }
    });

  }

}
