<div [class]="getTheme()">
<form #executionListForm="ngForm" *ngIf="!roouterPath">
    <div fxLayout="row">

        <div fxFlex="20" fxHide.xs="true" fxHide="false" fxHide.sm="true" fxHide.md="true">
            <div style="width: 350px;"></div>

            <app-fixed-sidebar></app-fixed-sidebar>
        </div>
        <div class="main-container small-layout" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100">
            <div ngClass.xs="lead-text-mobile" ngClass="lead-text" class="centerText">
                <p [innerHtml]="getText() | translate | safe: 'html'"> </p>
            </div>
    
            <div fxLayout="row" class="block-highlights card-center">
    
                <div fxFlex="80" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">
                    <mat-card appearance="outlined" class="card" (click)="goToPage('air/protocols')" appMaterialElevation>
                        <mat-card-content class="pointerHand">
                            <div class="action-floating new-badge" (click)="newProtocolAir()">
                                <mat-icon>add_circle</mat-icon>
                                <span>{{ 'button.new' | translate }}</span>
                            </div>
                            <div class="action-floating info-badge" fxHide.lt-md
                                matTooltip="{{ 'menu.protocol.info' | translate }}">
                                <mat-icon color="accent">info</mat-icon>
                            </div>
                            <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Resource-/-Icon-/-Protocolos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                    <g id="clipboard" transform="translate(8.000000, 4.000000)" stroke="#00ACC7" stroke-width="2">
                                        <path d="M24,4 L28,4 C30.209139,4 32,5.790861 32,8 L32,36 C32,38.209139 30.209139,40 28,40 L4,40 C1.790861,40 0,38.209139 0,36 L0,8 C0,5.790861 1.790861,4 4,4 L8,4" id="Path"></path>
                                        <rect id="Rectangle" x="8" y="0" width="16" height="8" rx="2"></rect>
                                    </g>
                                </g>
                            </svg>
                            <span class="title accent-color">{{ 'menu.protocol' | translate }}</span>
                        </mat-card-content>
                    </mat-card>
        
                    <mat-card appearance="outlined" class="card" (click)="goToPage('air/executions')" appMaterialElevation>
                        <mat-card-content class="pointerHand">
                            <div class="action-floating new-badge" (click)="newExecutionsAir()">
                                <mat-icon>add_circle</mat-icon>
                                <span>{{ 'button.new' | translate }}</span>
                            </div>
                            <div class="action-floating info-badge" fxHide.lt-md
                                matTooltip="{{ 'menu.execution.info' | translate }}">
                                <mat-icon color="accent">info</mat-icon>
                            </div>
                            <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                               <g id="Resource-/-Icon-/-Ejecucion-Servicios" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                                    <g id="calendar" transform="translate(6.000000, 4.000000)" stroke="#00ACC7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                        <rect id="Rectangle" x="0" y="4" width="36" height="36" rx="4"></rect>
                                        <line x1="26" y1="0" x2="26" y2="8" id="Path"></line>
                                        <line x1="10" y1="0" x2="10" y2="8" id="Path"></line>
                                        <line x1="0" y1="16" x2="36" y2="16" id="Path"></line>
                                    </g>
                                </g>
                            </svg>
                            <span class="title accent-color">{{ 'menu.execution' | translate }}</span>
                        </mat-card-content>
                    </mat-card>
        
                    <mat-card appearance="outlined" class="card" (click)="goToPage('air/internalEquipments')" appMaterialElevation>
                        <mat-card-content class="pointerHand">
                            <div class="action-floating new-badge" (click)="newInternalEquipment()">
                                <mat-icon>add_circle</mat-icon>
                                <span>{{ 'button.new' | translate }}</span>
                            </div>
                            <div class="action-floating info-badge" fxHide.lt-md
                                matTooltip="{{ 'menu.my-equipments.info' | translate }}">
                                <mat-icon color="accent">info</mat-icon>
                            </div>
                            <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Resource-/-Icon-/-Equipos-Uso-Interno" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                    <g id="tool-(1)" transform="translate(5.000000, 5.000000)" stroke="#00ACC7" stroke-width="2">
                                        <path d="M23.4,9.6 C22.6377142,10.377687 22.6377142,11.622313 23.4,12.4 L26.6,15.6 C27.377687,16.3622858 28.622313,16.3622858 29.4,15.6 L36.94,8.06 C38.999204,12.610527 38.0243848,17.9607289 34.4925569,21.4925569 C30.9607289,25.0243848 25.610527,25.999204 21.06,23.94 L7.24,37.76 C5.58314575,39.4168542 2.89685425,39.4168542 1.24,37.76 C-0.416854249,36.1031458 -0.416854249,33.4168542 1.24,31.76 L15.06,17.94 C13.000796,13.389473 13.9756152,8.03927106 17.5074431,4.50744314 C21.0392711,0.975615217 26.389473,0.000795958694 30.94,2.06 L23.42,9.58 L23.4,9.6 Z" id="Path"></path>
                                    </g>
                                </g>
                            </svg>
                            <span class="title accent-color">{{ 'menu.my-equipments' | translate }}</span>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</form>

<router-outlet *ngIf="roouterPath"></router-outlet>
</div>