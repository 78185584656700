
<h1 mat-dialog-title>{{ 'protocolEdit.dialog.generateSign.title' | translate }}</h1>
<form>

    <div mat-dialog-content class="qualificates-theme" style="overflow-y: hidden;">

        <div fxLayout="column">
            

            <div fxFlex *ngIf="!signer.internal">
                <mat-label>{{ 'common.reportType.options.reviewBy.label' | translate }}</mat-label>
                <mat-select name="respDocs" [(ngModel)]="signer.username" (selectionChange)="onNextSignChange($event)">
                    <mat-select-filter [displayMember]="'fullName'" [array]="respDocs"
                        (filteredReturn)="respDocsFiltered =$event"
                        [noResultsMessage]="'common.noResults' | translate"
                        [placeholder]="'common.selectFilterPlaceholder' | translate"> </mat-select-filter>
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of respDocsFiltered" [value]="item.username">
                        {{item.fullName}}
                    </mat-option>
                </mat-select>
            </div>

            <div>
                <mat-checkbox class="example-margin" [(ngModel)]="signer.internal">
                    {{ 'protocolEdit.dialog.signers.interal' | translate }}
                </mat-checkbox>
            </div>
        </div>
    </div>
</form>
