import { GenericClassTranslate } from './genericClass';

export class EquipmentType extends GenericClassTranslate {
}

export enum EquipmentTypeAirEnum {
    LAMINAR_CABIN_VERTICAL = 1,
    LAMINAR_CABIN_HORIZONTAL = 2,
    LAMINAR_CABIN_30_70 = 3,
    BIOLOGICAL_SAFETY_BOX_I = 4,
    BIOLOGICAL_SAFETY_BOX_IIA = 5,
    BIOLOGICAL_SAFETY_BOX_IIB = 6,
    BIOLOGICAL_SAFETY_BOX_III = 7,
    CARVING_TABLE = 8,
    EXTRACTOR_DISPLAY_CASE = 9,
    EXTRACTION_CABIN = 10,
    AIR_DRIVE_UNIT = 11,
    AIR_EXTRACTION_UNIT = 12,
    PROTECTION_HEAVY_CABIN = 13,
    LAMINATE_FLOW_MODULE = 14,
    AISLATOR = 15,
    FFU = 16,
    CELL = 17,
    TRANSFER = 18
}

export enum EquipmentTypeQualificationEnum {
    EQUIPMENT_TYPE = 1,
    WASHER_TYPE = 2,
    TANK_N2 = 3,
    VEHICLE = 4,
    CENTRIFUGE = 5,
    WAREHOUSE = 6,
    EST_DRY_HEAT = 7,
    CLIMATIC_CHAMBER = 8
}