
<h3 mat-dialog-title>{{ 'internalEquipmentList.dialog.folders.title' | translate }}</h3>
<form>

  <div mat-dialog-content class="calibrates-theme" style="overflow-y: hidden;">

    <mat-form-field class="campoFormulario">
      <mat-label>{{ 'internalEquipmentList.dialog.folders.label' | translate }}</mat-label>
      <mat-select [(ngModel)]="idGroupSelected" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of this.data.folders" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div mat-dialog-actions class="getTheme()">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial [disabled]="!idGroupSelected">
            {{ 'common.yes' | translate }}
        </button>

        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'common.no' | translate }}</button>
    </div>
</form>
    