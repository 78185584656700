export class NumberUtils {

    static countDecimals(num: number): number {
        let res = 0;
        if (num == null || Math.floor(num.valueOf()) === num.valueOf()) {
            return res;
        } else {
            const parts = num.toString().split('.');

            if (parts.length > 1) {
                res = parts[1].length;
            }
        }

        return res;
    }

    static countDecimalsAny(value: string | number): number | null {
        const valueStr = value.toString().trim();
        const decimalIndex = valueStr.indexOf('.');
        if (decimalIndex === -1 || decimalIndex === valueStr.length - 1) {
            return 0;
        }
        return valueStr.length - decimalIndex - 1;
    }

    static fixPrecision(numb: number | string): number {
        return this.round(numb, 10);
    }

    static round(numb: number | string, decimals: number = 2): number {
        if (numb == null) {
            return null;
        }

        let res: number = null;

        if (typeof numb === 'string') {
            res = +(parseFloat(numb));
        } else {
            res = numb;
        }

        const exponentialPlus = `e+${decimals}`;
        const exponentialMinum = `e-${decimals}`;

        const part1 = Math.round(+(`${res}` + exponentialPlus));

        return +(`${part1}` + exponentialMinum);
    }

    static standardDeviation(data: number[]): number {
        const lengthData = data.length;

        if (lengthData < 2) {
            return 0;
        }

        if (data.every(v => v === data[0])) {
            return 0;
        }

        const avg = this.fixPrecision(data.reduce((a, b) => a + b) / lengthData);

        const stdDev = Math.sqrt(data.map(x => Math.pow(x - avg, 2)).reduce((a, b) => a + b) / (lengthData - 1));

        return +this.fixPrecision(stdDev);
    }

    static sum(data: number[]): number {
        return this.fixPrecision(data.reduce((a, b) => a + b, 0));
    }

    static average(data: number[]): number {
        const summatory = data.reduce((sum, value) => sum + value, 0);

        return summatory / data.length;
    }

    static isBetweenEq(num: number, init: number, end: number): boolean {
        return num >= init && num <= end;
    }

    static fixPrecisionWithOtherMethod(numb: number | string): number {
        return this.roundNumber(numb, 10);
    }

    static roundNumber(numb: number | string, decimals: number): number {
        if (numb == null) {
            return null;
        }

        let res: number;

        if (typeof numb === 'string') {
            res = parseFloat(numb);
        } else {
            res = numb;
        }

        const rounded = Math.round(res * Math.pow(10, decimals));
        const result = rounded / Math.pow(10, decimals);

        if (isNaN(result)) {
            return null;
        }
        return parseFloat(result.toFixed(decimals));
    }
}
