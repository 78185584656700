import * as moment from 'moment';

import { AppType, AppTypeEnum } from 'src/app/model/appType';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AppTypeService } from 'src/app/services/appType.service';
import { CalibrationFilter } from 'src/app/model/calibration';
import { CalibrationStatusService } from 'src/app/services/calibrationStatus.service';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { GenericClass } from 'src/app/model/genericClass';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UsersService } from 'src/app/services/users.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { takeUntil } from 'rxjs/operators';
import { CalibrationPlaceService } from 'src/app/services/calibrationPlace.service';
import { GenericClassTranslate } from '../../../model/genericClass';
import { Variable } from '../../../model/variable';

@Component({
  selector: 'app-calibration-list-filter',
  templateUrl: './calibration-list-filter.component.html'
})
export class CalibrationListFilterComponent implements OnInit, OnDestroy {

  @Output() filterEmitter = new EventEmitter<CalibrationFilter>();

  filter: CalibrationFilter;

  statuses: GenericClass[];

  clients: Client[];
  clientsFiltered: Client[];

  users: User[];
  usersFiltered: User[];

  variables: Variable[];
  appTypes: AppType[];

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: FormGroup;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  places: GenericClassTranslate[];

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    private calibrationStatusService: CalibrationStatusService,
    private variableTypeService: VariableTypeService,
    private appTypeService: AppTypeService,
    private clientService: ClientService,
    private userService: UsersService,
    private calibrationPlaceService: CalibrationPlaceService) {
    this.cleanFilter();

    this.form = fb.group({
      idStatus: [this.filter.idStatus],
      idClient: [this.filter.idClient],
      regUser: [this.filter.regUser],

      name: [this.filter.name],
      type: [this.filter.type],

      maker: [this.filter.maker],
      model: [this.filter.model],

      serialNum: [this.filter.serialNum],
      certificateNum: [this.filter.certificateNum],

      projectNo: [this.filter.projectNo],

      calibrateDateStart: [this.filter.calibrateDateStart],
      calibrateDateEnd: [this.filter.calibrateDateEnd],
      expirationDateStart: [this.filter.expirationDateStart],
      expirationDateEnd: [this.filter.expirationDateEnd],

      realizationFcStart: [this.filter.realizationFcStart],
      realizationFcEnd: [this.filter.realizationFcEnd],
      idPlace: [this.filter.idPlace]
    });
  }

  ngOnInit(): void {
    this.statuses = [];
    this.calibrationStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.statuses = data);

    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => this.clients = data);

    this.users = [];
    this.userService.findAllFromGroupAppType(AppTypeEnum.CALIBRATES).pipe(takeUntil(this.destroy$))
      .subscribe((data: User[]) => this.users = data);

    this.variables = [];
    this.variableTypeService.findAllCalibrates().pipe(takeUntil(this.destroy$)).subscribe((data: Variable[]) => this.variables = data);

    this.appTypes = [];
    this.appTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: AppType[]) => this.appTypes = data);

    this.places = [];
    this.calibrationPlaceService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClassTranslate[]) => this.places = data);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new CalibrationFilter();
  }

  saveDate(field: string, event: any): void {

    let value: Date | string = event.target.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.filter[field] = date;
    } else {
      this.filter[field] = null;
    }

  }

}
