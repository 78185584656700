import { Component, Inject, OnInit } from '@angular/core';
import { GroupDocumentCoding } from '../../../../model/group';
import { DocumentListEnum } from '../../../../utils/documentListUtils';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../../services/snackBar.service';

export interface DialogDataDocumentCoding {
  groupCoding: GroupDocumentCoding;
  isNew: boolean;
  existsCoding: GroupDocumentCoding[]
}

@Component({
  selector: 'app-group-edit-coding-document',
  templateUrl: './group-edit-coding-document.component.html',
  styleUrls: ['./group-edit-coding-document.component.scss']
})
export class GroupEditCodingDocumentComponent implements OnInit {

  documentTypes = [DocumentListEnum.PROTOCOL_AIR, DocumentListEnum.EXECUTION_AIR, DocumentListEnum.CALIBRATES, DocumentListEnum.CHARACTERIZATION, DocumentListEnum.EXECUTION_THERMAL, DocumentListEnum.PROTOCOL_THERMAL]
  constructor(private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDocumentCoding,
    public dialogRef: MatDialogRef<GroupEditCodingDocumentComponent>,
    public snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
  }

  getDocumentTypeDescription(document: number ): string {
    switch (document) {
      case 1:
       return this.translate.instant('documentType.air.execution');
      case 2:
       return this.translate.instant('documentType.air.protocol');
      case 3:
       return this.translate.instant('documentType.thermal.execution');
      case 4:
       return this.translate.instant('documentType.thermal.protocol');
      case 5:
       return this.translate.instant('documentType.calibration.characterization');
      case 6:
       return this.translate.instant('documentType.calibration.calibration');
      default:
        return '';
    }
  }

  onOkClick(): void {
    const errors = [];

    if (!this.data.groupCoding.documentType) {
      errors.push(this.translate.instant('groupEdit.coding.document.error'));
    } else {
      if (!this.data.isNew) {
        const exists = this.data.existsCoding.find(e => e.documentType === this.data.groupCoding.documentType);

        if (exists) {
          errors.push(this.translate.instant('groupEdit.coding.document.exists.error'))
        }
      }
    }
    
    if (!this.data.groupCoding.coding) {
      errors.push(this.translate.instant('groupEdit.coding.code.error'));
    }

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      return;
    } else {
      this.dialogRef.close(this.data.groupCoding);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
