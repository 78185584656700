import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { ExecutionEditAttachmentsComponent } from './execution-edit-attachments.component';
import { ExecutionEditAuditComponent } from './execution-edit-audit.component';
import { ExecutionEditComponent } from './execution-edit.component';
import { ExecutionEditDialogAutomaticSignComponent } from './execution-edit-dialog-automatic-sign.component';
import { ExecutionEditDialogEssayComponent } from './execution-edit-dialog-essay.component';
import { ExecutionEditEssayCriteriaComponent } from './execution-edit-essay-criteria.component';
import { ExecutionEditFieldEditComponent } from './execution-edit-field-edit.component';
import { ExecutionEditGenerateReportComponent } from './execution-edit-generate-report.component';
import {
    ExecutionEditValidateExecutionAutomaticSignComponent
} from './execution-edit-validate-execution.component-automatic-sign';
import { ExecutionEditValidateExecutionComponent } from './execution-edit-validate-execution.component';
import { FlexLayoutModule } from 'ng-flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    declarations: [
        ExecutionEditComponent, ExecutionEditDialogEssayComponent, ExecutionEditGenerateReportComponent,
        ExecutionEditValidateExecutionAutomaticSignComponent, ExecutionEditValidateExecutionComponent, ExecutionEditEssayCriteriaComponent,
        ExecutionEditDialogAutomaticSignComponent, ExecutionEditAuditComponent, ExecutionEditAttachmentsComponent,
        ExecutionEditFieldEditComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        DevextremeModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule
    ]
})
export class ExecutionAirEditModule { }
