import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CalibrationAsLeft, CalibrationAsLeftValue } from 'src/app/model/calibration';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { NumberUtils } from 'src/app/utils/numberUtils';

export interface DialogDataCalibrationAsLeft {
  item: CalibrationAsLeft;
  minValues: number;
}

@Component({
  selector: 'app-calibration-edit-as-left-dialog',
  templateUrl: './calibration-edit-as-left-dialog.component.html'
})
export class CalibrationEditAsLeftDialogComponent implements OnInit {

  minValues: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCalibrationAsLeft,
    public dialogRef: MatDialogRef<CalibrationEditAsLeftDialogComponent>,
    private translate: TranslateService,
    public snackBarService: SnackBarService) { }

  ngOnInit(): void {
    this.minValues = Math.max(this.data.minValues, 1);

    if (ArrayUtils.isEmpty(this.data.item.values)) {
      this.data.item.values = [];

    }

    const diff = this.minValues - this.data.item.values.length;

    for (let i = 0; i < diff; i++) {
      this.newValue();
    }
  }

  newValue(): void {
    this.data.item.values.push(new CalibrationAsLeftValue());
  }

  removeValue(index: number): void {
    this.data.item.values.splice(index, 1);
  }

  public setValue(index: number, value: number | string): void {
    if (value == null || value === '') {
      this.data.item.values[index].value = null;
    } else {
      this.data.item.values[index].value = +value;
    }
  }

  onOkClick(): void {

    const errs = [];

    if (this.data.item.pattern == null || isNaN(+this.data.item.pattern)) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.dialog.pattern.error'));
    }
    if (ArrayUtils.isEmpty(this.data.item.values)) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.dialog.values.error'));
    } else {
      if (this.data.item.values.filter(v => v == null || v.value == null).length !== 0) {
        errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.dialog.values.value.error'));
      } else {
        if (this.data.item.pattern || !isNaN(+this.data.item.pattern)) {
          const resultCount = this.data.item.values.filter(v => NumberUtils.countDecimalsAny(v.value) >  NumberUtils.countDecimalsAny(this.data.item.pattern));
          if (resultCount && resultCount.length > 0) {
            errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.dialog.lengthPattern.error'));
          }
        }
      }
    
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.item);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
