<h1 mat-dialog-title></h1>
<form>

    <div mat-dialog-content class="calibrates-theme">
        <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
            *ngFor="let value of data.data; let indexValue = index">
            <div fxLayout="row" class="flexwrap">
                <div fxFlex="100" fxFlex.lt-md="95%">
                    <mat-form-field class="campoFormulario">
                        <input matInput name="num{{indexValue}}" [(ngModel)]="value.description"
                            placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.values.number.label' | translate }}">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="calibrates-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{ 'button.save' | translate
          }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
      </div>
</form>