import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Invoice, PaymentIntent, Subscription } from 'src/app/model/payment';
import { User, UserOfGroup } from 'src/app/model/user';

import { AppPlanType } from 'src/app/model/appPlanType';
import { AppPlanTypeService } from 'src/app/services/appPlanType.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { Country } from 'src/app/model/country';
import { CountryService } from 'src/app/services/country.service';
import { GenericClassTranslate } from 'src/app/model/genericClass';
import { Group } from 'src/app/model/group';
import { ManageGroupsService } from 'src/app/services/manageGroups.service';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { RoleUserFilter } from 'src/app/model/roleUser';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { Variable } from 'src/app/model/variable';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { GroupEditCodingDocumentComponent } from './group-edit-coding-document/group-edit-coding-document.component';
import { GroupDocumentCoding } from '../../../model/group';
import * as _ from 'lodash';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html'
})
export class GroupEditComponent implements OnInit, OnDestroy {

  @ViewChild('uploadLogo') uploadLogoInput: ElementRef;
  @ViewChild('usersPaginator', { static: true }) usersPaginator: MatPaginator;
  @ViewChild('codingTable') codingTable: MatTable<any>;

  matcher = new MyErrorStateMatcher();

  currentUser: User;

  group: Group = new Group();
  isEdit: boolean;

  image: Blob;

  appPlanTypes: AppPlanType[];

  usersDatasource: MatTableDataSource<UserOfGroup>;
  displayedColsUsers = ['username', 'role', 'app'];

  usersFilter: RoleUserFilter;

  phases: Phase[];
  roles: GenericClassTranslate[];
  variableTypes: Variable[];

  subscriptions: Subscription[];
  displayedColsSubscriptions = ['name', 'nextInvoiceDate', 'subscriptionEndDate', 'amount'];

  payments: PaymentIntent[];
  displayedColsPayments = ['name', 'amount', 'date', 'downloadUrl'];

  invoices: Invoice[];
  displayedColsInvoices = ['date', 'total', 'downloadUrl'];

  displayedColsCoding = ['code', 'document', 'edit', 'delete'];

  countries: Country[];
  countriesFiltered: Country[];

  idEditCode: boolean =false;

  private destroy$ = new Subject<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private manageGroupsService: ManageGroupsService,
    private appPlanTypeService: AppPlanTypeService,
    private userService: UserService,
    private translate: TranslateService,
    private attachmentThermalService: AttachmentThermalService,
    private phaseService: PhaseService,
    private variableTypeService: VariableTypeService,
    private manageUsersService: ManageUsersService,
    private countryService: CountryService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.currentUser = this.userService.currentProfile;

    this.appPlanTypes = [];
    if (this.currentUser.admin && !this.currentUser.manager) {
      this.appPlanTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: AppPlanType[]) => this.appPlanTypes = res);
    }

    this.countries = [];
    this.countriesFiltered = [];
    this.countryService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: Country[]) => this.reloadCountries(res, null));
    this.translationService.lang$.pipe(takeUntil(this.destroy$)).subscribe(res => this.reloadCountries(this.countries, res));

    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id != null) {
        this.loadGroup(id);
        this.isEdit = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.currentUser.admin) {
      this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: Phase[]) => this.phases = res);
      this.manageUsersService.getAssignableRoles().pipe(takeUntil(this.destroy$))
        .subscribe((res: GenericClassTranslate[]) => this.roles = res);
      this.variableTypeService.findAll().subscribe((res: Variable[]) => this.variableTypes = res);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(): void {
    this.spinnerService.show();

    this.manageGroupsService.update(this.group).pipe(takeUntil(this.destroy$)).subscribe((res: Group) => {
      this.group = res;
      this.loadGroup(this.group.id);

      if (!this.isEdit) {
        this.snackBarService.sendSuccess(this.translate.instant('groupEdit.form.create.ok') as string);

        void this.router.navigateByUrl(`/admin/group?id=${res.id}`);
      } else {
        this.snackBarService.sendSuccess(this.translate.instant('groupEdit.form.update.ok') as string);
      }

      this.spinnerService.hide();
    }, () => {
      if (!this.isEdit) {
        this.snackBarService.sendError(this.translate.instant('groupEdit.form.create.error') as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('groupEdit.form.update.error') as string);
      }

      this.spinnerService.hide();
    });

  }

  onLogoUpload(event): void {

    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadLogoToGroup(this.group.id, file).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
      this.group.idLogo = item;

      this.reloadImage();

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('groupEdit.form.uploadLogo.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('groupEdit.form.uploadLogo.error') as string);
    });

    this.uploadLogoInput.nativeElement.value = '';
  }

  reloadImage(): void {
    this.image = null;

    if (this.group.idLogo) {
      this.attachmentThermalService.downloadLogoToGroup(this.group).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        this.image = res;

      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.downloadImage.error.generic') as string);

        this.spinnerService.hide();
      });
    }
  }

  reloadCountries(res: Country[], lang: string): void {
    res = res.map(c => {
      c.name = this.translateCountry(c.id, lang);
      return c;
    }).filter(c => c.name != null);

    res = ArrayUtils.sortByField(res, 'name');

    this.countries = res;
    this.countriesFiltered = res;
  }

  reloadPaymentItems(): void {
    const groupId = this.group.id;
    const manager = this.currentUser.manager;

    const subscriptionsObservable = manager ?
      this.manageGroupsService.findSubscriptionsFromMyGroup() :
      this.manageGroupsService.findSubscriptionsFromGroup(groupId);

    const paymentsObservable = manager ?
      this.manageGroupsService.findPaymentsFromMyGroup() :
      this.manageGroupsService.findPaymentsFromGroup(groupId);

    const invoicesObservable = manager ?
      this.manageGroupsService.findInvoicesFromMyGroup() :
      this.manageGroupsService.findInvoicesFromGroup(groupId);

    subscriptionsObservable.pipe(takeUntil(this.destroy$)).subscribe((res: Subscription[]) => this.subscriptions = res);
    paymentsObservable.pipe(takeUntil(this.destroy$)).subscribe((res: PaymentIntent[]) => this.payments = res);
    invoicesObservable.pipe(takeUntil(this.destroy$)).subscribe((res: Invoice[]) => this.invoices = res);
  }

  public openDownloadLink(link: string): void {
    window.open(link, 'blank');
  }

  public showImage(): SafeUrl {
    if (this.image == null) {
      return '';
    }

    const unsafeImageUrl = URL.createObjectURL(this.image);

    return this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }

  onAppPlanTypeChange(event: MatSelectChange): void {
    const appPlan = this.appPlanTypes.find(a => a.id === event.value);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('groupEdit.essays.form.appPlanChange.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.group.idsPhasesAllowed = appPlan.idsPhasesAllowed;
        this.group.maxExecutions = appPlan.maxExecutions;
        this.group.maxUsers = appPlan.maxUsers;
        this.group.canGeneratePreReport = appPlan.canGeneratePreReport;
        this.group.canManageUsers = appPlan.canManageUsers;
      }
    });

  }

  cancel(): void {
    if (this.currentUser.manager) {
      void this.router.navigateByUrl('/');
    } else {
      void this.router.navigateByUrl('/admin/manageGroups');
    }
  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    this.manageGroupsService.downloadAuditPdf(this.group.id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, this.translate.instant('manageUsers.files.auditGroup', { name: this.group.name }) as string);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  getRolesOfUser(user: UserOfGroup): string {
    return user.roleTranslates.map(s => this.translate.instant(`role.${s}`) as string).sort().join(', ');
  }

  getAppsOfUser(user: UserOfGroup): string {
    return user.appNames.sort().join(', ');
  }

  getDocumentTypeDescription(document: number ): string {
    switch (document) {
      case 1:
       return this.translate.instant('documentType.air.execution');
      case 2:
       return this.translate.instant('documentType.air.protocol');
      case 3:
       return this.translate.instant('documentType.thermal.execution');
      case 4:
       return this.translate.instant('documentType.thermal.protocol');
      case 5:
       return this.translate.instant('documentType.calibration.characterization');
      case 6:
       return this.translate.instant('documentType.calibration.calibration');
      default:
        return '';
    }
  }

  addOrEditGroupCoding(groupCoding: GroupDocumentCoding, isEdit: boolean) {
    const dialogRef = this.dialog.open(GroupEditCodingDocumentComponent, {
      data: {
        isNew: isEdit,
        existsCoding: this.group.groupDocumentCoding,
        groupCoding: groupCoding ? _.cloneDeep(groupCoding) : new GroupDocumentCoding()
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
       if (!this.group.groupDocumentCoding) {
        this.group.groupDocumentCoding = [];
       }

       if (!result.id) {
        this.group.groupDocumentCoding.push(result);
       } else {
        this.group.groupDocumentCoding =  this.group.groupDocumentCoding.map(o => o.id === result.id ? { ...o, coding: result.coding } : o);
       }
       this.codingTable.renderRows();

      }
    });

  }

  deleteGroupCoding(index: number) {
    this.group.groupDocumentCoding.splice(index, 1);
    this.codingTable.renderRows();

  }

  private loadGroup(id: number): void {
    this.manageGroupsService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: Group) => {
      if (result !== null && result !== undefined) {
        this.group = result;

        if (this.group == null) {
          this.cancel();
        }

        this.reloadImage();
        this.reloadPaymentItems();

        this.loadUsers();

        this.spinnerService.hide();
      } else {
        this.group = null;
      }
    }, () => this.cancel());
  }

  private loadUsers(): void {
    if (!this.isEdit) {
      return;
    }

    this.usersFilter = new RoleUserFilter();
    this.usersFilter.idGroup = this.group.id;

    this.manageGroupsService.findUsersFromGroup(this.usersFilter).subscribe((data: UserOfGroup[]) => {
      this.usersDatasource = new MatTableDataSource<UserOfGroup>(data);
      this.usersDatasource.paginator = this.usersPaginator;
    });
  }

  private translateCountry(iso: string, lang: string): string {
    return this.translationService.translateCountry(iso, lang);
  }

}
