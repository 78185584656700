export const quillConfig = {
  theme: 'snow',
  toolbar: [
    ['image'],
    ['bold', 'italic', 'underline'],
    [{ list: 'bullet' }],
    [{ 'color': [] }], 
    [{ align: 'left' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
  ],
  blotFormatter2: {
    align: ['left', 'center', 'right'],
    resize: {
      allowResizing: true,
      allowResizeModeChange: true,
      imageOversizeProtection: false,
      minimumWidthPx: 25,
    }
  },
  imageResize: {
    displaySize: true
  }
};
