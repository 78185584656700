import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DialogDataConfirmSave, ProtocolEditConfirmSaveComponent } from './protocol-edit-dialog-confirmSave.component';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericClass, GenericWarnError } from '../../../model/genericClass';
import { Protocol, ProtocolStatus } from '../../../model/protocol';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { Client } from '../../../model/client';
import { ClientService } from '../../../services/client.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { CriticalInstrumentation } from '../../../model/criticalInstrumentation';
import { EditDocumentCodeEditComponent } from '../../shared/edit-document-code-edit/edit-document-code-edit.component';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import { Equipment } from '../../../model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EquipmentTypeService } from 'src/app/services/equipmentType.service';
import { Essay } from '../../../model/essay';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { InstrumentationService } from '../../../services/instrumentation.service';
import { InstrumentationUse } from 'src/app/model/instrumentationUse';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { OnlineService } from 'src/app/services/online.service';
import { Phase } from '../../../model/phase';
import { PhaseService } from '../../../services/phase.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolEditAttachmentsComponent } from './protocol-edit-attachments.component';
import { ProtocolEditAuditComponent } from './protocol-edit-audit.component';
import { ProtocolEditCriticalInstrDialogComponent } from './protocol-edit-dialog-criticalInstr.component';
import { ProtocolEditDialogAutomaticSignComponent } from './protocol-edit-dialog-automatic-sign.component';
import { ProtocolEditDialogManualSignComponent } from './protocol-edit-dialog-manual-sign.component';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-dialog-equipment.component';
import { ProtocolEditEssayDialogComponent } from './protocol-edit-dialog-essay.component';
import { ProtocolEditGenerateReportComponent } from './protocol-edit-generate-report.component';
import { ProtocolService } from '../../../services/protocol.service';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { SignInfo } from 'src/app/model/sign';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { ValidatorSpecifics } from 'src/app/model/validatorSpecific';
import { takeUntil } from 'rxjs/operators';
import { RoleEnum } from '../../../model/roleUser';

@Component({
  selector: 'app-protocol',
  templateUrl: './protocol-edit.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProtocolEditComponent implements OnInit, OnDestroy {

  @ViewChild('equipmentTable', { static: true }) equipmentTable: MatTable<any>;
  @ViewChild('criticalInstrTable') criticalInstrTable: MatTable<any>;
  @ViewChild('essaysTable', { static: true }) essaysTable: MatTable<any>;
  @ViewChildren('attachmentsComponent') attachmentsComponent: ProtocolEditAttachmentsComponent;
  @ViewChild(ProtocolEditAuditComponent) audit: ProtocolEditAuditComponent;
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;

  disableOfflineButton = false;

  currentUser: User;

  protocol: Protocol;
  clients: Client[];
  clientsFiltered: Client[];
  phases: Phase[];
  respField: User[];

  pendingSigns: SignInfo[];

  processes: GenericClass[];

  equipmentList: Equipment[] = [];
  equipmentCols = ['type', 'equipment', 'maker', 'model', 'serialNum', 'internalId', 'location', 'edit', 'delete'];

  calibrateCriticalInstrumentation = false;
  criticalInstr: CriticalInstrumentation[] = [];
  criticalInstrCols = ['equipment', 'internalId', 'procediment', 'edit', 'delete'];

  showValidatorSpecifics = false;
  instrumentationUse: InstrumentationUse[] = [];

  equipmentTypes: EquipmentType[];

  disableEssayButtonDialog = true;
  essays: Essay[] = [];
  essaysCols = ['essayType', 'variables', 'criteria', 'load', 'edit', 'delete'];

  matcher = new MyErrorStateMatcher();
  form: FormGroup;

  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private protocolService: ProtocolService,
    private clientService: ClientService,
    private phaseService: PhaseService,
    private instrumentationService: InstrumentationService,
    private processService: ProcessService,
    private onlineService: OnlineService,
    private userService: UserService,
    private usersService: UsersService,
    private equipmentTypeService: EquipmentTypeService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id == null || isNaN(+id)) {
        this.cancel();
      }

      this.protocol = new Protocol();
      this.protocol.validator = new ValidatorSpecifics();
      if (+id === 0) {
        this.calibrateCriticalInstrumentation = false;

        this.spinnerService.hide();
      } else {
        this.protocolService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: Protocol) => {
          if (result != null) {
            this.protocol = result;

            if (this.protocol == null) {
              this.cancel();
              return;
            }

            this.protocolService.signInfo(this.protocol).subscribe((res: SignInfo[]) => this.pendingSigns = res);

            this.equipmentList = this.protocol.equipments || [];
            this.essays = this.protocol.essays || [];
            this.criticalInstr = this.protocol.criticalInstrumentations || [];
            this.protocol.referenceDocs = this.protocol.referenceDocs || [];
            this.protocol.referenceDocs?.sort((eq1, eq2) => {
              let order = eq1.id - eq2.id;

              if (order === 0) {
                order = eq1.id - eq2.id;
              }

              return order;
            });
            this.calibrateCriticalInstrumentation = (this.protocol.criticalInstrumentations || []).length > 0;

            this.showValidatorSpecifics = this.protocol.validator != null;

            if (this.attachmentsComponent instanceof QueryList) {
              this.attachmentsComponent = this.attachmentsComponent.first as ProtocolEditAttachmentsComponent;
            }

            this.attachmentsComponent.setIdProtocol(this.protocol.id);

            this.disableEssayButtonDialog = (this.protocol.idPhase == null);
            this.spinnerService.hide();
          } else {
            this.protocol = null;
          }
        }, () => {
          this.protocol = null;
          this.cancel();
        });

      }

    });

    this.form = fb.group({
      idClient: [this.protocol.idClient, [Validators.required]],
      idProcess: [this.protocol.idProcess, [Validators.required]],
      phase: [this.protocol.idPhase, [Validators.required]],
      projectNo: [this.protocol.projectNo, [Validators.required]],
      documentCode: [{ value: this.protocol.documentCode, disabled: true }],
      idInstrumentation: [this.protocol.idInstrumentation, [Validators.required]],
      clientDocumentCode: [this.protocol.clientDocumentCode],

      projectManager: [this.protocol.usernameProjectManager],

      regUser: [{ value: this.protocol.regUser, disabled: true }],
      modUser: [{ value: this.protocol.modUser, disabled: true }],
      regFc: [{ value: this.protocol.regFc, disabled: true }],
      modFc: [{ value: this.protocol.modFc, disabled: true }],
      currentVersion: [{ value: this.protocol.currentVersion, disabled: true }],
      signedVersion: [{ value: this.protocol.signedVersion, disabled: true }],
      observations: [{ value: this.protocol.observations }],
      lowTemp: [{ value: this.protocol.validator.lowTemp }],
      highTemp: [{ value: this.protocol.validator.highTemp }],
      verifTemp: [{ value: this.protocol.validator.verifTemp }],
      lowDesv: [{ value: this.protocol.validator.lowDesv }],
      highDesv: [{ value: this.protocol.validator.highDesv }],
      verifDesv: [{ value: this.protocol.validator.verifDesv }],
      minStability: [{ value: this.protocol.validator.minStability }],
      minStabilityTime: [{ value: this.protocol.validator.minStabilityTime }],

      department: [{ value: this.protocol.department }]
    });

  }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.currentUser = this.userService.currentProfile;

    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    }
    );

    this.phases = [];
    this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Phase[]) => {
      data = data.filter(d => d.enabled);
      this.phases = data;
    });

    this.instrumentationUse = [];
    this.instrumentationService.findAllProtocol().pipe(takeUntil(this.destroy$))
      .subscribe((data: InstrumentationUse[]) => this.instrumentationUse = data);

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);

    this.equipmentTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypes = res);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.audit.getAudit();
    }
  }

  openDialogEquipment(): void {
    const dialogRef = this.dialog.open(ProtocolEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: new Equipment(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Equipment) => {
      if (result != null) {
        this.equipmentList.push(result);
        this.equipmentTable.renderRows();
      }
    });
  }

  openDialogEditEquipment(idx: number): void {

    const eq = this.equipmentList[idx];

    const dialogRef = this.dialog.open(ProtocolEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Equipment) => {
      if (result != null) {
        this.equipmentList[idx] = result;
        this.equipmentTable.renderRows();
      }
    });
  }

  deleteEquipmentRow(index: number): void {
    if (index > -1) {
      this.equipmentList.splice(index, 1);

      this.equipmentTable.renderRows();
    }
  }

  getEquipmentType(eq: Equipment): string {
    const eqType = this.equipmentTypes.find(e => e.id === eq.idType);

    return eqType?.translation;
  }

  onInstrumentationChange(event: MatRadioChange): void {
    const instr = this.instrumentationUse.find(i => i.id === event.value);

    const validatorFields = ['lowTemp', 'highTemp', 'verifTemp', 'lowDesv', 'highDesv', 'verifDesv', 'minStability'];

    this.showValidatorSpecifics = instr.hasValidatorSpecifics;

    if (this.showValidatorSpecifics) {
      const validator = new ValidatorSpecifics();
      validator.lowDesv = 1;
      validator.highDesv = 1;
      validator.verifDesv = 0.5;
      validator.minStability = 0.2;

      this.protocol.validator = validator;

      validatorFields.forEach(field => {
        this.form.get(field).setValidators([Validators.required]);
        this.form.get(field).updateValueAndValidity();
      });
    } else {
      this.protocol.validator = null;

      validatorFields.forEach(field => {
        this.form.get(field).clearValidators();
        this.form.get(field).updateValueAndValidity();
      });

      this.form.updateValueAndValidity();
    }
  }

  openDialogCriticalInstrumentation(): void {
    const dialogRef = this.dialog.open(ProtocolEditCriticalInstrDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        criticalInstr: new CriticalInstrumentation(),
        currentCriticalInstrs: this.protocol.criticalInstrumentations,
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CriticalInstrumentation) => {
      if (result != null) {
        this.criticalInstr.push(result);

        this.criticalInstrTable.renderRows();
      }
    });
  }

  openDialogEditCriticalInstrumentation(idx: number): void {

    const crit = this.criticalInstr[idx];

    const dialogRef = this.dialog.open(ProtocolEditCriticalInstrDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        criticalInstr: _.cloneDeep(crit),
        currentCriticalInstrs: this.protocol.criticalInstrumentations,
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CriticalInstrumentation) => {
      if (result != null) {
        this.criticalInstr[idx] = result;

        this.criticalInstrTable.renderRows();
      }
    });
  }

  deleteCriticalInstrumentationRow(index: number): void {
    if (index > -1) {
      this.criticalInstr.splice(index, 1);

      this.criticalInstrTable.renderRows();
    }
  }

  onPhaseChange($event: MatSelectChange): void {
    const oldPhase = this.protocol.idPhase;
    const newPhase = $event.source.value as number;

    const isPhaseSelected = newPhase != null;
    this.disableEssayButtonDialog = !isPhaseSelected;

    if (oldPhase !== newPhase) {
      if (!ArrayUtils.isEmpty(this.protocol.essays)) {
        const message = 'Al cambiar la fase se borrarán los ensayos existentes. ¿Desea continuar?';
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
          if (result === true) {
            this.essays = [];
            this.essaysTable.renderRows();

            this.protocol.idPhase = newPhase;
          } else {
            $event.source.writeValue(oldPhase);
          }
        });
      } else {
        this.protocol.idPhase = newPhase;
      }
    }

  }

  newEssayOpenDialog(): void {
    const dialogRef = this.dialog.open(ProtocolEditEssayDialogComponent, {
      minWidth: '90vw',
      height: '95vh',
      data: {
        essay: new Essay(),
        isEdit: false,
        idProtocol: this.protocol.id,
        idPhase: this.protocol.idPhase,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Essay) => {
      if (result != null) {
        this.essays.push(result);

        this.essaysTable.renderRows();
      }
    });
  }

  editEssayOpenDialog(idx: number): void {

    const essay = this.essays[idx];

    const dialogRef = this.dialog.open(ProtocolEditEssayDialogComponent, {
      minWidth: '90vw',
      height: '95vh',
      data: {
        essay: _.cloneDeep(essay),
        isEdit: true,
        idProtocol: this.protocol.id,
        idPhase: this.protocol.idPhase,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Essay) => {
      if (result != null) {
        this.essays[idx] = result;

        this.essaysTable.renderRows();
      }
    });
  }

  getVariablesFromEssay(essay: Essay): string {
    return essay.essayValues.map(i => this.translate.instant('variable.' + i.translationVariable) as string).join(', ');
  }

  getValueFromEssay(essay: Essay): string {
    let res = '';

    const valueFields = [FieldEnum.SET_POINT, FieldEnum.TEMPERATURE];
    const rehearsalFields = [FieldEnum.REHEARSAL_TIME];

    essay.essayValues.forEach(eV => {

      if (res === '') {
        const valueField = eV.fields.find(f => valueFields.includes(f.idField));
        const rehearsalField = eV.fields.find(f => rehearsalFields.includes(f.idField));

        if (valueField != null && rehearsalField != null) {
          const rehearsal = FieldUtils.rehearsalToHHMMSS(rehearsalField.value as string, this.translate);
          res = ` (${valueField.value} ${eV.unitName}, ${rehearsal})`;
        } else if (valueField != null) {
          res = ` (${valueField.value} ${eV.unitName})`;
        }
      }

    });

    return res;
  }

  getCriteriaFromEssay(essay: Essay): string {
    if (!essay || !essay.essayValues) {
      return null;
    }

    return essay.essayValues.map(i => EssayUtils.getCriteriasToShowThermal(i, this.translate)).filter(f => f != null).join('; ');
  }

  getLoadFromEssay(essay: Essay): string {
    if (!essay || !essay.essayValues) {
      return null;
    }

    const fields = [];
    essay.essayValues.forEach(i => i.fields.filter(field => field.idField === FieldEnum.LOAD).forEach(field => fields.push(field.value)));

    return fields.join(', ');
  }

  deleteEssayRow(index: number): void {
    if (index > -1) {
      this.essays.splice(index, 1);

      this.essaysTable.renderRows();
    }
  }

  onCriticalInstrumentationChange(event: MatSlideToggleChange): void {
    this.calibrateCriticalInstrumentation = event.checked;
  }

  saveProtocol(): void {

    const errores: string[] = [];

    if (!this.form.valid) {
      errores.push(this.translate.instant('protocolEdit.form.error.notValid') as string);
    }

    if (this.equipmentList.length < 1) {
      errores.push(this.translate.instant('protocolEdit.form.error.anyEquipment') as string);
    }

    if (this.essays.length < 1) {
      errores.push(this.translate.instant('protocolEdit.form.error.anyEssay') as string);
    }

    if (this.calibrateCriticalInstrumentation) {
      if (this.criticalInstr.length < 1) {
        errores.push(this.translate.instant('protocolEdit.form.error.anyCritical') as string);
      }
    }

    if (errores.length === 0) {

      if (this.protocol.id == null) {
        this.save();
      } else {

        const showReason = (+this.protocol.signedVersion) !== 0;

        if (showReason) {

          const dialogRef = this.dialog.open(ProtocolEditConfirmSaveComponent, {
            minWidth: '30vw',
            data: {
              protocol: this.protocol,
              reason: this.protocol.reason
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {
            if (result != null) {
              this.protocol.reason = result.reason;
              this.save();
            }
          });

        } else {
          this.save();
        }

      }

    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  manualSign(): void {
    this.prepareToSave();

    const dialogRef = this.dialog.open(ProtocolEditDialogManualSignComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.spinnerService.hide();

      if (result != null) {
        this.clientService.findOne(this.protocol.idClient).subscribe((client: Client) => {

          if (client.idGroup != null) {
            void this.cloneRow(this.protocol.id, client.idGroup).then(() => this.reloadPage());
          } else {
            this.reloadPage();
          }

        });
      }
    });

  }

  automaticSign(): void {
    this.spinnerService.show();

    const dialogRef = this.dialog.open(ProtocolEditDialogAutomaticSignComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result != null) {
        if (result != null) {
          this.clientService.findOne(this.protocol.idClient).subscribe((client: Client) => {

            if (client.idGroup != null) {
              void this.cloneRow(this.protocol.id, client.idGroup).then(() => this.reloadPage());
            } else {
              this.reloadPage();
            }

          });
        }
      } else {
        this.spinnerService.hide();
      }
    });

  }

  downloadPdf(): void {
    this.dialog.open(ProtocolEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('thermal/protocols');
  }

  openNewRefDoc(): void {

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {

    const refDoc = this.protocol.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.protocol.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.automaticSign;
  }

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.manualSign && ArrayUtils.isEmpty(this.pendingSigns);
  }

  showRevertSign(): boolean {
    const resultRol = this.currentUser?.profiles?.filter(e => e.idRole === RoleEnum.MANAGER || e.idRole === RoleEnum.ADMIN);
    return this.protocol.idStatus === ProtocolStatus.FIRMADO && (resultRol != null && resultRol !== undefined);
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return this.protocol.id != null && allowedStatuses.includes(this.protocol.idStatus);
  }

  editDocumentCode(): void {
    const dialogRef = this.dialog.open(EditDocumentCodeEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        documentCode: this.protocol.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {
        this.protocol.documentCode = result;
      }
    });
  }

  hasEquip(): boolean {
    return !this.protocol.equipments.length && !this.equipmentList.length;
  }

  revertSign(): void {

    if (this.protocol.currentlyUsed) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('protocolEdit.form.revertSign.isInUse') as string,
          canCancel: false,
          messageOk: this.translate.instant('button.accept') as string
        }
      });

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result === true) {
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });
          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.protocolService.revertSign(this.protocol.id, reason, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.spinnerService.hide();
              this.reloadPage(this.protocol.id);
            });
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.form.revertSign.error') as string);
            }
          });
        });
      }
    });

  }

  canCreateNewExecution(): boolean {
    const allowedStatuses = [ProtocolStatus.FIRMADO];

    return allowedStatuses.includes(this.protocol.idStatus);
  }

  createNewExecution(): void {
    if (this.protocol.id != null) {
      void this.router.navigateByUrl(`thermal/execution?id=0&${Constants.FIELD_ID_PROTOCOL}=${this.protocol.id}`);
    }
  }

  private canSign(): boolean {
    const allowedStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return this.protocol != null && allowedStatuses.includes(this.protocol.idStatus);
  }

  private prepareToSave() {
    this.protocol.equipments = this.equipmentList;
    this.protocol.essays = this.essays;

    if (this.calibrateCriticalInstrumentation) {
      this.protocol.criticalInstrumentations = this.criticalInstr;
    } else {
      this.protocol.criticalInstrumentations = [];
    }
  }

  private reloadPage(idProtocol = this.protocol.id) {
    if (idProtocol == null) {
      idProtocol = this.protocol.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`thermal/protocol?id=${idProtocol}`);
  }

  private save() {
    const isNew = this.protocol.id == null;

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {

        this.spinnerService.show();

        this.prepareToSave();

        this.protocolService.save(this.protocol, token).pipe(takeUntil(this.destroy$)).subscribe((res: Protocol) => {
          this.protocol = res;
          this.reloadPage();

          if (isNew) {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);

            void this.router.navigateByUrl(`thermal/protocol?id=${res.id}`);
          } else {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.update.ok') as string);
          }

          this.spinnerService.hide();
        }, err => {
          if (err != null && err.error != null && typeof err.error === 'string') {
            this.snackBarService.sendError(err.error as string);
          } else if (isNew) {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.create.error') as string);
          } else {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.update.error') as string);
          }

          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }


  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.protocolService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.protocolService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }

}
