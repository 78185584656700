import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalibrationVariableMultichannel } from '../../../../model/calibration';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../services/snackBar.service';

export interface DialogDataMultichannel {
  data: CalibrationVariableMultichannel[];
}
@Component({
  selector: 'app-calibration-edit-multichannel',
  templateUrl: './calibration-edit-multichannel.component.html',
  styleUrls: ['./calibration-edit-multichannel.component.scss']
})
export class CalibrationEditMultichannelComponent implements OnInit {

  calibrationVariableMultichannel: CalibrationVariableMultichannel[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataMultichannel,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CalibrationEditMultichannelComponent>) { }

  ngOnInit(): void {
  }

  onOkClick(): void {
    const vString = this.data?.data.filter(e => !e.description);
    const errs = [];

    if (vString?.length > 0) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.multichannel.dialog.description.error'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.data);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
