<mat-card appearance="outlined" class="card" [hidden]="execution == null">
  <mat-card-content>
    <mat-toolbar color="secondary">
      {{ 'executionEdit.attachments.title' | translate }}

      <button mat-icon-button color="accent" (click)="attachmentUpload.click()"
        *ngIf="execution != null && showUploadButton()">
        <mat-icon>backup</mat-icon>
      </button>
      <input hidden type="file" multiple (change)="uploadAttachment($event)" #attachmentUpload>
    </mat-toolbar>

    <table #executions mat-table matSort [dataSource]="dataSource" style="width: 100%;" cdkDropList
      [cdkDropListData]="dataSource" (cdkDropListDropped)="drop($event)">

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionEdit.attachments.cols.date' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ item.date | fixDateTime }} </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'protocolEdit.attachments.cols.name' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
      </ng-container>

      <!-- filename Column -->
      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'executionEdit.attachments.cols.filename' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ item.filename }} </td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'executionEdit.attachments.cols.type' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ 'attachmentType.' + item.translationType | translate }} </td>
      </ng-container>

      <!-- Button Edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="editAttachment(item)">
            <mat-icon>mode_edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Button download Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef> {{ 'button.download' | translate }} </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="downloadAttachment(item.id, item.filename, item.idExecution)">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Button delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button (click)="deleteAttachment(item.id, item.idExecution)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row">
        <span *cdkDragPreview>{{ row.name }}</span>
      </tr>

      <div class="placeholder" *cdkDragPlaceholder></div>

    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons [length]="dataSource.totalItems$ | async"
      lang="es">
    </mat-paginator>

  </mat-card-content>

</mat-card>