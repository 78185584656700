import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { AppType } from 'src/app/model/appType';
import { AppTypeService } from 'src/app/services/appType.service';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { Constants } from 'src/app/utils/constants';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { Field } from 'src/app/model/field';
import { GenericClass } from 'src/app/model/genericClass';
import { InternalEquipmentCustomField } from 'src/app/model/internalEquipmentCustomField';
import { InternalEquipmentCustomFieldsService } from 'src/app/services/internalEquipmentCustomFields.service';
import { InternalEquipmentFilter } from 'src/app/model/internalEquipment';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentFolderService } from 'src/app/services/internalEquipmentFolder.service';
import { InternalEquipmentStatusEnum } from 'src/app/model/internalEquipmentStatus';
import { InternalEquipmentStatusService } from 'src/app/services/internalEquipmentStatus.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionListChange } from '@angular/material/list';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { takeUntil } from 'rxjs/operators';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CalibrationResponsibleService } from '../../../services/calibrationResponsible.service';
import { FieldTypeEnum } from '../../../model/fieldType';
import { InternalEquipmentCustomSelectValueService } from '../../../services/internalEquipmentCustomSelectValue.service';
import { InternalEquipmentCustomSelectValue } from '../../../model/internalEquipmentCustomSelectValue';
import { UsersService } from '../../../services/users.service';
import { AppTypeEnum } from '../../../model/appType';
import { Variable } from '../../../model/variable';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-internal-equipment-list-filter',
  templateUrl: './internal-equipment-list-filter.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class InternalEquipmentListFilterComponent implements OnInit, OnDestroy {
  @Output() filterEmitter = new EventEmitter<InternalEquipmentFilter>();

  currentUser: User;

  filter: InternalEquipmentFilter;

  statuses: GenericClass[];
  calibrationResponsibles: GenericClass[];

  clients: Client[];
  clientsFiltered: Client[];

  variables: Variable[];
  appTypes: AppType[];

  folders: InternalEquipmentFolder[];
  customFields: InternalEquipmentCustomField[];

  expandedFilter = true;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  customSelects: InternalEquipmentCustomSelectValue[] = [];

  isExternal = false;
  isInternal = false;

  users: User[];
  usersFiltered: User[];

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private internalEquipmentStatusService: InternalEquipmentStatusService,
    private variableTypeService: VariableTypeService,
    private appTypeService: AppTypeService,
    private clientService: ClientService,
    private folderService: InternalEquipmentFolderService,
    private internalEquipmentCustomFieldsService: InternalEquipmentCustomFieldsService,
    private calibrationResponsibleService: CalibrationResponsibleService,
    private userService: UserService,
    private usersService: UsersService,
    private internalEquipmentCustomSelectValueService: InternalEquipmentCustomSelectValueService) {
    this.currentUser = this.userService.currentProfile;

    this.isExternal = this.router.url.toLowerCase().includes('external');
    this.isInternal = this.router.url.toLowerCase().includes('internal');

    this.cleanFilter();
  }

  ngOnInit(): void {
    this.statuses = [];
    this.internalEquipmentStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => {
      if (!this.currentUser.requireProcessApproval) {
        data = data.filter(d => d.id !== InternalEquipmentStatusEnum.PENDING_REVIEW);
      }

      this.statuses = data;
    });

    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => this.clients = data);

    this.variables = [];
    this.variableTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Variable[]) => this.variables = data);

    this.appTypes = [];
    this.appTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: AppType[]) => this.appTypes = data);

    this.folders = [];
    this.folderService.getAll().pipe(takeUntil(this.destroy$)).subscribe((data: InternalEquipmentFolder[]) => this.folders = data);

    this.customFields = [];
    this.internalEquipmentCustomFieldsService.findAll().subscribe(data => {
      this.customFields = (data.content as InternalEquipmentCustomField[]).filter(f => f.filtrable);
      this.customFields?.forEach(f => {
        if (f.idType === FieldTypeEnum.SELECT_ONE) {
          this.internalEquipmentCustomSelectValueService.findByIdField(f.id).subscribe(customSelect => {
            f.valueSelect = customSelect;
            this.customSelects = customSelect;
          })
        }
      });
      const customFieldsResult: string = localStorage.getItem('customFields');
      if (customFieldsResult) {
        const filterRe = new Map(Object.entries(JSON.parse(customFieldsResult) as string));
        filterRe?.forEach((val, key) => {
          this.customFields?.filter(e => {
            if (e.id === Number(key)) {
              e.value = val;
            }
          });
        });
        this.filter.customFields = filterRe;
      } else {
        this.filter.customFields = new Map<string, number>();
      }
    });

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.filter.idFolder = +params[Constants.FIELD_ID_FOLDER];
    });

    this.calibrationResponsibleService.findAll().pipe(takeUntil(this.destroy$))
        .subscribe((data: GenericClass[]) => this.calibrationResponsibles = data);

    this.usersService.findAllFromGroupAppType(AppTypeEnum.CALIBRATES).pipe(takeUntil(this.destroy$))
        .subscribe((data: User[]) => {
          this.users = data;
          this.usersFiltered = this.users;});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new InternalEquipmentFilter();
    this.filter.internal = this.isInternal;
    this.filter.external = this.isExternal;

    const filterResult =  localStorage.getItem('filter-equipment');
    if (filterResult) {
      this.filter = JSON.parse(filterResult) as InternalEquipmentFilter;
    }
  }

  public setValue(field: InternalEquipmentCustomField, index: number, value: string | MatCheckboxChange | MatSelectionListChange): void {

    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map(i => i.value as string).join(';');
    }

    this.setValueFilter(index, field.id, value);
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {
    const dateValue = event.value;
    if (dateValue != null) {
      /* while (dateValue.getFullYear() < 1970) {
       dateValue.setFullYear(dateValue.getFullYear() + 100);
       } */
      this.filter[field] = new Date(dateValue);
    } else {
      this.filter[field] = null;
    }
  }

  getFolderName(item: InternalEquipmentFolder): string {
    if (item == null) {
      return '';
    }

    let prefix = '';
    if (item.idFather != null) {
      const parent = this.folders.find(f => f.id === item.idFather);
      prefix = this.getFolderName(parent).concat(' > ');
    }

    return prefix.concat(item.name);
  }

  private setValueFilter(index: number, idField: number, value: string): void {
    const customFields: Map<number, string> = this.filter.customFields as Map<number, string>;

    if (index == null || index < 0) {
      customFields.set(idField, value);
      return;
    }

    const val = customFields.get(idField);

    if (Array.isArray(val)) {
      while (val.length < index) {
        val.push('');
      }

      val[index] = value;
      customFields.set(idField, val.join(Field.separator));
    }
  }

}
