import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  IsothermalCharacterization,
  IsothermalCharacterizationFilter,
  IsothermalCharacterizationStatus
} from 'src/app/model/isothermalCharacterization';
import { Location, ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Equipment } from 'src/app/model/equipment';
import { EquipmentUtils } from 'src/app/utils/equipmentUtils';
import { GenericWarnError } from 'src/app/model/genericClass';
import { IsothermalCharacterizationDataSource } from 'src/app/model/isothermalCharacterizationDataSource';
import {
  IsothermalCharacterizationEditGenerateReportComponent
} from '../isothermal-characterization-edit/isothermal-characterization-edit-generate-report.component';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OnlineService } from 'src/app/services/online.service';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { saveAs } from 'file-saver';
import { GlobalStateService } from '../../../services/globalState.service';

@Component({
  selector: 'app-isothermal-characterization-list',
  templateUrl: './isothermal-characterization-list.component.html'
})
export class IsothermalCharacterizationListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: IsothermalCharacterizationDataSource;
  filter: IsothermalCharacterizationFilter = new IsothermalCharacterizationFilter();

  currentUser: User;

  disableOfflineButton = false;

  hasFiltered = false;
  showCanCloneCharacterization = false;

  displayedColumns: string[] = [
    'documentCode', 'client', 'equipment', 'serialNum', 'internalId', 'projectNo', 'status', 'department', 'endWorkDate', 'realizationDate',
    'download', 'edit', 'delete', 'clone', 'more', 'clone_characterization'
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private onlineService: OnlineService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private themeService: ThemeService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private globalStateService: GlobalStateService) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('isothermalCharacterizationList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;

      this.loadIsothermalCharacterizationList();
    });

    const excludedCols: string[] = [];

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    if (!this.currentUser.showCanCloneCharacterization) {
      this.showCanCloneCharacterization = true;
    }

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));

    this.disableOfflineButton = !this.onlineService.latestOnline;
    const filterResult = localStorage.getItem('filter');
    if (filterResult) {
      this.hasFiltered = true;
      this.filter = JSON.parse(filterResult) as IsothermalCharacterizationFilter;
    }
    this.loadIsothermalCharacterizationList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadIsothermalCharacterizationList()))
      .pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = 'calibrates/isothermalCharacterization?id=0';
    } else if (this.router.url.includes('thermal')) {
      url = 'thermal/isothermalCharacterization?id=0';
    }
    void this.router.navigateByUrl(url);
  }

  editRow(id: number): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/isothermalCharacterization?id=${id}`
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/isothermalCharacterization?id=${id}`
    }
    void this.router.navigateByUrl(url) as string;
  }

  deleteRow(id: number): void {

    if (id > 0) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('internalEquipmentList.dialog.delete.confirmation') as string
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {

          this.spinnerService.show();

          this.isothermalCharacterizationService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.spinnerService.hide();

            this.loadIsothermalCharacterizationList();
          });
        }
      });
    }
  }

  canClone(isothermalCharacterization: IsothermalCharacterization): boolean {
    return isothermalCharacterization?.idStatus === IsothermalCharacterizationStatus.FIRMADO;
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.isothermalCharacterizationService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              this.spinnerService.show();
              this.isothermalCharacterizationService.copyToGroup(id, idGroup).subscribe(() => {
                this.spinnerService.hide();

                this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.clone.ok') as string);
              }, () => {
                this.spinnerService.hide();
                this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.clone.error') as string);
              });
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.clone.error') as string);
        });
      }
    });
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

  doFilter(event: IsothermalCharacterizationFilter): void {
    this.filter = event;

    this.hasFiltered = true;
    this.paginator.pageIndex = 0;
    this.loadIsothermalCharacterizationList();
  }

  loadIsothermalCharacterizationList(): void {
    this.dataSource = new IsothermalCharacterizationDataSource(this.isothermalCharacterizationService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadIsothermalCharacterizations(this.filter);
    }

    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  showDownloadButton(isothermalCharacterization: IsothermalCharacterization): boolean {
    const validStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA, IsothermalCharacterizationStatus.FIRMADO];

    return validStatuses.includes(isothermalCharacterization.idStatus);
  }

  showDeleteButton(isothermalCharacterization: IsothermalCharacterization): boolean {
    const validStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return validStatuses.includes(isothermalCharacterization.idStatus);
  }

  downloadPdf(isothermalCharacterization: IsothermalCharacterization): void {
    this.dialog.open(IsothermalCharacterizationEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        isothermalCharacterization,
        reason: isothermalCharacterization.reason,
        hasToLoadData: true
      }
    });
  }

  showEquipment(eqs: Equipment[]): string {
    return EquipmentUtils.showEquipmentsShort(eqs);
  }

  showSerialNumEquipment(eqs: Equipment[]): string {
    const res = [];
    eqs.forEach(item => {
      res.push(item.serialNum);
    });

    return res.join('\n');
  }

  showInternalIdEquipment(eqs: Equipment[]): string {
    const res = [];
    eqs.forEach(item => {
      res.push(item.internalId);
    });

    return res.join('\n');
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.spinnerService.show();
    this.isothermalCharacterizationService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  cancel(): void {
    const url = this.router.url
    if (url?.includes('calibrates')) {
      this.globalStateService.setHomeCalibratesState('calibrates-state');
      void this.router.navigate(['calibrates']);
    } else {
      this.globalStateService.setHomeThermalState('thermal-state');
      this.router.onSameUrlNavigation = 'reload';
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      void this.router.navigate(['thermal']);
    }
  }

  saveSearchFilter(execution: IsothermalCharacterizationFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  cloneCharacterization(id: number): void {
    this.spinnerService.show();
    this.isothermalCharacterizationService.cloneCharacterization(id).subscribe(data => {
      if (data) {
        let url: string;
        if (this.router.url.includes('calibrates')){
          url = `calibrates/isothermalCharacterization?id=${data}`
        } else if (this.router.url.includes('thermal')) {
          url = `thermal/isothermalCharacterization?id=${data}`
        }

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        void this.router.navigateByUrl(url);
        this.spinnerService.hide();
      }
    }, () => {
      this.spinnerService.hide();
      this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.clone.error') as string);
    });
  }
}
