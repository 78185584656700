<div class="main-container">
    <form>

        <div class="toolbar-list">

            <div class="toolbar-list-title">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <h1>{{ 'sendNotifications.title' | translate }}</h1>
                </div>
                <div class="breadcrumbs">
                    <a href="#">{{ 'common.start.label' | translate }}</a> /
                    <span>{{ 'sendNotifications.title' | translate }}</span>
                </div>
            </div>

            <div class="toolbar-back-button">
                <a (click)="cancel()" mat-button>
                    <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
                </a>
            </div>
        </div>

        <div class="table-container" style="overflow: hidden;">
            <div fxLayout="column">
                <div fxFlex>
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'sendNotifications.subject.label' | translate }}" name="subject"
                            [(ngModel)]="notification.subject" required>
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <mat-form-field class="campoFormulario">
                        <textarea matInput matInput placeholder="{{ 'sendNotifications.message.label' | translate }}"
                            name="message" [(ngModel)]="notification.text" required rows="10"></textarea>
                    </mat-form-field>
                </div>

                <br /><br />

                <div fxFlex>
                    <mat-form-field class="campoFormulario" appearance="fill">
                        <mat-label> {{ 'sendNotifications.notifiedUsers.label' | translate }} </mat-label>
                        <mat-chip-grid #chipList aria-label="{{ 'sendNotifications.notifiedUsers.label' | translate }}">
                            <mat-chip-row *ngFor="let user of notification.notifiedUsers" selectable removable required
                                (removed)="remove(user)">
                                {{ user }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip-row>
                            <input #userInput [formControl]="userCtrl" [matAutocomplete]="auto"
                                [matChipInputFor]="chipList" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-grid>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let user of filteredUsers | async" [value]="user.username">
                                {{ user.username }} ({{ user.fullName }})
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div fxFlex>
                    <span> {{ 'sendNotifications.sendMail.label' | translate }} </span>
                    <mat-radio-group [(ngModel)]="notification.sendMail" name="sendMail">
                        <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
                        <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <div class="table-footer-container">
            <div fxLayout="row" style="width: 100%;">
                <div fxFlex></div>
                <button mat-raised-button color="primary" (click)="sendNotification()">
                    {{ 'button.send' | translate }}</button>
                <div fxFlex="10px"></div>
                <button mat-raised-button color="secondary" (click)="clear()">
                    {{ 'button.clear' | translate }}</button>
            </div>
        </div>
    </form>
</div>