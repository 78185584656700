import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionConfirmPasswordComponent } from 'src/app/components/shared/action-confirm-password/action-confirm-password.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { ReasonDialogComponent } from 'src/app/components/shared/reason-dialog/reason-dialog.component';
import { ProtocolReportFilter } from 'src/app/model/attachment';
import { PendingSignStatusEnum } from 'src/app/model/pendingSignStatus';
import { PendingSignType, PendingSignTypeEnum } from 'src/app/model/pendingSignType';
import { ProtocolQ } from 'src/app/model/protocol';
import { SignInfo } from 'src/app/model/sign';
import { SignersInfo } from 'src/app/model/signatureDocumentUsers';
import { User } from 'src/app/model/user';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { PendingSignTypeService } from 'src/app/services/pendingSignType.service';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { DateUtils } from 'src/app/utils/dateUtils';
import { GroupEnum } from 'src/app/utils/groupUtils';
import { ProtocolUtils } from 'src/app/utils/protocolUtils';

export interface DialogDataAutomaticSignProtocol {
  protocol: ProtocolQ;
}

@Component({
  selector: 'app-protocol-q-edit-dialog-automatic-sign',
  templateUrl: './protocol-q-edit-dialog-automatic-sign.component.html'
})
export class ProtocolQEditDialogAutomaticSignComponent implements OnInit, OnDestroy {

  respDocs: User[];
  respDocsFiltered: User[];

  reportConfig: ProtocolReportFilter;
  langs: LangFlag[];

  signTypes: PendingSignType[];
  signTypeChecked = new Map<number, boolean>();
  disableSignType = false;

  showLang = false;
  nextSign: string;

  currentUser: User;

  canRefuse = false;

  selectedGroup: number;

  displayedColsUsers = ['fullname', 'username', 'email'];

  signersDatasource: SignersInfo[];
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ProtocolQEditDialogAutomaticSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAutomaticSignProtocol,
    private protocolQService: ProtocolQService,
    private userService: UserService,
    private usersService: UsersService,
    private manageUsersService: ManageUsersService,
    private pendingSignType: PendingSignTypeService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.currentUser = this.userService.currentProfile;
  }

  ngOnInit(): void {
    this.spinnerService.show();

    const currentProfile = this.userService.currentProfile;

    this.selectedGroup = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;

    this.langs = this.translationService.configuredLangs;

    this.reportConfig = new ProtocolReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;

    this.signTypes = [];
    this.pendingSignType.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: PendingSignType[]) => this.signTypes = res);

    this.protocolQService.signInfo(this.data.protocol).pipe(takeUntil(this.destroy$)).subscribe((inf: SignInfo[]) => {
      this.spinnerService.hide();

      inf.forEach(i => {
        i.date = DateUtils.anyToDate(i.date);
      });

      const anySign = !ArrayUtils.isEmpty(inf);
      let usersToRemove = [];

      this.signTypeChecked.set(PendingSignTypeEnum.REVIEW, true);
      this.signTypeChecked.set(PendingSignTypeEnum.APPROVED, false);

      if (!anySign) {
        this.showLang = true;
      } else {
        const anyApprove = anySign && inf.map(i => i.idType).includes(PendingSignTypeEnum.APPROVED);

        if (anyApprove) {
          this.disableSignType = true;
          this.signTypeChecked.set(PendingSignTypeEnum.REVIEW, false);
          this.signTypeChecked.set(PendingSignTypeEnum.APPROVED, true);
        }

        usersToRemove = inf.map(i => i.username);

        const lastDate = Math.max.apply(Math, inf.filter(s => s.idStatus === PendingSignStatusEnum.PENDING).map(s => s.date));
        const lastSign = inf.filter(s => s.idStatus === PendingSignStatusEnum.PENDING).find(i => i.date.getTime() === lastDate);
        const isLastSign = lastSign != null && (lastSign.username === this.currentUser.username);

        if (anySign && usersToRemove.includes(this.currentUser.username) && !isLastSign) {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.alreadySign') as string);
          this.onNoClick();
          this.spinnerService.hide();
          return;
        }

        if (anySign && !isLastSign) {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.notLastSign') as string);
          this.onNoClick();
          this.spinnerService.hide();
          return;
        }

        this.canRefuse = isLastSign;
      }

      this.usersService.findAllUserToSignFromGroup().pipe(takeUntil(this.destroy$)).subscribe((item: User[]) => {
        const sorted = item.filter(u => !usersToRemove.includes(u.username) && u.username !== this.currentUser.username)
          .sort((v1, v2) => v1.fullName.localeCompare(v2.fullName));
        this.respDocs = sorted;
        this.respDocsFiltered = sorted.filter(u => u.fullName !== this.currentUser.fullName);

        this.spinnerService.hide();
      });

    }, () => {
      this.spinnerService.hide();
      this.onNoClick();
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNextSignChange(event: MatSelectChange): void {
    this.nextSign = event.value;
  }

  isSignTypeChecked(item: PendingSignType): boolean {
    return this.signTypeChecked.get(item.id) || false;
  }

  onChangeSignTypeCheck(event: MatCheckboxChange): void {
    const signType = event.source.value as unknown as PendingSignType;

    this.signTypeChecked.set(signType.id, event.checked);
  }

  getOkLabel(): string {
    let label = 'button.sign';

    if (this.nextSign != null) {
      label = 'button.sendSign';
    }

    return this.translate.instant(label) as string;
  }

  onOkClick(hybride: boolean): void {
    this.spinnerService.show();

    const errors = [];

    const checks: number[] = [];

    this.signTypeChecked.forEach((value, key) => {
      if (value) {
        checks.push(key);
      }
    });

    if (ArrayUtils.isEmpty(checks)) {
      errors.push(this.translate.instant('protocolEdit.dialog.generateReport.form.error.anySignReason'));
    }

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      this.spinnerService.hide();
      return;
    }

    const protocol = this.data.protocol;

    if (this.nextSign == null) {
      const message = this.translate.instant('protocolEdit.dialog.generateReport.form.isLastSign') as string;

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response === true) {
         if (protocol.idClient) {
          this.manageUsersService.checkIfHasUser(protocol.idClient).subscribe(hasUser => {
            this.spinnerService.hide();
            if(!hasUser && !(this.selectedGroup === GroupEnum.QUALIPHARMA)) {
              this.snackBarService.sendError(this.translate.instant('manageUsers.dialog.clientHasUsers.error') as string);
            } else {
              this.requestSign((token) => {
                this.spinnerService.show();
                if (hybride) {
                  this.reportConfig.lang = 'es_en';
                }
                this.protocolQService.automaticSign(protocol, token, checks, this.reportConfig.lang).pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                  this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.sign.ok') as string);
                  this.protocolQService.downloadPdfVersion(protocol.id, protocol.currentVersion).pipe(takeUntil(this.destroy$))
                    .subscribe((file: Blob) => {
                      this.spinnerService.hide();
                      const name = ProtocolUtils.getNameForProtocolQualificates(protocol, this.translate);
                      saveAs(file, name);
                      this.dialogRef.close(this.data);
                    }, err => {
                      if (err != null && err.error != null && typeof err.error === 'string') {
                        this.snackBarService.sendError(err.error as string);
                      } else {
                        this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
                      }
                      this.spinnerService.hide();
                    });
                }, err => {
                  if (err != null && err.error != null && typeof err.error === 'string') {
                    this.snackBarService.sendError(err.error as string);
                  } else {
                    this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
                  }
                  this.spinnerService.hide();
                });
              });
            }
          });
         } else {
          this.requestSign((token) => {
            this.spinnerService.show();
            if (hybride) {
              this.reportConfig.lang = 'es_en';
            }
            this.protocolQService.automaticSign(protocol, token, checks, this.reportConfig.lang).pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.spinnerService.hide();
              this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.sign.ok') as string);

              this.protocolQService.downloadPdfVersion(protocol.id, protocol.currentVersion).pipe(takeUntil(this.destroy$))
                .subscribe((file: Blob) => {
                  const name = ProtocolUtils.getNameForProtocolQualificates(protocol, this.translate);
                  saveAs(file, name);
                  this.dialogRef.close(this.data);
                });

            }, err => {
              if (err != null && err.error != null && typeof err.error === 'string') {
                this.snackBarService.sendError(err.error as string);
              } else {
                this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
              }
              this.spinnerService.hide();
            });
          });
         }
        } else {
          this.spinnerService.hide();
        }
      });

    } else {
      this.requestSign((token) => {
        this.spinnerService.show();

        this.protocolQService.addSign(protocol, token, checks, this.nextSign, this.reportConfig.lang).pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.dialog.generateReport.form.addSign.ok') as string);

            this.spinnerService.hide();

            this.dialogRef.close(this.data);
          }, () => {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.addSign.error') as string);
            this.spinnerService.hide();
          });
      });
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  refuseSign(): void {
    this.requestReason((reason) => {
      this.requestSign((result) => {
        this.spinnerService.show();

        this.protocolQService.refuseToSign(this.data.protocol, result, reason).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.dialog.generateReport.form.reject.ok') as string);

          this.spinnerService.hide();

          this.dialogRef.close(this.data);
        }, () => {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.reject.error') as string);
          this.spinnerService.hide();
        });
      });
    });

  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  addSigners() {
    if (!this.signersDatasource) {
      this.signersDatasource = [];
    }

    this.signersDatasource.push(new SignersInfo());
  }

  private requestSign(callback: (token: string) => void) {
    this.spinnerService.hide();

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        title: 'protocolAuditAction.refuseSign'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

}
