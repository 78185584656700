<mat-tab-group style="width: 100%;" [selectedIndex]="_essayIndex" (focusChange)="onEssayTabChange($event.index)"
  dynamicHeight>

  <mat-tab *ngFor="let essay of _essays; let indexEssay = index" style="min-height: 50vh;">

    <ng-template mat-tab-label>
      <span class="tab-text"> {{essay.active ? '' : '🚫'}} {{'essayType.' + essay.translationType | translate}} </span>
      <span class="tab-text" *ngIf="displayCycle(essay)"> {{ 'executionEdit.essays.cicle' | translate }}
        {{essay.numCycle}} </span>
    </ng-template>

    <div>

      <mat-checkbox class="example-margin" (change)="onEssayActiveChange(essay, $event)" [value]="item"
        [checked]="essay.active">
        {{'executionEdit.essays.active.title' | translate}}
      </mat-checkbox>

      <p *ngIf="!essay.active">
        <mat-label>{{'executionEdit.essays.active.reason' | translate}}</mat-label>
        <textarea rows="5" matAutosizeMinRows="5" matInput required [(ngModel)]="essay.reasonActive"
          name="reason" required style="resize: none;" (change)="onEssayActiveReasonChange(essay, $event)"></textarea>
      </p>
    </div>


    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'executionEdit.essays.additionalInfo' | translate }}
      </mat-toolbar>

      <div fxLayout="column">
        <div fxFlex="100%">
          <mat-form-field class="campoFormulario" style="width: 100%">
            <mat-label>{{ 'executionEdit.essays.title.label' | translate }}</mat-label>
            <input matInput [(ngModel)]="essay.title"
              name="essayTitle{{essay.idVariable}}">
          </mat-form-field>
        </div>
        <div fxFlex="100%">
          <mat-form-field class="campoFormulario" style="width: 100%">
            <mat-label>{{ 'executionEdit.essays.additionalInfo' | translate }}</mat-label>
            <textarea rows="5" matAutosizeMinRows="5" matInput [(ngModel)]="essay.additionalInfo"
              name="additionalInfo{{essay.idVariable}}" style="resize: none;"></textarea>
          </mat-form-field>
        </div>
        <div fxFlex="100%">
          <mat-form-field class="campoFormulario" style="width: 100%">
            <mat-label>{{ 'executionEdit.essays.additionalInfoProtocol' | translate }}</mat-label>
            <textarea rows="5" matAutosizeMinRows="5" matInput
              [ngModel]="essay.additionalInfoProtocol" name="additionalInfoProtocol{{essay.idVariable}}"
              style="resize: none;" disabled></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>

    <mat-tab-group style="width: 100%;" [selectedIndex]="_variableIndex"
      (focusChange)="onVariableTabChange($event.index)" *ngIf="essay.active" dynamicHeight>

      <mat-tab *ngFor="let tab of essay.essayValues; let indexVariable = index"
        label="{{'variable.' + tab.translationVariable | translate }}" style="min-height: 50vh;">

        <mat-toolbar *ngIf="essay.essayValues[indexVariable].fields.length > 0" color="secondary">
          {{ 'executionEdit.essays.fieldsTitle' | translate }}
        </mat-toolbar>

        <br />

        <div fxLayout="row">
          <div fxFlex="33" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ 'protocolEdit.dialog.essay.unit.label' | translate }}</mat-label>
              <mat-select name="unit{{tab.idVariable}}" [(ngModel)]="tab.idUnit" required
                (selectionChange)="onUnitChange(indexVariable, $event)" [disabled]="!enableEditCriteria()">
                <mat-option *ngFor="let item of variableUnits" [value]="item.id">
                  {{item.unit}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ 'protocolEdit.dialog.essay.unit.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" class="flexwrap">
          <div fxFlex="50" fxFlex.lt-md="100%"
            *ngFor="let field of essay.essayValues[indexVariable].fields; let indexField = index">

            <!-- STRING -->
            <div *ngIf="field.idTypeField == 1">
              <mat-form-field class="campoFormulario" style="width: 90%">
                <mat-label>{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}</mat-label>
                <input matInput disabled="true"
                  [name]="field.translationField" [ngModel]="field.value" [errorStateMatcher]="matcher"
                  (ngModelChange)="setValue(tab.idVariable, field, -1, $event)" [required]="field.required">
                <mat-error>
                  {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                </mat-error>
              </mat-form-field>
              <button mat-icon-button *ngIf="enableEditField(field)"
                (click)="openEditField(indexEssay, indexVariable, indexField)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <!-- NUMÉRICO -->
            <div *ngIf="field.idTypeField == 2">
              <mat-form-field class="campoFormulario" style="width: 90%">
                <mat-label>{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}</mat-label>
                <input matInput disabled="true"
                  [name]="field.translationField" [ngModel]="field.value" [errorStateMatcher]="matcher"
                  (ngModelChange)="setValue(tab.idVariable, field, -1, $event)" type="number"
                  [required]="field.required">
                <mat-error>
                  {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                </mat-error>
              </mat-form-field>
              <button mat-icon-button *ngIf="enableEditField(field)"
                (click)="openEditField(indexEssay, indexVariable, indexField)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <!-- TOLERANCIA -->
            <div *ngIf="field.idTypeField == 3">
              <div fxFlex="30%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}</mat-label>
                  <input matInput disabled="true"
                    [name]="field.translationField" [(ngModel)]="field.value[0]"
                    (ngModelChange)="setValue(tab.idVariable, field, 0, $event)" [errorStateMatcher]="matcher"
                    type="number" [required]="field.required">
                  <mat-error>
                    {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="30%" fxFlex.lt-md="100%">
                ±
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}</mat-label>
                  <input matInput disabled="true"
                    [name]="field.translationField + 'Tolerance'" [(ngModel)]="field.value[1]"
                    (ngModelChange)="setValue(tab.idVariable, field, 1, $event)" [errorStateMatcher]="matcher"
                    type="number" [required]="field.required">
                  <mat-error>
                    {{ 'protocolEdit.dialog.essay.valueTolerance.error' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <button mat-icon-button *ngIf="enableEditField(field)"
                (click)="openEditField(indexEssay, indexVariable, indexField)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <!-- DIA HORA MIN -->
            <div *ngIf="field.idTypeField == 4">
              <div fxLayout="row">
                <div fxFlex="30%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}: {{ 'common.days.label' | translate }}</mat-label>
                    <input matInput [name]="field.translationField + 'Day'" [(ngModel)]="field.value[0]"
                      (ngModelChange)="setValue(tab.idVariable, field, 0, $event)" disabled="true"
                      [errorStateMatcher]="matcher" type="number" [required]="field.required" min="0">
                  </mat-form-field>
                </div>
                <div fxFlex="30%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'common.hours.label' | translate }}</mat-label>
                    <input matInput [name]="field.translationField + 'Hour'" [(ngModel)]="field.value[1]"
                      (ngModelChange)="setValue(tab.idVariable, field, 1, $event)"
                      [errorStateMatcher]="matcher" type="number"
                      disabled="true" [required]="field.required" min="0">
                  </mat-form-field>
                </div>
                <div fxFlex="30%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'common.minutes.label' | translate }}</mat-label>
                    <input matInput [name]="field.translationField + 'Minute'" [(ngModel)]="field.value[2]"
                      (ngModelChange)="setValue(tab.idVariable, field, 2, $event)"
                      [errorStateMatcher]="matcher" type="number"
                      disabled="true" [required]="field.required" min="0">
                  </mat-form-field>
                </div>
                <button mat-icon-button *ngIf="enableEditField(field)"
                  (click)="openEditField(indexEssay, indexVariable, indexField)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>

            <!-- SELECT_ONE -->
            <div *ngIf="field.idTypeField == 6">
              <span>
                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
              </span>
              <br />
              <mat-radio-group class="vertical-radio-group" [required]="field.required">
                <mat-radio-button class="vertical-radio-button" *ngFor="let doma of field.fieldDomain"
                  [value]="doma.value" disabled="true">
                  {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                </mat-radio-button>
              </mat-radio-group>
              <button mat-icon-button *ngIf="enableEditField(field)"
                (click)="openEditField(indexEssay, indexVariable, indexField)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <!-- SELECT_MULTI -->
            <div *ngIf="field.idTypeField == 7">
              <span>
                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
              </span>
              <button mat-icon-button *ngIf="enableEditField(field)"
                (click)="openEditField(indexEssay, indexVariable, indexField)">
                <mat-icon>edit</mat-icon>
              </button>
              <mat-selection-list disabled="true">
                <mat-list-option *ngFor="let doma of field.fieldDomain" checkboxPosition="before" disabled="true"
                  [value]="doma.value" [selected]="isSelectedMulti(field.value, doma.value)">
                  {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                </mat-list-option>
              </mat-selection-list>
            </div>

            <!-- BOOLEAN -->
            <div *ngIf="field.idTypeField == 8">
              <mat-checkbox [value]="field.value" disabled="true">
                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
              </mat-checkbox>
              <button mat-icon-button *ngIf="enableEditField(field)"
                (click)="openEditField(indexEssay, indexVariable, indexField)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

          </div>
        </div>

        <mat-toolbar color="secondary">
          {{ 'executionEdit.essays.criteriaTitle' | translate }}

          <button mat-icon-button color="accent" (click)="openNewCriteria(indexEssay, indexVariable)"
            *ngIf="enableEditCriteria()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>

        <div fxLayout="row" class="flexwrap">
          <div fxFlex>
            <table mat-table matSort #criteriaTable [dataSource]="essay.essayValues[indexVariable].criterias"
              style="width: 95%;">

              <!-- criteria Column -->
              <ng-container matColumnDef="criteria">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'protocolEdit.dialog.essay.criteria.cols.criteria' | translate }}
                </th>
                <td mat-cell *matCellDef="let item"> {{ showCriteria(item) }} </td>
              </ng-container>

              <!-- Button edit Column -->
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                <td mat-cell *matCellDef="let element; let criteriaIndex = index">
                  <button mat-icon-button *ngIf="enableEditCriteria()"
                    (click)="openEditCriteria(indexEssay, indexVariable, criteriaIndex)">
                    <mat-icon>mode_edit</mat-icon>
                  </button>
                </td>
              </ng-container>

              <!-- Button delete Column -->
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                <td mat-cell *matCellDef="let element; let criteriaIndex = index">
                  <button mat-icon-button
                    *ngIf="enableEditCriteria() && essay.essayValues[indexVariable].criterias.length > 1"
                    (click)="removeCriteria(indexEssay, indexVariable, criteriaIndex)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColsCriteria"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColsCriteria;"></tr>

            </table>

            <br />
          </div>
        </div>

        <mat-toolbar color="secondary" *ngIf="essay.essayValues[indexVariable].sensors.length > 0">
          {{ 'executionEdit.essays.sensorsTitle' | translate }}

          <button mat-icon-button color="accent" (click)="openNewSensor(indexEssay, indexVariable)"
            *ngIf="enableEditSensor()">
            <mat-icon>add</mat-icon>
          </button>

          <button mat-icon-button color="primary" (click)="uploadGeneralPhoto.click()"
            *ngIf="essay.id != null && enableEditSensor() && essay.idPhoto == null">
            <mat-icon class="mat-icon material-icons opacity50">image</mat-icon>
          </button>
          <input accept="image/*" hidden type="file" (change)="onGeneralPhotoUpload(indexEssay, $event)"
            #uploadGeneralPhoto>

          <button mat-icon-button color="accent" (click)="openDialogGeneralPhoto(indexEssay)"
            *ngIf="essay.id != null && essay.idPhoto != null">
            <mat-icon class="mat-icon material-icons">image</mat-icon>
          </button>

          <button mat-icon-button color="accent" (click)="openDialogCorrectSensors(indexEssay, indexVariable)"
            *ngIf="essay.id != null && enableEditSensor()"
            matTooltip="{{ 'executionEdit.dialog.correctSensor.title' | translate }}">
            <mat-icon class="mat-icon material-icons correctedSensorIcon">done_all</mat-icon>
          </button>
        </mat-toolbar>

        <div fxLayout="row" class="flexwrap">
          <div fxFlex="100%">
            <div fxLayout="row" class="flexwrap"
              *ngFor="let sensor of essay.essayValues[indexVariable].sensors; let indexSensor = index">
              <div fxFlex="15" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.sensors.sensorNum.label' | translate }}</mat-label>
                  <input matInput name="numSensor{{indexSensor}}" value="{{ indexSensor + 1 }}" disabled>
                </mat-form-field>
              </div>
              <div fxFlex="45" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.sensors.location.label' | translate }}</mat-label>
                  <input matInput name="numSensor{{indexSensor}}" [ngModel]="sensor.location" disabled="true">
                </mat-form-field>
              </div>
              <div fxFlex="25" fxFlex.lt-md="100%">
                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="20px" *ngIf="sensor.corrected" style="margin-top: 1em;margin-right: 1em;">
                    <mat-icon class="correctedSensorIcon"
                      matTooltip="{{ 'executionEdit.dialog.correctSensor.sensor.tooltip' | translate }}">done_all
                    </mat-icon>
                  </div>
                  <div fxFlex="20px" *ngIf="!sensor.evaluate" style="margin-top: 1em;">
                    <span>❌</span>
                  </div>
                  <div fxFlex="20px" *ngIf="sensor.outer" style="margin-top: 1em;">
                    <span>️☀️</span>
                  </div>
                  <div fxFlex>
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.dialog.essay.sensors.serialNum.label' | translate }}</mat-label>
                      <input matInput [ngModel]="sensor.equipmentName" [disabled]="disableSerialNumLabel()"
                        #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
                        (keyup)="lookupEquipment(indexEssay, indexVariable, indexSensor, sensor.id, $event)">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)='onSensorChange(indexEssay, indexVariable, indexSensor, $event, trigger)'>
                        <mat-option *ngFor="let item of getResultsLookup(sensor.id); let index = index" [value]="item">
                          {{ item.name}} ({{item.serialNum}})
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error>
                        {{ 'protocolEdit.dialog.essay.sensors.serialNum.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex *ngIf="essay.essayValues[indexVariable].bioindicatorsConfig != null">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.dialog.essay.sensors.bioindicator.label' | translate }}</mat-label>
                      <input matInput name="bioindicatorSerialNumSensor{{indexSensor}}"
                        [ngModel]="sensor.bioindicatorSerialNum != null ? sensor.bioindicatorSerialNum : 'N/A'" disabled>
                    </mat-form-field>
                  </div>
                  <div fxFlex *ngIf="essay.essayValues[indexVariable].endotoxinsConfig != null">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.dialog.essay.sensors.endotoxin.label' | translate }}</mat-label>
                      <input matInput name="endotoxinSerialNumSensor{{indexSensor}}"
                        [ngModel]="sensor.endotoxinSerialNum != null ? sensor.endotoxinSerialNum : 'N/A'" disabled>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div fxFlex="15" fxFlex.lt-md="100%">
                <button mat-icon-button [disabled]="disableUploadPhotoButton()" (click)="uploadPhoto.click()"
                  *ngIf="!photoAlreadyUploaded(indexEssay, indexVariable, indexSensor) && showPhotoButton(indexEssay, indexVariable, indexSensor)">
                  <mat-icon
                    class="mat-icon material-icons opacity50 {{ styleUploadPhotoButton(indexEssay, indexVariable, indexSensor) }}">
                    camera_alt
                  </mat-icon>
                </button>
                <button mat-icon-button (click)="openDialogSensorPhoto(indexEssay, indexVariable, indexSensor)"
                  *ngIf="photoAlreadyUploaded(indexEssay, indexVariable, indexSensor) && showPhotoButton(indexEssay, indexVariable, indexSensor)">
                  <mat-icon class="mat-icon material-icons">
                    camera_alt
                  </mat-icon>
                </button>

                <button mat-icon-button [disabled]="disableUploadExcelButton()" (click)="uploadExcel.click()"
                  *ngIf="!excelAlreadyUploaded(indexEssay, indexVariable, indexSensor) && showExcelButton(indexEssay, indexVariable, indexSensor)">
                  <mat-icon
                    class="mat-icon material-icons opacity50 {{ styleUploadExcelButton(indexEssay, indexVariable, indexSensor) }}">
                    insert_chart
                  </mat-icon>
                </button>
                <button mat-icon-button (click)="openDialogSensorExcel(indexEssay, indexVariable, indexSensor)"
                  *ngIf="excelAlreadyUploaded(indexEssay, indexVariable, indexSensor) && showExcelButton(indexEssay, indexVariable, indexSensor)">
                  <mat-icon class="mat-icon material-icons">
                    insert_chart
                  </mat-icon>
                </button>

                <button mat-icon-button [disabled]="disableUploadOriginalButton()" (click)="uploadPrimaryFile.click()"
                  *ngIf="!primaryAlreadyUploaded(indexEssay, indexVariable, indexSensor) && showPrimaryButton(indexEssay, indexVariable, indexSensor)">
                  <mat-icon style="max-height: 20px; vertical-align: text-bottom;" svgIcon="doc_lock"
                    class="mat-icon material-icons opacity50 {{ styleUploadOriginalButton(indexEssay, indexVariable, indexSensor) }}">
                  </mat-icon>
                </button>
                <button mat-icon-button (click)="openDialogSensorPrimary(indexEssay, indexVariable, indexSensor)"
                  *ngIf="primaryAlreadyUploaded(indexEssay, indexVariable, indexSensor) && showPrimaryButton(indexEssay, indexVariable, indexSensor)">
                  <mat-icon style="max-height: 20px; vertical-align: text-bottom;" svgIcon="doc_lock"
                    class="mat-icon material-icons"> </mat-icon>
                </button>

                <button mat-icon-button (click)="openEditSensor(indexEssay, indexVariable, indexSensor)">
                  <mat-icon style="max-height: 20px; vertical-align: text-bottom;" class="mat-icon material-icons">
                    build
                  </mat-icon>
                </button>

                <button mat-icon-button (click)="openDeleteSensor(indexEssay, indexVariable, indexSensor)"
                  *ngIf="enableReasonModifyExpositionTime()">
                  <mat-icon style="max-height: 20px; vertical-align: text-bottom;" class="mat-icon material-icons">
                    delete
                  </mat-icon>
                </button>

                <input accept="image/*" hidden type="file"
                  (change)="onPhotoUploadSensor(indexEssay, indexVariable, indexSensor, $event)" #uploadPhoto>

                <input accept=".xlsx, .xls, .csv, .pdf" hidden type="file"
                  (change)="onExcelUploadSensor(indexEssay, indexVariable, indexSensor, $event)" #uploadExcel>

                <input accept="*" hidden type="file"
                  (change)="onPrimaryDataUploadSensor(indexEssay, indexVariable, indexSensor, $event)"
                  #uploadPrimaryFile>
              </div>
            </div>

            <div fxLayout="row" class="flexwrap">

              <div fxFlex="20" fxFlex.lt-md="100%" *ngIf="essay.essayValues[indexVariable].bioindicatorsConfig != null">
                <p> {{ 'executionEdit.bioindicators.valid' | translate }} </p>
                <mat-radio-group [(ngModel)]="essay.essayValues[indexVariable].bioindicatorsConfig.valid"
                  name="validBioindicators">
                  <mat-radio-button [value]="true"
                    [checked]="essay.essayValues[indexVariable].bioindicatorsConfig.valid === true">
                    {{'common.yes' | translate}} </mat-radio-button>
                  <mat-radio-button [value]="false"
                    [checked]="essay.essayValues[indexVariable].bioindicatorsConfig.valid === false">
                    {{'common.no' | translate}} </mat-radio-button>
                  <mat-radio-button [value]="null"
                    [checked]="essay.essayValues[indexVariable].bioindicatorsConfig.valid == null">
                    {{'common.unknown' | translate}} </mat-radio-button>
                </mat-radio-group>
              </div>

              <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="essay.essayValues[indexVariable].bioindicatorsConfig != null">
                <button mat-raised-button color="accent"
                  (click)="showDialogConfigBioindicator(indexEssay, indexVariable)">
                  {{ 'button.configureBioindicators' | translate }}
                </button>
              </div>

              <div fxFlex="20" fxFlex.lt-md="100%" *ngIf="essay.essayValues[indexVariable].endotoxinsConfig != null">
                <p> {{ 'executionEdit.endotoxins.valid' | translate }} </p>
                <mat-radio-group [(ngModel)]="essay.essayValues[indexVariable].endotoxinsConfig.valid"
                  name="validEndotoxin">
                  <mat-radio-button [value]="true"
                    [checked]="essay.essayValues[indexVariable].endotoxinsConfig.valid === true">
                    {{'common.yes' | translate}} </mat-radio-button>
                  <mat-radio-button [value]="false"
                    [checked]="essay.essayValues[indexVariable].endotoxinsConfig.valid === false">
                    {{'common.no' | translate}} </mat-radio-button>
                  <mat-radio-button [value]="null"
                    [checked]="essay.essayValues[indexVariable].endotoxinsConfig.valid == null">
                    {{'common.unknown' | translate}} </mat-radio-button>
                </mat-radio-group>
              </div>

              <div fxFlex="33" fxFlex.lt-md="100%" *ngIf="essay.essayValues[indexVariable].endotoxinsConfig != null">
                <button mat-raised-button color="accent" (click)="showDialogConfigEndotoxin(indexEssay, indexVariable)">
                  {{ 'button.configureEndotoxins' | translate }}
                </button>
              </div>

            </div>

            <br />

            <div fxLayout="row" class="flexwrap" *ngIf="essay.essayValues[indexVariable].showBowieDick === true">

              <div fxFlex="25" fxFlex.lt-md="100%">

                <br />

                <label id="labelResultBowie{{indexVariable}}">
                  {{'protocolEdit.dialog.essay.criteria.bowieDick.label' | translate}}
                </label>

                <br /> <br />

                <mat-radio-group [(ngModel)]="essay.essayValues[indexVariable].validBowieDick" name="validBowieDick">
                  <mat-radio-button [value]="true">{{'common.correct' | translate}}</mat-radio-button>
                  <br />
                  <mat-radio-button [value]="false">{{'common.incorrect' | translate}}</mat-radio-button>
                </mat-radio-group>
              </div>

              <div fxFlex="25" fxFlex.lt-md="100%">
                <button mat-raised-button color="accent" (click)="uploadBowieDickPhoto.click()">
                  {{ 'button.upload-result' | translate }}
                </button>

                <input accept="image/*" hidden type="file" (change)="onPhotoUploadBowieDick($event)"
                  #uploadBowieDickPhoto>
              </div>

            </div>

            <br *ngIf="essay.essayValues[indexVariable].showBowieDick === true" />

            <div fxLayout="row" class="flexwrap" *ngIf="essay.essayValues[indexVariable].sensors.length != 0">

              <div fxFlex="25" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.criteria.percentSensorsCanFail.label' | translate }}</mat-label>
                  <input matInput [name]="percentSensorsCanFail"
                    [(ngModel)]="essay.essayValues[indexVariable].percentSensorsCanFail" required
                    [errorStateMatcher]="matcher" type="number" disabled onkeypress="return event.charCode >= 48"
                    min="0" max="99">
                  <mat-error>
                    {{ 'protocolEdit.dialog.essay.criteria.percentSensorsCanFail.error' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showSetpoint(indexEssay, indexVariable)">
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.criteria.setpoint.label' | translate }}</mat-label>
                  <input matInput [name]="setpoint" [(ngModel)]="essay.essayValues[indexVariable].setpoint"
                    [errorStateMatcher]="matcher">
                </mat-form-field>
              </div>

              <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showRealLoad(indexEssay, indexVariable)">
                <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'protocolEdit.dialog.essay.criteria.dsLoad.label' | translate }}</mat-label>
                  <input matInput [name]="dsLoad" [(ngModel)]="essay.essayValues[indexVariable].dsLoad"
                    [errorStateMatcher]="matcher">
                </mat-form-field>
              </div>

              <div fxFlex="25" fxFlex.lt-md="100%">
                <mat-checkbox [value]="reasonModifyExpositionTime != null"
                  [checked]="reasonModifyExpositionTime != null" [disabled]="!enableReasonModifyExpositionTime()"
                  (change)="openReasonModifyExpositionTime($event)">
                  {{ 'executionEdit.essays.allowEditGraph' | translate }}</mat-checkbox>
              </div>

            </div>

            <div fxLayout="row" class="flexwrap" *ngIf="essay.essayValues[indexVariable].sensors.length != 0">

              <button mat-raised-button (click)="openUploadGeneric(indexEssay, indexVariable)" color="accent">
                <mat-icon>backup</mat-icon>
                {{ 'button.upload' | translate }}
              </button>

              <div fxFlex></div>

              <div fxFlex="5px"></div>

              <button mat-raised-button (click)="copyToEssay()" *ngIf="showCopyToOtherEssay()" color="accent">
                {{ 'button.copy-from' | translate }}</button>

              <div fxFlex="5px"></div>

              <button mat-raised-button (click)="copySensorToAll()" *ngIf="!disableSerialNumLabel()" color="accent">
                {{ 'button.copy-to-all' | translate }}</button>

              <div fxFlex="5px"></div>

              <button mat-raised-button (click)="showDialogDefrost()" *ngIf="!disableDefrostsButton()" color="accent">
                {{ 'button.defrost' | translate }}</button>

              <div fxFlex="5px"></div>

              <button mat-raised-button (click)="showDialogDiscardTime(essay)" *ngIf="!disableDefrostsButton()"
                color="accent">{{ 'button.discardTimes' | translate }}</button>

              <div fxFlex="5px"></div>

              <button mat-raised-button [hidden]="!showRestartGraph()" (click)="restartGraph()" color="accent">
                {{ 'button.restartGraph' | translate }}</button>
            </div>

          </div>
        </div>

      </mat-tab>
    </mat-tab-group>

  </mat-tab>

</mat-tab-group>

<app-execution-edit-graph [hidden]="!displayEssay()" (fcStartEssayEmitter)="onFcStartEssayEmit($event)"
  (copyDateFirstSensorEmitter)="onCopyTimeGraphChange($event)" (onHotColdSpotEmitter)="onHotColdSpotChange($event)"
  (onIdPeakEmitter)="onIdPeakChange($event)">
</app-execution-edit-graph>