<form>
    <div mat-dialog-content class="qualificates-theme">
        <div fxFlex fxLayout="column">

            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" fxLayout="row" class="table-title">
                    <div fxFlex="100">
                        <div fxFlex="3">
                            <span>{{data.testQType.id}}</span>
                        </div>
                        <div fxFlex="97">
                            <input matInput name="title-essay" [(ngModel)]="data.data.testDescription" [disabled]="true">
                        </div>
                    </div>
                </div>

                <div *ngIf="testConfig?.testGeneralData" [fxHide]="true">
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Objetivo</span>
                        </div>
                        <div fxLayout="row"
                            *ngFor="let value of dataSource.executionQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.objective" [name]="'objective' + indexTest"
                                    [id]="'objective' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }"
                                    style="height: 150px; font-weight: normal;" [disabled]="true"></quill-editor>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" [fxHide]="true">
                        <div fxFlex="100" class="title-table-test">
                            <span>Metodología</span>
                        </div>
                        <div fxLayout="row"
                            *ngFor="let value of dataSource.executionQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.methodology" [name]="'methodology' + indexTest"
                                    [id]="'methodology' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }"
                                    style="height: 150px; font-weight: normal;" [disabled]="true"></quill-editor>
                            </div>
                        </div>
                        <div fxFlex="15" *ngIf="data.data.idTestType == 10 ">
                            <button class="btn-add" mat-raised-button color="primary" (click)="fileInput.click()">
                                {{ 'executionTest.test.image' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                            <input type="file" #fileInput (change)="onImageSelected($event)" style="display: none;">
                            <span class="file-name" *ngIf="fileName">{{ fileName }}</span>
                        </div>
                    </div>
                    <div fxLayout="column" [fxHide]="true">
                        <div fxFlex="100" class="title-table-test">
                            <span>Criterios de aceptación</span>
                        </div>
                        <div fxLayout="row"
                            *ngFor="let value of dataSource.executionQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.criteria" [name]="'criteria' + indexTest"
                                    [id]="'criteria' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }"
                                    style="height: 150px; font-weight: normal;" [disabled]="true"></quill-editor>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="testConfig?.testVerification">
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Verificación de Pruebas</span>
                        </div>
                        <div *ngFor="let value of dataSource.executionQTestVerification; let indexTest = index" fxLayout="column" fxFlex="100" class="table-item">

                            <div fxLayout="row" fxFlex="100">
                                <div fxFlex="30" class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'Prueba' | translate }}</mat-label>
                                        <input matInput [name]="'test' + indexTest" [(ngModel)]="value.test" [disabled]="true">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30" class="table-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'Descripcion' | translate }}</mat-label>
                                        <textarea matInput [name]="'descriptionTest' + indexTest"
                                                  [(ngModel)]="value.descriptionTest" rows="5" [disabled]="true"></textarea>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="25" class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'Resultado' | translate }}</mat-label>
                                        <mat-select [name]="'vResultVerification' + indexTest" [(ngModel)]="value.selectedResult" 
                                                    (selectionChange)="onSelectedVerificationTestOQResult($event, indexTest)">
                                            <mat-option *ngFor="let sel of value.resultDesc" [value]="sel">
                                                {{ 'executionTest.result.' + sel | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" style="margin-top: 10px;">
                                <mat-form-field class="campoFormulario" fxFlex="100">
                                    <mat-label>{{ 'Observacion' | translate }}</mat-label>
                                    <textarea matInput [name]="'observation' + indexTest"
                                              [(ngModel)]="value.observation" rows="1"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="testConfig?.probeTraceability">
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Trazabilidad de las sondas</span>
                        </div>
                        <div fxFlex="100" fxLayout="column" class="container-verification-data">
                            <div fxFlex="100"
                                *ngFor="let value of dataSource.executionQTestProbeTraceability; let indexProbe = index">
                                <div fxFlex="100" style="justify-content: center;">
                                    <div fxFlex="30">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'Sonda' | translate }}</mat-label>
                                            <input matInput [name]="'descriptionProbe'+indexProbe"
                                                [(ngModel)]="value.descriptionProbe" [disabled]="true">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="30"> 
                                        <mat-form-field class="campoFormulario">
                                            <input matInput [name]="'identifier'+indexProbe" *ngIf="!value.external" [ngModel]="value.identifier" #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
                                                placeholder="{{ 'executionEditQ.test.probe.identificative.label' | translate }}"
                                                (keyup)="lookupEquipment($event)" [ngModelOptions]="{standalone: true}" required>
                                              <mat-autocomplete autoActiveFirstOption *ngIf="!value.external" #auto="matAutocomplete" [displayWith]="displayFn"
                                                (optionSelected)="onEquipmentSelected($event, trigger, indexProbe)">
                                                <mat-option *ngFor="let item of equipmentAutoComplete; let index = index" [value]="item">
                                                  {{ item.name}} ({{item.serialNum}})
                                                </mat-option>
                                              </mat-autocomplete>

                                              <mat-label *ngIf="value.external">{{ 'Identificador' | translate }}</mat-label>
                                              <input matInput [name]="'identifier'+indexProbe" *ngIf="value.external" [(ngModel)]="value.identifier">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="10">
                                        
                                        <mat-checkbox style="margin-left: 1em;"
                                        [(ngModel)]="value.external" [ngModelOptions]="{standalone: true}" 
                                        (change)="onChangeExternalCheck($event, indexProbe)">
                                          <span style="white-space: pre-line;">
                                            {{'Externo'}}
                                          </span>
                                        </mat-checkbox>
                                    </div>
                                    <div fxFlex="30">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'Posición' | translate }}</mat-label>
                                            <input matInput [name]="'locationProbe'+indexProbe"
                                                [(ngModel)]="value.location" [disabled]="true">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="30">
                                        <mat-checkbox style="margin-left: 1em;" *ngIf="showLastTacometer(indexProbe)"  
                                        [(ngModel)]="value.tacometer" [ngModelOptions]="{standalone: true}" 
                                        (change)="onChangeTacometerCheck($event, indexProbe)" [disabled]="true">
                                          <span style="white-space: pre-line;">
                                            {{'Tacómetro'}}
                                          </span>
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="testConfig?.bioTraceability">
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Trazabilidad de los bioindicadores</span>
                        </div>
                        <div fxFlex="100"
                            *ngFor="let value of dataSource.executionQTestBioindicadores; let indexBio = index">
                                <div fxFlex="30">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'Bioindicador' | translate }}</mat-label>
                                        <input matInput [name]="'descriptionBio'+indexBio"
                                            [(ngModel)]="value.bioDescription" [disabled]="true">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'Identificador' | translate }}</mat-label>
                                            <input matInput [name]="'identifier'+indexBio"
                                                [(ngModel)]="value.identifier">
                                        </mat-form-field>
                                </div>
                                <div fxFlex="30">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'Control' | translate }}</mat-label>
                                        <input matInput [name]="'control'+indexBio"
                                            [(ngModel)]="value.control">
                                    </mat-form-field>
                                </div>
                        </div>
                    </div>
                </div>

                <div fxFlex="100" *ngFor="let item of dataSource?.executionQTestItem; let indexItem = index"
                    fxLayout="column">
                    <div fxFlex="100" fxLayout="row" class="table-item" >
                        <div fxFlex="50" *ngIf="testConfig?.item">
                            <mat-form-field class="campoFormulario" >
                                <mat-label>{{ 'Item' | translate }}</mat-label>
                                <input matInput [name]="'item'+indexItem" [(ngModel)]="item.description" [disabled]="true" >
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngFor="let valueItemV of item.executionQTestItemVerification; let indexItemV = index">

                        <div fxFlex="100" fxLayout="column" class="table-item">
                            <div fxFlex="100" fxLayout="row">
                                <div fxFlex="100">
                                    <div fxFlex="100">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'Verificación' | translate }}</mat-label>
                                            <textarea matInput [name]="'verificationTypeDesc'+indexItem+indexItemV"
                                                [(ngModel)]="valueItemV.verification" rows="1" [disabled]="true"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let value of valueItemV.executionQTestDataMultiple; let indexValue = index">
                                <div fxFlex="100" fxLayout="row" class="table-item">
                                    <div fxFlex="40" class="input-dinamyc-item" *ngIf="testConfig?.expectedResult && value.expectedResultDesc">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label *ngIf="!value.expectedResultRhDesc">{{  'Resultado esperado' | translate }}</mat-label>
                                            <mat-label *ngIf="value.expectedResultRhDesc">{{ value.expectedResultRhDes }}</mat-label>

                                            <input matInput
                                                [name]="'expectedResultDesc' +indexItem+indexItemV+ indexValue"
                                                [ngModel]="value.expectedResultDesc" [disabled]="true">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="100" fxLayout="column" class="table-item">
                                        <div fxFlex="100" fxLayout="row">
                                            <div fxFlex="100">
                                                <mat-form-field class="campoFormulario">
                                                    <mat-label>{{ 'Resultado Obtenido' | translate }}</mat-label>
                                                    <textarea matInput [name]="'obtainedResult'+indexItem+indexItemV+indexValue" 
                                                    [(ngModel)]="value.obtainedResult" rows="1"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxFlex="35" class="input-dinamyc-item">
                                        <mat-form-field class="campoFormulario">
                                          <mat-label>{{ 'Resultado' | translate }}</mat-label>
                                          <mat-select [name]="'sResultDesc'+indexItem+indexItemV+indexValue" [(ngModel)]="value.selectedResult" (selectionChange)="onSelectedVerificationResult($event, indexItem, indexItemV, indexValue)">
                                            <mat-option *ngFor="let sel of value.resultDesc" [value]="sel">
                                              {{ 'executionTest.result.' + sel | translate }}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="5">
                                        <button mat-icon-button color="accent" *ngIf="value.executionQTestDeviations != null"
                                            (click)="showDeviationSelected(indexItem, indexItemV, indexValue)">
                                            <mat-icon>note_add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Observacion' | translate }}</mat-label>
                                    <textarea matInput [name]="'observation'+indexItemV"
                                        [(ngModel)]="valueItemV.observation" rows="1"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxFlex="100" fxLayout="column" *ngIf="testConfig.testResult">
                <div fxLayout="column">
                    <div fxFlex="100" class="title-table-test">
                        <span>Resultado de ensayos</span>
                    </div>
                    <div *ngFor="let value of dataSource.executionQTestResults; let indexValue = index" fxLayout="column" fxFlex="100" class="table-item">
                        <div fxLayout="row" fxFlex="100">
                            <div fxFlex="30">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Verificación' | translate }}</mat-label>
                                    <textarea matInput [name]="'verificationTypeDesc'+indexValue"
                                        [(ngModel)]="value.verification" rows="5" [disabled]="true"></textarea>
                                </mat-form-field>
                            </div>
                            <div fxFlex="20" class="input-dinamyc-item">
                                <mat-form-field class="campoFormulario" *ngFor="let itemResult of value.resultExpected; let indexEx = index">
                                    <mat-label>{{ 'Resultado esperado' | translate }}</mat-label>
                                    <input matInput [name]="'resultExpected'+indexValue+indexEx"
                                        [ngModel]="value.resultExpected[indexEx]"
                                        (blur)="value.resultExpected[indexEx] = $event?.target?.value" [disabled]="true">
                                </mat-form-field>
                            </div>
                            <div fxFlex="25" class="input-dinamyc-item">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Resultado Obtenido' | translate }}</mat-label>
                                    <textarea matInput [name]="'obtainedResult'+indexValue" 
                                        [(ngModel)]="value.obtainedResult" rows="1"></textarea>
                                </mat-form-field>
                            </div>
                            <div fxFlex="20" class="input-dinamyc-item">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Resultado' | translate }}</mat-label>
                                    <mat-select [name]="'sResult'+indexValue" [(ngModel)]="value.selectedResult" 
                                                (selectionChange)="onSelectedVerificationTestResult($event, indexValue)">
                                        <mat-option *ngFor="let sel of resultQTypes" [value]="sel.translation">
                                            {{ 'executionTest.result.' + sel.translation | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="5" fxLayoutAlign="center end" style="margin-bottom: 10px;">
                                <button mat-icon-button color="accent" *ngIf="value.executionQTestDeviations != null"
                                        (click)="showDeviationTestResultSelected(indexValue)">
                                    <mat-icon>note_add</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxFlex="100" fxLayout="row" style="margin-top: 10px;">
                            <mat-form-field class="campoFormulario" fxFlex="100">
                                <mat-label>{{ 'Observacion' | translate }}</mat-label>
                                <textarea matInput [name]="'observation'+indexValue"
                                    [(ngModel)]="value.observation" rows="1"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <app-execution-q-edit-test-attachments #attachmentsTestComponent *ngIf="data.data.id !=null">
            </app-execution-q-edit-test-attachments>
         </div>
    </div>
    <div mat-dialog-actions class="qualificates-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>