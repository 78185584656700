<mat-card appearance="outlined" class="paymentCard">
  <mat-card-title>
    <h1>Se ha completado su suscripción al plan {{ paymentInfo.planName }}</h1>
  </mat-card-title>
  <mat-card-content>
    <p>Aquí están los datos de la suscripción:</p>
  </mat-card-content>
</mat-card>

<br />

<mat-card appearance="outlined" class="paymentCard">
  <mat-card-title>
    <mat-toolbar color="secondary" style="width: 95vw;">
      Datos de cliente
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content>
    <p>
      {{ paymentInfo.managerFullname }}<br />
      {{ paymentInfo.managerEmail }}<br />
      <br />
      {{ paymentInfo.companyName }} ({{ paymentInfo.companyCif }})<br />
      {{ paymentInfo.companyAddress }}<br />
      {{ paymentInfo.companyPostalCode }} {{ paymentInfo.companyCity }} {{ paymentInfo.companyCountry }}<br />
    </p>
  </mat-card-content>
</mat-card>

<br />

<mat-card appearance="outlined" class="paymentCard">
  <mat-card-title>
    <mat-toolbar color="secondary" style="width: 95vw;">
      Datos de pago
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content>
    <p>
      Plan de pago: {{ paymentInfo.planName }}<br />
      Pago inicial: {{ paymentInfo.initialPayment }} €<br />
      Pago mensual: {{ paymentInfo.monthlyPayment }}<br />
      <br />
      Total pagado hoy: {{ (paymentInfo.initialPayment + paymentInfo.monthlyPayment) }} €<br />
      Fecha próxima factura: {{ paymentInfo.nextInvoice | fixDate }}<br />
    </p>
  </mat-card-content>
</mat-card>

<br />

<mat-card appearance="outlined" class="paymentCard">
  <mat-card-title>
    <mat-toolbar color="secondary" style="width: 95vw;">
      Datos de acceso
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content>
    <p>
      Usuario: {{ paymentInfo.managerUsername }}<br />
      Contraseña: La recibirá en su correo electrónico.<br />
    </p>
  </mat-card-content>
</mat-card>

<br />

<mat-card appearance="outlined" class="paymentCard">
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="backToLogin()">{{ 'button.backToLogin' | translate }}</button>
    <div fxFlex></div>
  </mat-card-actions>
</mat-card>