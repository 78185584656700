import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { SharedModule } from '../shared.module';
import { FlexLayoutModule } from 'ng-flex-layout';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    /* Custom modules */
    AngularMaterialModule,
    SharedModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule
  ],
  exports: [
    ProfileComponent
  ]
})
export class ProfileModule { }
