import * as _ from 'lodash-es';
import * as moment from 'moment';

import { Component, EventEmitter, Output } from '@angular/core';
import {
  IsothermalCharacterizationSensor,
  IsothermalCharacterizationStatus,
  IsothermalSummaryData
} from 'src/app/model/isothermalCharacterization';
import { SensorData, SensorDataGraph, SensorDataGraphData } from 'src/app/model/sensorData';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { Criteria } from 'src/app/model/criteria';
import { CriteriaTypeEnum } from 'src/app/model/criteriaType';
import { DateUtils } from 'src/app/utils/dateUtils';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { IsothermalCharacterizationService } from '../../../services/isothermalCharacterization.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-isothermal-characterization-edit-graph',
  templateUrl: './isothermal-characterization-edit-graph.component.html'
})
export class IsothermalCharacterizationEditGraphComponent {

  @Output() fcStartEssayEmitter = new EventEmitter<any>();
  @Output() copyDateFirstSensorEmitter = new EventEmitter<any>();

  idStatus: number;
  idTypeVariable: number;
  unitName: string;

  variableIndex: number;
  copyDateFirstSensor: boolean;
  reasonModifyExpositionTime: string;

  criterias: Criteria[];

  dateZoomInit: Date;
  dateZoomEnd: Date;

  dateZoomInitStr: string;
  dateZoomEndStr: string;
  datasource: SensorDataGraph[];
  sensorsToShow: any[] = [];
  sensors: any[];
  dataToUse: SensorData[];

  calculatedData: SensorDataGraphData[] = [];
  summaryData: IsothermalSummaryData;

  fcStartEssay: Date;
  fcEndEssay: Date;

  maxDate: any;
  minDate: any;

  fullEssay = false;

  originalSensors: IsothermalCharacterizationSensor[];
  changeTimezone: boolean;
  idIso: number;
  idVariable: number;

  private _data: SensorData[];

  private daysRange = 0;
  private hoursRange = 0;
  private minutesRange = 0;

  private destroy$ = new Subject<void>();

  constructor(private translate: TranslateService, public snackBarService: SnackBarService, private spinnerService: SpinnerService,
    private isoService: IsothermalCharacterizationService
  ) { }

  set data(data: SensorData[]) {

    if (data) {
      data.forEach(d => {
        if (typeof d.date === 'string') {
          d.date = DateUtils.anyToDate(d.date);
        }
      });
    }

    this._data = data;

    this.dateZoomInit = null;
    this.dateZoomEnd = null;

    if (data != null) {
      data.forEach((value, i, arr) => {
        if (this.dateZoomInit == null || this.dateZoomInit >= value.date) {
          this.dateZoomInit = value.date;
        }

        if (this.dateZoomEnd == null || this.dateZoomEnd <= value.date) {
          this.dateZoomEnd = value.date;
        }

        if (i === (arr.length - 1)) {
          this.recalculateDatasource(data);
        }

      });
    } else {
      this.recalculateDatasource(data);
    }

    if (this.dateZoomInit != null) {
      this.dateZoomInitStr = moment(this.dateZoomInit).format('DD/MM/YYYY HH:mm:ss');
    } else {
      this.dateZoomInitStr = null;
    }

    if (this.dateZoomEnd != null) {
      this.dateZoomEndStr = moment(this.dateZoomEnd).format('DD/MM/YYYY HH:mm:ss');
    } else {
      this.dateZoomEndStr = null;
    }

  }

  set range(range: string) {
    let rangeSplit = [];

    if (StringUtils.isNotEmpty(range)) {
      if (range.indexOf(';') > -1) {
        rangeSplit = range.split(';');
      } else {
        rangeSplit = range.split(',');
      }
    }

    this.daysRange = +rangeSplit[0] || 0;
    this.hoursRange = +rangeSplit[1] || 0;
    this.minutesRange = +rangeSplit[2] || 0;
  }

  recalculateGraph(): void {
    this.recalculateDatasource(this._data);
  }

  disableGraph(): boolean {
    return this.datasource == null || this.datasource.length === 0;
  }

  recalculateDatasource(data: SensorData[]): void {
    this.spinnerService.show();

    if (data == null || ArrayUtils.isEmpty(data)) {
      data = this._data;
    }

    if (data == null || ArrayUtils.isEmpty(data)) {
      this.datasource = [];
      this.sensors = [];
      this.spinnerService.hide();

      return;
    }

    const res: SensorDataGraph[] = [];
    const sensors = [];

    if (data != null && this.dateZoomInit != null && this.dateZoomEnd != null) {
      data = data.filter(item => this.dateZoomInit == null || this.dateZoomEnd == null
        || (DateUtils.isDateBetweenEq(item.date, this.dateZoomInit, this.dateZoomEnd))
      );
    }

    const groupBy = ArrayUtils.groupBy(data, (item: SensorData) => item.date);
    groupBy.forEach((values: SensorData[], key: Date) => {
      values.forEach(value => {

        const sen = new SensorDataGraph();
        sen.date = key;

        if (!value.equipmentName) {
          return;
        }

        const beautySerialNum = value.equipmentName.replace('-', '').replace(' ', '').toLowerCase();

        sen[beautySerialNum] = value.value;

        const currentSensors = sensors.map(item => item.name);

        const originalSensor = this.originalSensors.find(s => value.equipmentName === s.equipmentName);
        const evaluate = originalSensor.evaluate;
        const idEquipment = originalSensor.idEquipment;

        if (!currentSensors.includes(value.equipmentName)) {

          sensors.push({
            name: value.equipmentName,
            idEquipment,
            serialNum: value.serialNum,
            value: beautySerialNum,
            evaluate,
            uncertainty: originalSensor.uncertainty,
            outer: originalSensor.outer
          });
        }

        if (sen == null || evaluate) {
          res.push(sen);
        }

      });
    });

    this.datasource = res;

    if(sensors.length > 47) {
      for(let i= 0; i < sensors.length; i++) {
        if(this.sensorsToShow.length < 42 && i % 2 === 0) {
          this.sensorsToShow.push(sensors[i]);
        }
      }
    } else {
      this.sensorsToShow = sensors;
    }

    this.sensors = sensors;

    this.minDate = this.calculateMinDate();
    this.maxDate = this.calculateEndDate(this.minDate as Date, this.fcEndEssay);

    this._data.forEach(item => item.date = DateUtils.anyToDate(item.date));

    const dataToUse = this._data.filter(item => DateUtils.isDateBetweenEq(item.date, this.minDate as Date, this.maxDate as Date));
    this.dataToUse = dataToUse;

    this.recalculateValues(dataToUse);

    this.spinnerService.hide();
  }

  disableRange(): boolean {
    const disallowedStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA, IsothermalCharacterizationStatus.FIRMADO];

    const res = disallowedStatuses.includes(this.idStatus);

    return res || this.showCopyDateFirstSensor() && this.copyDateFirstSensor === true;
  }

  onValueChanged: ((arg0: any) => void) = (e) => {

    this.spinnerService.show();

    let initDate = DateUtils.anyToDate(_.cloneDeep(e.value[0] as Date));
    let endDate = DateUtils.anyToDate(_.cloneDeep(e.value[1] as Date));

    initDate = EssayUtils.calculateNearDate(initDate, this._data);
    endDate = EssayUtils.calculateNearDate(this.calculateEndDate(initDate, endDate), this._data);

    this.minDate = initDate;
    this.maxDate = endDate;

    const dataToUse = this._data.filter(item => DateUtils.isDateBetweenEq(item.date, initDate, endDate));
    this.dataToUse = dataToUse;

    this.recalculateValues(dataToUse);

    let dateInitEvent: Date = null;
    let dateEndEvent: Date = null;

    if (initDate != null) {
      dateInitEvent = _.cloneDeep(initDate);
    }

    if (endDate != null) {
      dateEndEvent = _.cloneDeep(endDate);
    }

    if (!this.showCopyDateFirstSensor() || this.copyDateFirstSensor !== true) {
      this.fcStartEssayEmitter.next({
        variableIndex: this.variableIndex,
        fcStartEssay: dateInitEvent,
        fcEndEssay: dateEndEvent,
        changeTimezone: this.changeTimezone
      });
    }

    this.changeTimezone = true;

    this.spinnerService.hide();
  }

  recalculateDateFilter(): void {

    try {
      this.dateZoomInit = moment(this.dateZoomInitStr, 'DD/MM/YYYY HH:mm:ss').toDate();
    } catch (e) {
      this.dateZoomInit = null;
    }

    if (this.dateZoomInit == null) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.zoomInit') as string);
      return;
    }

    try {
      this.dateZoomEnd = moment(this.dateZoomEndStr, 'DD/MM/YYYY HH:mm:ss').toDate();
    } catch (e) {
      this.dateZoomEnd = null;
    }

    if (this.dateZoomEnd == null) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.zoomEnd') as string);
      return;
    }

    if (this.dateZoomInit > this.dateZoomEnd) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.endBeforeInit') as string);
      return;
    }

    let gap = 0;
    if (!isNaN(this.daysRange) && this.daysRange > 0) {
      gap += this.daysRange * 86400000;
    }
    if (!isNaN(this.hoursRange) && this.hoursRange > 0) {
      gap += this.hoursRange * 3600000;
    }
    if (!isNaN(this.minutesRange) && this.minutesRange > 0) {
      gap += this.minutesRange * 60000;
    }

    const initDateToCheck = DateUtils.anyToDate(this.fcStartEssay) || this.dateZoomInit;
    const initDateAndRange = DateUtils.anyToDate(initDateToCheck.getTime() + gap);

    if (initDateAndRange > this.dateZoomEnd) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.smallRange') as string);
      return;
    }

    this.recalculateDatasource(this._data);

  }

  recalculateValues(list: SensorData[] = this.dataToUse, criterias: Criteria[] = this.criterias): void {
    this.calculatedData = [];

    if (list != null && list.length !== 0) {

      const countMeasurements: number[] = [];

      for (const sensor of this.sensors) {

        if (!sensor.evaluate) {
          continue;
        }

        const sensorDataList = list.filter(item => item.equipmentName === sensor.name);

        countMeasurements.push(sensorDataList.length);

        const values = sensorDataList.map(item => item.value);

        const data = new SensorDataGraphData();
        data.equipmentName = sensor.name as string;
        data.serialNum = sensor.serialNum as string;
        data.maxValue = Math.max(...values);
        data.minValue = Math.min(...values);
        data.average = ArrayUtils.calcAvg(values, false);
        data.diffAvgMin = NumberUtils.fixPrecision(Math.abs(data.minValue - data.average));
        data.diffMaxAvg = NumberUtils.fixPrecision(Math.abs(data.maxValue - data.average));
        data.diffMaxMin = NumberUtils.fixPrecision(Math.abs(data.maxValue - data.minValue));
        data.standardDeviation = NumberUtils.standardDeviation(values);
        data.countData = sensorDataList.length || 0;
        data.uncertainty = sensor.uncertainty as number || 0;
        data.uncertaintyVariable = Math.sqrt(Math.pow(data.uncertainty, 2) + Math.pow(data.standardDeviation, 2) / data.countData);
        data.outer = sensor.outer as boolean;
        data.isErrMax = false;
        data.isErrMin = false;

        this.calculatedData.push(data);
      }

     if (this.idIso) {
      this.isoService.calculateSummaryData(this.idIso, this.idVariable).pipe(takeUntil(this.destroy$)).subscribe((summaryData: IsothermalSummaryData) => {
        if (summaryData) {
          summaryData.uniformityValid = true;
          summaryData.stabilityValid = true;

          criterias.forEach(crit => {
            const idType = crit.idType;
            const val1 = crit.criteriaValue1;
            const val2 = crit.criteriaValue2;

            if (summaryData.uniformityExpandedUncertainty != null) {
              if (idType === CriteriaTypeEnum.UNIFORMITY_LE && val1 < summaryData.uniformityExpandedUncertainty) {
                summaryData.uniformityValid = false;
              } else if (idType === CriteriaTypeEnum.UNIFORMITY_LOWER && val1 <= summaryData.uniformityExpandedUncertainty) {
                summaryData.uniformityValid = false;
              }
            }
            if (summaryData.stabilityExpandedUncertainty != null) {
              if (idType === CriteriaTypeEnum.STABILITY_LE && val1 < summaryData.stabilityExpandedUncertainty) {
                summaryData.stabilityValid = false;
              } else if (idType === CriteriaTypeEnum.STABILITY_LOWER && val1 <= summaryData.stabilityExpandedUncertainty) {
                summaryData.stabilityValid = false;
              }
            }

            const criteriasSensor = [CriteriaTypeEnum.GREATER_THAN, CriteriaTypeEnum.LOWER_THAN, CriteriaTypeEnum.GREATER_EQUAL,
            CriteriaTypeEnum.LOWER_EQUAL, CriteriaTypeEnum.TOLERANCE, CriteriaTypeEnum.RANGE];

            if (criteriasSensor.includes(idType)) {
              this.calculatedData.forEach(sen => {

                if (idType === CriteriaTypeEnum.LOWER_EQUAL && sen.maxValue > val1) {
                  sen.isErrMax = true;
                } else if (idType === CriteriaTypeEnum.LOWER_THAN && sen.maxValue >= val1) {
                  sen.isErrMax = true;
                } else if (idType === CriteriaTypeEnum.GREATER_THAN && sen.minValue <= val1) {
                  sen.isErrMin = true;
                } else if (idType === CriteriaTypeEnum.GREATER_EQUAL && sen.minValue < val1) {
                  sen.isErrMin = true;
                } else if (idType === CriteriaTypeEnum.TOLERANCE) {
                  if (sen.minValue < val1 - val2) {
                    sen.isErrMin = true;
                  }
                  if (sen.maxValue > val1 + val2) {
                    sen.isErrMax = true;
                  }
                } else if (idType === CriteriaTypeEnum.RANGE) {
                  if (sen.minValue < val1) {
                    sen.isErrMin = true;
                  }
                  if (sen.maxValue > val2) {
                    sen.isErrMax = true;
                  }
                }
              });
            }
          });
          this.summaryData = summaryData;
        }
      });
     }
    }

  }

  public onError(event: any): void {

    if (event.target.id === '2203') {
      this.restartGraph();
    }
  }

  public restartGraph(): void {
    this.minDate = DateUtils.anyToDate(this.dateZoomInit);
    this.maxDate = this.calculateEndDate(this.minDate as Date, this.fcEndEssay);
  }

  calculateMinDate(): Date {
    let date: Date;

    this.datasource.forEach(value => {
      if (date == null) {
        date = value.date;
      } else if (date > value.date) {
        date = value.date;
      }

    });

    if (this.fcStartEssay != null && !this.fullEssay) {
      const dates = [];
      dates.push(DateUtils.anyToDate(this.fcStartEssay));
      dates.push(DateUtils.anyToDate(date));
      // Comparamos las fechas a través del apply
      date = DateUtils.anyToDate(Math.max.apply(null, dates));
    }

    return date;
  }

  maxDaysRange(): number {
    let res = this.daysRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.daysRange * 1.20;
    }

    return res;
  }

  maxHoursRange(): number {
    let res = this.hoursRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.hoursRange * 1.20;
    }

    return res;
  }

  maxMinutesRange(): number {
    let res = this.minutesRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.minutesRange * 1.20;
    }

    return res;
  }

  minDaysRange(): number {
    let res = this.daysRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.daysRange / 1.20;
    }

    return res;
  }

  minHoursRange(): number {
    let res = this.hoursRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.hoursRange / 1.20;
    }

    return res;
  }

  minMinutesRange(): number {
    let res = this.minutesRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.minutesRange / 1.20;
    }

    return res;
  }

  customizeSliderMarker: ((arg0: any) => string) = (data) => {
    return moment(data.value as Date).format('DD/MM/YYYY HH:mm:ss');
  }

  customizeLabel: ((arg0: any) => string) = (data) => {
    return moment(data.value as Date).format('DD/MM/YYYY HH:mm:ss');
  }

  customizeDateLabel: ((arg0: any) => string) = (data) => {
    let res = '';

    if (typeof data === 'string') {
      res = this.customizeDateLabel(DateUtils.anyToDate(data));
    } else if (typeof data === 'number') {
      res = this.customizeDateLabel(DateUtils.anyToDate(data));
    } else {
      res = (data as Date).toLocaleString();
    }

    return res;
  }

  showCopyDateFirstSensor(): boolean {
    return this.variableIndex !== 0;
  }

  onCopyTimeGraphChange(event: MatCheckboxChange): void {
    const value = event.checked;

    this.copyDateFirstSensor = value;

    this.copyDateFirstSensorEmitter.emit({
      variableIndex: this.variableIndex,
      value
    });
  }

  private calculateEndDate(initDate: Date, endDate: Date): Date {
    if (this.fullEssay) {
      let date: Date;

      this.datasource.forEach(value => {
        if (date == null) {
          date = value.date;
        } else if (date < value.date) {
          date = value.date;
        }
      });

      return date;
    }

    if (initDate == null || (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '')) {
      return endDate;
    }

    let gap = 0;
    if (!isNaN(this.daysRange) && this.daysRange > 0) {
      gap += this.daysRange * 86400000;
    }
    if (!isNaN(this.hoursRange) && this.hoursRange > 0) {
      gap += this.hoursRange * 3600000;
    }
    if (!isNaN(this.minutesRange) && this.minutesRange > 0) {
      gap += this.minutesRange * 60000;
    }

    const calculated = DateUtils.anyToDate(initDate.getTime() + gap);

    const listDates = [...this.datasource.map(d => d.date.getTime())];
    let lastDate = new Date(ArrayUtils.max(listDates));

    if (calculated != null) {
      const dates = [];
      dates.push(DateUtils.anyToDate(calculated));
      dates.push(DateUtils.anyToDate(lastDate));
      // Comparamos las fechas a través del apply
      lastDate = DateUtils.anyToDate(Math.min.apply(null, dates));
    }

    return lastDate;
  }

}
