import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../../services/users.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../../../model/user';
import { SpinnerService } from '../../../../services/spinner.service';
import { UserService } from '../../../../services/user.service';
import { SignersInfo } from '../../../../model/signatureDocumentUsers';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-protocol-q-edit-dialog-signers',
  templateUrl: './protocol-q-edit-dialog-signers.component.html',
  styleUrls: ['./protocol-q-edit-dialog-signers.component.scss']
})
export class ProtocolQEditDialogSignersComponent implements OnInit {

  respDocs: User[];
  respDocsFiltered: User[];

  currentUser: User;
  signer: SignersInfo;
  

  private destroy$ = new Subject<void>();

  constructor(private usersService: UsersService,
    private userService: UserService,
    private spinnerService: SpinnerService
  ) {
    this.currentUser = this.userService.currentProfile;

  }

  ngOnInit(): void {
    let usersToRemove = [];
    this.spinnerService.show();

    this.usersService.findAllUserToSignFromGroup().pipe(takeUntil(this.destroy$)).subscribe((item: User[]) => {
      const sorted = item.filter(u => !usersToRemove.includes(u.username) && u.username !== this.currentUser.username)
        .sort((v1, v2) => v1.fullName.localeCompare(v2.fullName));
      this.respDocs = sorted;
      this.respDocsFiltered = sorted.filter(u => u.fullName !== this.currentUser.fullName);

      this.spinnerService.hide();
    });
  }

  onNextSignChange(event: MatSelectChange): void {
    this.signer.username = event.value;
  }

}
