<div class="main-container">

  <mat-card appearance="outlined" class="loginBox">
    <mat-card-content>
      <form #userlogin="ngForm" class="form">
        <h2>{{ 'forgottenPassword.title' | translate }}</h2>
        <mat-form-field class="full-width">
          <input matInput placeholder="{{ 'login.username.label' | translate }}" name="username"
            autocomplete="username" [(ngModel)]="username" required [errorStateMatcher]="matcher"
            (keydown.enter)="forgotPassword()">
          <mat-error>
            {{ 'login.username.error' | translate }}
          </mat-error>
        </mat-form-field>
        <p *ngIf="message">
          {{ message }}
        </p>
        <p *ngIf="error" class="error">
          {{ error }}
        </p>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="forgotPassword()" color="primary">{{ 'button.send' | translate }}</button>
    </mat-card-actions>
  </mat-card>

  <a class="under-card-link" (click)="backLogin()">
    {{ 'button.goBack' | translate }}
  </a>

</div>
