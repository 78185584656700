import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ng-flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ProtocolListComponent } from './protocol-list.component';
import { ProtocolListFilterComponent } from './protocol-list-filter.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ThermalModule } from '../thermal.module';

@NgModule({
    declarations: [
        ProtocolListComponent,
        ProtocolListFilterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThermalModule,
        /* Custom modules */
        AngularMaterialModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule
    ],
    exports: [ProtocolListComponent, ProtocolListFilterComponent]
})
export class ProtocolListModule { }
