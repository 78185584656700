import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CompressedGas, CompressedGasEnum } from 'src/app/model/compressedGasesAir';
import { DialogDataConfirmSave, ExecutionEditConfirmSaveComponent }
from '../../thermal/execution-edit/execution-edit-dialog-confirmSave.component';
import { EssayAirTypeEnum, EssayType } from 'src/app/model/essayType';
import { Execution, ExecutionAir, ExecutionStatus } from 'src/app/model/execution';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import { GenericClass, GenericClassTranslate, GenericWarnError } from 'src/app/model/genericClass';
import { ProtocolAir, ProtocolAirFilter, ProtocolAssignable } from 'src/app/model/protocol';
import { RoomAir, RoomAirEnum } from 'src/app/model/roomAir';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { AirUtils } from 'src/app/utils/airUtils';
import { Area } from 'src/app/model/area';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { CompressedGasesTypeService } from 'src/app/services/compressedGasesType.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { DateUtils } from 'src/app/utils/dateUtils';
import { EditDocumentCodeEditComponent } from '../../shared/edit-document-code-edit/edit-document-code-edit.component';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import { EquipmentAir } from 'src/app/model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EquipmentTypeAirService } from 'src/app/services/equipmentTypeAir.service';
import { EssayAirService } from 'src/app/services/essayAir.service';
import { EssayConfigAir } from 'src/app/model/essayConfigAir';
import { EssayConfigAirService } from 'src/app/services/essayConfigAir.service';
import { EssayExecutionAirInternalEquipment } from 'src/app/model/essayExecutionAirInternalEquipment';
import { EssayProtocolAir } from 'src/app/model/essayProtocolAir';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { ExecutionAirUtils } from 'src/app/utils/executionAirUtils';
import { ExecutionEditAttachmentsComponent } from './execution-edit-attachments.component';
import { ExecutionEditAuditComponent } from './execution-edit-audit.component';
import { ExecutionEditDialogAutomaticSignComponent } from './execution-edit-dialog-automatic-sign.component';
import { ExecutionEditDialogEssayComponent } from './execution-edit-dialog-essay.component';
import { ExecutionEditGenerateReportComponent } from './execution-edit-generate-report.component';
import { ExecutionEditValidateExecutionAutomaticSignComponent } from './execution-edit-validate-execution.component-automatic-sign';
import { ExecutionEditValidateExecutionComponent } from './execution-edit-validate-execution.component';
import { ExecutionEssayDialogResponse } from 'src/app/model/executionEssayDialogResponse';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { FilterAir } from 'src/app/model/filterAir';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { OnlineService } from 'src/app/services/online.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';
import { ProtocolEditDialogAreaComponent } from '../protocol-edit/protocol-edit-dialog-area.component';
import { ProtocolEditDialogCompressedGasAirComponent } from '../protocol-edit/protocol-edit-dialog-compressed-gas-air.component';
import { ProtocolEditDialogEquipmentAirComponent } from '../protocol-edit/protocol-edit-dialog-equipment-air.component';
import { ProtocolEditDialogEssayAirComponent } from '../protocol-edit/protocol-edit-dialog-essay-air.component';
import { ProtocolEditDialogRoomAirComponent } from '../protocol-edit/protocol-edit-dialog-room-air.component';
import { ProtocolListComponent } from '../protocol-list/protocol-list.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { RoomSubtypeAirService } from 'src/app/services/roomSubtypeAir.service';
import { RoomTypeAirService } from 'src/app/services/roomTypeAir.service';
import { SignInfo } from 'src/app/model/sign';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { Volume } from 'src/app/model/volume';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { EditDataProtocolComponent, DialogDataProtocol } from '../../shared/edit-data-protocol/edit-data-protocol.component';
import { EssayExecutionResultAir } from '../../../model/essayExecutionResultAir';
import { Criteria } from '../../../model/criteria';
import { ClientShort } from 'src/app/model/clientShort';
import { Field } from 'devextreme/ui/filter_builder';
import { RoomUtils } from 'src/app/utils/RoomUtils';
import { RoleEnum } from 'src/app/model/roleUser';
import { PendingSignExecutionService } from '../../../services/pendingSignExecution.service';

@Component({
  selector: 'app-execution-edit',
  templateUrl: './execution-edit.component.html'
})
export class ExecutionEditComponent implements OnInit {

  @ViewChild(ExecutionEditAuditComponent) audit: ExecutionEditAuditComponent;
  @ViewChild('protocolAutocomplete') protocolAutocompleteInput: ElementRef;
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;
  @ViewChildren('attachmentsComponent') attachmentsComponent: ExecutionEditAttachmentsComponent;

  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];

  currentUser: User;

  execution: ExecutionAir;
  original: ExecutionAir;
  protocol = new ProtocolAir();

  useExistingProtocol: boolean;
  protocolFilter = new ProtocolAirFilter();
  protocols: ProtocolAir[];
  protocolsFiltered: ProtocolAir[];
  equipmentTypes: EquipmentType[];
  pendingSigns: SignInfo[];

  clients: Client[];
  clientsShort: ClientShort[];
  clientsFiltered: Client[];
  clientsShortFiltered: ClientShort[];
  processes: GenericClass[];

  respField: User[];
  areasToShow: Area[];

  execHasChanges = false;
  areaToCreate: Area[] = [];
  areaToEdit: Area[] = [];
  areaToDelete: Area[] = [];

  roomsToCreate: RoomAir[] = [];
  roomsToEdit: RoomAir[] = [];
  roomsToDelete: RoomAir[] = [];

  equipmentsToCreate: EquipmentAir[] = [];
  equipmensToEdit: EquipmentAir[] = [];
  equipmensToDelete: EquipmentAir[] = [];

  essaysRoomToCreate: EssayProtocolAir[] = [];
  essaysRoomToEdit: EssayProtocolAir[] = [];
  essaysRoomToDelete: EssayProtocolAir[] = [];

  essayTypes: EssayType[];
  equipmentTypesAir: EquipmentType[];
  roomTypes: GenericClassTranslate[];
  roomSubtypes: GenericClassTranslate[];
  compressedGasesAirTypes: GenericClassTranslate[];

  areaTab = 0;

  mapEquipmentsAccordion: Map<number, boolean> = new Map();
  mapRoomsAccordion: Map<number, boolean> = new Map();
  mapCompressedGasesAccordion: Map<number, boolean> = new Map();
  isOnline = true;

  disableOfflineButton = false;
  private destroy$ = new Subject<void>();
  private hasLoaded$ = new Subject<void>();
  private online: boolean;
  private prevProtocolAutocomplete: Subscription;
  private loadingAutocompleteSubject = new BehaviorSubject<boolean>(false);
  private executionEssays: EssayProtocolAir[];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public loadingAutocomplete$ = this.loadingAutocompleteSubject.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    public dialog: MatDialog,
    private equipmentTypeService: EquipmentTypeAirService,
    private equipmentTypeAirService: EquipmentTypeAirService,
    private essayService: EssayAirService,
    private executionService: ExecutionAirService,
    private processService: ProcessService,
    private protocolService: ProtocolAirService,
    private onlineService: OnlineService,
    private roomTypeService: RoomTypeAirService,
    private roomSubtypeAirService: RoomSubtypeAirService,
    private compressedGasesTypeService: CompressedGasesTypeService,
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private userService: UserService,
    private usersService: UsersService,
    private essayConfigService: EssayConfigAirService,
    private pendingSignExecutionService: PendingSignExecutionService,
    private spinnerService: SpinnerService) {
    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;
      const idProtocolParam = +params[Constants.FIELD_ID_PROTOCOL];

      if (id == null) {
        this.cancel();
      }

      this.protocols = [];
      this.clients = [];

      this.execution = new ExecutionAir();
      if (+id === 0) {
        this.spinnerService.hide();

        this.clearProtocolFilter();
        this.protocolService.findAllAssignable(this.protocolFilter).pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data && data.content) {
            this.protocols = data.content as ProtocolAir[];
          } else if (data) {
            this.protocols = data as ProtocolAir[];
          } else {
            this.protocols = [];
          }
          this.protocolsFiltered = this.protocols.slice();
        });

        this.useExistingProtocol = true;
        if (idProtocolParam != null) {
          this.onProtocolChange(idProtocolParam);
        }

       // this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => this.loadClients(data));
      this.clientService.findAllShort().pipe(takeUntil(this.destroy$)).subscribe((data: ClientShort[]) => this.loadClientsShort(data));
      } else {
        this.executionService.findOne(id).pipe(takeUntil(this.hasLoaded$)).subscribe((result: ExecutionAir) => {
          if (result != null) {
            this.execution = result;
            this.execution.referenceDocs?.sort((eq1, eq2) => {
              let order = eq1.id - eq2.id;

              if (order === 0) {
                order = eq1.id - eq2.id;
              }

              return order;
            });
            this.execution.areas.forEach(area => {
              area.rooms = area.rooms.sort((eq1, eq2) => {
                let order = eq1.order - eq2.order

                if (order === 0) {
                  order = eq1.name.localeCompare(eq2.name);
                }

                return order;
              });

              area.equipments = area.equipments.sort((eq1, eq2) => {
                let order = eq1.order - eq2.order;

                if (order === 0) {
                  order = eq1.id - eq2.id;
                }

                return order;
              });

              area.equipments.forEach(eq => {
                eq.filters = eq.filters.sort((f1, f2) => f1.id - f2.id);
                eq.essays.forEach(essay => essay.essayValues = essay.essayValues.map(i => FieldUtils.objectToField(i)));
              });

              area.rooms.forEach(room => {
                room.filters = room.filters.sort((f1, f2) => f1.id - f2.id);
                room.volumes = room.volumes.map(v => ExecutionAirUtils.objectToVolume(v));
                room.essays.forEach(essay => essay.essayValues = essay.essayValues.map(i => FieldUtils.objectToField(i)));
              });

              area.compressedGases
                .forEach(gas => gas.essays.forEach(essay => essay.essayValues = essay.essayValues.map(i => FieldUtils.objectToField(i))));
            });

            this.areasToShow = this.execution.areas?.sort((f1, f2) => f1.id - f2.id);
            void this.setAccordingEssays();

            this.useExistingProtocol = this.execution.idProtocol != null;

            if (this.useExistingProtocol) {
              this.protocol = new ProtocolAir();
            }

            // this.executionService.signInfo(this.execution).subscribe((res: SignInfo[]) => this.pendingSigns = res);
            this.spinnerService.hide();

            this.updateAttachmentList();

            if (result.idProtocol) {
              this.protocolService.findOne(result.idProtocol).pipe(takeUntil(this.hasLoaded$)).subscribe((prot: ProtocolAir) => {
                this.protocol = prot;
                this.spinnerService.hide();
                this.clientService.findOne(result.idClient).pipe(takeUntil(this.destroy$)).subscribe((data: Client) => {
                  this.protocol.idClient = result.idClient;
                  this.loadClients([data]);
                  this.hasLoaded$.next();
                });
              });
            } else {
              this.spinnerService.hide();
              this.clientService.findOne(result.idClient).pipe(takeUntil(this.destroy$)).subscribe((data: Client) => {
                this.loadClients([data]);
                this.hasLoaded$.next();
                this.spinnerService.hide();
              });
            }

          } else {
            this.execution = null;
          }
        }, () => {
          this.execution = null;

          this.spinnerService.hide();
        }, () => {
          if (this.execution == null) {
            this.cancel();
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.currentUser = this.userService.currentProfile;

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);
    this.essayService.findAllAir().pipe(takeUntil(this.destroy$)).subscribe((res: EssayType[]) => this.essayTypes = res);
    this.equipmentTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypes = res);
    this.equipmentTypeAirService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypesAir = res);
    this.roomTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: GenericClassTranslate[]) => this.roomTypes = res);
    this.roomSubtypeAirService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: GenericClassTranslate[]) => this.roomSubtypes = res);
    this.compressedGasesTypeService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: GenericClassTranslate[]) => this.compressedGasesAirTypes = res);
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.audit.getAudit();
    }
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION, ExecutionStatus.PENDIENTE_FIRMA];

    return this.execution.id != null && allowedStatuses.includes(this.execution.idStatus);
  }

  editDocumentCode(): void {
    const dialogRef = this.dialog.open(EditDocumentCodeEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        documentCode: this.execution.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {
        this.execution.documentCode = result;
      }
    });
  }

  onUseExistingProtocolChange(event: MatRadioChange): void {
    this.useExistingProtocol = event.value as boolean;

    if (this.useExistingProtocol) {
      this.execution.idClient = null;
      this.execution.idProcess = null;
      this.execution.projectNo = null;
      this.execution.usernameProjectManager = null;
    } else {
      this.protocol = null;
      this.execution.idProtocol = null;
    }
  }

  downloadProtocolPdf(): void {
    this.spinnerService.show();

    this.protocolService.downloadPdfVersion(this.execution.idProtocol, this.execution.originalProtocolVersion.toString())
      .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = this.protocol.documentCode + ' v.' + this.execution.originalProtocolVersion.toString().padStart(2, '0');

        saveAs(res, this.translate.instant('executionEdit.files.report', { name }) as string);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
  }

  downloadPdf(): void {
    const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol,
        reason: this.execution.reason
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
      if (result != null) {
        this.execution.conclusions = result.conclusions;
      }
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('air/executions');
  }

  onProtocolChange(event: MatAutocompleteSelectedEvent | number): void {
    this.spinnerService.show();

    let idProtocol: number = null;

    if (event instanceof MatAutocompleteSelectedEvent) {
      idProtocol = (event.option.value as ProtocolAssignable)?.id;
    } else {
      idProtocol = event;
    }

    if (idProtocol == null || isNaN(idProtocol)) {
      this.spinnerService.hide();

      return;
    }

    this.execution.idProtocol = idProtocol;

    this.protocolService.findOne(idProtocol).pipe(takeUntil(this.destroy$)).subscribe((prot: ProtocolAir) => {
      this.protocol = prot;

      this.protocolAutocompleteInput.nativeElement.value = prot.documentCode;

      this.areasToShow = this.convertAreas(this.protocol.areas);

      this.spinnerService.hide();
    });
  }

  loadClients(res: Client[]): void {
    this.clients = res;
    this.clientsFiltered = this.clients.slice();
  }

  loadClientsShort(res: ClientShort[]): void {
    this.clientsShort = res;
    this.clientsShortFiltered = this.clientsShort.slice();
  }

  displayProtocol(prot: ProtocolAssignable): string {
    if (!prot) {
      return '';
    }

    const documentCode = prot?.documentCode != null ? prot.documentCode : '';
    const client = prot?.nameClient != null ? prot.nameClient : '';

    return documentCode.concat(' ').concat(client);
  }

  openSearchProtocol(): void {
    event.stopPropagation();

    this.loadingAutocompleteSubject.next(true);

    const dialogRef = this.dialog.open(ProtocolListComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        isList: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
      this.loadingAutocompleteSubject.next(false);
      if (result != null) {
        this.onProtocolChange(result);
      }
    });
  }

  openNewRefDoc(): void {
    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.execution.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {
    const refDoc = this.execution.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.execution.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.execution.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  lookupProtocol($event): void {
    if (this.prevProtocolAutocomplete) {
      this.prevProtocolAutocomplete.unsubscribe();
    }

    this.loadingAutocompleteSubject.next(true);

    const filter = new ProtocolAirFilter();

    filter.autocomplete = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';

    this.prevProtocolAutocomplete = this.protocolService.findAllAssignable(filter).pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data && data.content) {
        this.protocols = data.content as ProtocolAir[];
      } else if (data) {
        this.protocols = data as ProtocolAir[];
      } else {
        this.protocols = [];
      }

      this.protocolsFiltered = this.protocols.slice();

      this.loadingAutocompleteSubject.next(false);
    });
  }

  showRevertSign(): boolean {
    const resultRol = this.currentUser?.profiles?.find(e => e.idRole === RoleEnum.MANAGER || e.idRole === RoleEnum.ADMIN);
    return this.execution.idStatus === ExecutionStatus.FIRMADO
    && (resultRol != null && resultRol !== undefined);
  }

  revertSign(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result === true) {
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });
          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.executionService.revertSign(this.execution.id, reason, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.spinnerService.hide();
              this.reloadPage(this.execution.id);
            });
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.form.revertSign.error') as string);
            }
          });
        });
      }
    });
  }

  showBack(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    if (this.execution.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA];

    return validStatuses.includes(this.execution.idStatus);
  }

  calculateBack(): void {

    if (this.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA) {
      this.validateInvalidateByDoc();
    } else {
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.error.notAllowed') as string);
    }

  }

  validateInvalidateByDoc(): void {
    const errors = [];

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    this.pendingSignExecutionService.validateByIdAndUsername(this.execution.id).pipe(takeUntil(this.destroy$)).subscribe((res: Boolean) => {
      if (res) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message:  this.translate.instant('executionEdit.dialog.generateReport.confirm.notLastSign') as string
          }
        });
        
        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
          if (result === true) {
            this.invalidateByDoc();
          }
        });
      } else {
        this.invalidateByDoc();
      }
    });

  }

  invalidateByDoc(): void {
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        this.requestReason((reason) => {
          this.spinnerService.show();

          this.executionService.invalidateByDoc(this.execution, result, reason).pipe(takeUntil(this.destroy$))
            .subscribe((item: Execution) => {
              const status = this.translate.instant(`executionStatus.${item.statusTranslation}`) as string;
              this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.status.back.ok', { status }) as string);

              this.reloadPage();

              this.spinnerService.hide();

            }, () => {
              this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);

              this.spinnerService.hide();
            });
        });
      } else {
        this.spinnerService.hide();
      }
    });
  }



  showExecute(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.EN_EJECUCION];

    return validStatuses.includes(this.execution.idStatus);
  }

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.manualSign && ArrayUtils.isEmpty(this.pendingSigns);
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.automaticSign;
  }

  execute(): void {
    /* this.execution.areas.forEach(area => {
      area.equipments.forEach(eq => eq.essays
        .forEach(essay => essay.according = AirUtils.checkAccordingEssay(essay, null, eq.filters, eq.idType, false)));
      area.rooms.forEach(room => {
        const volume = ExecutionAirUtils.getTotalVolume(room);
        room.essays.forEach(essay => essay.according = AirUtils.checkAccordingEssay(essay, volume, room.filters, 1, false));
      });
      area.compressedGases
        .forEach(gas => gas.essays.forEach(essay => essay.according = AirUtils.checkAccordingEssay(essay, null, [], 1, true)));
    }); */

    void this.setAccordingEssays().then(() => {
      this.saveExecutionCallback(() => {
        this.showDialogValidate();
      });
    });
  }

  manualSign(): void {
    this.saveExecutionCallback(() => {
      this.showDialogValidate();
    });
  }

  automaticSign(): void {
    this.spinnerService.show();

    this.executionService.signInfo(this.execution).pipe(takeUntil(this.destroy$)).subscribe((res: SignInfo[]) => {
      this.spinnerService.hide();

      if (res == null || res.length === 0) {
        this.saveExecutionCallback(() => {
          this.showDialogValidateAutomatic();
        });
      } else {
        this.showDialogSignAutomatic();
      }

    }, () => {
      this.spinnerService.hide();
      this.saveExecutionCallback(() => {
        this.showDialogValidateAutomatic();
      });
    }
    );

  }

  showDownloadButton(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA, ExecutionStatus.FIRMADO];

    return validStatuses.includes(this.execution.idStatus);
  }

  saveExecution(): void {
    this.online = this.onlineService.latestOnline;
    const errors: string[] = [];
    const isCreation = this.execution.id == null;
    void this.setAccordingEssays();
    this.execution.workOffline = !this.online;
    if (!this.useExistingProtocol) {
      if (isCreation) {
        if (this.execution.idClient == null) {
          errors.push(this.translate.instant('executionEdit.protocol.client.error') as string);
        }
        if (this.execution.idProcess == null) {
          errors.push(this.translate.instant('executionEdit.protocol.process.error') as string);
        }
        if (this.execution.projectNo == null) {
          errors.push(this.translate.instant('executionEdit.protocol.projectNo.error') as string);
        }
      } else {
        if (ArrayUtils.isEmpty(this.areasToShow)) {
          errors.push(this.translate.instant('protocolEdit.areas.empty') as string);
        }
      }
    }

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      this.spinnerService.hide();

      return;
    }
    this.execution.areas = this.areasToShow;
    if (isCreation) {
      const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {}
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

        if (result != null) {

          if (!this.useExistingProtocol) {
            const ref1 = new ReferenceDocument();
            ref1.code = 'UNE-EN ISO 14644';
            ref1.name = this.translate.instant('protocolEdit.referenceDocs.default.iso14644.name', { breakline: '\n\t' }) as string;

            const ref2 = new ReferenceDocument();
            ref2.code = 'GMP';
            ref2.name = this.translate.instant('protocolEdit.referenceDocs.default.gmp.name', { breakline: '\n\t' }) as string;

            this.execution.referenceDocs = [ref1, ref2];
          }

          this.spinnerService.show();

          this.executionService.create(this.execution, result).pipe(takeUntil(this.destroy$)).subscribe((item: Execution) => {
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.create.ok') as string);

            void this.router.navigateByUrl(`air/execution?id=${item.id}`);

            this.spinnerService.hide();
          }, err => {
            let message = err.error as string;
            if (message == null) {
              message = this.translate.instant('executionEdit.form.create.error.generic') as string;
            }
            this.snackBarService.sendError(message);
            this.spinnerService.hide();
          });

        } else {
          this.spinnerService.hide();
        }

      });

    } else if (this.execution.idStatus === ExecutionStatus.FIRMADO || (+this.execution.currentVersion) > 1) {

      this.execution.areas = this.areasToShow;

      const dialogRef = this.dialog.open(ExecutionEditConfirmSaveComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          reason: this.execution.reason,
          showReason: true
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {

        if (result != null) {

          const reason = result.reason;

          const dialogRefConfirmPassword = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });

          dialogRefConfirmPassword.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(resultConfirm => {

            this.execution.reason = reason;

            if (resultConfirm != null) {
              this.spinnerService.show();

              this.executionService.save(this.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.save.ok') as string);

                this.spinnerService.hide();

                this.reloadPage(this.execution.id);
              }, err => {
                if (err != null && err.error != null && typeof err.error === 'string') {
                  this.snackBarService.sendError(err.error as string);
                } else {
                  this.snackBarService.sendError(this.translate.instant('executionEdit.form.save.error.generic') as string);
                }

                this.spinnerService.hide();
              });

            } else {
              this.spinnerService.hide();
            }
          });
        }

      });

    } else {
      if (this.online) {
        const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
          minWidth: '20%',
          maxHeight: '95vh',
          data: {}
        });
        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

          if (result != null) {
            this.save();

          } else {
            this.spinnerService.hide();
          }
        });
      } else {
        this.save();
      }

    }

  }

  reloadPage(idExecution = this.execution.id): void {
    if (idExecution == null) {
      idExecution = this.execution.id;
    }

    const url = `air/execution?id=${idExecution}`;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(url);
  }

  onAreaTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.areaTab = tabChangeEvent.index;
  }

  isEquipmentOpened(index: number): boolean {
    return this.mapEquipmentsAccordion.get(index);
  }

  setEquipmentOpened(index: number, state: boolean): void {
    this.mapEquipmentsAccordion.set(index, state);
  }

  isRoomOpened(index: number): boolean {
    return this.mapRoomsAccordion.get(index);
  }

  setRoomOpened(index: number, state: boolean): void {
    this.mapRoomsAccordion.set(index, state);
  }

  isCompressedGasOpened(index: number): boolean {
    return this.mapCompressedGasesAccordion.get(index);
  }

  setCompressedGasOpened(index: number, state: boolean): void {
    this.mapCompressedGasesAccordion.set(index, state);
  }

  moveToTop(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.execution.areas[areaIndex].rooms;
    ArrayUtils.moveToFirst(rooms, index);

    this.execution.areas[areaIndex].rooms = this.reorderRooms(rooms);
  }

  movePrevious(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.execution.areas[areaIndex].rooms;
    ArrayUtils.moveToPrevious(rooms, index);

    this.execution.areas[areaIndex].rooms = this.reorderRooms(rooms);
  }

  moveNext(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.execution.areas[areaIndex].rooms;
    ArrayUtils.moveToNext(rooms, index);

    this.execution.areas[areaIndex].rooms = this.reorderRooms(rooms);
  }

  moveToBottom(areaIndex: number, index: number): void {
    event.stopPropagation();

    const rooms = this.execution.areas[areaIndex].rooms;
    ArrayUtils.moveToLast(rooms, index);

    this.execution.areas[areaIndex].rooms = this.reorderRooms(rooms);
  }

  moveToTopCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const gases = this.execution.areas[areaIndex].compressedGases;
    ArrayUtils.moveToFirst(gases, index);

    this.execution.areas[areaIndex].compressedGases = this.reorderCompressedGases(gases);
  }

  movePreviousCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const gases = this.execution.areas[areaIndex].compressedGases;
    ArrayUtils.moveToPrevious(gases, index);

    this.execution.areas[areaIndex].compressedGases = this.reorderCompressedGases(gases);
  }

  moveNextCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const gases = this.execution.areas[areaIndex].compressedGases;
    ArrayUtils.moveToNext(gases, index);

    this.execution.areas[areaIndex].compressedGases = this.reorderCompressedGases(gases);
  }

  moveToBottomCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const gases = this.execution.areas[areaIndex].compressedGases;
    ArrayUtils.moveToLast(gases, index);

    this.execution.areas[areaIndex].compressedGases = this.reorderCompressedGases(gases);
  }

  moveToTopEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.execution.areas[areaIndex].equipments;
    ArrayUtils.moveToFirst(equipments, index);

    this.execution.areas[areaIndex].equipments = this.reorderEquipments(equipments);
  }

  movePreviousEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.execution.areas[areaIndex].equipments;
    ArrayUtils.moveToPrevious(equipments, index);

    this.execution.areas[areaIndex].equipments = this.reorderEquipments(equipments);
  }

  moveNextEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.execution.areas[areaIndex].equipments;
    ArrayUtils.moveToNext(equipments, index);

    this.execution.areas[areaIndex].equipments = this.reorderEquipments(equipments);
  }

  moveToBottomEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const equipments = this.execution.areas[areaIndex].equipments;
    ArrayUtils.moveToLast(equipments, index);

    this.execution.areas[areaIndex].equipments = this.reorderEquipments(equipments);
  }

  getTotalSurface(room: RoomAir): number {
    return NumberUtils.sum(room.volumes.map(v => v.surface));
  }

  getDisplayColumns(essays: EssayProtocolAir[]): string[] {
    const res = ['essay'];

    const sortMap = AirUtils.getMapOrderEssay();

    res.push(...essays.sort((e1, e2) => {
      const sort1 = sortMap.get(e1.idEssayType) || 99;
      const sort2 = sortMap.get(e2.idEssayType) || 99;

      return sort1 - sort2;
    }).map(e => this.essayTypes?.find(essay => essay.id === e.idEssayType)?.translation).filter(e => e != null));

    return res;
  }

  getCriteriaByEssayName(eq: EquipmentAir | RoomAir | CompressedGas, essayName: string): string {
    const essay = eq.essays.find(e => essayName === this.getEssayName(e.idEssayType, false));

    return this.getCriteriaOfEssay(essay);
  }

  getEssayName(essayType: number, translate = true): string {
    if (ArrayUtils.isEmpty(this.essayTypes)) {
      return null;
    }

    let res = this.essayTypes.find(essay => essay.id === essayType)?.translation;

    if (translate) {
      res = this.translate.instant('essayAir.' + res) as string;
    }

    return res;
  }

  getCriteriaOfEssay(essay: EssayProtocolAir): string {
    if (essay == null) {
      return '';
    }

    let res = essay.criterias.map(crit => EssayUtils.getCriteriaToShowAir(crit, essay.idEssayType, this.translate)).join('<br />');

    if (StringUtils.isEmpty(res)) {
      res = this.translate.instant('protocolEdit.dialog.essay.fields.empty') as string;
    }

    return res;
  }

  showEquipment(eq: EquipmentAir): string {
    return AirUtils.showEquipment(eq);
  }

  getEquipmentType(eq: EquipmentAir): string {
    if (ArrayUtils.isEmpty(this.equipmentTypes)) {
      return null;
    }

    const eqType = this.equipmentTypes.find(e => e.id === eq.idType);
    return eqType?.translation;
  }

  openDialogArea(): void {
    const dialogRef = this.dialog.open(ProtocolEditDialogAreaComponent, {
      minWidth: '40%',
      maxHeight: '100vh',
      data: {
        area: new Area(),
        isEdit: false,
        isExecution: true,
        withoutProtocol: this.protocol?.id == null
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Area) => {
      if (result != null) {
        result.fromProtocol = false;
        if (this.execution.id != null) {
          result.idExecution = this.execution.id;
        }
        this.areasToShow.push(result);
      }
    });
  }

  openDialogEditArea(idx: number): void {
    const area = this.areasToShow[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogAreaComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        area: _.cloneDeep(area),
        isEdit: true,
        isExecution: true,
        withoutProtocol: this.protocol?.id == null
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Area) => {
      if (result != null) {
        this.areasToShow[idx] = result;
      }
    });
  }

  deleteArea(index: number): void {
    const message = this.translate.instant('protocolEdit.dialog.area.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.areasToShow.splice(index, 1);
        }
      }
    });
  }

  openDialogEquipment(areaIndex: number): void {
    const dialogRef = this.dialog.open(ProtocolEditDialogEquipmentAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentTypes: this.equipmentTypesAir,
        equipment: new EquipmentAir(),
        isEdit: false,
        isExecution: true,
        idExecution: this.execution.id
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentAir) => {
      if (result != null) {
        if (this.areasToShow[areaIndex].equipments == null) {
          this.areasToShow[areaIndex].equipments = [];
        }
        this.areasToShow[areaIndex].equipments.push(result);
      }
    });
  }

  openDialogEditEquipment(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const eq = this.areasToShow[areaIndex].equipments[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogEquipmentAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipmentTypes: this.equipmentTypesAir,
        equipment: _.cloneDeep(eq),
        isEdit: true,
        isExecution: true,
        idExecution: this.execution.id,
        executionStatus: this.execution.idStatus
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentAir) => {
      if (result != null) {
        this.areasToShow[areaIndex].equipments[idx] = result;
      }
    });
  }

  deleteEquipment(areaIndex: number, index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.equipment.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
    const eq = this.areasToShow[areaIndex].equipments[index];
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.requestReason((reasonAdd) => {

            if (this.execution.deviations == null) {
              this.execution.deviations = '';
            } else {
              this.execution.deviations += '\n';
            }

            this.execution.deviations += this.translate.instant('executionEdit.deviations.air.deleteEquipment.label', {
              equipment: this.translate.instant('equipmentType.'.concat(this.getEquipmentType(eq))) as string,
              area: this.areasToShow[areaIndex].name, reason: reasonAdd
            });
            this.areasToShow[areaIndex].equipments.splice(index, 1);
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
          });
        }
      }
    });

  }

  openDialogRoom(areaIndex: number): void {
    const room = new RoomAir();
    room.idType = RoomAirEnum.ROOM;
    const order = Math.max(...this.areasToShow[areaIndex].rooms.map(r => r.order)) || 0;
    room.order = order + 1;

    const dialogRef = this.dialog.open(ProtocolEditDialogRoomAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        roomTypes: this.roomTypes,
        room,
        isEdit: false,
        isExecution: true,
        idExecution: this.execution.id
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: RoomAir) => {
      if (result != null) {
        if (this.areasToShow[areaIndex].rooms == null) {
          this.areasToShow[areaIndex].rooms = [];
        }

        this.areasToShow[areaIndex].rooms.push(result);
        this.areasToShow[areaIndex].rooms = this.reorderRooms(this.areasToShow[areaIndex].rooms);
      }
    });
  }

  openDialogEditRoom(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const room = this.areasToShow[areaIndex].rooms[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogRoomAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        roomTypes: this.roomTypes,
        room: _.cloneDeep(room),
        isEdit: true,
        isExecution: true,
        idExecution: this.execution.id
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: RoomAir) => {
      if (result != null) {
        this.areasToShow[areaIndex].rooms[idx] = result;
        this.areasToShow[areaIndex].rooms = this.reorderRooms(this.areasToShow[areaIndex].rooms);

        this.areasToShow.forEach((area) => {
          area.rooms.forEach((room) => {
            const sumVolume =  RoomUtils.getTotalVolume(room);
            room.essays.forEach(eassy=>{
              eassy.essayValues.forEach(value=>{
                if (value.idField===FieldEnum.VOLUME) {
                  if (value.value !==sumVolume.toString()) {
                    value._value = sumVolume.toString();
                    eassy.modFc = new Date();
                  }
                }
              })
            })
          });
        });
      }
    });
  }

  getTotalVolume(room: RoomAir): number {
     return NumberUtils.sum(room.volumes.map(v => v.surface * v.height));
  }

  deleteRoom(areaIndex: number, index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.room.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    const room = this.areasToShow[areaIndex].rooms[index];
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.requestReason((reasonAdd) => {

            if (this.execution.deviations == null) {
              this.execution.deviations = '';
            } else {
              this.execution.deviations += '\n';
            }

            this.execution.deviations += this.translate.instant('executionEdit.deviations.air.deleteRoom.label', {
              room: room.name, area: this.areasToShow[areaIndex].name, reason: reasonAdd
            });
            this.areasToShow[areaIndex].rooms.splice(index, 1);
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
          });
        }
      }
    });

  }

  openDialogCompressedGas(areaIndex: number): void {
    const gas = new CompressedGas();
    gas.idType = CompressedGasEnum.COMPRESSED_AIR;
    const order = Math.max(...this.areasToShow[areaIndex].compressedGases.map(r => r.order)) || 0;
    gas.order = order + 1;

    const dialogRef = this.dialog.open(ProtocolEditDialogCompressedGasAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        compressedGasTypes: this.compressedGasesAirTypes,
        compressedGas: _.cloneDeep(gas),
        isEdit: false,
        isExecution: true,
        idExecution: this.execution.id
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CompressedGas) => {
      if (result != null) {
        if (this.areasToShow[areaIndex].compressedGases == null) {
          this.areasToShow[areaIndex].compressedGases = [];
        }

        this.areasToShow[areaIndex].compressedGases.push(result);
        this.areasToShow[areaIndex].compressedGases = this.reorderCompressedGases(this.areasToShow[areaIndex].compressedGases);
      }
    });
  }

  openDialogEditCompressedGas(areaIndex: number, idx: number): void {
    event.stopPropagation();

    const gas = this.areasToShow[areaIndex].compressedGases[idx];

    const dialogRef = this.dialog.open(ProtocolEditDialogCompressedGasAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        compressedGasTypes: this.compressedGasesAirTypes,
        compressedGas: _.cloneDeep(gas),
        isEdit: true,
        isExecution: true,
        idExecution: this.execution.id
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CompressedGas) => {
      if (result != null) {
        this.areasToShow[areaIndex].compressedGases[idx] = result;
        this.areasToShow[areaIndex].compressedGases = this.reorderCompressedGases(this.areasToShow[areaIndex].compressedGases);
      }
    });
  }

  deleteCompressedGas(areaIndex: number, index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.compressedGas.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
    const gas = this.areasToShow[areaIndex].compressedGases[index];
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.requestReason((reasonAdd) => {

            if (this.execution.deviations == null) {
              this.execution.deviations = '';
            } else {
              this.execution.deviations += '\n';
            }

            this.execution.deviations += this.translate.instant('executionEdit.deviations.air.deleteGas.label', {
              gas: gas.code, area: this.areasToShow[areaIndex].name, reason: reasonAdd
            });
            this.areasToShow[areaIndex].compressedGases.splice(index, 1);
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
          });
        }
      }
    });

  }

  convertAreas(areas: Area[]): Area[] {
    const execAreas: Area[] = [];

    areas.forEach(area => {
      const execArea = new Area();

      execArea.id = area.id;
      execArea.name = area.name;
      execArea.rooms = area.rooms;
      execArea.equipments = area.equipments;

      execAreas.push(execArea);
    });

    return execAreas?.sort((f1, f2) => f1.id - f2.id);
  }

  showAreaButtons(indexArea: number): boolean {
    return this.areaTab === indexArea && this.canAdd();
  }

  canAdd(): boolean {
    return this.execution.id != null;
  }

  updateAttachmentList(): void {
    if (this.attachmentsComponent instanceof QueryList) {
      this.attachmentsComponent = this.attachmentsComponent.first as ExecutionEditAttachmentsComponent;
    }

    this.attachmentsComponent.setExecution(this.execution);
  }

  checkAccordanceRoom(areaIndex: number, roomIndex: number): string {
    const equipment = this.areasToShow[areaIndex].rooms[roomIndex];

    if (ArrayUtils.isEmpty(equipment.essays)) {
      return 'essayPendingEvaluation';
    }

    const results = equipment.essays.map((essay, essayIndex) => this.checkEssayAccordanceRoom(areaIndex, roomIndex, essayIndex));

    if (results.some(r => r === 'essayPendingEvaluation')) {
      return 'essayPendingEvaluation';
    } else if (results.some(r => r === '')) {
      return '';
    } else if (results.some(r => r === 'essayNotAccording')) {
      return 'essayNotAccording';
    }

    return 'essayAccording';
  }

  checkEssayAccordanceRoom(areaIndex: number, roomIndex: number, essayIndex: number | string): string {
    const room = this.areasToShow[areaIndex].rooms[roomIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = room.essays[essayIndex];
    } else {
      essay = room?.essays?.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = room.essays.findIndex(e => e === essay);
      }
    }

    if (essay == null) {
      return 'essayPendingEvaluation';
    } else if (!essay.active) {
      return 'essayDisabled';
    }

    const volume = ExecutionAirUtils.getTotalVolume(room);

    const according = AirUtils.checkAccordingEssay(essay, volume, room.filters, 1, false);

    return according == null ? 'essayPendingEvaluation' : according ? 'essayAccording' : 'essayNotAccording';
  }

  checkAccordanceCompressedGas(areaIndex: number, compressedGasIndex: number): string {
    const equipment = this.areasToShow[areaIndex].compressedGases[compressedGasIndex];

    if (ArrayUtils.isEmpty(equipment.essays)) {
      return 'essayPendingEvaluation';
    }

    const results = equipment.essays
      .map((essay, essayIndex) => this.checkEssayAccordanceCompressedGas(areaIndex, compressedGasIndex, essayIndex));

    if (results.some(r => r === 'essayPendingEvaluation')) {
      return 'essayPendingEvaluation';
    } else if (results.some(r => r === '')) {
      return '';
    } else if (results.some(r => r === 'essayNotAccording')) {
      return 'essayNotAccording';
    }

    return 'essayAccording';
  }

  checkEssayAccordanceCompressedGas(areaIndex: number, compressedGasIndex: number, essayIndex: number | string): string {
    const compressedGas = this.areasToShow[areaIndex].compressedGases[compressedGasIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = compressedGas.essays[essayIndex];
    } else {
      essay = compressedGas?.essays?.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = compressedGas.essays.findIndex(e => e === essay);
      }
    }

    if (essay == null) {
      return 'essayPendingEvaluation';
    } else if (!essay.active) {
      return 'essayDisabled';
    }

    const according = AirUtils.checkAccordingEssay(essay, null, [], 1, true);
    essay.according = according;
    return according == null ? 'essayPendingEvaluation' : according ? 'essayAccording' : 'essayNotAccording';
  }

  checkAccordanceEquipment(areaIndex: number, equipmentIndex: number): string {
    const equipment = this.areasToShow[areaIndex].equipments[equipmentIndex];

    if (ArrayUtils.isEmpty(equipment.essays)) {
      return 'essayPendingEvaluation';
    }

    const results = equipment.essays.map((essay, essayIndex) => this.checkEssayAccordanceEquipment(areaIndex, equipmentIndex, essayIndex));

    if (results.some(r => r === 'essayPendingEvaluation')) {
      return 'essayPendingEvaluation';
    } else if (results.some(r => r === '')) {
      return '';
    } else if (results.some(r => r === 'essayNotAccording')) {
      return 'essayNotAccording';
    }

    return 'essayAccording';
  }

  checkEssayAccordanceEquipment(areaIndex: number, equipmentIndex: number, essayIndex: number | string): string {
    const equipment = this.areasToShow[areaIndex].equipments[equipmentIndex];
    let volume: number;
    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = equipment.essays[essayIndex];
    } else {
      essay = equipment?.essays?.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = equipment.essays.findIndex(e => e === essay);
      }
    }

    if (essay == null) {
      return 'essayPendingEvaluation';
    } else if (!essay.active) {
      return 'essayDisabled';
    }

    essay.essayValues.forEach(field => {
      if (field.idField === FieldEnum.VOLUME) {
        volume = field.value as number;
      }
    });
    const according = AirUtils.checkAccordingEssay(essay, volume, equipment.filters, equipment.idType, false);
    essay.according = according;
    return according == null ? 'essayPendingEvaluation' : according ? 'essayAccording' : 'essayNotAccording';
  }

  openDialogEssayEquipment(index: number, areaIndex: number): void {
    event.stopPropagation();

    const area = this.areasToShow[areaIndex];
    const equipment = area.equipments[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: new EssayProtocolAir(),
        idsEssaysToRemove: equipment.essays.map(e => e.idEssayType),
        idEquipment: equipment.idType,
        isEdit: false,
        surfaceRoom: null,
        accesories: equipment.accessories,
        filters: equipment.filters,
        isEquipment: true,
        isExecution: true,
        entityType: 'equipment'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir) => {
      if (result != null) {
        if (this.protocol?.id != null) {
          // aquí
          this.requestReason((reasonAdd) => {
            result.reasonChange = reasonAdd;
            this.areasToShow[areaIndex].equipments[index].essays.push(result);

            if (this.execution.deviations == null) {
              this.execution.deviations = '';
            } else {
              this.execution.deviations += '\n';
            }

            this.execution.deviations += this.translate.instant('executionEdit.deviations.air.addEssay.equipment.label', {
              essay: this.getEssayName(result.idEssayType, true), equipment: AirUtils.showEquipment(equipment), area: area.name,
              reason: reasonAdd
            });

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
          });
        } else {
          this.areasToShow[areaIndex].equipments[index].essays.push(result);

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
        }
      }
    });
  }

  openDialogEssayRoom(index: number, areaIndex: number): void {
    event.stopPropagation();

    const area = this.areasToShow[areaIndex];
    const room = area.rooms[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: new EssayProtocolAir(),
        idsEssaysToRemove: room.essays.map(e => e.idEssayType),
        idEquipment: 1,
        isEdit: false,
        surfaceRoom: this.calculateSurface(room.volumes),
        volumeRoom: this.calculateVolume(room.volumes),
        accesories: null,
        filters: room.filters,
        isEquipment: false,
        isExecution: true,
        entityType: 'room'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir) => {
      if (result != null) {
        if (this.protocol?.id != null) {
          this.requestReason((reasonAdd) => {
            result.reasonChange = reasonAdd;
            this.areasToShow[areaIndex].rooms[index].essays.push(result);

            if (this.execution.deviations == null) {
              this.execution.deviations = '';
            } else {
              this.execution.deviations += '\n';
            }

            this.execution.deviations += this.translate.instant('executionEdit.deviations.air.addEssay.room.label', {
              essay: this.getEssayName(result.idEssayType, true), room: room.name, area: area.name,
              reason: reasonAdd
            });

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
          });
        } else {
          this.areasToShow[areaIndex].rooms[index].essays.push(result);

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
        }
      }
    });
  }

  openDialogEssayCompressedGas(index: number, areaIndex: number): void {
    event.stopPropagation();

    const area = this.areasToShow[areaIndex];
    const gas = area.compressedGases[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogEssayAirComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        essay: new EssayProtocolAir(),
        idsEssaysToRemove: gas.essays.map(e => e.idEssayType),
        idEquipment: 1,
        isEdit: false,
        surfaceRoom: null,
        volumeRoom: null,
        accesories: null,
        filters: [],
        isEquipment: false,
        isExecution: true,
        entityType: 'compressedGas'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EssayProtocolAir) => {
      if (result != null) {
        if (this.protocol?.id != null) {
          this.requestReason((reasonAdd) => {
            result.reasonChange = reasonAdd;
            this.areasToShow[areaIndex].compressedGases[index].essays.push(result);

            if (this.execution.deviations == null) {
              this.execution.deviations = '';
            } else {
              this.execution.deviations += '\n';
            }

            this.execution.deviations += this.translate.instant('executionEdit.deviations.air.addEssay.compressedGas.label', {
              essay: this.getEssayName(result.idEssayType, true), compressedGas: gas.code, area: area.name,
              reason: reasonAdd
            });

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
          });
        } else {
          this.areasToShow[areaIndex].compressedGases[index].essays.push(result);

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.addEssay.ok') as string);
        }
      }
    });
  }

  downloadDatasheetEquipment(index: number, areaIndex: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    const equipment = this.areasToShow[areaIndex].equipments[index];

    this.executionService.downloadDatasheetEquipment(this.execution, equipment).subscribe((res: Blob) => {
      const name = AirUtils.showEquipment(equipment);

      saveAs(res, this.translate.instant('executionEdit.files.datasheet', { name }) as string);

      this.spinnerService.hide();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.error.generic') as string);
      }
      this.spinnerService.hide();
    });
  }

  downloadDatasheetRoom(index: number, areaIndex: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    const room = this.areasToShow[areaIndex].rooms[index];

    this.executionService.downloadDatasheetRoom(this.execution, room).subscribe((res: Blob) => {
      const name = room.name;

      saveAs(res, this.translate.instant('executionEdit.files.datasheet', { name }) as string);

      this.spinnerService.hide();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.error.generic') as string);
      }
      this.spinnerService.hide();
    });
  }

  downloadDatasheetCompressedGas(index: number, areaIndex: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    const compressedGas = this.areasToShow[areaIndex].compressedGases[index];

    this.executionService.downloadDatasheetCompressedGas(this.execution, compressedGas).subscribe((res: Blob) => {
      const name = compressedGas.code;

      saveAs(res, this.translate.instant('executionEdit.files.datasheet', { name }) as string);

      this.spinnerService.hide();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.error.generic') as string);
      }
      this.spinnerService.hide();
    });
  }

  enableEditArea(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.execution.idStatus)
  }

  save(): void {
    this.spinnerService.show();

    if (!this.useExistingProtocol) {
      this.execution.idProtocol = null;
    }

    this.executionService.save(this.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.save.ok') as string);

      this.spinnerService.hide();

      this.reloadPage(this.execution.id);
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.save.error.generic') as string);
      }

      this.spinnerService.hide();
    });

  }

  isExecutionAccording(): boolean {
    let according = true;
    this.executionEssays = [];
    this.execution.areas.forEach(area => {
      area.rooms.forEach(room => {
        room.essays.forEach(essay => {
          this.executionEssays.push(essay);
        });
      });
      area.equipments.forEach(equipment => {
        equipment.essays.forEach(essay => {
          this.executionEssays.push(essay);
        });
      });
      area.compressedGases.forEach(gas => {
        gas.essays.forEach(essay => {
          this.executionEssays.push(essay);
        });
      });

      this.executionEssays.forEach(essay => {
        if(essay.active && !essay.according){
          according = false;
        }
      })
    });

    this.execution.according = according;
    return according;
  }

  editDataExecution(): void {
    const dialogRef = this.dialog.open(EditDataProtocolComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        useExistingProtocol: this.useExistingProtocol,
        idClient: this.execution.idClient,
        idProtocol: this.execution.idProtocol,
        projectNo: this.execution.projectNo,
        usernameProjectManager: this.execution.usernameProjectManager,
        idClientProtocol: this.protocol != null ? this.protocol.idClient : null
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataProtocol) => {
      if (result != null) {
        this.execution.projectNo = result.projectNo;
        this.execution.usernameProjectManager = result.usernameProjectManager;
        if (this.useExistingProtocol) {
          this.protocol.idClient = result.idClientProtocol;
          this.execution.idClient = result.idClientProtocol;
        } else {
          this.execution.idClient = result.idClient;
        }
        this.clientService.findOne(this.execution.idClient).pipe(takeUntil(this.destroy$)).subscribe((data: Client) => {
          this.loadClients([data]);
          this.hasLoaded$.next();
        });
      }
    });
  }

  private clearProtocolFilter() {
    this.protocolFilter = new ProtocolAirFilter();

    this.protocolFilter.pageSize = 10000;
  }

  private canSign(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA];

    return validStatuses.includes(this.execution.idStatus);
  }

  private saveExecutionCallback(callback: () => void) {
    this.spinnerService.show();

    this.executionService.save(this.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.spinnerService.hide();
      callback();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);
      }
      this.spinnerService.hide();
    });
  }

  private showDialogValidate() {
    const errors = ExecutionAirUtils.validateExecution(this.execution, this.translate);

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialog = this.dialog.open(ExecutionEditValidateExecutionComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol
      }
    });

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        this.reloadPage(this.execution.id);
      }
    });
  }

  private showDialogValidateAutomatic() {
    const errors = ExecutionAirUtils.validateExecution(this.execution, this.translate);

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialog = this.dialog.open(ExecutionEditValidateExecutionAutomaticSignComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol
      }
    });

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        this.clientService.findOne(this.execution.idClient).subscribe((client: Client) => {

          if (client.idGroup != null) {
            void this.cloneRow(this.execution.id, client.idGroup).then(() => this.reloadPage());
          } else {
            this.reloadPage();
          }

        });
      }
    });
  }

  private showDialogSignAutomatic() {
    const errors = ExecutionAirUtils.validateExecution(this.execution, this.translate);

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialog = this.dialog.open(ExecutionEditDialogAutomaticSignComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol
      }
    });

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        if (this.execution.idClient) {
          this.clientService.findOne(this.execution.idClient).subscribe((client: Client) => {

            if (client.idGroup != null) {
              void this.cloneRow(this.execution.id, client.idGroup).then(() => this.reloadPage());
            } else {
              this.reloadPage();
            }
          });
        } else {
          this.reloadPage();
        }
      }
    });
  }

  private reorderRooms(rooms: RoomAir[]) {
    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i];
      room.order = i + 1;
    }

    return rooms;
  }

  private reorderCompressedGases(gases: CompressedGas[]) {
    for (let i = 0; i < gases.length; i++) {
      const gas = gases[i];
      gas.order = i + 1;
    }

    return gases;
  }

  private reorderEquipments(equipments: EquipmentAir[]) {
    for (let i = 0; i < equipments.length; i++) {
      const equipment = equipments[i];
      equipment.order = i + 1;
    }

    return equipments;
  }
  
  private openDialogEditEssayRoom(areaIndex: number, roomIndex: number, essayIndex: number | string): void {
    event.stopPropagation();

    const room = this.areasToShow[areaIndex].rooms[roomIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = room.essays[essayIndex];
    } else {
      essay = room.essays.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = room.essays.findIndex(e => e === essay);
      }
    }

    if (!this.canOpenEssay(essay, room.filters)) {
      return;
    }

    this.essayConfigService.findConfigByEssayIdAndRoomId(1, essay.idEssayType).pipe(takeUntil(this.destroy$))
      .subscribe((config: EssayConfigAir) => {
        const dialogRef = this.dialog.open(ExecutionEditDialogEssayComponent, {
          minWidth: '80%',
          maxHeight: '95vh',
          data: {
            execution: this.execution,
            essay: _.cloneDeep(essay),
            essayTypes: this.essayTypes,
            room: _.cloneDeep(room),
            isEdit: true,
            idConfig: config.id,
            isNew: false
          }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionEssayDialogResponse | string) => {
          if (result != null) {
            if (result instanceof ExecutionEssayDialogResponse) {
              this.areasToShow[areaIndex].rooms[roomIndex].essays[essayIndex] = result.essay;
              this.areasToShow[areaIndex].rooms[roomIndex].filters = result.filters;
              this.areasToShow[areaIndex].rooms.forEach(r => {
                r.essays.forEach(es => {
                  if (es.idEssayType === result.essay.idEssayType) {
                    if (ArrayUtils.isEmpty(es.equipments)) {
                      es.equipments = result.essay.equipments;
                    }
                  }
                });
              });
              if (result.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
                this.areasToShow[areaIndex].rooms[roomIndex].essays.forEach(essayR => {
                  if (essayR.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
                    this.copyDataBetweenAirSpeedAndRenovations(result.essay, essayR);
                  }
                });
              } else if (result.essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
                this.areasToShow[areaIndex].rooms[roomIndex].essays.forEach(essayR => {
                  if (essayR.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
                    this.copyDataBetweenAirSpeedAndRenovations(result.essay, essayR);
                  }
                });
              }
            } else if (result === 'delete') {
              this.areasToShow[areaIndex].rooms[roomIndex].essays = this.areasToShow[areaIndex].rooms[roomIndex].essays
                .filter(e => e !== essay);
            }
          }
        });
      });

  }

  private openDialogEditEssayEquipment(areaIndex: number, equipmentIndex: number, essayIndex: number | string): void {
    event.stopPropagation();

    const equipment = this.areasToShow[areaIndex].equipments[equipmentIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = equipment.essays[essayIndex];
    } else {
      essay = equipment?.essays?.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = equipment.essays.findIndex(e => e === essay);
      }
    }

    if (!this.canOpenEssay(essay, equipment.filters)) {
      return;
    }

    const call = this.essayConfigService.findConfigByEssayIdAndEquipmentId(equipment.idType, essay.idEssayType);

    call.pipe(takeUntil(this.destroy$)).subscribe((config: EssayConfigAir) => {
      const dialogRef = this.dialog.open(ExecutionEditDialogEssayComponent, {
        minWidth: '80%',
        maxHeight: '95vh',
        data: {
          execution: this.execution,
          essay: _.cloneDeep(essay),
          idConfig: config.id,
          isNew: false,
          equipment: _.cloneDeep(equipment)
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionEssayDialogResponse | string) => {
        if (result instanceof ExecutionEssayDialogResponse) {
          this.areasToShow[areaIndex].equipments[equipmentIndex].essays[essayIndex] = result.essay;
          this.areasToShow[areaIndex].equipments[equipmentIndex].filters = result.filters;
          this.areasToShow[areaIndex].equipments.forEach(eq => {
            eq.essays.forEach(es => {
              if (es.idEssayType === result.essay.idEssayType) {
                if (ArrayUtils.isEmpty(es.equipments)) {
                  es.equipments = result.essay.equipments;
                }
              }
            });
          });

          if (result.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
            this.areasToShow[areaIndex].equipments[equipmentIndex].essays.forEach(essayR => {
              if (essayR.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
                this.copyDataBetweenAirSpeedAndRenovations(result.essay, essayR);
              }
            });
          } else if (result.essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
            this.areasToShow[areaIndex].equipments[equipmentIndex].essays.forEach(essayR => {
              if (essayR.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
                this.copyDataBetweenAirSpeedAndRenovations(result.essay, essayR);
              }
            });
          }
        } else if (result === 'delete') {
          this.areasToShow[areaIndex].equipments[equipmentIndex].essays = this.areasToShow[areaIndex].equipments[equipmentIndex]
            .essays.filter(e => e !== essay);
        }
      });
    });
  }

  private openDialogEditEssayCompressedGas(areaIndex: number, gasIndex: number, essayIndex: number | string): void {
    event.stopPropagation();

    const gas = this.areasToShow[areaIndex].compressedGases[gasIndex];

    let essay: EssayProtocolAir = null;

    if (typeof essayIndex === 'number') {
      essay = gas.essays[essayIndex];
    } else {
      essay = gas.essays.find(e => {
        const name = this.getEssayName(e.idEssayType, false);
        return name === essayIndex;
      });

      if (essay != null) {
        essayIndex = gas.essays.findIndex(e => e === essay);
      }
    }

    this.essayConfigService.findConfigByEssayIdAndCompressedGasId(1, essay.idEssayType).pipe(takeUntil(this.destroy$))
      .subscribe((config: EssayConfigAir) => {
        const dialogRef = this.dialog.open(ExecutionEditDialogEssayComponent, {
          minWidth: '80%',
          maxHeight: '95vh',
          data: {
            execution: this.execution,
            essay: _.cloneDeep(essay),
            essayTypes: this.essayTypes,
            compressedGas: _.cloneDeep(gas),
            isEdit: true,
            idConfig: config.id,
            isNew: false
          }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionEssayDialogResponse | string) => {
          if (result != null) {
            if (result instanceof ExecutionEssayDialogResponse) {
              this.areasToShow[areaIndex].compressedGases[gasIndex].essays[essayIndex] = result.essay;
              this.areasToShow[areaIndex].compressedGases.forEach(r => {
                r.essays.forEach(es => {
                  if (es.idEssayType === result.essay.idEssayType) {
                    if (ArrayUtils.isEmpty(es.equipments)) {
                      es.equipments = result.essay.equipments;
                    }
                  }
                });
              });
            } else if (result === 'delete') {
              this.areasToShow[areaIndex].compressedGases[gasIndex].essays = this.areasToShow[areaIndex].compressedGases[gasIndex].essays
                .filter(e => e !== essay);
            }
          }
        });
      });
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private calculateVolume(volumes: Volume[]): number {
    return NumberUtils.round(NumberUtils.fixPrecision(ArrayUtils.sum(volumes.map(v => v.surface * v.height))), 1);
  }

  private calculateSurface(volumes: Volume[]): number {
    return NumberUtils.round(NumberUtils.fixPrecision(ArrayUtils.sum(volumes.map(v => v.surface))), 2);
  }

  private canOpenEssay(essay: EssayProtocolAir, filters: FilterAir[]): boolean {
    let res = true;

    const essaysCheckFiltersSaved = [
      EssayAirTypeEnum.HEPA_FILTER_INTEGRITY, EssayAirTypeEnum.AIR_SPEED, EssayAirTypeEnum.REN_FLOW_AND_RATE,
      EssayAirTypeEnum.FILTER_SATURATION
    ];
    if (this.onlineService.latestOnline) {
      if (essaysCheckFiltersSaved.includes(essay.idEssayType) && filters.some(f => f.id == null)) {
        this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.filters.anyNotSaved') as string);
        res = false;
      }
    }

    return res;
  }

  private prepareForChanges(essay: EssayProtocolAir): EssayProtocolAir {
    const cloned = _.cloneDeep(essay);

    cloned.essayValues = essay.essayValues.map(e => FieldUtils.objectToField(e).toJSON());

    return cloned;
  }

  private getTimestampOffline(): string {
    const date = new Date();
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return DateUtils.toDateTimeStr(date);
  }

  private copyDataBetweenAirSpeedAndRenovations(original: EssayProtocolAir, toCopy: EssayProtocolAir): void {
    if (original == null || toCopy == null) {
      return;
    }
    // Si no tiene relleno los equipos en el ensayo a copiar, clonamos los del original y le quitamos el ID y asignamos el ID de ensayo
    // En caso de ser creado offline se vaciará, pero como se rellena en el back, nos da un poco igual
    if (ArrayUtils.isEmpty(toCopy.equipments)) {
      for (const originalEquipment of original.equipments) {
        const eq = new EssayExecutionAirInternalEquipment();
        eq.idEssay = toCopy.id;
        eq.idEquipment = originalEquipment.idEquipment;
        eq.equipmentName = originalEquipment.equipmentName;

        toCopy.equipments.push(eq);
      }
    } else {
      // Si ya tenía equipos antes, hay que comparar el número de equipos de antes y los de ahora. No hacemos lo mismo que en el
      // anterior if porque si ya tenían ID, no nos interesa borrarlo.
      const totalEquipmentsOriginal = original.equipments.length;
      const totalEquipmentsCopy = toCopy.equipments.length;
      const diff = totalEquipmentsOriginal - totalEquipmentsCopy;

      // Caso hay más en la copia que en original, tenemos que borrar los que hay de más
      if (diff < 0) {
        toCopy.equipments = toCopy.equipments.slice(0, diff);
        // Caso hay más en el original que en la copia, tenemos que añadirlos
      } else if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const eq = new EssayExecutionAirInternalEquipment();
          eq.idEssay = toCopy.id;

          toCopy.equipments.push(eq);
        }
      }

      // Llegados a este punto hay el mismo número (independientemente de lo que hubiera antes), así que no nos tenemos que preocupar
      // de los índices
      toCopy.equipments.forEach((eq, index) => {
        eq.idEquipment = original.equipments[index].idEquipment;
        eq.equipmentName = original.equipments[index].equipmentName;
      });
    }

    original.equipmentNA = toCopy.equipmentNA;

    toCopy.criterias.forEach(criteria => {
      if (original.criterias != null && original.criterias[0]?.values != null && original.criterias[0]?.values?.length > 0) {
        criteria.values = _.cloneDeep(original.criterias[0].values);

        toCopy.values = criteria.values;
      } else {
        criteria.values = toCopy.values;
      }
      criteria.values.forEach(value => {
        value.idEssay = toCopy.id;
        if (criteria.id != null) {
          value.idCriteria = criteria.id;
          const valueCopy = original.criterias[0].values?.find(e => e.idFilter === value.idFilter)?.value;
          if (valueCopy) {
            value.value = valueCopy;
          }
        } else {
          value.idCriteria = null;
        }
      });
    });
  }

  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.executionService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.executionService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }

  private async setAccordingEssays() {
    for await(const area of this.areasToShow ){
      for await (const eq of area.equipments) {
        for await (const eqE of eq.essays) {
          let volume: number;
          for await (const field of eqE.essayValues) {
            if (field.idField === FieldEnum.VOLUME) {
              volume = field.value as number;
            }
          }
          eqE.according = AirUtils.checkAccordingEssay(eqE, volume, eq.filters, eq.idType, false);
        }
      }
      for await(const room of area.rooms) {
        const volume = ExecutionAirUtils.getTotalVolume(room);
        for await (const rE of room.essays) {
          rE.according = AirUtils.checkAccordingEssay(rE, volume, room.filters, 1, false);
        }
      }
      for await(const gas of area.compressedGases){
        for await (const gE of gas.essays) {
          gE.according = AirUtils.checkAccordingEssay(gE, null, [], 1, true);
        }
      }
    }
  }

  private getValuesOfCriteria(essay: EssayProtocolAir): boolean {
    let result = true;
    if (ArrayUtils.isNotEmpty(essay.criterias)) {
      essay.criterias.forEach(criteria => {
        criteria.values?.forEach(value => {
          if (!value.value && result) {
            result = false;
          }
        })
      });
    } else if (ArrayUtils.isNotEmpty(essay.values)){
      essay.values?.forEach(value => {
        if (!value.value && result) {
          result = false;
        }
      })
    }
    return result;
  }
}
