import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { CalibrationAsFound, CalibrationVariable } from 'src/app/model/calibration';
import { VariableUnitEnum } from 'src/app/model/variable';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { NumberUtils } from 'src/app/utils/numberUtils';

export interface DialogDataCalibrationAsFound {
  item: CalibrationAsFound;
  variable: CalibrationVariable;
}

@Component({
  selector: 'app-calibration-edit-as-found-dialog',
  templateUrl: './calibration-edit-as-found-dialog.component.html'
})
export class CalibrationEditAsFoundDialogComponent {

  radius: number = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCalibrationAsFound,
    public dialogRef: MatDialogRef<CalibrationEditAsFoundDialogComponent>,
    private variableTypeService: VariableTypeService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    if (this.showExtraField()) {
      this.spinnerService.show();

      this.variableTypeService.convert(this.data.variable.extraField, this.data.variable.idUnitExtra, VariableUnitEnum.LENGHT_M)
        .subscribe((res: number) => {
          this.radius = res;
          this.spinnerService.hide();
        });
    }

  }

  onOkClick(): void {

    const errs = [];

    if (this.data.item.point == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asFound.dialog.point.error'));
    }

    if (this.data.item.pattern == null || isNaN(+this.data.item.pattern)) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asFound.dialog.pattern.error'));
    }

    if (this.data.item.equipment == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asFound.dialog.equipment.error'));
    }

    if (this.data.item.pattern && this.data.item.equipment) {
      if (NumberUtils.countDecimalsAny(this.data.item.pattern) != NumberUtils.countDecimalsAny(this.data.item.equipment)) {
       if (NumberUtils.countDecimalsAny(this.data.item.equipment) > NumberUtils.countDecimalsAny(this.data.item.pattern)) { 
        errs.push(this.translate.instant('calibrateEquipmentEdit.asFound.dialog.lengthPatternEqupiment.error'));
       }
      }
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.item);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  async onChangePattern(value: string): Promise<void> {
    this.data.item.pattern = value;

    if (this.radius != null) {
      const idUnitFrom = this.data.variable.idUnitInput;
      const idUnitTo = this.data.variable.idUnit;

      this.data.item.patternOriginal = NumberUtils.fixPrecision(await firstValueFrom(
        this.variableTypeService.convertWithRadius(+this.data.item.pattern, this.radius, idUnitFrom, idUnitTo)) as number).toString();
    }
  }

  async onChangeValue(value: number): Promise<void> {
    this.data.item.equipment = value;

    if (this.radius != null) {
      const idUnitFrom = this.data.variable.idUnitInput;
      const idUnitTo = this.data.variable.idUnit;
      this.data.item.equipmentOriginal = NumberUtils.fixPrecision(await firstValueFrom(
        this.variableTypeService.convertWithRadius(this.data.item.equipment, this.radius, idUnitFrom, idUnitTo)) as number);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showExtraField(variable: CalibrationVariable = this.data.variable): boolean {
    return CalibrationUtils.showExtraField(variable);
  }

  getExtraFieldName(variable: CalibrationVariable = this.data.variable): string {
    return CalibrationUtils.getExtraFieldName(variable);
  }

}
