<h1 mat-dialog-title>{{ 'groupEdit.coding.title' | translate }}</h1>
<form>

    <div mat-dialog-content>
        <div fxLayout="column">
            <div fxFlex *ngIf="!data.isNew">
                <mat-form-field class="campoFormulario" >
                    <mat-label>{{ 'groupEdit.coding.documentType.label' | translate }}</mat-label>
                    <mat-select name="idProcess" [(ngModel)]="data.groupCoding.documentType" >
                        <mat-option *ngFor="let item of documentTypes" [value]="item">
                            {{ getDocumentTypeDescription(item) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'groupEdit.coding.coding.label' | translate }}" name="state"
                    [(ngModel)]="data.groupCoding.coding">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">
            {{ 'button.save' | translate }}
        </button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>

    </div>

</form>

