import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ng-flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ThermalModule } from '../thermal.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThermalModule,

    /* Custom modules */
    AngularMaterialModule,
    SharedModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule,
    RouterModule
  ],
  exports: []
})
export class ExecutionListModule { }
