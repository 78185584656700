import { ArrayUtils } from '../utils/arrayUtils';
import { GenericFilter } from './genericClass';
import { InternalEquipment } from './internalEquipment';
import { NumberUtils } from '../utils/numberUtils';
import { CalibrationAmbientConditions, CalibrationAmbientResultParticles } from './calibrationAmbientConditions';

export interface ICalibration {
    id: number;

    certificateNum: string;
    calibrationDate: Date;
    expirationDate: Date;

    idEquipment: number;

    internal: boolean;
    multichannel: boolean;
    channelNumber: number;
}

export class Calibration implements ICalibration {
    id: number;

    internal = true;

    certificateNum: string;
    calibrationDate: Date;
    expirationDate: Date;
    showExpirationDate = true;

    projectNo: string;

    idStatus: number;
    statusTranslation: string;

    idEquipment: number;

    temperature: string;
    humidity: string;
    pressure: string;
    idPlace: number;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    realizationDate: Date;

    currentVersion: string;
    signedVersion: string;

    variables: CalibrationVariable[] = [];

    observations: string;
    correct: boolean;

    reason: string;
    multichannel: boolean;
    channelNumber: number;
}

export class CalibrationAudit {
    id: number;
    username: string;

    idAction: number;
    actionName: string;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    date: Date;

    currentVersion: string;
    signedVersion: string;

    changes: string;

    canDownload: boolean;
}

export class CalibrationAuditFilter extends GenericFilter {
}

export class ExternalCalibration implements ICalibration {
    id: number;

    internal = false;

    certificateNum: string;
    calibrationDate: Date;
    expirationDate: Date;

    idEquipment: number;
    idDocument: number;

    variables: ExternalCalibrationVariable[];
    uploadFile?: File;
    multichannel: boolean;
    channelNumber: number;
}

export class CalibrationFilter extends GenericFilter {
    idStatus: number;
    idClient: number;
    regUser: string;

    name: string;
    type: string;

    maker: string;
    model: string;

    serialNum: string;
    certificateNum: string;

    calibrateDateStart: Date;
    calibrateDateEnd: Date;

    expirationDateStart: Date;
    expirationDateEnd: Date;

    realizationFcStart: Date;
    realizationFcEnd: Date;

    projectNo: string;

    idsVariable: number[];
    idsApp: number[];
    idPlace: number;

    cols: string[];
}

export class ExternalCalibrationVariable {
    id: number;

    idVariable: number;
    variableTranslation: string;

    idUnit: number;
    unitName: string;

    values: ExternalCalibrationValue[] = [];
}

export class ExternalCalibrationValue {
    id: number;

    point: number;
    average: number;
    uncertainty: number; // Incertidumbre expandida
    uncertaintyOriginal: number;

    get correction(): number {
        if (this.point == null || this.average == null) {
            return null;
        }

        return NumberUtils.fixPrecisionWithOtherMethod(this.point - this.average);
    }
}

export class CalibrationVariable {
    id: number;

    idProcedure: number;

    idVariable: number;
    variableTranslation: string;

    idUnit: number;
    unitName: string;

	extraField: number;
	idUnitExtra: number;

	idUnitInput: number;

    config: CalibrationVariableConfig[] = [];
    asFound: CalibrationAsFound[] = [];
    patterns: CalibrationPattern[] = [];
    ambientConditions: CalibrationAmbientConditions;
    valuesParticles: CalibrationAmbientResultParticles[];
    valuesMultichannel: CalibrationVariableMultichannel[];
}

export class CalibrationVariableConfig {
    id: number;

    rangeInit: number;
    rangeEnd: number;

    resolution: number;
    tolerance: string;
    uncertaintyResolution: number;

    asLeft: CalibrationAsLeft[] = [];
    uuidConfig: string;
}

export class CalibrationAsFound {
    id: number;
    point: number;
    pattern: string;
    equipment: number;

    patternOriginal: string;
    equipmentOriginal: number;

    get correction(): number {
        if (this.pattern == null || this.equipment == null) {
            return null;
        }
        return NumberUtils.fixPrecision((+this.pattern) - this.equipment);
    }
}

export class CalibrationAsLeft {
    id: number;
    point: number;
    pattern: string;
    patternOriginal: string;

    indexConfig: number;
    uncertaintyResolution: number;

    // No hacen falta porque están implicitos con su respectivo get/set
    // values: number[];
    // uP: number[];
    // resolution: number;

    average: number;
    correction: number;
    cPlusU: number;  // Incertidumbre corregida

    uRep: number; // Incertidumbre de repetibilidad
    uResol: number; // Incertidumbre por resolución
    uUnif: number; // Incertidumbre por falta de uniformidad
    uEstab: number; // Incertidumbre de estabilidad
    uC: number; // Incertidumbre típica
    neff: number; // Grados de libertad
    k: number; // Constante
    U: number; // Incertidumbre expandida

    _values: CalibrationAsLeftValue[];
    _patterns: number[];
    _resolution: number;

    idCalibrationMultichannel?: number
    idCalibrationConfig?: any;
    uuidConfig?: string;

    get patterns(): number[] {
        return this._patterns;
    }

    get resolution(): number {
        return this._resolution;
    }

    set resolution(resolution: number) {
        this._resolution = resolution;

        this.recalculateValues();
    }

    set values(values: CalibrationAsLeftValue[]) {
        this._values = values;

        this.recalculateValues();
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get values(): CalibrationAsLeftValue[] {
        return this._values;
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    set patterns(patterns: number[]) {
        if (patterns) {
            this._patterns = patterns.map(p => p / 2);

            this.recalculateValues();
        }
    }

    public initValues(): void {
        if (this._values == null && this.values != null) {
            this._values = this.values;
        }

        if (this._resolution == null && this.resolution != null) {
            this._resolution = this.resolution;
        }

        if (this._patterns == null && this.patterns != null) {
            this._patterns = this.patterns;
        }

        this.recalculateValues();
    }

    private recalculateValues() {

        let values: number[] = [];
        if (!ArrayUtils.isEmpty(this._values)) {
            values = this._values.map(v => v.value);
        }

        const resolution = this._resolution;
        const patterns = this._patterns;

        // Reseteamos todos los valores antes de empezar
        this.average = null;
        this.correction = null;
        this.uRep = null;
        this.uResol = null;
        this.uEstab = null;
        this.uC = null;
        this.k = null;
        this.U = null;
        this.cPlusU = null;

        // Si algún valor es nulo no recalculamos los valores y así evitamos errores
        if (ArrayUtils.isEmpty(values) || resolution == null || ArrayUtils.isEmpty(patterns)) {
            return;
        }

        this.average = NumberUtils.fixPrecision(+NumberUtils.average(values));
        this.correction = NumberUtils.fixPrecision((+this.pattern) - this.average);

        const standarDevs = NumberUtils.fixPrecision(NumberUtils.standardDeviation(values));
        const totalValues = values.length;
        this.uRep = NumberUtils.fixPrecision(standarDevs / Math.sqrt(totalValues));

        this.uResol = resolution / Math.sqrt(12);

        this.uEstab = (Math.max(...values) - Math.min(...values)) / (2) / (Math.sqrt(3));

        const dataUC: number[] = [];
        dataUC.push(this.uRep);
        dataUC.push(this.uResol);
        dataUC.push(this.uUnif);
        dataUC.push(this.uEstab);

        if (patterns != null) {
            patterns.filter(d => d != null).forEach(d => dataUC.push(d));
        }

        let counterUC = 0.0;
        dataUC.filter(d => d != null).forEach(d => counterUC += Math.pow(d, 2));

        this.uC = Math.sqrt(counterUC);

        if (this.uRep === 0) {
            this.neff = Infinity;
        } else {
            const dataNeff = [this.uRep, this.uResol, this.uUnif, ...this.patterns, this.uEstab];

            let counterNeff = 0.0;
            dataNeff.filter(d => d != null).forEach(d => counterNeff += (Math.pow(d, 4)) / (totalValues - 1));

            this.neff = (Math.pow(this.uC, 4) / counterNeff);
        }

        let resultK: number;

        if (this.neff < 10) {
            resultK = 2.37;
        } else if (this.neff < 20) {
            resultK = 2.28;
        } else if (this.neff < 50) {
            resultK = 2.13;
        } else {
            resultK = 2;
        }

        this.k = resultK;

        this.U = this.uC * this.k;

        this.cPlusU = Math.abs(this.correction) + this.U;
    }

}

export class CalibrationAsLeftValue {
    id: number;

    value: number;
    valueOriginal: number;
}

export class CalibrationPattern {
    id: number;
    idEquipment: number;
    equipment: InternalEquipment;

    values: CalibrationPatternValue[];
}

export class CalibrationPatternValue {
    id: number;

    value: number;
    uncertainty: number;

    include: boolean;
}

export class CalibrationUncertaintiesValues {
    values: CalibrationPatternValue[];
    idUnit: number;
    unit: string;
}

export enum CalibrationStatus {
    PENDIENTE_FIRMA = 1,
    FIRMADO = 2,
    EN_EJECUCION = 3,
    NO_VALIDO = 4
}

export enum CalibrationResponsibleEnum {
    INTERNAL = 1,
    EXTERNAL = 2
}

export enum CalibrationActionEnum {
    CREATE = 1,
    SAVE = 2,
    SIGN = 3
}

export class CalibrationVariableMultichannel {
    id: number;
    idVariable: number;
    description: string;
    order: number;
    asFound: CalibrationAsFound[] = [];
    asLeft: CalibrationAsLeft[] = [];
    active: boolean;
    variableConfigUuid: string;
}