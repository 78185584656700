<mat-card appearance="outlined" class="card">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
        <h1 style="margin: 0 auto; display: table;">
          {{ appPlan.name }}
        </h1>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="!isEdit">
        <div fxLayout="column">
          <div fxFlex="100">
            <mat-form-field class="campoFormulario">
              <input matInput placeholder="{{'appPlanEdit.name.label' | translate }}" name="username"
                [(ngModel)]="appPlan.name" [errorStateMatcher]="matcher"
                [disabled]="!currentUser.admin || currentUser.manager">
              <mat-error>
                {{ 'appPlanEdit.name.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="card">
  <mat-card-header>
    <mat-card-title>
      <mat-toolbar color="secondary" style="width: 95vw;">
        {{ 'appPlanEdit.administration.title' | translate }}
      </mat-toolbar>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

      <div fxFlex="30" fxFlex.lt-md="100%">
        <span> {{ 'appPlanEdit.administration.canManageUsers.label' | translate }} </span>
        <mat-radio-group [(ngModel)]="appPlan.canManageUsers" name="canManageUsers">
          <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
          <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

      <div fxFlex="30" fxFlex.lt-md="100%">
        <span> {{ 'appPlanEdit.administration.canGeneratePreReport.label' | translate }} </span>
        <mat-radio-group [(ngModel)]="appPlan.canGeneratePreReport" name="canGeneratePreReport">
          <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
          <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

      <div fxFlex="30" fxFlex.lt-md="100%">
        <span> {{ 'appPlanEdit.administration.idsPhasesAllowed.label' | translate }} </span>
        <mat-selection-list [(ngModel)]="appPlan.idsPhasesAllowed" name="idsPhasesAllowed">
          <mat-list-option *ngFor="let item of phases" checkboxPosition="before" [value]="item.id">
            {{ 'phase.' + item.translation | translate }}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

      <div fxFlex="30" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'appPlanEdit.administration.maxUsers.label' | translate }}" name="maxUsers"
            [(ngModel)]="appPlan.maxUsers" [errorStateMatcher]="matcher" type="number" required min="-1">
          <mat-error>
            {{ 'appPlanEdit.administration.maxUsers.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

      <div fxFlex="30" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'appPlanEdit.administration.maxExecutions.label' | translate }}"
            name="maxExecutions" [(ngModel)]="appPlan.maxExecutions" [errorStateMatcher]="matcher" type="number"
            required min="-1">
          <mat-error>
            {{ 'appPlanEdit.administration.maxExecutions.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="20" fxFlex.lt-md="0%"><br /><br /></div>

    </div>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="card">
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button (click)="save()" color="primary">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
  </mat-card-actions>
</mat-card>