/* eslint-disable max-len */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import * as saveAs from 'file-saver';
import { Subject, takeUntil } from 'rxjs';
import { Calibration, CalibrationStatus, CalibrationVariable } from '../../../model/calibration';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExcelUtils } from '../../../utils/excelUtils';
import { AttachmentThermalService } from '../../../services/attachmentThermal.service';
import { TranslateService } from '@ngx-translate/core';
import { CalibrationResultExcelParticles } from 'src/app/model/calibrationAmbientConditions';
import { SnackBarService } from '../../../services/snackBar.service';
import { AttachmentCalibratesService } from '../../../services/attachmentCalibrates.service';
import { CalibrationUtils } from '../../../utils/calibratesUtils';
import { CalibrationAmbientResultParticles } from '../../../model/calibrationAmbientConditions';

export interface CalibrationEditDialogParticlesData {
  calibration: Calibration;
  variable: CalibrationVariable,
  idConfig: number;
  isNew: boolean;
}


@Component({
  selector: 'app-calibration-edit-dialog-particles',
  templateUrl: './calibration-edit-dialog-particles.component.html'
})
export class CalibrationEditDialogParticlesComponent implements OnInit {

  @ViewChild('uploadExcelParticleCalibratesCountInput') uploadExcelParticleCountInput: any;

  valuesParticles: CalibrationAmbientResultParticles[] = [];

  displayedColumnsParticleCount = ['date', 'location', 'volume', 'zeroOne', 'zeroTwo', 'zeroThree', 'zeroFive', 'oneZero', 'fiveZero'];
  private destroy$ = new Subject<void>();

  constructor(private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: CalibrationEditDialogParticlesData,
    private translate: TranslateService,
    private attachmentThermalService: AttachmentThermalService,
    private attachmentCalibratesService: AttachmentCalibratesService,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CalibrationEditDialogParticlesComponent>) { }

  ngOnInit(): void {
  }

  downloadExcelParticleCount(): void {
    this.spinnerService.show();

    this.attachmentCalibratesService.downloadExcelToAmbientCalibration(this.data.variable.id, this.data.variable.ambientConditions.idParticlesExcel)
      .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        saveAs(res, 'excel.csv');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
    });
  }

  uploadExcelParticleCount(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();

    if (this.data.variable.id) {
      void ExcelUtils.excelToParticleCountValidate(file,this.attachmentThermalService,this.translate).then((error:string)=>{
        if (!error){
          void ExcelUtils.excelToCalibrationParticleCount(file, this.attachmentThermalService).then((result: CalibrationResultExcelParticles) => {
            this.attachmentCalibratesService.uploadExcelToAmbientParticles(this.data.variable.id, file).subscribe((idFile: number) => {
              this.data.variable.valuesParticles = result.particles;
              this.data.variable.ambientConditions.serialNumber = result.serialNum;
              if (!isNaN(idFile)) {
                this.data.variable.ambientConditions.idParticlesExcel = idFile;
              } else {
                this.data.variable.ambientConditions.idParticlesExcel = null;
              }
              this.spinnerService.hide();
            }, errorUpload => {
              console.error(errorUpload);
              this.spinnerService.hide();
            });
          }, errorExcel => {
            console.error(errorExcel);
            this.spinnerService.hide();
          });
        }else {
          this.snackBarService.sendError(error);
          this.spinnerService.hide();
        }
      });
    } else {
      this.spinnerService.hide();
      this.snackBarService.sendError('Debe guardar primero la variable para subir el archivo Excel');
    }
  }

  enableEditCriteria(): boolean {
    const allowedStatuses = [CalibrationStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.data.calibration.idStatus);
  }

  getClassParticleNum(): string {
    const rowCount = this.data.variable.valuesParticles[0];
    let count = 0;
    count = CalibrationUtils.getTotalParticlesBySize(rowCount);

    return count >= 92 ? 'essayAccording' : 'essayNotAccording bold';
  }

  showParticleNum(num: number): string {
    return num != null ? num.toString() : this.translate.instant('common.notApplicable') as string;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.dialogRef.close(this.data.variable);
  }

  deleteBtnDataAmbientParticles(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.data.variable.valuesParticles = [];
  }
}
