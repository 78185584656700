<br /><br />

<mat-accordion *ngIf="!disableGraph()">
  <mat-expansion-panel [expanded]="graphOpen" [disabled]="graphOpen" (opened)="graphOpen = true"
    (closed)="graphOpen = false">

    <mat-expansion-panel-header *ngIf="!graphOpen">
      Gráfica
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>

      <div *ngIf="!isFixedRange()">
        <dx-chart [dataSource]="datasource">

          <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>

          <dxo-size [height]="500" width="80%"></dxo-size>

          <dxi-series *ngFor="let sensor of sensors" [valueField]="sensor.value" [name]="sensor.name">
          </dxi-series>

          <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="5000"></dxo-tooltip>

          <dxi-value-axis>
            <dxi-constant-line [width]="2" [value]="minMaxValue.minValue" color="#8c8cff" dashStyle="dash">
              <dxo-label text="Criterio mínimo"></dxo-label>
            </dxi-constant-line>
            <dxi-constant-line [width]="2" [value]="minMaxValue.maxValue" color="#ff7c7c" dashStyle="dash">
              <dxo-label text="Criterio máximo"></dxo-label>
            </dxi-constant-line>
          </dxi-value-axis>

          <dxo-argument-axis argumentType="datetime">
            <dxo-label [format]="customizeDateLabel"></dxo-label>
            <dxi-constant-line [width]="2" *ngFor="let def of defrosts" [value]="def.startDate" color="green"
              dashStyle="longDash">
              <dxo-label text="Inicio" horizontalAlignment="left"></dxo-label>
            </dxi-constant-line>
            <dxi-constant-line [width]="2" *ngFor="let def of defrosts" [value]="def.endDate" color="red"
              dashStyle="longDash">
              <dxo-label text="Fin {{ def.nameType || lowercase }}" horizontalAlignment="right"></dxo-label>
            </dxi-constant-line>
          </dxo-argument-axis>

          <dxo-common-series-settings argumentField="date" type="line">
          </dxo-common-series-settings>

        </dx-chart>
      </div>

      <div *ngIf="isFixedRange()">
        <dx-range-selector #graph id="range-selector" [value]="[minDate, maxDate]" [dataSource]="datasource"
          (onValueChanged)="onValueChanged($event)" (onIncidentOccurred)="onError($event)" [disabled]="disableRange()"
          style="width: 98vw">

          <dxo-size [height]="500" width="80%"></dxo-size>

          <dxo-behavior [snapToTicks]="false"></dxo-behavior>

          <dxo-scale argumentType="datetime">
            <dxo-tick-interval [hours]="1"></dxo-tick-interval>
            <dxo-max-range [days]="maxDaysRange()" [hours]="maxHoursRange()" [minutes]="maxMinutesRange()">
            </dxo-max-range>
            <dxo-min-range [days]="minDaysRange()" [hours]="minHoursRange()" [minutes]="minMinutesRange()">
            </dxo-min-range>
            <dxo-minor-tick [visible]="false"></dxo-minor-tick>
            <dxo-marker [visible]="false"></dxo-marker>
            <dxo-label [customizeText]="customizeLabel"></dxo-label>
          </dxo-scale>

          <dxo-slider-marker [customizeText]="customizeSliderMarker" invalidRangeColor="white">
          </dxo-slider-marker>

          <dxo-chart (onDisposing)="showLoading()" (onDone)="hideLoading()">
            <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>

            <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="5000"></dxo-tooltip>

            <dxi-series *ngFor="let sensor of sensors" [valueField]="sensor.value" [name]="sensor.name"></dxi-series>

            <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
            </dxo-legend>

            <dxo-common-series-settings argumentField="date" type="line"></dxo-common-series-settings>

          </dxo-chart>

        </dx-range-selector>
      </div>


      <br />

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isFixedRange()">

          {{ 'executionEdit.essays.graph.form.filter.label' | translate }}

          <mat-form-field>
            <input matInput [(ngModel)]="dateZoomInitStr" placeholder="{{ 'common.start.label' | translate }}"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field>
            <input matInput [(ngModel)]="dateZoomEndStr" placeholder="{{ 'common.end.label' | translate }}"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <button mat-icon-button color="accent" (click)="recalculateDateFilter()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="showCopyDateFirstSensor() && isFixedRange()">
          <mat-checkbox class="example-margin" (change)="onCopyTimeGraphChange($event)" [value]="item"
            [checked]="copyDateFirstSensor">
            {{ 'executionEdit.essays.graph.form.copyDates.label' | translate }}
          </mat-checkbox>
        </div>
        <div fxFlex="50" fxFlex.lt-md="100%"></div>

      </div>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="isFixedRange()">
        <div fxFlex="50" fxFlex.lt-md="100%">
          {{ 'executionEdit.essays.graph.form.exhibitionTime.label' | translate }}
          {{ 'executionEdit.essays.graph.form.exhibitionTime.from' | translate }} {{ minDate | fixDateTime }}
          {{ 'executionEdit.essays.graph.form.exhibitionTime.to' | translate }} {{ maxDate | fixDateTime }}
        </div>
        <div fxFlex="50" fxFlex.lt-md="100%"></div>

        <div fxFlex="25" fxFlex.lt-md="100%" *ngFor="let data of calculatedData">
          <p style="font-weight: bold"> {{ 'executionEdit.essays.graph.form.calculatedData.sensorName' | translate }}
            {{ data.equipmentName }} ({{ data.serialNum }}) </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.min' | translate }} {{ data.minValue }} </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.average' | translate }} {{ data.average }} </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.max' | translate }} {{ data.maxValue }} </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.minAvg' | translate }} {{ data.diffAvgMin }} </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.avgMax' | translate }} {{ data.diffMaxAvg }} </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.minMax' | translate }} {{ data.diffMaxMin }} </p>
          <p> {{ 'executionEdit.essays.graph.form.calculatedData.standardDeviation' | translate }}
            {{ data.standardDeviation | number:'1.2-2' }} </p>
          <p *ngIf="showMkt"> {{ 'executionEdit.essays.graph.form.calculatedData.mkt' | translate }}
            {{ data.mkt | number:'1.2-2' }} </p>

          <p *ngIf="data.outer">
            {{ 'executionEdit.essays.graph.form.calculatedData.outer' | translate }}
          </p>

          <p *ngIf="data.isErrMax && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrMax' | translate }} </p>
          <p *ngIf="data.isErrMin && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrMin' | translate }} </p>
          <p *ngIf="data.isErrPeakMax && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrPeakMax' | translate }} </p>
          <p *ngIf="data.isErrPeakMin && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrPeakMin' | translate }} </p>
          <p *ngIf="data.isColdSpot && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isColdSpot' | translate }} </p>
          <p *ngIf="data.isHotSpot && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isHotSpot' | translate }} </p>
          <p *ngIf="data.isErrMaxDelta && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrMaxDelta' | translate }} </p>
          <p *ngIf="data.isErrMinDelta && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrMinDelta' | translate }} </p>
          <p *ngIf="data.isErrMaxStandardDeviation && !data.outer" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isErrMaxStandardDeviation' | translate }} </p>
          <p *ngIf="data.isErrMaxStandardDeviation && !data.outer" [class]="getClassError(data)">
              {{ 'executionEdit.essays.graph.form.calculatedData.isErrMaxStandardDeviation' | translate }} </p>
          <p *ngIf="data.isErrDiffAvg || data.isErrorDiffMax" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.calculatedData.isDiffErrAvgMin' | translate }} </p>
          <div *ngIf="data.isColdSpot || data.isHotSpot">
            <p *ngIf="data.isColdSpot">
              {{ 'executionEdit.essays.graph.form.calculatedData.isColdSpot.title' | translate }}
            </p>
            <p *ngIf="data.isHotSpot">
              {{ 'executionEdit.essays.graph.form.calculatedData.isHotSpot.title' | translate }}
            </p>
            <mat-radio-group [(ngModel)]="data.hotColdSpot" name="hotColdSpot{{data.equipmentName}}"
              [ngModelOptions]="{standalone: true}">
              <mat-radio-button [value]="true" (change)="onHotColdSpotChange(data.equipmentName, $event)">
                {{'common.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button [value]="false" (change)="onHotColdSpotChange(data.equipmentName, $event)">
                {{'common.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <br />

        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap" *ngIf="isEventEssay() && eventValue != null">
        <div fxFlex="50" fxFlex.lt-md="100%">
          {{ 'executionEdit.essays.graph.form.exhibitionTime.label' | translate }}
          {{ 'executionEdit.essays.graph.form.exhibitionTime.from' | translate }}
          {{ eventValue?.startDateShow | fixDateTime }}
          {{ 'executionEdit.essays.graph.form.exhibitionTime.to' | translate }}
          {{ eventValue?.endDateShow | fixDateTime }}
        </div>
        <div fxFlex="50" fxFlex.lt-md="100%"></div>

        <div fxFlex="20" fxFlex.lt-md="100%">
          <p style="font-weight: bold">{{ 'executionEdit.essays.graph.form.event.labelBeforeEvent' | translate }}</p>
          <p> {{ 'executionEdit.essays.graph.form.event.min' | translate }} {{ eventValue?.minValueBefore }} </p>
          <p> {{ 'executionEdit.essays.graph.form.event.average' | translate }} {{ eventValue?.avgValueBefore }} </p>
          <p> {{ 'executionEdit.essays.graph.form.event.max' | translate }} {{ eventValue?.maxValueBefore }} </p>
        </div>
        <div fxFlex="20" fxFlex.lt-md="100%">
          <p style="font-weight: bold">{{ 'executionEdit.essays.graph.form.event.labelUntilCriteria' | translate }}</p>

          <p *ngIf="eventValue?.timeEventDate == null" class="error">
            {{ 'executionEdit.essays.graph.form.event.untilCriteriaEmpty' | translate }}
          </p>

          <p *ngIf="eventValue?.timeEventDate != null">
            {{ 'executionEdit.essays.graph.form.event.date' | translate }} {{ eventValue?.timeEvent }}
          </p>
          <p *ngIf="eventValue?.timeEventDate != null">
            {{ 'executionEdit.essays.graph.form.event.labelUntilCriteriaDate' | translate }}
            {{ eventValue?.timeEventDate | fixDateTime }}
          </p>
          <p [class]="classForPeakMax()" *ngIf="eventValue?.timeEventDate != null">
            {{ 'executionEdit.essays.graph.form.event.peakValueMax' | translate }} {{ eventValue?.peakValueMax }}
          </p>
          <p [class]="classForPeakMin()" *ngIf="eventValue?.timeEventDate != null">
            {{ 'executionEdit.essays.graph.form.event.peakValueMin' | translate }} {{ eventValue?.peakValueMin }}
          </p>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex="50">
              <span> {{ 'executionEdit.essays.graph.form.event.peak.type.label' | translate }} </span>
            </div>
            <div fxFlex="40">
              <mat-select required name="idPeak" [(ngModel)]="idPeak" (selectionChange)="onIdPeakChange($event)">
                <mat-option *ngFor="let item of peakTypes" [value]="item.id">
                  {{ 'executionEdit.essays.graph.form.event.peak.' + item.translation + '.label' | translate }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div fxFlex="20" fxFlex.lt-md="100%">
          <p style="font-weight: bold">{{ 'executionEdit.essays.graph.form.event.labelAfterStability' | translate }}</p>

          <p *ngIf="eventValue?.timeAfterDate == null" class="error">
            {{ 'executionEdit.essays.graph.form.event.stabilityEmpty' | translate }}
          </p>

          <p> {{ 'executionEdit.essays.graph.form.event.date' | translate }} {{ eventValue?.timeAfter }} </p>
          <p *ngIf="eventValue?.timeAfterDate">
            {{ 'executionEdit.essays.graph.form.event.labelAfterStabilityDate' | translate }}
            {{ eventValue?.timeAfterDate | fixDateTime }}
          </p>
          <p *ngIf="eventValue?.minValueAfter">
            {{ 'executionEdit.essays.graph.form.event.min' | translate }} {{ eventValue?.minValueAfter }}
          </p>
          <p *ngIf="eventValue?.avgValueAfter">
            {{ 'executionEdit.essays.graph.form.event.average' | translate }} {{ eventValue?.avgValueAfter }}
          </p>
          <p *ngIf="eventValue?.maxValueAfter">
            {{ 'executionEdit.essays.graph.form.event.max' | translate }} {{ eventValue?.maxValueAfter }}
          </p>
        </div>

        <div fxFlex="20" fxFlex.lt-md="100%">
          <p style="font-weight: bold">Salida de criterio</p>

          <div *ngIf="eventValue?.firstOutOfCriteria">
            <div *ngIf="eventValue?.firstOutOfCriteriaDate">
              {{ 'executionEdit.essays.graph.form.event.firstOutOfCriteria' | translate : { sensor: eventValue?.firstOutOfCriteria, date: eventValue?.firstOutOfCriteriaDate | fixDateTime } }}
            </div>
          </div>
         <!--<div *ngIf="eventValue?.lastOutOfCriteria">
            <div *ngIf="eventValue?.lastOutOfCriteriaDate">
              {{ 'executionEdit.essays.graph.form.event.lastOutOfCriteria' | translate : { sensor: eventValue?.lastOutOfCriteria, date: eventValue?.lastOutOfCriteriaDate | fixDateTime } }}
            </div>
            <div *ngIf="!eventValue?.lastOutOfCriteriaDate">
              {{ 'executionEdit.essays.graph.form.event.lastOutOfCriteria.none' | translate : { sensor: eventValue?.lastOutOfCriteria } }}
            </div>
          </div>-->

        </div>
        <div fxFlex="20" fxFlex.lt-md="100%">
          <p style="font-weight: bold">Entrada a criterio</p>

         <!--<div *ngIf="eventValue?.firstInCriteria">
            <div *ngIf="eventValue?.firstInCriteriaDate">
              {{ 'executionEdit.essays.graph.form.event.firstInCriteria' | translate : { sensor: eventValue?.firstInCriteria, date: eventValue?.firstInCriteriaDate | fixDateTime } }}
            </div>
          </div> -->

          <div *ngIf="eventValue?.lastInCriteria">
            <div *ngIf="eventValue?.lastInCriteriaDate">
              {{ 'executionEdit.essays.graph.form.event.lastInCriteria' | translate : { sensor: eventValue?.lastInCriteria, date: eventValue?.lastInCriteriaDate | fixDateTime } }}
            </div>
          </div>

          <div *ngIf="eventValue?.probeNeverRecovers">
            <div>
              {{ 'executionEdit.essays.graph.form.event.neverRecovers' | translate : { sensor: eventValue?.probeNeverRecovers } }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
        *ngIf="lethalityValue != null && lethalityValue.length > 0">

        <div fxFlex="100">
          {{ 'executionEdit.essays.graph.form.exhibitionTime.label' | translate }}
          {{ 'executionEdit.essays.graph.form.exhibitionTime.from' | translate }} {{minDate | fixDateTime }}
          {{ 'executionEdit.essays.graph.form.exhibitionTime.to' | translate }} {{maxDate | fixDateTime }}
        </div>

        <div fxFlex="16" fxFlex.lt-md="100%" *ngFor="let data of lethalityValue">
          <p style="font-weight: bold"> {{ 'executionEdit.essays.graph.form.calculatedData.sensorName' | translate }}
            {{ data.sensorName }} ({{ data.serialNum }}) </p>

          <p *ngIf="data.showAccumulated || data.showTotal">
            {{ 'executionEdit.essays.graph.form.lethality.labelStartExhibition' | translate }}
            {{ data.initExhibition }}
          </p>
          <p *ngIf="data.showAccumulated">
            {{ 'executionEdit.essays.graph.form.lethality.labelEndExhibition' | translate }} {{ data.endExhibition }}
          </p>
          <p *ngIf="data.showTotal || data.showHundredToEnd">
            {{ 'executionEdit.essays.graph.form.lethality.labelEndEssay' | translate }} {{ data.endEssay }}
          </p>
          <p *ngIf="data.showHundredToHundred || data.showHundredToEnd">
            {{ 'executionEdit.essays.graph.form.lethality.labelAt100Degree' | translate }} {{ data.init100Degree }}
          </p>
          <p *ngIf="data.showHundredToHundred">
            {{ 'executionEdit.essays.graph.form.lethality.labelDown100Degree' | translate }} {{ data.end100Degree }}
          </p>

          <p *ngIf="data.showAccumulated">
            {{ 'executionEdit.essays.graph.form.lethality.accumulated' | translate }} {{ data.accumulated }}
          </p>
          <p *ngIf="data.showTotal">
            {{ 'executionEdit.essays.graph.form.lethality.total' | translate }} {{ data.total }}
          </p>
          <p *ngIf="data.showHundredToHundred">
            {{ 'executionEdit.essays.graph.form.lethality.hundredToHundred' | translate }}
            {{ data.hundredToHundred }}
          </p>
          <p *ngIf="data.showHundredToEnd">
            {{ 'executionEdit.essays.graph.form.lethality.hundredToEnd' | translate }} {{ data.hundredToEnd }}
          </p>

          <p *ngIf="data.showAccumulated && data.accumulated < data.lethalityCriteria" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.lethality.isErrAccumulatedMin' | translate }}
          </p>
          <p *ngIf="data.showTotal && data.total < data.lethalityCriteria" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.lethality.isErrTotalMin' | translate }}
          </p>
          <p *ngIf="data.showHundredToHundred && data.hundredToHundred < data.lethalityCriteria"
            [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.lethality.isErrHundredToHundredMin' | translate }}
          </p>
          <p *ngIf="data.showHundredToEnd && data.hundredToEnd < data.lethalityCriteria" [class]="getClassError(data)">
            {{ 'executionEdit.essays.graph.form.lethality.isErrHundredToEndMin' | translate }}
          </p>
        </div>
      </div>

      <mat-toolbar color="secondary">
        {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
        <div fxFlex="100" fxFlex.lt-md="100%" style="padding: 10px;">
          <div fxLayout="column" *ngFor="let item of avgSensors">
            <p fxFlex="100" class="error">
              {{item}}
            </p>
          </div>
          <div fxLayout="column" *ngFor="let item of diffAvarages">
            <p fxFlex="100" class="error">
              {{item}}
            </p>
          </div>
          <div fxLayout="column" *ngFor="let item of diffProbeRespectAvg">
            <p fxFlex="100" class="error">
              {{item}}
            </p>
          </div>
        </div>
      </div>
    </ng-template>

  </mat-expansion-panel>
</mat-accordion>