import { CriteriaType, CriteriaTypeEnum } from '../model/criteriaType';

import { ArrayUtils } from './arrayUtils';
import { Criteria } from '../model/criteria';
import { DateUtils } from './dateUtils';
import { EssayAirTypeEnum } from '../model/essayType';
import { EssayProtocol } from '../model/essayProtocol';
import { SensorData } from '../model/sensorData';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolUtils } from './protocolUtils';
import { FieldEnum } from './fieldUtils';

export enum EssayTypeEnum {
    DISTRIBUCION_EN_VACIO = 1,
    DISTRIBUCION_CON_MEDIA_CARGA = 2,
    DISTRIBUCION_CON_CARGA_COMPLETA = 3,
    ENSAYO_APERTURA_DE_PUERTA = 4,
    ENSAYO_CORTE_ELECTRICO = 5,
    TEST_ESTANQUEIDAD = 6,
    TEST_VACIO = 7,
    TEST_BOWIE_DICK = 8,
    PENETRACION_CON_CARGA = 9,
    CICLO_SIP_CICLO_ESTERILIZACION_FILTRO = 10,
    DISTRIBUCION_CON_CARGA = 11,
}

export enum DependencyTypeEnum {
    MANDATORY = 1,
    OPTIONAL = 2,
    NONE = 3,
}

export enum TypeUploadAirEnum {
    TYPE_ESSAY = 1,
    TYPE_FILTER = 2,
}

export class MinMaxValueEssay {
    minValue: number;
    maxValue: number;
    canBeEqual: boolean;

    minValuePeak: number;
    maxValuePeak: number;

    minValueDelta: number;
    maxValueDelta: number;
    canBeEqualDelta: boolean;

    maxValueStandardDeviation: number;
    canBeEqualStandardDeviation: boolean;
}

export class HotColdValueEssay {
    hotspot: number;
    coldspot: number;
}

export class EventValueEssay {
    minValueBefore: number;
    avgValueBefore: number;
    maxValueBefore: number;

    timeEvent: string;
    timeEventDate: Date;

    peakValueMax: number;
    peakValueMin: number;

    minValueAfter: number;
    avgValueAfter: number;
    maxValueAfter: number;

    timeAfter: string;
    timeAfterDate: Date;

    startDateShow: Date;
    endDateShow: Date;

    firstOutOfCriteria: string;
    firstOutOfCriteriaDate: Date;

    lastOutOfCriteria: string;
    lastOutOfCriteriaDate: Date;

    firstInCriteria: string;
    firstInCriteriaDate: Date;

    lastInCriteria: string;
    lastInCriteriaDate: Date;

    probeNeverRecovers: string;
}

export class LethalityValueEssay {
    sensorName: string;
    serialNum: string;

    initExhibition: number;
    endExhibition: number;
    endEssay: number;
    init100Degree: number;
    end100Degree: number;

    accumulated: number;
    total: number;
    hundredToHundred: number;
    hundredToEnd: number;

    showAccumulated: boolean;
    showTotal: boolean;
    showHundredToHundred: boolean;
    showHundredToEnd: boolean;

    lethalityCriteria: number;

    outer: boolean;
}

export class EssayUtils {

    public static getCriteriasToShowThermal(essay: EssayProtocol, translate: TranslateService): string {
        return this.getCriteriasToShow(essay, null, translate, true);
    }

    public static getCriteriaToShowAir(crit: Criteria | CriteriaType, idEssayType: number, translate: TranslateService): string {
        return this.getCriteriaToShow(crit, idEssayType, translate, true);
    }
    public static getCriteriaToShowThermal(crit: Criteria | CriteriaType, translate: TranslateService): string {
        return this.getCriteriaToShow(crit, null, translate, false);
    }
    public static getCriteriasToShowQualificatesWithSpace(essay: EssayProtocol, translate: TranslateService, cycle: number): string[] {
        return this.getCriteriasToShowWithSpace(essay, null, translate, true, cycle);
    }

    public static checkColdSpot(name: string, coldSpotValue: number, data: SensorData[]): boolean {
        let res = false;

        if (coldSpotValue == null) {
            return res;
        }

        const groupBy = ArrayUtils.groupBy(data, (item: SensorData) => {
            const date = item.date;
            return date.toUTCString();
        });
        groupBy.forEach((values: SensorData[]) => {
            const avg = ArrayUtils.calcAvg(values.map(item => item.value));

            values.forEach(val => {

                if (res || val.equipmentName !== name) {
                    return;
                }

                if (val.value < (avg - coldSpotValue)) {
                    res = true;
                }
            });
        });

        return res;
    }

    public static checkHotSpot(name: string, hotSpotValue: number, data: SensorData[]): boolean {
        let res = false;

        if (hotSpotValue == null) {
            return res;
        }

        const groupBy = ArrayUtils.groupBy(data, (item: SensorData) => {
            const date: Date = item.date;
            return date.toUTCString();
        });
        groupBy.forEach((values: SensorData[]) => {
            const avg = ArrayUtils.calcAvg(values.map(item => item.value));

            values.forEach(val => {

                if (res || val.equipmentName !== name) {
                    return;
                }

                if (val.value > (avg + hotSpotValue)) {
                    res = true;
                }
            });
        });

        return res;
    }

    public static calculateNearDate(date: Date, data: SensorData[]): Date {
        if (date == null) {
            return date;
        }

        date = DateUtils.anyToDate(date);

        const dataBefore = data.map(d => d.date).filter(d => d.getTime() <= date.getTime());
        const dataAfter = data.map(d => d.date).filter(d => d.getTime() >= date.getTime());

        const dateBefore = dataBefore[dataBefore.length - 1] || DateUtils.anyToDate(0);
        const dateAfter = dataAfter[0] || DateUtils.anyToDate(0);

        const diffBefore = Math.abs(date.getTime() - dateBefore.getTime());
        const diffAfter = Math.abs(date.getTime() - dateAfter.getTime());

        if (diffBefore > diffAfter) {
            date.setTime(date.getTime() + diffAfter);
        } else if (diffBefore < diffAfter) {
            date.setTime(date.getTime() - diffBefore);
        } else {
            const minInit = date.getMinutes();

            const minAfter = dateAfter.getMinutes();
            const minBefore = dateBefore.getMinutes();

            const diffMinBefore = Math.abs(minInit - minBefore);
            const diffMinAfter = Math.abs(minInit - minAfter);

            if (diffMinBefore > diffMinAfter) {
                date.setTime(date.getTime() + diffAfter);
            } else {
                date.setTime(date.getTime() - diffBefore);
            }
        }

        return date;
    }

    private static getCriteriaToShow(crit: Criteria | CriteriaType, idEssayType: number, translate: TranslateService,
        isAir: boolean): string {
        let value1: number = null;
        let value2: number = null;
        let translation: string = null;
        let customValue1: string = null;
        let customValue2: string = null;
        let idCriteriaType: number = null;

        if (crit instanceof Criteria) {
            idCriteriaType = crit.idType;
            value1 = crit.criteriaValue1;
            value2 = crit.criteriaValue2;
            customValue1 = crit.customValue1;
            customValue2 = crit.customValue2;
            translation = crit.criteriaTranslation;
        } else if (crit instanceof CriteriaType) {
            idCriteriaType = crit.id;
            value1 = crit.defaultValue1;
            value2 = crit.defaultValue2;
            customValue1 = crit.defaultValue1.toString();
            customValue2 = crit.defaultValue2.toString();
            translation = crit.translation;
        } else {
            // Typescript es demasiado pesado con esto
            const cri: any = crit;

            if (cri.criteriaTranslation != null) {
                idCriteriaType = cri.idType;
                value1 = cri.criteriaValue1;
                value2 = cri.criteriaValue2;
                translation = cri.criteriaTranslation;
                customValue1 = cri.customValue1;
                customValue2 = cri.customValue2;
            } else {
                idCriteriaType = cri.id;
                value1 = cri.defaultValue1;
                value2 = cri.defaultValue2;
                translation = cri.translation;
                customValue1 = cri.customValue1;
                customValue2 = cri.customValue2;
            }
        }

        const customAirEssays = [
            EssayAirTypeEnum.DEF_PRESSURE, EssayAirTypeEnum.REN_FLOW_AND_RATE, EssayAirTypeEnum.AIR_SPEED, EssayAirTypeEnum.DEW_POINT,
            EssayAirTypeEnum.HYDROCARBON_LEVEL
        ];

        if (!isAir || !customAirEssays.includes(idEssayType)) {
            if (customValue1 != null) {
                customValue1 = translate.instant(`protocolEdit.dialog.essay.criteria.${customValue1}.label`) as string;
            }

            if (customValue2 != null) {
                customValue2 = translate.instant(`protocolEdit.dialog.essay.criteria.${customValue2}.label`) as string;
            }
        }

        let res = translate.instant(`protocolEdit.dialog.essay.criteria.table.${translation}.label`,
            { value1, value2, customValue1, customValue2 }) as string;

        if (isAir) {
            if (idEssayType === EssayAirTypeEnum.DEF_PRESSURE) {
                 const prefix = translate.instant('protocolEdit.dialog.essay.about.label') as string;
                 res += ' ' + prefix.toLowerCase() + ' ' + customValue1;
            } else if (idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
                res += ' (' + (translate.instant(`protocolEdit.dialog.essay.criteria.${customValue1}.label`) as string) + ')';
            } else if (idEssayType === EssayAirTypeEnum.AIR_SPEED) {
                if (customValue1 != null) {
                    res = translate.instant(`protocolEdit.dialog.essay.criteria.${customValue1}.label`) as string + ': ' + res;
                }
            } else if (idEssayType === EssayAirTypeEnum.DEW_POINT) {
                res = translate.instant(`protocolEdit.dialog.essay.criteria.${customValue1}.label`) as string;
            } else if (idEssayType === EssayAirTypeEnum.HYDROCARBON_LEVEL) {
                res = translate.instant(`protocolEdit.dialog.essay.criteria.${customValue1}.label`) as string;
            }
        }

        if (idCriteriaType === CriteriaTypeEnum.INFORMATIVE) {
            res = translate.instant('protocolEdit.dialog.essay.informative.label') as string;
        }

        return res;
    }

    private static getCriteriasToShow(essay: EssayProtocol, idEssayType: number, translate: TranslateService, isAir: boolean): string {
        const res = [];

        if (essay != null && essay.criterias != null) {
            essay.criterias.forEach(crit => res.push(this.getCriteriaToShow(crit, idEssayType, translate, isAir)));
        }

        return res.filter(f => f != null).join(', ');
    }

    public static getCriteriasToShowWithSpace(essay: EssayProtocol, idEssayType: number, translate: TranslateService, isAir: boolean, cycle: number): string[] {
        const res = [];

        if (essay != null) {
            let highestFrequency;
            let time = '';
            let resCycle = '';
            if (essay.fields != null) {
                highestFrequency = essay.fields?.filter(field => field.idField === FieldEnum.REHEARSAL_TIME).map(field => field.value);         
                time =  ProtocolUtils.getValueDayHourMin(highestFrequency, translate);
                resCycle = cycle === 0 ? '' : `(${(cycle > 1 ? translate.instant('test.essay.cycle.plural', {cycle}): translate.instant('test.essay.cycle.singular', {cycle}))})`;
            }
           
            essay?.criterias.forEach(crit => {
                const resCrit = this.getCriteriaToShow(crit, idEssayType, translate, isAir);
                const variable = translate.instant(`variable.${essay.translationVariable}`);

                let text = '';
                if (crit.idType === CriteriaTypeEnum.INFORMATIVE) {
                    text = translate.instant('test.essay.criteria.informative.text', {variable: variable, criteria: resCrit, unit: essay.unitName, time: time, cycle: resCycle});
                } else {
                    if (time && time !== '') {
                        text = translate.instant('test.essay.criteria.time.text', {variable: variable, criteria: resCrit, unit: essay.unitName, time: time, cycle: resCycle});
                    } else {
                        text = translate.instant('test.essay.criteria.text', {variable: variable, criteria: resCrit, unit: essay.unitName, cycle: resCycle});

                    }

                }
                res.push(text);
            });
        }

        return res.filter(f => f != null);
    }

}
