/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { ProtocolAir, ProtocolAirFilter, ProtocolAttachmentFilter, ProtocolAuditFilter } from '../model/protocol';

import { DexieService } from './dexieService';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { ProtocolReportFilter } from '../model/attachment';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ProtocolAirService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(filter: ProtocolAirFilter): Observable<any> {
        const url = environment.airUrl + '/protocol/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: ProtocolAirFilter): Observable<any> {
        const url = environment.airUrl + '/protocol/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findAllAssignable(filter: ProtocolAirFilter): Observable<any> {
        const url = environment.airUrl + '/protocol/assignable';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/protocol/${id}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.protocol.get(+id).timeout(30000));
        }
    }

    getAudit(id: number, filter: ProtocolAuditFilter): Observable<any> {
        const url = environment.airUrl + `/protocol/${id}/audit`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(protocol: ProtocolAir, token: string): Observable<any> {
        return this.genericSave(protocol, token, null);
    }

    signInfo(prot: ProtocolAir): Observable<any> {
        const url = environment.airUrl + `/protocol/${prot.id}/signInfo/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    addSign(prot: ProtocolAir, token: string, signTypes: number[], user: string, lang: string): Observable<any> {
        const url = environment.airUrl + `/protocol/${prot.id}/addSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('user', user);
        form.append('signTypes', signTypes.join(';'));
        form.append('lang', lang);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    refuseToSign(prot: ProtocolAir, token: string, reason: string): Observable<any> {
        const url = environment.airUrl + `/protocol/${prot.id}/refuseToSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    manualSign(prot: ProtocolAir, file: File, token: string): Observable<any> {
        const url = environment.airUrl + `/protocol/${prot.id}/manualSign`;

        const form = new FormData();
        form.append('protocol', JSON.stringify(prot));
        form.append('token', token);
        form.append('document', file, file.name);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    automaticSign(prot: ProtocolAir, token: string, signTypes: number[], lang: string): Observable<any> {
        const url = environment.airUrl + `/protocol/${prot.id}/automaticSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('signTypes', signTypes.join(';'));
        form.append('lang', lang);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(id: number): Observable<any> {
        const url = environment.airUrl + `/protocol/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdf(id: number, config: ProtocolReportFilter = new ProtocolReportFilter()): Observable<any> {
        const url = environment.airUrl + `/report/protocol/${id}`;

        const headers = new HttpHeaders({
            lang: config.lang
        });

        const data = {
            reason: config.reason,
            reviewBy: config.reviewBy,
            toSign: config.toSign,
            hybride: false
        };

        return this.http.post(url, data, { responseType: 'blob', headers });
    }

    downloadPdfProtocolVersion(protocol: ProtocolAir): Observable<any> {
        return this.downloadPdfVersion(protocol.id, protocol.signedVersion);
    }

    downloadPdfVersion(idProtocol: number, version: string): Observable<any> {
        const url = environment.airUrl + `/report/protocol/${idProtocol}/version/${version}`;

        return this.http.post(url, {}, { responseType: 'blob' });
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.airUrl + `/report/protocol/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    getAttachments(id: number, filter: ProtocolAttachmentFilter): Observable<any> {
        const url = environment.airUrl + `/protocol/${id}/attachment`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAreas(id: number): Observable<any> {
        const url = environment.airUrl + `/protocol/${id}/area`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    moveAttachment(id: number, previousIndex: number, currentIndex: number): Observable<any> {
        const url = environment.airUrl + `/protocol/${id}/attachment/move`;

        return this.http.post(url, { previousIndex, currentIndex }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    revertSign(idProtocol: number, reason: string, token: string): Observable<any> {
        const url = environment.airUrl + `/protocol/${idProtocol}/revertSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveProtocolToDexie(id: number): Promise<void> {
        return new Promise<void>((resolve) => this.findOne(id).subscribe((res: ProtocolAir) => {
            void this.dexieService.protocol.put(res).then(() => resolve());
        }));
    }

    checkCopyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.airUrl + `/protocol/clonate/${id}/toGroup/${idGroup}/check`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.airUrl + `/protocol/clonate/${id}/toGroup/${idGroup}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    private genericSave(protocol: ProtocolAir, token: string, action?: string): Observable<any> {
        let url = environment.airUrl + '/protocol/';

        if (protocol.id != null) {
            url += protocol.id;

            if (action != null) {
                url += action;
            }

            return this.http.put(url, { protocol, token }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';

            return this.http.post(url, { protocol, token }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    downloadPdfHybride(id: number, config: ProtocolReportFilter = new ProtocolReportFilter()): Observable<any> {
        const url = environment.airUrl + `/report/protocol/hybride/${id}`;

        const headers = new HttpHeaders({
            lang: config.lang
        });

        const data = {
            hybride: true,
            reason: config.reason,
            reviewBy: config.reviewBy,
            toSign: config.toSign
        };

        return this.http.post(url, data, { responseType: 'blob', headers });
    }

    moveEssayProtocol(id: number, type: number, previousIndex: number, currentIndex: number): Observable<any> {
        const url = environment.airUrl + `/protocol/${id}/essay/${type}/move`;

        return this.http.post(url, { previousIndex, currentIndex }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
