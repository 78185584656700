/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subject, takeUntil } from 'rxjs';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { Phase } from 'src/app/model/phase';
import { RoleUser } from 'src/app/model/roleUser';
import { User } from 'src/app/model/user';
import { LangFlag } from 'src/app/services/translation.service';
import { FooterComponent } from '../footer/footer.component';
import { RoleEnum } from '../../../model/roleUser';
import { NavigationStart, Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { DrawerService } from '../../../services/drawer.service';
import { OnlineService } from '../../../services/online.service';
import { UserService } from '../../../services/user.service';
import { PhaseService } from '../../../services/phase.service';
import { InternalEquipmentFolderService } from '../../../services/internalEquipmentFolder.service';
import { CookieService } from 'ngx-cookie';
import { SpinnerService } from '../../../services/spinner.service';
import { GlobalStateService } from '../../../services/globalState.service';
import { TranslationService } from '../../../services/translation.service';
import { AppTypeEnum } from '../../../model/appType';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Constants } from '../../../utils/constants';
import { StringUtils } from '../../../utils/stringUtils';

interface ExampleFlatNode {
  id: number;
  name: string;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-fixed-sidebar',
  templateUrl: './fixed-sidebar.component.html',
  styleUrls: ['./fixed-sidebar.component.scss']
})
export class FixedSidebarComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  isLoggedIn: boolean;
  phases: Phase[];

  user = new User();
  folders: InternalEquipmentFolder[] = [];

  disableOfflineButton = false;

  sideBarHide: boolean;

  profils: RoleUser[];
  idAppSelected: number;
  appActive: string;
  idAppActive: number;
  selectedGroup: number;

  public langs: LangFlag[];
  public activeFlag: string;
  public activeNameFlag: string;

  private _transformer = (node: InternalEquipmentFolder, level: number) => {
    return {
      expandable: !!this.getFolders(node.id) && this.getFolders(node.id).length > 0,
      name: node.name,
      level,
      id: node.id
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => this.getFolders(node.id)
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  private destroy$ = new Subject<void>();

  constructor(
    private route: Router,
    private loginService: LoginService,
    private drawerService: DrawerService,
    private onlineService: OnlineService,
    private userService: UserService,
    private phaseService: PhaseService,
    private internalEquipmentFolderService: InternalEquipmentFolderService,
    private router: Router,
    private spinnerService: SpinnerService,
    private globalStateService: GlobalStateService,
    public translationService: TranslationService) {
  }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    const currentUser = this.userService.currentProfile;

    this.isLoggedIn$ = this.loginService.isLoggedIn$;
    this.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(item => {
      this.isLoggedIn = item;

      if (this.isLoggedIn === true && currentUser.idActiveApp === AppTypeEnum.THERMAL) {
        this.reloadPhases();
      }
    });

    this.userService.currentProfile.profiles.forEach(pr => {
      const existsApp = this.profils?.find(ps => ps.idApp === pr.idApp);

      if (!existsApp) {
        if (!this.profils) {
          this.profils = [];
        }
        this.profils.push(pr);
      }
    });

    if (this.isLoggedIn === true && currentUser.idActiveApp === AppTypeEnum.THERMAL) {
      this.reloadPhases();
    }

    this.langs = this.translationService.configuredLangs;


    if (this.isLoggedIn === true) {
      this.updateSidebar();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateSidebar(): void {
    if (this.isLoggedIn) {
      this.userService.profile().pipe(takeUntil(this.destroy$)).subscribe((item: User) => {
        if (item.username == null) {
          this.loginService.logout();
        }
        this.user = item;
      });

      const currentUser = this.userService.currentProfile;

      this.selectedGroup = currentUser.profiles.find(p => p.idGroup === currentUser.idActiveProfile)?.idGroup;

      if (currentUser.idActiveApp === AppTypeEnum.THERMAL) {
        this.reloadPhases();
      }

      this.appActive = this.userService.currentProfile.activeApp;
      this.idAppActive = this.userService.currentProfile.idActiveApp;
      this.activeFlag = this.langs.find(la => la.lang === this.translationService.currentLang)?.flag;
      this.activeNameFlag = this.translationService.currentLang;
      this.internalEquipmentFolderService.getAll().subscribe((res: InternalEquipmentFolder[]) => {
        this.folders = res;
        this.dataSource.data = this.getFolders();
      });
    }
  }

  showThermal(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }
    return currentUser.profiles.find(a => a.idApp === AppTypeEnum.THERMAL) ? true : false;
  }

  showQualificates(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }
    return currentUser.profiles.find(a => a.idApp === AppTypeEnum.QUALIFICATES) ? true : false;
  }


  showCalibrates(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }
    return currentUser.profiles.find(a => a.idApp === AppTypeEnum.CALIBRATES) ? true : false;
  }

  showThermalItem(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }
    return currentUser.idActiveApp === AppTypeEnum.THERMAL;
  }

  showCalibratesItem(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }
    return currentUser.idActiveApp === AppTypeEnum.CALIBRATES;
  }

  showAir(): boolean {
    const currentUser = this.userService.currentProfile;
    if (currentUser == null) {
      return false;
    }

    return currentUser.profiles.find(a => a.idApp === AppTypeEnum.AIR) ? true : false;
  }


  showAirItem(): boolean {
    const currentUser = this.userService.currentProfile;
    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.AIR;
  }

  showCharacterization(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.showCharacterization && (this.showThermal() || this.showCalibrates());
  }


  showCharacterizationItem(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.showCharacterization && (this.showThermalItem() || this.showCalibratesItem());
  }

  showManageClients(): boolean {
    const idRole = this.user.idActiveRole;

    const rolesCanManage = [RoleEnum.ADMIN, RoleEnum.MANAGER, RoleEnum.RESPONSABLE];

    return this.user.admin || rolesCanManage.includes(idRole);
  }

  showCalibrationPlan(): boolean {
    const idRole = this.user.idActiveRole;

    const rolesCanManage = [RoleEnum.ADMIN, RoleEnum.MANAGER];
    return rolesCanManage.includes(idRole) && this.user.showCalibrationPlan;
  }

  getFolders(idParent: number = null): InternalEquipmentFolder[] {
    let res = this.folders?.filter(f => f.idFather === idParent);

    if (idParent == null) {
      res = this.folders?.filter(f => f.idFather == null);
    }

    return res != null ? res : [];
  }

  reloadPhases(): void {
    this.phases = [];
    this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((item: Phase[]) => this.phases = item);
  }

  checkRouterDisabledOffline(url: string): string {
    const currentUser = this.userService.currentProfile;
    if (!url.includes('admin') && !url.includes('clients')) {
      if (this.route.url.includes('thermal')) {
        url = '/thermal/' + url;
      } else if (this.route.url.includes('air')) {
        url = '/air/' + url;
      } else if (this.route.url.includes('calibrates')) {
        url = '/calibrates/' + url;
      } else {
        if (currentUser) {
          if (currentUser.idActiveApp === AppTypeEnum.AIR) {
            url = '/air/'+url;
          } else if (currentUser.idActiveApp === AppTypeEnum.THERMAL) {
            url = '/thermal/'+url;
          } else if (currentUser.idActiveApp === AppTypeEnum.CALIBRATES) {
            url = '/calibrates/'+url;
          }
        }
      }
    }
    return url;
  }

  getInitials(): string {
    let res = '';
    if (this.user.fullName) {
      res = StringUtils.getInitials(this.user.fullName);
    }
    return res;
  }

  goToProtocol(phase: Phase): void {
    this.goToPage('protocols', Constants.FIELD_ID_PHASE, phase.id);
  }

  goToExecutions(phase: Phase): void {
    this.goToPage('executions', Constants.FIELD_ID_PHASE, phase.id);
  }

  goToInternalEquipment(folder: InternalEquipmentFolder | ExampleFlatNode): void {
    this.goToPage('internalEquipments', Constants.FIELD_ID_FOLDER, folder.id);
  }

  goToPage(page: string, field: string, id: number): void {
    let url: string;
    const currentUser = this.userService.currentProfile;

    if (!page.includes('admin') && !page.includes('clients')) {
      if (this.route.url.includes('thermal')) {
        url = '/thermal/'+page;
      } else if (this.route.url.includes('air')) {
        url = '/air/'+page;
      } else if (this.route.url.includes('calibrates')) {
        url = '/calibrates/'+page;
      } else {
       if (currentUser) {
        if (currentUser.idActiveApp === AppTypeEnum.AIR) {
          url = '/air/'+page;
        } else if (currentUser.idActiveApp === AppTypeEnum.THERMAL) {
          url = '/thermal/'+page;
        } else if (currentUser.idActiveApp === AppTypeEnum.CALIBRATES) {
          url = '/calibrates/'+page;
        }
       }
      }
    }

    if (id != null) {
      url = `${url}?${field}=${id}`;
    }

    void this.router.navigateByUrl(url);
  }

  redirectionPage(): void {
    void this.router.navigateByUrl('/');
  }

  getThemeIconPath(): string {
    return 'assets/icons/';
  }

  onSelectionOption(option: number): void {
    if (this.onlineService.latestOnline) {
      this.spinnerService.show();
      this.userService.changeProfile(this.selectedGroup, option).pipe(takeUntil(this.destroy$)).subscribe(item => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.userService.saveProfile(item);
        if (option === AppTypeEnum.AIR) {
          this.globalStateService.setHomeAirState('air-state');
          void this.router.navigate(['air']);
        } else if (option === AppTypeEnum.THERMAL) {
          this.globalStateService.setHomeThermalState('thermal-state');
          void this.router.navigate(['thermal']);
        } else if (option === AppTypeEnum.CALIBRATES) {
          this.globalStateService.setHomeCalibratesState('calibrates-state');
          void this.router.navigate(['calibrates']);
        }
        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
      });
    }
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  showSideBarFixed(): boolean {
    if (this.route.url.includes('/air') || this.route.url.includes('/thermal') || this.route.url.includes('/calibrates')
    || this.route.url.includes('/qualificates')) {
      return true;
    } else {
      return false;
    }
  }

  showQualificatesItem(): boolean {
    const currentUser = this.userService.currentProfile;
    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.QUALIFICATES;
  }
  
  changeColorApp(idApp: number): string {
    if (idApp ===  AppTypeEnum.CALIBRATES) {
      return '#009a43';
    } else if (idApp ===  AppTypeEnum.THERMAL) {
      return '#e16b2f';
    } else if (idApp ===  AppTypeEnum.QUALIFICATES) {
      return '#8f5da0';
    } else if (idApp ===  AppTypeEnum.AIR) {
      return '#00aec7';
    }
  }

  public changeLang(lang: LangFlag): void {
    this.activeNameFlag = lang.lang;
    this.activeFlag = lang.flag;
    this.translationService.changeLang(lang.lang);
  }

}
