<mat-card appearance="outlined" class="card">
  <mat-card-content>

    <h1>{{ 'cookies.title' | translate }}</h1>

    <p> {{ 'cookies.text1' | translate }} </p>

    <p>{{ 'cookies.text2' | translate }}</p>

    <h2>{{ 'cookies.text3' | translate }}</h2>

    <ul>
      <li>{{ 'cookies.text4' | translate }}</li>

      <li>{{ 'cookies.text5' | translate }}</li>

      <li>{{ 'cookies.text6' | translate }}</li>

    </ul>

  </mat-card-content>
</mat-card>