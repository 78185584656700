import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppPlanType } from 'src/app/model/appPlanType';
import { AppPlanTypeService } from 'src/app/services/appPlanType.service';
import { Constants } from 'src/app/utils/constants';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/translation.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-app-plan-edit',
  templateUrl: './app-plan-edit.component.html'
})
export class AppPlanEditComponent implements OnInit, OnDestroy {

  matcher = new MyErrorStateMatcher();
  editorConfig: any;

  currentUser: User;

  appPlan: AppPlanType;
  isEdit: boolean;

  appPlanTypes: AppPlanType[];

  phases: Phase[];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appPlanTypeService: AppPlanTypeService,
    private userService: UserService,
    private translate: TranslateService,
    private phaseService: PhaseService,
    private translationService: TranslationService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.currentUser = this.userService.currentProfile;

    this.editorConfig = {};
    this.editorConfig.language = this.translationService.currentLang;
    this.translationService.lang$.pipe(takeUntil(this.destroy$)).subscribe(lang => this.editorConfig.language = lang);

    this.appPlanTypes = [];
    this.appPlanTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: AppPlanType[]) => this.appPlanTypes = res);

    this.spinnerService.show();
    this.appPlan = new AppPlanType();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id != null) {
        this.loadAppPlan(id);
        this.isEdit = true;
      } else {
        this.appPlan = new AppPlanType();

        this.isEdit = false;
        this.spinnerService.hide();
      }
    });
  }

  ngOnInit(): void {
    if (this.currentUser.admin) {
      this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: Phase[]) => this.phases = res);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(): void {
    this.spinnerService.show();

    this.appPlanTypeService.update(this.appPlan).pipe(takeUntil(this.destroy$)).subscribe((res: AppPlanType) => {
      this.appPlan = res;
      this.loadAppPlan(this.appPlan.id);

      if (!this.isEdit) {
        this.snackBarService.sendSuccess(this.translate.instant('appPlanEdit.form.create.ok') as string);

        void this.router.navigateByUrl(`/admin/appPlan?id=${res.id}`);
      } else {
        this.snackBarService.sendSuccess(this.translate.instant('appPlanEdit.form.update.ok') as string);
      }

      this.spinnerService.hide();
    }, () => {
      if (!this.isEdit) {
        this.snackBarService.sendError(this.translate.instant('appPlanEdit.form.create.error') as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('appPlanEdit.form.update.error') as string);
      }

      this.spinnerService.hide();
    });

  }

  cancel(): void {
    void this.router.navigateByUrl('/admin/manageAppPlan');
  }

  private loadAppPlan(id: number): void {
    this.appPlanTypeService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: AppPlanType) => {
      if (result != null) {
        this.appPlan = result;
      } else {
        this.appPlan = null;
      }
    }, () => {
      this.appPlan = null;
    }, () => {

      // Si ha habido un error (por lo que el usuario es nulo) o
      // el nombre de usuario es nulo pero está editando, volvemos al listado
      if (this.appPlan == null) {
        this.cancel();
      }

      this.spinnerService.hide();
    });
  }

}
