import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService, CookieService as NgxCookieService } from 'ngx-cookie-service';
import { OnlineService } from './online.service';
import { RequestCacheService } from './requestCache.service';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../utils/constants';

@Injectable()
export class LoginService {

    public isLoggedIn: boolean;

    private loggedInSubject = new BehaviorSubject<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public isLoggedIn$ = this.loggedInSubject.asObservable();

    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true'
    });

    constructor(
        private http: HttpClient, private router: Router, private dialogRef: MatDialog, private userService: UserService,
        private onlineService: OnlineService, private cacheService: RequestCacheService, private cookieService: CookieService,
        private ngxCookieService: NgxCookieService) {

        void cacheService.getCache('profile').then(item => this.loggedInSubject.next(item != null));

        this.isLoggedIn$.subscribe(res => this.isLoggedIn = res);
    }

    login(username: string, password: string): Observable<any> {
        const data = {
            username: username.toLowerCase(),
            password
        };

        return this.http.post(environment.authUrl + '/login', data, { headers: this.headers, withCredentials: true });
    }

    forgotPassword(username: string): Observable<any> {
        const data = { username: username.toLowerCase() };

        return this.http.post(environment.coreUrl + '/forgotPassword/', data, { headers: this.headers });
    }

    changePassword(username: string, token: string, newPassword: string): Observable<any> {
        const data = {
            username: username.toLowerCase(),
            token,
            newPassword
        };

        return this.http.post(environment.coreUrl + '/forgotPassword/changePassword', data, { headers: this.headers });
    }

    async goHome(): Promise<boolean> {
        if (this.onlineService.latestOnline) {
            return new Promise<boolean>((resolve) => this.userService.profile().subscribe((res: User) => {
                this.ngxCookieService.set('User', res.username, 7);
                this.userService.setProfileSidebar(res);
                this.loggedInSubject.next(true);
                if (res.idActiveRole !== Constants.USER_ROL_GUEST) {
                    this.ngxCookieService.set('rol', 'qloud', 7);
                    void this.router.navigate(['/']);
                    resolve(true);
                } else {
                    resolve(false);
                }
             }));
        } else {
            const username = this.cookieService.get('User');

            const user = new User();
            user.username = username;
            user.fullName = username;
            user.activeRoleTranslation = 'offline';

            this.userService.setProfileSidebar(user);
            this.loggedInSubject.next(true);
            void this.router.navigate(['/']);
        }
    }

    logout(): void {
        if (this.onlineService.latestOnline) {
            const url = environment.authUrl + '/logout';
            this.http.post(url, {}).subscribe(() => this.completeLogout(), () => this.completeLogout());
        } else {
            this.completeLogout();
        }
    }

    clearSession(): void {
        this.cacheService.deleteCache('profile');
        this.ngxCookieService.delete('rol');
        this.loggedInSubject.next(false);
        void this.router.navigateByUrl('/login?expired=true');

        const dbs: any = window.indexedDB;
    }

    async goHomeClient(): Promise<boolean> {
        return new Promise<boolean>((resolve) => this.userService.profile().subscribe((res: User) => {
            if (res.idActiveRole === Constants.USER_ROL_GUEST) {
                this.ngxCookieService.set('User', res.username, 7);
                this.userService.setProfileSidebar(res);
                this.loggedInSubject.next(true);
                this.ngxCookieService.set('rol', 'guest', 7);
                void this.router.navigate(['/portal-client']);
                resolve(true);
            } else {
                resolve(false);
            }
        }));
    }

    private completeLogout(): void {
        const client = this.cookieService.get('rol');

        this.dialogRef.closeAll();
        this.clearSession();
        this.ngxCookieService.delete('rol');

        if (client === 'guest') {
            void this.router.navigate(['/login-client'], { queryParamsHandling: 'merge' });
        }else {
            void this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
        }

    }

}
