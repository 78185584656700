<h1 mat-dialog-title>{{'protocolEdit.section.template.title' | translate}}</h1>
<form>

    <div fxLayout="column" class="flexwrap" style="display: block;">
        <mat-card class="card" fxFlex style="width: 100%;">
            <mat-card-content>
                <mat-toolbar color="secondary">
                    {{ 'protocolEdit.introduction.title' | translate }}
                </mat-toolbar>

                <br />

                <div fxLayout="column">

                    <div fxFlex>
                        <mat-accordion>
                            <mat-expansion-panel class="overflowX" [expanded]="precedentsTab" (opened)="precedentsTab = true" (closed)="precedentsTab = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div style="margin: auto 0;display: block;">
                                           <span [class]="precedentsTab ? 'bold' : ''">{{'protocolEdit.introduction.precedents.title' | translate}}</span>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxFlex>
                                    <quill-editor class="quill-container ql-editor" [(ngModel)]="data.protocol.precedents" [name]="'precedents'" [id]="'precedents'" [modules]="{ toolbar: quillConfig.toolbar }" style="height: auto; font-weight: normal;"></quill-editor>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div fxFlex>
                        <mat-accordion>
                            <mat-expansion-panel class="overflowX" [expanded]="scopeTab" (opened)="scopeTab = true" (closed)="scopeTab = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div style="margin: auto 0;display: block;">
                                            <span [class]="scopeTab ? 'bold' : ''">{{'protocolEdit.introduction.target.title' | translate}}</span>
                                         </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxFlex>
                                    <quill-editor class="quill-container ql-editor" [(ngModel)]="data.protocol.scope" [name]="'scope'" [id]="'scope'" [modules]="{ toolbar: quillConfig.toolbar }" style="height: auto; font-weight: normal;"></quill-editor>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div fxFlex>
                        <mat-accordion>
                            <mat-expansion-panel class="overflowX" [expanded]="targetTab" (opened)="targetTab = true" (closed)="targetTab = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div style="margin: auto 0;display: block;">
                                            <span [class]="targetTab ? 'bold' : ''">{{'protocolEdit.introduction.scope.title' | translate}}</span>
                                         </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxFlex>
                                    <quill-editor class="quill-container ql-editor" [(ngModel)]="data.protocol.target" [name]="'target'" [id]="'target'" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <br/>
        <mat-card class="card" fxFlex style="width: 100%;">
            <mat-card-content>
                <mat-toolbar color="secondary">
                    {{ 'protocolEdit.resposability.title' | translate }}
                </mat-toolbar>

                <br />

                <div fxLayout="column">

                    <div fxFlex="70" fxLayout="row">
                        <div fxFlex="100" class="table-column">
                            <span>{{'protocolEdit.resposability.organization.title' | translate}}</span>
                        </div>
                        <div fxFlex="100" class="table-column">
                            <span>{{'protocolEdit.resposability.principal.resposability.title' | translate}}</span>
                        </div>
                    </div>
                    <div fxFlex="70" fxLayout="row">
                        <div fxFlex="100" style="border:1px solid #ccc; padding: 20px; justify-content: center; place-content: center;">
                            <span>{{'protocolEdit.resposability.validation.team.title' | translate}}</span>
                        </div>
                        <div fxFlex="100" style="border:1px solid #ccc; padding: 20px; justify-content: center; display: inline-table;">
                            <quill-editor class="quill-container ql-editor" [(ngModel)]="data.protocol.validationTeamResponsibility" [name]="'target'" [id]="'target'" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                        </div>
                    </div>
                    <div fxFlex="70" fxLayout="row">
                        <div fxFlex="100" style="border:1px solid #ccc; padding: 20px; justify-content: center; place-content: center;">
                            <span>{{'protocolEdit.resposability.engineering.title' | translate}}</span>
                        </div>
                        <div fxFlex="100" style="border:1px solid #ccc; padding: 20px; justify-content: center;display: inline-table;">
                            <quill-editor class="quill-container ql-editor" [(ngModel)]="data.protocol.engineeringResponsibility" [name]="'target'" [id]="'target'" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                        </div>
                    </div>
                    <div  fxFlex="70" fxLayout="row">
                        <div fxFlex="100" style="border:1px solid #ccc; padding: 20px; justify-content: center; place-content: center;">
                            <span>{{data.nameClient ? data.nameClient : 'protocolEdit.resposability.client.title' | translate}}</span>
                        </div>
                        <div fxFlex="100" style="border:1px solid #ccc; padding: 20px; justify-content: center; display: inline-table;">
                            <quill-editor class="quill-container ql-editor" [(ngModel)]="data.protocol.clientResponsibility" [name]="'target'" [id]="'target'" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>


    <div mat-dialog-actions class="qualificates-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>