<form #executionListForm="ngForm">

    <div fxLayout="row" class="flexwrap" *ngIf="false">
        <div fxFlex="95%" fxFlex.lt-md="80%">
        </div>
        <div fxFlex="5%" fxFlex.lt-md="10%">
            <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button [disabled]="disableOfflineButton" (click)="downloadData()">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </div>
            <div fxFlex="5px"></div>
            <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button [disabled]="disableOfflineButton" (click)="uploadData()">
                    <mat-icon class="badgeOculto" [matBadge]="anyUnsentAction ? '*' : ''"
                        [matBadgeColor]="anyUnsentAction ? 'warn' : ''">backup</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="main-container small-layout">

        <div class="lead-text centerText">
            <p [innerHtml]="getText() | translate | safe: 'html'"> </p>
        </div>

        <div fxLayout="row" class="block-highlights" *ngIf="showThermal()">

            <mat-card appearance="outlined" class="card" (click)="goToPage('protocols')" appMaterialElevation>
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newProtocol()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.protocol.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}protocols.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.protocol' | translate }}">
                    <span class="title accent-color">{{ 'menu.protocol' | translate }}</span>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="card" (click)="goToPage('executions')" appMaterialElevation>
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newExecutions()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.execution.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}executions.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.execution' | translate }}">
                    <span class="title accent-color">{{ 'menu.execution' | translate }}</span>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="card" (click)="goToPage('isothermalCharacterizations')" appMaterialElevation
                *ngIf="showCharacterization()">
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newIsothermalCharacterization()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.isothermal-characterization.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}isothermalCharacterization.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.isothermal-characterization' | translate }}">
                    <span class="title accent-color">{{ 'menu.isothermal-characterization' | translate }}</span>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="card" (click)="goToPage('internalEquipments')" appMaterialElevation>
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newInternalEquipment()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.my-equipments.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}internalEquipments.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.my-equipments' | translate }}">
                    <span class="title accent-color">{{ 'menu.my-equipments' | translate }}</span>
                </mat-card-content>
            </mat-card>

        </div>

        <div fxLayout="row" class="block-highlights" *ngIf="showCalibrates()">
            <mat-card appearance="outlined" class="card" (click)="goToPage('calibrations')" appMaterialElevation>
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newCalibration()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.calibrate-equipments.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}calibrates.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.calibrate-equipments' | translate }}">
                    <span class="title accent-color">{{ 'menu.calibrate-equipments' | translate }}</span>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="card" (click)="goToPage('isothermalCharacterizations')" appMaterialElevation
                *ngIf="showCharacterization()">
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newIsothermalCharacterization()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.isothermal-characterization.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}isothermalCharacterization.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.isothermal-characterization' | translate }}">
                    <span class="title accent-color">{{ 'menu.isothermal-characterization' | translate }}</span>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="card" (click)="goToPage('internalEquipments')" appMaterialElevation>
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newInternalEquipment()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.my-equipments.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}internalEquipments.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.my-equipments' | translate }}">
                    <span class="title accent-color">
                        {{ 'menu.my-equipments' | translate }}
                    </span>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="card" (click)="goToPage('externalEquipments')" appMaterialElevation
                *ngIf="showExternalEquipments()">
                <mat-card-content class="pointerHand">
                    <div class="action-floating new-badge" (click)="newExternalEquipment()">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'button.new' | translate }}</span>
                    </div>
                    <div class="action-floating info-badge" fxHide.lt-md
                        matTooltip="{{ 'menu.external-equipment.info' | translate }}">
                        <mat-icon color="accent">info</mat-icon>
                    </div>
                    <img class="icon" src="{{ getThemeIconPath() }}externalEquipments.svg"
                        alt="{{ 'menu.manage' | translate }} {{ 'menu.external-equipment' | translate }}">
                    <span class="title accent-color">{{ 'menu.external-equipment' | translate }}</span>
                </mat-card-content>
            </mat-card>

        </div>


    </div>

    <div *ngIf="timestampt" class="generatedDate">
        {{ 'home.appVersion' | translate: {version: timestampt} }}
    </div>

</form>