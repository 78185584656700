import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/model/user';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { GenericClass } from 'src/app/model/genericClass';
import { Client } from 'src/app/model/client';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/utils/constants';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';
import { ProcessService } from 'src/app/services/process.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { TestQualificationTypeService } from '../../../services/testQualificationType.service';
import { TestQualificationTypeShort } from '../../../model/testQualificationType';
import { GroupTestQDataVerification } from 'src/app/model/groupTestQDataVerification';
import { MatTable } from '@angular/material/table';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import * as _ from 'lodash';
import { ReferenceDocument } from 'src/app/model/referenceDocument';;
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ExecutionQ, ExecutionQTest, ExecutionQTestDeviations, ExecutionStatus } from 'src/app/model/execution';
import { ExecutionQService } from 'src/app/services/executionQ.service';
import { DialogDataConfirmSave } from '../protocol-edit/protocol-edit-confirm-save/protocol-q-edit-confirm-save.component';
import { ExecutionQEditConfirmSaveComponent } from './execution-edit-q-confirm-save/execution-edit-q-confirm-save.component';
import { ExecutionQEditAuditComponent } from './execution-edit-q-audit.component';
import { TestQType } from 'src/app/model/testQType';
import { EquipmentQExecution, EquipmentQualificates } from 'src/app/model/equipment';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { ExecutionEditEquipmentDialogComponent } from './execution-edit-q-equipment-dialog/execution-edit-q-equipment-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ExecutionQEditTestComponent } from './execution-q-edit-test/execution-q-edit-test.component';
import { ExecutionQTestsService } from 'src/app/services/execution-q-tests.service';
import { ExecutionQEditAttachmentsComponent } from './execution-q-edit-attachments/execution-q-edit-attachments.component';
import { MatRadioChange } from '@angular/material/radio';
import { ProtocolAssignable, ProtocolQ, ProtocolQFilter, ProtocolQTest } from '../../../model/protocol';
import { ProtocolQListComponent } from '../protocol-list/protocol-q-list.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as saveAs from 'file-saver';
import { ClientShort } from '../../../model/clientShort';
import { ExecutionQTestItemVerification, ExecutionQTestItem, ExecutionQTestData } from '../../../model/execution';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ExecutionEditQDeviationListComponent } from './execution-edit-q-deviation-list/execution-edit-q-deviation-list.component';


@Component({
  selector: 'app-execution-q-edit',
  templateUrl: './execution-q-edit.component.html',
  styleUrls: ['./execution-q-edit.component.scss']
})
export class ExecutionQEditComponent implements OnInit {

@ViewChild(ExecutionQEditAuditComponent) auditTab: ExecutionQEditAuditComponent;
@ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;

@ViewChildren('attachmentsComponent') attachmentsComponent: ExecutionQEditAttachmentsComponent;

  form: FormGroup;
  matcher = new MyErrorStateMatcher();
  execution: ExecutionQ;
  protocol = new ProtocolQ();
  clients: Client[];
  clientsFiltered: Client[];
  processes: GenericClass[];
  respField: User[];
  groupSelected: GroupTestQDataVerification[] = [];
  testQualificationTypeList: TestQualificationTypeShort[] = [];
  testQTypeChecked:TestQType[] = [];
  equipmentList:EquipmentQExecution[] = [];
  deviationList:ExecutionQTestDeviations[] = [];
  useExistingProtocol = true;
  displayedColumns = ['code','modDate','modUser'];
  totalItems: number;

  protocols: ProtocolQ[];
  protocolsFiltered: ProtocolQ[];
  protocolFilter = new ProtocolQFilter();

  clientsShort: ClientShort[]; 
  clientsShortFiltered: ClientShort[];

  @ViewChild('protocolAutocomplete') protocolAutocompleteInput: ElementRef;

  private destroy$ = new Subject<void>();
  private prevProtocolAutocomplete: Subscription;
  private loadingAutocompleteSubject = new BehaviorSubject<boolean>(false);
  public loadingAutocomplete$ = this.loadingAutocompleteSubject.asObservable();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];

  quillConfig = {
		toolbar: [
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }]
		]
	}
  
  constructor(
    fb: FormBuilder,
    private clientService: ClientService,
    public dialog: MatDialog,
    private processService: ProcessService,
    private executionService: ExecutionQService,
    private executionQTestsService: ExecutionQTestsService,
    private route: ActivatedRoute,
    private router: Router,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    private usersService: UsersService,
    private testQualificationTypeService: TestQualificationTypeService,
    private protocolService: ProtocolQService
  ) {
    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;
      const idProtocolParam = +params[Constants.FIELD_ID_PROTOCOL];

      if (id == null) {
        this.cancel();
      }

      this.protocols = [];
      this.clients = [];

      this.execution = new ExecutionQ();
      if (+id === 0) {
        this.testQualificationTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: TestQualificationTypeShort[]) => {
          this.testQualificationTypeList = data;
        });

        this.clearProtocolFilter();
        this.protocolService.findAllAssignable(this.protocolFilter).pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data && data.content) {
            this.protocols = data.content as ProtocolQ[];
          } else if (data) {
            this.protocols = data as ProtocolQ[];
          } else {
            this.protocols = [];
          }
          this.protocolsFiltered = this.protocols.slice();
        });

        this.useExistingProtocol = true;
        if (idProtocolParam != null) {
          
          this.onProtocolChange(idProtocolParam);
        }

        this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: ClientShort[]) => this.loadClientsShort(data));

        this.spinnerService.hide();
      } else {
        
        this.executionService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQ) => {
          if (result != null) {
              this.execution = result;
            
              this.initializeAttachmentsComponent();

              this.execution.referenceDocs?.sort((eq1, eq2) => {
                let order = eq1.id - eq2.id;
  
                if (order === 0) {
                  order = eq1.id - eq2.id;
                }
  
                return order;
              });

             
              this.execution.equipments?.sort((eq1, eq2) => {
                let order = eq1.id - eq2.id;
  
                if (order === 0) {
                  order = eq1.id - eq2.id;
                }
  
                return order;
              });
              this.equipmentList = result.equipments;

              this.deviationList = result.executionQTestDeviations;
            if (this.execution == null) {
              this.cancel();
              return;
            } else {
              this.spinnerService.show();
              this.executionQTestsService.findAll(this.execution.id).pipe(takeUntil(this.destroy$))
              .subscribe((data: TestQualificationTypeShort[]) => {

                data.forEach(item => {
                
                  let testQTypeChecked: TestQType[] = [];
            
                  item.testQTypes.forEach(test => {
                    if (test.checked) {
                      testQTypeChecked.push(test); 
                    }
                  });
                  item.testQTypes = testQTypeChecked;
                });
                this.testQualificationTypeList = data;
              });

              this.spinnerService.hide();
            }
          } else {
           
            this.execution = null;
          }
        }, () => {
          
          this.execution = null;
          this.cancel();
        });
      }
    });
  }

  initializeAttachmentsComponent(): void {
   
    if (this.attachmentsComponent instanceof QueryList) {
			this.attachmentsComponent = this.attachmentsComponent.first as ExecutionQEditAttachmentsComponent; 
		}
		
		if (this.attachmentsComponent) {
			this.attachmentsComponent.setIdExecution(this.execution.id);
			this.attachmentsComponent.setIdStatus(this.execution.idStatus);
		}
  }

  

  ngOnInit(): void {
    
    this.clients = [];
    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);

    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });
    
  }

  cancel(): void {
    void this.router.navigateByUrl('/qualificates/executions');
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION, ExecutionStatus.PENDIENTE_FIRMA];

    return this.execution.id != null && allowedStatuses.includes(this.execution.idStatus);
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
        this.auditTab.getAudit();
    }
  }

  showAutomaticSign(): boolean {
     return false;
  }

  showManualSign(): boolean {
    return false;
  }

  showRevertSign(): boolean {
    return this.execution.idStatus === ExecutionStatus.FIRMADO;
  }

  saveExecution(): void {

    const errores: string[] = [];

    if (!this.execution?.idProtocol) {
      errores.push(this.translate.instant('executionEdit.protocol.error.empty') as string);
    }

    if (errores.length === 0) {
      if (this.execution.id == null) {
        this.save();
      } else {

        const showReason = (+this.execution.signedVersion) !== 0;

        if (showReason) {

          const dialogRef = this.dialog.open(ExecutionQEditConfirmSaveComponent, {
            minWidth: '30vw',
            data: {
              execution: this.execution,
              reason: this.execution.reason
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {
            if (result != null) {
              this.execution.reason = result.reason;
              this.save();
            }
          });

        } else {
          this.save();
        }

      }

    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  openNewRefDoc(): void {
    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      
      if (result != null) {

        if (!this.execution.referenceDocs) {
          this.execution.referenceDocs = [];
        }

        this.execution.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {

    const refDoc = this.execution.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.execution.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.execution.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }


  private save() {

    if (this.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA){
      const dialogRef = this.dialog.open(ReasonDialogComponent, {
        minWidth: '40%',
        maxHeight: '95vh',
        data: {}
      });
    
      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
        if (result != null) {
        this.execution.reason = result;
        this.saveAll();
        }
      });      
    } else {
      this.saveAll();
    }
  }

  private saveAll() {
    const isNew = this.execution.id == null;
        
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {

        this.spinnerService.show();
        this.executionService.save(this.execution, token).pipe(takeUntil(this.destroy$)).subscribe((res: ExecutionQ) => {
        
          this.execution = res;
          this.reloadPage(this.execution.id);

          if (isNew) {
            this.snackBarService.sendSuccess(this.translate.instant('executionEditQ.form.create.ok') as string);
            void this.router.navigateByUrl(`qualificates/execution?id=${res.id}`);
          } else {
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.update.ok') as string);
            void this.router.navigateByUrl(`qualificates/execution?id=${res.id}`);
          }
        
          this.spinnerService.hide();
        }, err => {
          if (err != null && err.error != null && typeof err.error === 'string') {
            this.snackBarService.sendError(err.error as string);
          } else if (isNew) {
            this.snackBarService.sendError(this.translate.instant('executionEditQ.form.create.error.generic') as string);
          } else {
            this.snackBarService.sendError(this.translate.instant('executionEditQ.form.update.error') as string);
          }

          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });
  }

 private reloadPage(idExecution) {
  
    if (idExecution == null) {
      idExecution = this.execution.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`qualificates/execution?id=${idExecution}`);
  }

  openDialogEquipment(): void {
    const dialogRef = this.dialog.open(ExecutionEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: new EquipmentQExecution(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentQExecution) => {
      if (result != null) {
        if (this.execution.equipments == null) {
          this.execution.equipments = [];
        }
        this.execution.equipments.push(result);
      }
    });
  }

  openDialogEditEquipment(idx: number): void {
    event.stopPropagation();

    const eq = this.execution.equipments[idx];

    const dialogRef = this.dialog.open(ExecutionEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentQExecution) => {
      if (result != null) {
        this.execution.equipments[idx] = result;
      }
    });
  }


  deleteEquipment( index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('executionEditQ.dialog.equipment.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.execution.equipments.splice(index, 1);
        }
      }
    });

  }
  createTestsSelected(test: TestQType): void {
    let isEdit = false;
    let isEditTest = false;
    let idStatus = this.execution.idStatus;
    // eslint-disable-next-line max-len
    
    const result = this.execution?.executionQTests?.find(e => e.idTestType === test?.id);
    if (result) {
      isEdit = true;
      if (result.executionQTestItem) {
        isEditTest = true;
      }
    }
    const dialogRef = this.dialog.open(ExecutionQEditTestComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        idStatus,
        idItem: test.id,
        testQType: test,
        isEdit,
        isEditTest,
        data: _.cloneDeep(result),
        protocolNumber: this.protocol?.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: ExecutionQTest) => {
     
      if (res) {
        
        this.execution?.executionQTests?.filter(e=> e.idTestType === test.id).forEach(d => {
          d.testDescription = res.testDescription;
          d.regUser = res.regUser;
          d.regDate = res.regDate;
          d.modUser = res.modUser;
          d.modDate = res.modDate;
          d.reason = res.reason;
          d.imageTest = res.imageTest;
          d.according = res.according;
          d.executionQTestItem = res.executionQTestItem;
          d.observation = res.observation;
          if (res.executionQTestProbeTraceability) {
            d.executionQTestProbeTraceability = res.executionQTestProbeTraceability;
          }
          if (res.executionQTestResults) {
            d.executionQTestResults = res.executionQTestResults;}

          if (res.executionQTestVerification) {
            d.executionQTestVerification = res.executionQTestVerification;
          }
          if (res.executionQTestBioindicadores) {
            d.executionQTestBioindicadores = res.executionQTestBioindicadores;
          }
          if (res.executionQTestGeneralData) {
            d.executionQTestGeneralData = res.executionQTestGeneralData;
          }
        })
      }
      // this.groupSelected = [...res];
    });
  }

  onUseExistingProtocolChange(event: MatRadioChange): void {
    this.useExistingProtocol = event.value as boolean;

    if (this.useExistingProtocol) {
      this.execution.idClient = null;
      this.execution.idProcess = null;
      this.execution.projectNo = null;
      this.execution.usernameProjectManager = null;
    } else {
      this.protocol = null;
      this.execution.idProtocol = null;
    }
  }

  lookupProtocol($event): void {
    if (this.prevProtocolAutocomplete) {
      this.prevProtocolAutocomplete.unsubscribe();
    }

    this.loadingAutocompleteSubject.next(true);

    const filter = new ProtocolQFilter();

    filter.autocomplete = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';

    this.prevProtocolAutocomplete = this.protocolService.findAllAssignable(filter).pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data && data.content) {
        this.protocols = data.content as ProtocolQ[];
      } else if (data) {
        this.protocols = data as ProtocolQ[];
      } else {
        this.protocols = [];
      }

      this.protocolsFiltered = this.protocols.slice();

      this.loadingAutocompleteSubject.next(false);
    });
  }

  displayProtocol(prot: ProtocolAssignable): string {
    if (!prot) {
      return '';
    }

    const documentCode = prot?.documentCode != null ? prot.documentCode : '';
    const client = prot?.nameClient != null ? prot.nameClient : '';

    return documentCode.concat(' ').concat(client);
  }

  openSearchProtocol(): void {
    event.stopPropagation();

    this.loadingAutocompleteSubject.next(true);

    const dialogRef = this.dialog.open(ProtocolQListComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        isList: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
      this.loadingAutocompleteSubject.next(false);
      if (result != null) {
        this.onProtocolChange(result);
      }
    });
  }

  onProtocolChange(event: MatAutocompleteSelectedEvent | number): void {
    this.spinnerService.show();

    let idProtocol: number = null;

    if (event instanceof MatAutocompleteSelectedEvent) {
      idProtocol = (event.option.value as ProtocolAssignable)?.id;
    } else {
      idProtocol = event;
    }

    if (idProtocol == null || isNaN(idProtocol)) {
      this.spinnerService.hide();

      return;
    }

    this.execution.idProtocol = idProtocol;

    this.protocolService.findOne(idProtocol).pipe(takeUntil(this.destroy$)).subscribe((prot: ProtocolQ) => {
      this.protocol = prot;

      this.protocolAutocompleteInput.nativeElement.value = prot.documentCode;

      //this.execution.executionQTests = this.convertTest(this.protocol.protocolQTest);

      this.spinnerService.hide();
    });
  }

  downloadProtocolPdf(): void {
    this.spinnerService.show();

    this.protocolService.downloadPdfVersion(this.execution.idProtocol, this.execution.originalProtocolVersion.toString())
      .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = this.protocol.documentCode + ' v.' + this.execution.originalProtocolVersion.toString().padStart(2, '0');

        saveAs(res, this.translate.instant('executionEditQ.files.report', { name }) as string);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEditQ.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
  }

  canAdd(): boolean {
    return this.execution.id != null;
  }

  enableEditTest(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.execution.idStatus)
  }

  private clearProtocolFilter() {
    this.protocolFilter = new ProtocolQFilter();

    this.protocolFilter.pageSize = 10000;
  }

  loadClientsShort(res: ClientShort[]): void {
    this.clientsShort = res;
    this.clientsShortFiltered = this.clientsShort.slice();
  }

  isCompletedTest(test: TestQType): boolean {
    let res = [];
    const testResult = this.execution.executionQTests.find(e => e.idTestType === test.id);
 
    if (testResult) {

      for (let index = 0; index < testResult.executionQTestItem.length; index++) {
        const element = testResult.executionQTestItem[index];

        for (let indextiv= 0; indextiv < element.executionQTestItemVerification.length; indextiv++) {
          const elementtiv = element.executionQTestItemVerification[indextiv];

         for (let indextdm= 0; indextdm < elementtiv.executionQTestDataMultiple.length; indextdm++) {
          const element = elementtiv.executionQTestDataMultiple[indextdm];
           if (!element.obtainedResult) {
            res.push(true);
           } else {
            res.push(false);
           }
         }
        }
      }

      for (let index = 0; index < testResult.executionQTestProbeTraceability?.length; index++) {
        const elementProbeT = testResult.executionQTestProbeTraceability[index];

        if (!elementProbeT.identifier) {
          res.push(true);
        } else {
          res.push(false);
        }
      }
     return !res.find(e => e === true);
    } else {
      return false;
    }
  }

  showExecute(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.EN_EJECUCION];

    return validStatuses.includes(this.execution.idStatus);
  }

  execute(): void {
    
  }

  showDeviationSelected(idTestExecution: number) {
    this.spinnerService.show();
    this.executionQTestsService.getDeviations(idTestExecution).subscribe(resSelected => {
        
        if (resSelected.deviations != null) {
            const dialogRef = this.dialog.open(ExecutionEditQDeviationListComponent, {
                minWidth: '70%',
                maxHeight: '95vh',
                data: {
                    deviations: _.cloneDeep(resSelected.deviations)
                }
                
            });
            dialogRef.afterOpened().subscribe(() => {
              this.spinnerService.hide(); 
          });
           /*SI SE HABILITA LA ACCIÓN DE EDITAR DESDE EL LISTADO, LOS CAMBIOS LLEGAN HASTA AQUI FALTARIA GUARDARLOS
           
           dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQTestDeviations) => {
              if (result) {
               
               
              }
            })*/
        }
    }, error => {
        console.error('Error al obtener los datos:', error);
    });
  }

  
}


