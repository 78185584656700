import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';

@Injectable({
  providedIn: 'root'
})
export class AttachmentQualificatesService {

  constructor(private http: HttpClient
  ) { }

  uploadAttachmentToTest(idProtocol: number, idTest: number, file: File): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolQTestAttachment/protocol/${idProtocol}/test/${idTest}`;
    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  uploadAttachmentToProtocol(idProtocol: number, file: File): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolAttachment/protocol/${idProtocol}`;
    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  uploadAttachmentToExecution(idExecution: number, file: File): Observable<any> {
    const url = environment.qualificatesUrl + `/executionAttachment/execution/${idExecution}`;
    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
}

  uploadAttachmentToExecutionTest(idExecution: number, idTest: number, file: File): Observable<any> {
    const url = environment.qualificatesUrl + `/executionQTestAttachment/execution/${idExecution}/test/${idTest}`;
    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  uploadAttachmentToDeviation(idDeviation: number, file: File): Observable<any> {
    const url = environment.qualificatesUrl + `/deviation/${idDeviation}`;
    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  getAllTypesForProtocolAir(): Observable<any> {
    const url = environment.coreUrl + '/attachment/types/protocol';

    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  getAllTypesForExecution(): Observable<any> {
    const url = environment.coreUrl + '/attachment/types/execution';

    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  updateAttachmentToTest(id: number, idProtocol: number, idTest: number, idAttachment: number, idType: number, name: string): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolQTestAttachment/protocol/test/attachment/${idAttachment}`;

    const body = {
        id,
        idProtocol,
        idTest,
        idAttachment, idType, name
    };

    return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  updateAttachmentToProtocol(idProtocol: number, idAttachment: number, idType: number, name: string): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolAttachment/attachment/${idAttachment}`;

      const body = {
        idProtocol: idProtocol,  
        idAttachment: idAttachment,
        idType: idType,
        name: name
    };
    
    return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  updateAttachmentToExecution(idExecution: number, idAttachment: number, idType: number, name: string): Observable<any> {
    const url = environment.qualificatesUrl + `/executionAttachment/attachment/${idAttachment}`;

    const body = {
      idExecution: idExecution,
      idAttachment: idAttachment,
      idType: idType,
      name: name
  };

    return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  updateAttachmentToExecutionTest(id: number, idExecution: number, idTest: number, idAttachment: number, idType: number, name: string): Observable<any> {
    const url = environment.qualificatesUrl + `/executionQTestAttachment/execution/test/attachment/${idAttachment}`;

    const body = {
        id,
        idExecution,
        idTest,
        idAttachment, idType, name
    };

    return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  downloadAttachmentToTest(idTest: number, idAt: number): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolQTestAttachment/protocol/test/${idTest}/attachment/${idAt}`;

    return this.http.get(url, { responseType: 'blob' });
  }

  downloadAttachmentToProtocol(idProtocol: number, idAt: number): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolAttachment/protocol/${idProtocol}/attachment/${idAt}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadAttachmentToExecution(idExecution: number, idAt: number): Observable<any> {
    const url = environment.qualificatesUrl + `/executionAttachment/execution/${idExecution}/attachment/${idAt}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadAttachmentToExecutionTest(idTest: number, idAt: number): Observable<any> {
    const url = environment.qualificatesUrl + `/executionQTestAttachment/execution/test/${idTest}/attachment/${idAt}`;

    return this.http.get(url, { responseType: 'blob' });
  }

  downloadAttachmentToDeviation(idAt: number): Observable<any> {
    const url = `${environment.qualificatesUrl}/deviation/download/${idAt}`;
    return this.http.get(url, { responseType: 'blob' });
}

  deleteAttachmentToTest(idTest: number, idAttachment: number): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolQTestAttachment/protocol/test/${idTest}/attachment/${idAttachment}`;

    return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  deleteAttachmentToProtocol(idProtocol: number, idAttachment: number): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolAttachment/protocol/${idProtocol}/attachment/${idAttachment}`;

    return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  deleteAttachmentToExecution(idExecution: number, idAttachment: number): Observable<any> {
    const url = environment.qualificatesUrl + `/executionAttachment/execution/${idExecution}/attachment/${idAttachment}`;

    return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  deleteAttachmentToDeviation(idAttachment: number, idDeviation: number): Observable<any> {
    const url = environment.qualificatesUrl + `/deviation/delete/${idDeviation}/attachment/${idAttachment}`;

    return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  deleteAttachmentToExecutionTest(idTest: number, idAttachment: number): Observable<any> {
    const url = environment.qualificatesUrl + `/executionQTestAttachment/execution/test/${idTest}/attachment/${idAttachment}`;

    return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  getImageToVerification(idVerification: number, id: number): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolQTestAttachment/protocol/itemVerification/${idVerification}/verification/${id}`;
    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  uploadImageToVerification(idVerification: number, id: number, file: File, width: number, height: number): Observable<any> {
    const url = environment.qualificatesUrl + `/protocolQTestAttachment/protocol/itemVerification/${idVerification}/verification/${id}/${width}/${height}`;
    const form = new FormData();
    form.append('file', file, file.name);

    return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  


}
