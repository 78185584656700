export class CalibrationAmbientConditions {
    id: number;
    idVariableCalibration: number;
    temperature: string;
    pressureUsed: string;
    oilUsed: string;
    idExcel: number;
    idParticlesExcel: number;
    serialNumber: string;
}

export class CalibrationAmbientResultParticles {
    id: number;
    model: string;
    date: Date;
    location: string;
    samplingTime: number;
    volume: number;
    ambientConditionId: number;
    zeroOne: number;
    zeroTwo: number;
    zeroThree: number;
    zeroFive: number;
    oneZero: number;
    fiveZero: number;

    alarm: string;
    flow: string;
    laser: string;
    service: string;

    unit: string;

}

export class CalibrationResultExcelParticles {
    particles: CalibrationAmbientResultParticles[] = [];
    serialNum: string;
}