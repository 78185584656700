<div class="main-container">
  <div class="toolbar-list-title">
    <div fxLayout="row" fxLayoutAlign="start center">
      <h1>{{ 'calibrationPlan.title' | translate }}</h1>
    </div>
    <div class="breadcrumbs">
      <a href="#">{{ 'common.start.label' | translate }}</a> /
      <span>{{ 'calibrationPlan.title' | translate }}</span>
    </div>
  </div>

  <mat-card appearance="outlined" class="card">
    <mat-card-header>
      <mat-card-title>
        <mat-toolbar color="secondary" style="width: 95vw;">
          {{ 'folderInternalEquipment.title' | translate }}
          <button mat-icon-button color="accent" (click)="newFolder()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <table #usersTable mat-table [dataSource]="folderDatasource" style="width: 100%;">

        <!-- Name Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.folderInternalEquipment.cols.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{item.name}} </td>
        </ng-container>

        <!-- Father Column -->
        <ng-container matColumnDef="father">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.folderInternalEquipment.cols.father' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ getFatherName(item) }} </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.folderInternalEquipment.cols.edit' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="editFolder(item)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.customizedFields.cols.delete' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">

            <button mat-icon-button (click)="deleteFolder(item)" matTooltip="{{ 'button.delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColsFolders"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColsFolders;"></tr>

      </table>

      <div fxLayout="row" class="flexwrap">
        <div fxFlex>
          <mat-paginator #usersPaginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons lang="es">
          </mat-paginator>
        </div>
        <div fxFlex="45px">

        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="card">
    <mat-card-header>
      <mat-card-title>
        <mat-toolbar color="secondary" style="width: 95vw;">
          {{ 'customizedFields.title' | translate }}
          <button mat-icon-button color="accent" (click)="newField()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <table #usersTable mat-table [dataSource]="fieldsDatasource" style="width: 100%;">

        <!-- Name Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.customizedFields.cols.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.customizedFields.cols.type' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ getTypeName(item) }} </td>
        </ng-container>

        <!-- Filtrable Column -->
        <ng-container matColumnDef="filtrable">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'calibrationPlan.customizedFields.cols.filtrable' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ (item.filtrable ? 'common.yes' : 'common.no') | translate }} </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.customizedFields.cols.edit' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">

            <button mat-icon-button (click)="editField(item)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrationPlan.customizedFields.cols.delete' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">

            <button mat-icon-button (click)="deleteField(item)" matTooltip="{{ 'button.delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColsFields"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColsFields;"></tr>

      </table>

      <div fxLayout="row" class="flexwrap">
        <div fxFlex>
          <mat-paginator #usersPaginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons lang="es">
          </mat-paginator>
        </div>
        <div fxFlex="45px">

        </div>
      </div>
      <div fxLayout="row" class="flexwrap">
        <button mat-raised-button color="primary" (click)="downloadExcelTemplate()">
          {{ 'button.download-template' | translate }}</button>
      </div>
    </mat-card-content>
  </mat-card>

</div>