<h1 mat-dialog-title>{{ 'protocolEdit.dialog.essay.criteria.title' | translate }}</h1>

<div mat-dialog-content class="qualificates-theme">

  <div fxLayout="row" class="flexwrap">
    <div fxFlex="30em">
      <mat-form-field class="campoFormulario">
        <mat-label>{{ 'protocolEdit.dialog.essay.criteria.label' | translate }}</mat-label>

        <mat-select required [(ngModel)]="data.criteria.idType" [disabled]="data.disabled"
          (selectionChange)="onCriteriaChange($event)">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let item of criterias" [value]="item.id">
            {{ 'protocolEdit.dialog.essay.criteria.' + item.translation + '.label' | translate }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'protocolEdit.dialog.essay.criteria.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="criteriaSelected != null && criteriaSelected.variablesToShow >= 1">
      <mat-form-field class="campoFormulario">
        <mat-label>{{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.label' | translate }}</mat-label>
        <input matInput [name]="criteriaValue1" [(ngModel)]="data.criteria.criteriaValue1" required
          [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled">
        <mat-error>
          {{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="criteriaSelected != null && criteriaSelected.variablesToShow >= 2">
      <mat-form-field class="campoFormulario">
        <mat-label>{{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.label' | translate }}</mat-label>
        <input matInput [name]="criteriaValue2" [(ngModel)]="data.criteria.criteriaValue2" required
          [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled">
        <mat-error>
          {{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="data.showMkt">
    <mat-checkbox name="showMkt" [(ngModel)]="data.criteria.showMkt">
      {{'protocolEdit.dialog.essay.criteria.showMkt.label' | translate }}
    </mat-checkbox>
  </div>

</div>

<div mat-dialog-actions align="end" class="thermal-theme">
  <button mat-raised-button color="primary" (click)="onOkClick()" *ngIf="!data.disabled">
    {{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>