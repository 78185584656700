import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup/signup.component';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FlexLayoutModule } from 'ng-flex-layout';
import { RouterModule } from '@angular/router';
import { PaymentComponent } from './payment/payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { PaymentCompleteComponent } from './payment-complete/payment-complete.component';

@NgModule({
    declarations: [SignupComponent, PaymentComponent, PaymentCompleteComponent],
    imports: [
        CommonModule,
        FormsModule,
        /* Stripe */
        NgxStripeModule.forRoot(environment.stripeKey),
        /* Custom modules */
        AngularMaterialModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule
    ]
})
export class PaymentsModule { }
