import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppTypeEnum } from 'src/app/model/appType';
import { Area } from 'src/app/model/area';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { CompressedGas } from 'src/app/model/compressedGasesAir';
import { EquipmentAir } from 'src/app/model/equipment';
import { ExecutionAir, ExecutionStatus } from 'src/app/model/execution';
import { ProtocolAir } from 'src/app/model/protocol';
import { RoomAir } from 'src/app/model/roomAir';
import { User } from 'src/app/model/user';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { UsersService } from 'src/app/services/users.service';
import { AirUtils } from 'src/app/utils/airUtils';
import { ExecutionAirUtils } from 'src/app/utils/executionAirUtils';
import { FileUtils } from 'src/app/utils/fileUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import { UserService } from '../../../services/user.service';

export interface DialogDataGenerateReport {
  execution: ExecutionAir;
  protocol: ProtocolAir;
  reason: string;
  toSign: boolean;
  config: ExecutionReportFilter;
  isForAutomaticSign: boolean;
}

@Component({
  selector: 'app-execution-edit-generate-report',
  templateUrl: './execution-edit-generate-report.component.html'
})
export class ExecutionEditGenerateReportComponent implements OnInit, OnDestroy {

  respDocs: User[];

  reportConfig: ExecutionReportFilter;

  langs: LangFlag[];

  changeConclusions: boolean;
  changeDeviations: boolean;
  changeObservations: boolean;

  selectedEquipments: number[] = [];
  selectedRooms: number[] = [];
  selectedCompressedGases: number[] = [];

  private destroy$ = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExecutionEditGenerateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataGenerateReport,
    private executionService: ExecutionAirService,
    private usersService: UsersService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private userService: UserService) {
  }

  ngOnInit(): void {
    if (this.data.config == null) {
      this.reportConfig = new ExecutionReportFilter();
      this.reportConfig.lang = this.translationService.currentLang;
    } else {
      this.reportConfig = this.data.config;
    }

    this.data.execution.areas.forEach(area => {
      area.equipments.forEach(eq => {
        const precheck = eq.essays.some(e => e.active)
          && eq.essays.filter(e => e.active).every(e => AirUtils.checkAccordingEssay(e, null, eq.filters, eq.idType, false) != null);
        this.onEquipmentSelectChange(eq, precheck);
      });
      area.rooms.forEach(room => {
        const volume = ExecutionAirUtils.getTotalVolume(room);
        const precheck = room.essays.some(e => e.active)
          && room.essays.filter(e => e.active).every(e => AirUtils.checkAccordingEssay(e, volume, room.filters, 0, false) != null);
        this.onRoomSelectChange(room, precheck);
      });
      area.compressedGases.forEach(gas => {
        const precheck = gas.essays.some(e => e.active)
          && gas.essays.filter(e => e.active).every(e => AirUtils.checkAccordingEssay(e, null, [], 0, true) != null);
        this.onCompressedGasSelectChange(gas, precheck);
      });
    });

    this.reportConfig.conclusions = this.data.execution.conclusions;
    this.changeConclusions = StringUtils.isNotEmpty(this.data.execution.conclusions);
    this.changeDeviations = StringUtils.isNotEmpty(this.data.execution.customDeviations);
    this.changeObservations = StringUtils.isNotEmpty(this.data.execution.customObservations);
    if (!this.changeDeviations) {
      this.reloadDeviations();
    } else {
      this.reportConfig.deviations = this.data.execution.customDeviations;
    }

    if (!this.changeObservations) {
      this.reloadObservations();
    } else {
      this.reportConfig.observations = this.data.execution.customObservations;
    }
    this.langs = this.translationService.configuredLangs;

    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((item: User[]) => this.respDocs = item);

    if (this.data.execution.idStatus === ExecutionStatus.FIRMADO) {
      this.spinnerService.show();

      this.executionService.downloadPdfExecutionVersion(this.data.execution).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionAirUtils.getNameForReportPdf(this.data.execution, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();

        this.dialogRef.close();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  showEquipment(eq: EquipmentAir): string {
    return AirUtils.showEquipment(eq);
  }

  isAreaSelected(area: Area): boolean {
    return area.equipments.some(e => this.selectedEquipments.includes(e.id)) || area.rooms.some(r => this.selectedRooms.includes(r.id))
      || area.compressedGases.some(r => this.selectedCompressedGases.includes(r.id));
  }

  onAreaSelectChange(area: Area, event: MatCheckboxChange | boolean): void {
    if ((event instanceof MatCheckboxChange && event.checked) || event === true) {
      this.selectedEquipments.push(...area.equipments.map(e => e.id));
      this.selectedRooms.push(...area.rooms.map(r => r.id));
      this.selectedCompressedGases.push(...area.compressedGases.map(r => r.id));
    } else {
      this.selectedEquipments = this.selectedEquipments.filter(el => !area.equipments.map(e => e.id).includes(el));
      this.selectedRooms = this.selectedRooms.filter(el => !area.rooms.map(r => r.id).includes(el));
      this.selectedCompressedGases = this.selectedCompressedGases.filter(el => !area.rooms.map(r => r.id).includes(el));
    }
  }

  isEquipmentSelected(eq: EquipmentAir): boolean {
    return this.selectedEquipments.includes(eq.id);
  }

  onEquipmentSelectChange(eq: EquipmentAir, event: MatCheckboxChange | boolean): void {
    if ((event instanceof MatCheckboxChange && event.checked) || event === true) {
      this.selectedEquipments.push(eq.id);
    } else {
      this.selectedEquipments = this.selectedEquipments.filter(el => eq.id !== el);
    }
  }

  isRoomSelected(room: RoomAir): boolean {
    return this.selectedRooms.includes(room.id);
  }

  onRoomSelectChange(room: RoomAir, event: MatCheckboxChange | boolean): void {
    if ((event instanceof MatCheckboxChange && event.checked) || event === true) {
      this.selectedRooms.push(room.id);
    } else {
      this.selectedRooms = this.selectedRooms.filter(el => room.id !== el);
    }
  }

  isCompressedGasSelected(room: RoomAir): boolean {
    return this.selectedCompressedGases.includes(room.id);
  }

  onCompressedGasSelectChange(gas: CompressedGas, event: MatCheckboxChange | boolean): void {
    if ((event instanceof MatCheckboxChange && event.checked) || event === true) {
      this.selectedCompressedGases.push(gas.id);
    } else {
      this.selectedCompressedGases = this.selectedCompressedGases.filter(el => gas.id !== el);
    }
  }

  onChangeConclusionsCheck(event: MatCheckboxChange): void {
    this.changeConclusions = event.checked;

    if (event.checked && StringUtils.isEmpty(this.reportConfig.conclusions)) {
      this.reloadConclusions();
    }
  }

  reloadConclusions(): void {
    this.spinnerService.show();

    this.executionService.getConclusions(this.data.execution.id, this.reportConfig.lang).pipe(takeUntil(this.destroy$)).subscribe(con => {
      this.reportConfig.conclusions = con.conclusions as string;
      this.spinnerService.hide();
    });
  }

  onChangeDeviationsCheck(event: MatCheckboxChange): void {
    this.changeDeviations = event.checked;

    if (event.checked && StringUtils.isEmpty(this.reportConfig.deviations)) {
      this.reloadDeviations();
    }
  }

  reloadDeviations(): void {
    this.spinnerService.show();

    this.executionService.getDeviations(this.data.execution.id, this.reportConfig.lang).pipe(takeUntil(this.destroy$)).subscribe(con => {
      this.reportConfig.deviations = con.conclusions as string;
      this.spinnerService.hide();
    });
  }

  onChangeObservationsCheck(event: MatCheckboxChange): void {
    this.changeObservations = event.checked;

    if (event.checked && StringUtils.isEmpty(this.reportConfig.observations)) {
      this.reloadObservations();
    }
  }

  reloadObservations(): void {
    this.spinnerService.show();

    this.executionService.getObservations(this.data.execution.id, this.reportConfig.lang).pipe(takeUntil(this.destroy$)).subscribe(con => {
      this.reportConfig.observations = con.conclusions as string;
      this.spinnerService.hide();
    });
  }

  onOkClick(hybride: boolean): void {
    this.spinnerService.show();
    this.reportConfig.hybride = hybride;
    this.reportConfig.toSign = this.data.toSign;
    this.reportConfig.idsEquipment = this.selectedEquipments;
    this.reportConfig.idsRooms = this.selectedRooms;
    this.reportConfig.idsCompressedGases = this.selectedCompressedGases;

    if (!this.changeConclusions) {
      this.reportConfig.conclusions = null;
    }

    if (!this.changeDeviations) {
      this.reportConfig.deviations = null;
    }

    if (!this.changeObservations) {
      this.reportConfig.observations = null;
    }

    this.executionService.checkForDownload(this.data.execution.id).pipe(takeUntil(this.destroy$)).subscribe((errors: string[]) => {

      if (errors != null && errors.length > 0) {
        this.spinnerService.hide();

        this.snackBarService.sendError(errors.join('\n'));

        return;
      }

      if (this.data.reason) {
        this.reportConfig.reason = this.data.reason;
      }

      if (this.data.isForAutomaticSign) {
        this.dialogRef.close(this.reportConfig);
      } else {
        this.defaultActionReport();
      }
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
      }

      this.spinnerService.hide();
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  defaultActionReport(): void {
    this.executionService.downloadPdf(this.data.execution.id, this.reportConfig).pipe(takeUntil(this.destroy$)).subscribe(err => {

      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else if (err.errors) {
        const error: string = err.errors.join('\n');
        this.snackBarService.sendError(error);
      } else if (err.file) {
        const name = ExecutionAirUtils.getNameForReportPdf(this.data.execution, this.translate);
        saveAs(FileUtils.base64toBlob(err.file.contentString, err.file.contentType as string), name);
      }

      this.spinnerService.hide();

      this.dialogRef.close(this.reportConfig);
    }, err => {
      console.error(err);

      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
      }

      this.spinnerService.hide();
    });
  }

  showThermal(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.THERMAL;
  }

}
