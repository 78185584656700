<form>
  <div mat-dialog-content class="thermal-theme">

    <div fxLayout="row" class="flexwrap" *ngIf="data.criticalInstr.fromProtocol">

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.criticalInstr.equipment.label' | translate }} {{ 'common.inProtocol' | translate }}</mat-label>
          <input matInput name="equipmentProtocol" [(ngModel)]="data.criticalInstr.equipmentProtocol" disabled>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.criticalInstr.procediment.label' | translate }} {{ 'common.inProtocol' | translate }}</mat-label>
          <input matInput name="procedimentProtocol" [(ngModel)]="data.criticalInstr.procedimentProtocol" disabled>
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.criticalInstr.equipment.label' | translate }}</mat-label>
          <input matInput name="equipment" [(ngModel)]="data.criticalInstr.equipment" required>
          <mat-error>
            {{ 'protocolEdit.dialog.criticalInstr.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.criticalInstr.procediment.label' | translate }}</mat-label>
          <input matInput name="procediment" [(ngModel)]="data.criticalInstr.procediment" required>
          <mat-error>
            {{ 'protocolEdit.dialog.criticalInstr.procediment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="100" *ngIf="requiredReason()">
        <mat-label>{{ 'common.reasonChange' | translate }}</mat-label>
        <textarea rows="5" matAutosizeMinRows="5" matInput
          [(ngModel)]="data.criticalInstr.reasonChange" name="reasonChange" required style="resize: none;"></textarea>
      </div>
    </div>

    <div fxLayout="column" class="flexwrap" *ngIf="data.criticalInstr.fromProtocol">
      <div fxFlex>
        <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.criticalInstr.showDiffInDeviations">
          {{ 'protocolEdit.dialog.criticalInstr.showDiffInDeviations.label' | translate }}
        </mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.criticalInstr.showDiffInObservations">
          {{ 'protocolEdit.dialog.criticalInstr.showDiffInObservations.label' | translate }}
        </mat-checkbox>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>