import { InstrumentationEnum } from '../model/instrumentationUse';
import { Protocol, ProtocolAir, ProtocolQ, ProtocolQTest, ProtocolQTestEssay, ProtocolQTestEssayGroup } from '../model/protocol';
import { TestQType, TestTypeEnum } from '../model/testQType';
import { VariableTypeEnum, VariableUnitEnum } from '../model/variable';

import { ArrayUtils } from './arrayUtils';
import { QualificationUtils } from './qualificationUtils';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { FieldEnum } from './fieldUtils';
import { EssayTypeEnum, EssayUtils } from './essayUtils';
import { TranslationService } from '../services/translation.service';
import { EquipmentTypeQualificationEnum } from '../model/equipmentType';
import { Field } from '../model/field';

export class ProtocolUtils {

    public static BREAK: string = '<p><br></p>';

    public static getNameForProtocol(protocol: Protocol, translate: TranslateService): string {
        const documentCode = protocol.documentCode;
        const versionName = 'v.' + protocol.currentVersion.toString().padStart(2, '0');
        const clientName = protocol.nameClient;
        let equipment = null;
        let serialNum = null;
        let internalId = null;

        if (!ArrayUtils.isEmpty(protocol.equipments)) {
            const eq = protocol.equipments[0];

            equipment = eq.equipment;
            if (StringUtils.isNotEmpty(eq.serialNum)) {
                serialNum = '(' + eq.serialNum + ')';
            }

            if (StringUtils.isNotEmpty(eq.internalId)) {
                internalId = '(' + eq.internalId + ')';
            }
        }

        const name = [documentCode, versionName, translate.instant('protocolEdit.files.protocol.documentType'), clientName, equipment,
            serialNum, internalId].filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        const res: string = translate.instant('protocolEdit.files.protocol', { name }) as string;

        return res;
    }

    public static getNameForProtocolAir(protocol: ProtocolAir, translate: TranslateService): string {
        const documentCode = protocol.documentCode;
        const versionName = 'v.' + protocol.currentVersion.toString().padStart(2, '0');
        const clientName = protocol.nameClient;

        let areas = null;

        if (!ArrayUtils.isEmpty(protocol?.areas)) {
            areas = protocol.areas.map(a => a.name).join(', ');
        }
        const name = [documentCode, versionName, translate.instant('protocolEdit.files.protocol.documentType'), clientName, areas]
            .filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        const res: string = translate.instant('protocolEdit.files.protocol', { name }) as string;

        return res;
    }

    public static getNameTestQualificates(protTest: ProtocolQTest,  translate: TranslateService): string {
        let resVariablesTempHumCo = ''
        const resVariables = this.getVariablesFromEssayQualificationTempHumCo(protTest, translate);

        let essay = '';
        if (protTest.protocolQTestEssayGroup?.length === 1) {
            essay = translate.instant('test.essay.singular');
        } else {
            essay = translate.instant('test.essay.plural');
        }

        let openDoorCutElectric = '';
        let isOpenDoor = false;
        let isCutElectric = false; 
        for (let index = 0; index < protTest.protocolQTestEssayGroup?.length; index++) {
            const group = protTest.protocolQTestEssayGroup[index];
            if (!isOpenDoor) {
                isOpenDoor = this.existsOpenDoor(group);
            }
            if (!isCutElectric) {
                isCutElectric = this.existsCutElectric(group);
            }
        }

        if (isOpenDoor && isCutElectric) {
            openDoorCutElectric = `, ${translate.instant('testQualification.openDoor')} ${translate.instant('test.essay.y')} ${translate.instant('testQualification.cutElectric')}`;
        } else if (isOpenDoor && !isCutElectric) {
            openDoorCutElectric = ` ${translate.instant('test.essay.y')} ${translate.instant('testQualification.openDoor')}`;
        } else if (!isOpenDoor && isCutElectric) {
            openDoorCutElectric = ` ${translate.instant('test.essay.y')} ${translate.instant('testQualification.cutElectric')}`;
        } else {
            openDoorCutElectric = '';
        }

        if (protTest.idTestType === TestTypeEnum.TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS) {
            resVariablesTempHumCo = translate.instant('testQualification.tests_temperature_distribution_load', {essay: essay, variableHumCo: resVariables, openDoorCutElectric: openDoorCutElectric});
        } else if (protTest.idTestType === TestTypeEnum.RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM) {
            resVariablesTempHumCo = translate.instant('testQualification.tests_temperature_distribution_vacuum', {essay: essay, variableHumCo: resVariables, openDoorCutElectric: openDoorCutElectric});
        } else if (protTest.idTestType === TestTypeEnum.TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE) {
            resVariablesTempHumCo = translate.instant('testQualification.tests_temperature_loadPenetration', {essay: essay, variableHumCo: resVariables, openDoorCutElectric: openDoorCutElectric});

        }
        return resVariablesTempHumCo;
    }

    public static getNameForProtocolQualificates(protocol: ProtocolQ, translate: TranslateService): string {
        const documentCode = protocol.documentCode;
        const versionName = 'v.' + protocol.currentVersion.toString().padStart(2, '0');
        const clientName = protocol.nameClient;
        let equipment = null;
        let serialNum = null;
        let internalId = null;

        if (!ArrayUtils.isEmpty(protocol.equipments)) {
            const eq = protocol.equipments[0];

            equipment = eq.equipment;
            if (StringUtils.isNotEmpty(eq.serialNumber)) {
                serialNum = '(' + eq.serialNumber + ')';
            }

            if (StringUtils.isNotEmpty(eq.internalId)) {
                internalId = '(' + eq.internalId + ')';
            }
        }

        const name = [documentCode, versionName, translate.instant('protocolEdit.files.protocol.documentType'), clientName, equipment,
            serialNum, internalId].filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        const res: string = translate.instant('protocolEdit.files.protocol', { name }) as string;

        return res;
    }

    public static getObjectiveTestQualificates(protocol: ProtocolQ, protTest: ProtocolQTest,  translate: TranslateService, objetive: string): string {
        let res = '';
        let resVariables;
        const equipmentDela = this.getEquipmentType(protocol, translate, true);
        const equipmentLa = this.getEquipmentType(protocol, translate, false);

        if (protTest != null && protTest.protocolQTestEssayGroup) {
            resVariables = this.getVariablesFromEssayQualificationJoin(protTest, translate, false);
            for (let index = 0; index < protTest.protocolQTestEssayGroup.length; index++) {
                const group = protTest.protocolQTestEssayGroup[index];
            }

            res = `<span>${translate.instant('test.vacuumDistribution.objetive.firstSubtitle.text', {equipment: equipmentDela, variable: resVariables, client: protocol.nameClient})}</span>`;
            res += this.BREAK;
            res += `<span>${translate.instant('test.vacuumDistribution.objetive.firstSubtitle.verification', {equipment: equipmentDela, variable: resVariables})}</span>`;
            if (this.isCentrifuges(protocol)) {
                res += `<span>${translate.instant('test.vacuumDistribution.objetive.firstSubtitle.centrifuges', {client: protocol.nameClient})}</span>`;
                res += this.BREAK;
            }
        } else {
            res = this.remplaceVariableInText(protocol, objetive, translate);
        }

        return res;
    }

    public static getMethodologyTestQualificates(protocol: ProtocolQ, protTest: ProtocolQTest, translate: TranslateService, methodology: string): string {
        let res = '';
        let isProbePressure = false;
        let isProbeCo2 = false;
        let probeNumberTempHum = 0;
        let probeNumberCo2 = 0;
        let numberProbePressure = 0;
        let resDifferentProbes = '';
        let resDifferentFrequency = '';
        let resDifferentCycle = '';
        let cycleText = '';
        let essayText = '';
        let frequencyOpen = '';
        let frequencyCut = ''
        let numberEssay = 0;
        let frequency = this.getFrecuency(protTest, translate);

        let differencySensor = this.differenceInSensory(protTest);
        let differencyFrequency = this.differenceInFrequency(protTest);
        let differencyCycle = this.differenceInCycle(protTest);

        let equipmentWithDelDeLa = this.getEquipmentType(protocol, translate, true);
        let equipmentWithElLa = this.getEquipmentType(protocol, translate, false);
        let isOpenDoor = false;
        let isCutElectric = false;
        let isOuterProbe = false;
        let isLethality = false;
        let variableSensor = '';
        let resVariableTempHum  = this.getVariablesFromEssayQualificationJoin(protTest, translate, true);
        let resVariables = this.getVariablesFromEssayQualificationJoin(protTest, translate, false);
        if (protTest != null && protTest.protocolQTestEssayGroup) {
            let totalCycle = this.getNumCycleByGroup(protTest.protocolQTestEssayGroup, translate);
            let totalEssay =  this.getTotalEssay(protTest.protocolQTestEssayGroup);
            let totalEssayText = this.getTotalEssayText(protTest.protocolQTestEssayGroup, translate);
            isLethality = this.getLethalityAccumulated(protTest.protocolQTestEssayGroup);
            for (let index = 0; index < protTest.protocolQTestEssayGroup.length; index++) {
                const group = protTest.protocolQTestEssayGroup[index];
                probeNumberTempHum = this.getNumberSensorTemperatureHumd(group);
                probeNumberCo2 = this.getNumberSensorCo2(group);
                numberProbePressure = this.getNumberSensorPressure(group);
                numberEssay = this.getNumberSensor(group);
                essayText = this.getEssayType(group, translate);
                if (!isProbePressure) {
                    isProbePressure = this.existsPressureProbe(group);
                }
                if (!isProbeCo2) {
                    isProbeCo2 = this.existsPressureCo2(group);
                }
                if (!isOpenDoor) {
                    isOpenDoor = this.existsOpenDoor(group);
                }
                if (!isCutElectric) {
                    isCutElectric = this.existsCutElectric(group);
                }

                if (group.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA) {
                    frequencyOpen = this.frequencyEssay(group, translate);
                }

                if (group.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO) {
                    frequencyCut = this.frequencyEssay(group, translate);
                }

                if (!isOuterProbe) {
                    isOuterProbe = this.existsOutsideProbe(group);
                    variableSensor = this.getVariableOutsideProbe(group, translate);
                }
            }

            res += `<span><strong><u><i>${translate.instant('test.loadDistribution.methodology.firstSubtitle.title')}</i></u></strong></span>`;
            res += this.BREAK;

            let firstSubtitlePressure = '';
            let firstSubtitleCo2 = '';

            if (isProbePressure) {
                firstSubtitlePressure += `${translate.instant('test.loadDistribution.methodology.firstSubtitle.pressureProbe.text')}`;
            }
            if (isProbeCo2) {
                firstSubtitleCo2 += `${translate.instant('test.loadDistribution.methodology.firstSubtitle.co2Probe.text')}`;
            }
            res += `<span>${translate.instant('test.loadDistribution.methodology.firstSubtitle.text', {variableTempHum: resVariableTempHum, textPressure: firstSubtitlePressure, textCo2: firstSubtitleCo2})}</span>`;
            
            res += this.BREAK;

            if (this.existsInstRegisterData(protTest) || this.existsInstValidator(protTest)) {
                res += `<ul><li><span><strong><u>${translate.instant('test.loadDistribution.methodology.firstSubtitle.list.tempProbe.title')}</u></strong></span></li></ul><br/><span>${translate.instant('test.vacuumDistribution.methodology.firstSubtitle.list.tempProbe.description')}</span>${this.BREAK}`;
                res += `<ul><li><span><strong><u>${translate.instant('test.loadDistribution.methodology.firstSubtitle.list.tempPar.title')}</u></strong></span></li></ul><br/><span>${translate.instant('test.vacuumDistribution.methodology.firstSubtitle.list.tempPar.description')}</span>${this.BREAK}`;
            }

            if (this.existsInstDataLogger(protTest)) {
                // sonda de presion y sonda de co2 y tipo data logger
                res += `<ul><li><span><strong><u>${translate.instant('test.loadDistribution.methodology.firstSubtitle.list.dataloogger.title')}</u></strong></span></li></ul><br/><span>${translate.instant('test.vacuumDistribution.methodology.firstSubtitle.list.dataloogger.description')}</span>${this.BREAK}`;
            }

            if (this.existsInstDataLogger(protTest)) {
                res += `<ul><li><span><strong><u>${translate.instant('test.loadDistribution.methodology.firstSubtitle.list.tempProbe.title')}</u></strong></span></li></ul><br /><span>${translate.instant('test.vacuumDistribution.methodology.firstSubtitle.list.tempProbe.description')}</span>${this.BREAK}`;
            }

            if (isProbePressure) {
                res += `<ul><li><span><strong><u>${translate.instant('test.loadDistribution.methodology.firstSubtitle.list.pressureProbe.title')}</u></strong></span></li></ul><br/><span>${translate.instant('test.vacuumDistribution.methodology.firstSubtitle.list.pressureProbe.description')}</span>${this.BREAK}`;
            }

            if (isProbeCo2) {
                res += `<ul><li><span><strong><u>${translate.instant('test.loadDistribution.methodology.firstSubtitle.list.co2Probe.title')}</u></strong></span></li></ul><br/><span>${translate.instant('test.vacuumDistribution.methodology.firstSubtitle.list.co2Probe.description')}</span>${this.BREAK}`;
            }
            res += `<span><strong><u>${protTest.testDescription}</u></strong></span>`;

            if ((protTest.idTestType === TestTypeEnum.RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM) || (protTest.idTestType === TestTypeEnum.TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS)) {
                resDifferentProbes += `<span>${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.differenceSensor.text', {trial: totalEssayText, variable: resVariables, equipment: equipmentWithDelDeLa })}</span>${this.BREAK}`;
            } else if (TestTypeEnum.TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE) {
                resDifferentProbes += `<span>${translate.instant('test.loadPenetration.methodology.secondSubtitle.differenceSensor.text', {trial: totalEssayText, variable: resVariables, equipment: equipmentWithDelDeLa })}</span>${this.BREAK}`;
            }
            let textComplemetEssayCycle = translate.instant('test.loadDistribution.methodology.secondSubtitle.cycle.essay.plural');
            let textComplementEssay = translate.instant('test.loadDistribution.methodology.secondSubtitle.frequency.text.plural');
            if (totalEssay == 1) {
                textComplementEssay = translate.instant('test.loadDistribution.methodology.secondSubtitle.frequency.text.singular');
                textComplemetEssayCycle = translate.instant('test.loadDistribution.methodology.secondSubtitle.cycle.essay.singular', {essayName: essayText});
            }
            resDifferentFrequency += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.frequency.text', {variable: resVariables, complementTextEssay: textComplementEssay})}</span>${this.BREAK}`;
            resDifferentCycle += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.cycle.text', {totalCycleText: totalCycle, titleEssay: textComplemetEssayCycle})}</span>${this.BREAK}}`;
            for (let index = 0; index < protTest.protocolQTestEssayGroup.length; index++) {
                const group = protTest.protocolQTestEssayGroup[index];
                
                if (differencySensor) {
                    resDifferentProbes += this.getDifferenceSensor(group, translate);
                }
                if (differencyFrequency || totalEssay > 1) {
                    resDifferentFrequency += this.getDifferenceFrequency(group, translate);
                }
                if (differencyCycle || totalEssay > 1) {
                    resDifferentCycle += this.getDifferenceCycle(group, translate);
                }
            }

            res += this.BREAK;
            if (differencySensor) {
                res += resDifferentProbes;
            } else {
                let textCo2 = '';
                if (isProbeCo2) {
                    if (probeNumberCo2 === 1) {
                        textCo2 = `${translate.instant('test.loadDistribution.methodology.secondSubtitle.text.co2.singular', {probeNumberCo2: probeNumberCo2})}`;
                    } else {
                        textCo2 = `${translate.instant('test.loadDistribution.methodology.secondSubtitle.text.co2.plural', {probeNumberCo2: probeNumberCo2})}`;
                    }
                }

                res += `<span>${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.text', {trial: totalEssayText, variable: resVariables, probeNumberTempHum: probeNumberTempHum, variableTempHum: resVariableTempHum, probeCo2Text: textCo2, equipment: equipmentWithElLa})}`;
            }

            res += this.BREAK;

            if ((protTest.idTestType === TestTypeEnum.TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS) || (protTest.idTestType === TestTypeEnum.TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE)) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.load', {phaseEssay: totalEssayText})}${this.BREAK}</span>`;
                res += this.getDescriptionLoadWithTitle(protTest.protocolQTestEssayGroup, translate);
                res += this.BREAK;
            }

            if (this.isTankN2(protocol)) {
                res += `<span>${translate.instant('test.methodology.secondSubtitle.n2.text', {equipment: equipmentWithDelDeLa})}</span>`;
                res += this.BREAK;
            }

            res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.closedAndStart', {equipment: equipmentWithElLa, phaseEssay: totalEssayText})}</span>`;

            res += this.BREAK;

            if (differencyFrequency) {
                res += resDifferentFrequency;
            } else {
                res +=  `<span>${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.dataRecording', {variable: resVariables, timeVariable: frequency } )}</span>`;
            }
            res += this.BREAK;

            res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.dataCompilation', {variable: resVariables})}</span>`;

            res += this.BREAK;

            if (isLethality) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.cumulativeLethality.text')}</span>`;
                res += this.BREAK;
                res += `<span class="ql-align-center">${translate.instant('test.loadDistribution.methodology.cumulativeLethality.formula')}<br/></span>`;
                res += `<span>${translate.instant('test.loadDistribution.methodology.cumulativeLethality.where')}</span>${this.BREAK}`;
                res += `<span>${translate.instant('test.loadDistribution.methodology.cumulativeLethality.t')}<br/></span>`;
                res += `<span>${translate.instant('test.loadDistribution.methodology.cumulativeLethality.T')}<br/></span>`;
                res += `<span>${translate.instant('test.loadDistribution.methodology.cumulativeLethality.Z')}<br/></span>`;
                res += `<span>${translate.instant('test.loadDistribution.methodology.cumulativeLethality.L')}</span>`;
                res += this.BREAK;
            }

            res += resDifferentCycle;
            res += this.BREAK;

            if (isOuterProbe) {
                res += `<span>${translate.instant('test.methodology.probes.outside.text', {variable: variableSensor.toLowerCase(), equipment: equipmentWithElLa})}</span>`;
                res += this.BREAK;
            }

            res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.endCycle', {cycles: totalEssayText, equipment: equipmentWithElLa})}</span>`;

            res += this.BREAK;

            if (isOpenDoor || isCutElectric) {
                let totalOpenDoor = this.getTotalOpenDoorCutElectric(protTest.protocolQTestEssayGroup, translate);

                let openDoorText = this.getTotalOpenDoorText(protTest.protocolQTestEssayGroup, equipmentWithElLa, translate);
                let cutElectricText = this.getTotalCutElectricText(protTest.protocolQTestEssayGroup, translate);
                let openCutText = '';

                if ((openDoorText) && (cutElectricText)) {
                    openCutText = `${openDoorText} ${translate.instant('test.essay.y')} ${cutElectricText}`;

                } else if( (openDoorText) && (!cutElectricText)) {
                    openCutText = openDoorText;
                } else if ((!openDoorText) && (cutElectricText)) {
                    openCutText = cutElectricText;
                }

                res += `<span>${translate.instant('test.methodology.time.thirdSubtitle.text', {countOpenCutElectric: openCutText, variable: resVariables, equipment: equipmentWithElLa, openCutElectric: totalOpenDoor})}</span>`;
            }
            // Equipments
            res += this.BREAK;
            if (this.isLiofilizador(protocol)) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.lyophilizers')}</span>`;
                res += this.BREAK;
            }
            if (this.isCentrifuges(protocol)) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.centrifuges')}</span>`;
                res += this.BREAK;
            }
            if (this.isN2Liquid(protocol)) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.n2Liquid')}</span>`;
                res += this.BREAK;
            }

            if (this.isTankN2(protocol)) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.n2Gas')}</span>`;
                res += this.BREAK;
            }

            let textPressure ='';
            if (isProbePressure) {
                textPressure =  `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.verificationMatrix.pressure')}</span>`;
            }
            res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.verificationMatrix', {equipment: equipmentWithDelDeLa, presionProbe: textPressure})}</span>`;
        } else {
            res += this.remplaceVariableInText(protocol, methodology, translate);
        }
        return res;
    }

    public static getCriteriaTestQualificates(protocol: ProtocolQ, protTest: ProtocolQTest, translate: TranslateService, criteria: string): string {
        let res = '';

        if (protTest != null && protTest.protocolQTestEssayGroup) {
            let resCriteria ='';
            let resEssay = '';
            let isProbeCo2 = false;
            let isOpenDoor = false;
            let isCutElectric = false;
            let frequencyOpen = '';
            let frequencyCut = '';
            let cycle = 0;
            let allowedEssay = [];

            let totalEssay = this.getTotalEssay(protTest.protocolQTestEssayGroup);
            let equipmentWithDelDeLa = this.getEquipmentType(protocol, translate, true);
            let equipmentWithElLa = this.getEquipmentType(protocol, translate, false);
            let resVariables = this.getVariablesFromEssayQualificationJoin(protTest, translate, false);

            for (let index = 0; index < protTest.protocolQTestEssayGroup.length; index++) {
                resEssay += this.BREAK;
                const group = protTest.protocolQTestEssayGroup[index];
                
                if (!isProbeCo2) {
                    isProbeCo2 = this.existsPressureCo2(group);
                }
                cycle = this.getCycleFields(group);
                if (totalEssay > 1 && !allowedEssay.includes(group.uuid)) {
                    let essay = (translate.instant('essayType.' + group.translationType) as string);
                    if (!essay.includes(translate.instant('test.essay.singular') as string)) {
                        essay = `${translate.instant('test.essay.singular')} ${essay.toLowerCase()}`;
                    }
                    
                    resEssay += `<span>- ${essay}:</span>`;
                }
                const resCriterias = this.getCriteriaFromEssayComplete(group, translate, cycle);
                resCriteria = '';

                for (let indexC = 0; indexC < resCriterias.length; indexC++) {
                    const element= resCriterias[indexC];
                    let elements = [...element];
                    for (let indexCriteria = 0; indexCriteria < elements.length; indexCriteria++) {
                        const elementLi = elements[indexCriteria];
                        resCriteria += `<li>${elementLi}</li>`;
                    }
                }

                resEssay += `<ul>${resCriteria}</ul>`;

                if (group.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA) {
                    frequencyOpen = this.frequencyEssay(group, translate);
                }

                if (group.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO) {
                    frequencyCut = this.frequencyEssay(group, translate);
                }

                if (!isOpenDoor) {
                    isOpenDoor = this.existsOpenDoor(group);
                }
                if (!isCutElectric) {
                    isCutElectric = this.existsCutElectric(group);
                }
                
            }

            res += `<span>${translate.instant('test.vacuumDistribution.criteriaAcceptace.firstSubtitle.text')}</span>`;
            res += resEssay;
            res += this.BREAK;
            res += `<span>${translate.instant('test.vacuumDistribution.criteriaAcceptace.secondSubtitle.text', {equipment: equipmentWithDelDeLa})}</span>`;
            res += this.BREAK;
            if (isProbeCo2) {
                res += `<span>${translate.instant('test.loadDistribution.acceptanceCriteria.secondSubtitle.cutElectric.text', {equipment: equipmentWithDelDeLa})}</span>`;
                res += this.BREAK;
            }

            if (isOpenDoor || isCutElectric) {

                let openDoorText = this.getTotalOpenDoorText(protTest.protocolQTestEssayGroup, equipmentWithElLa, translate);
                let cutElectricText = this.getTotalCutElectricText(protTest.protocolQTestEssayGroup, translate);

                let openCutText = '';

                if ((openDoorText) && (cutElectricText)) {
                    openCutText = `${openDoorText} ${translate.instant('test.essay.y')} ${cutElectricText}`;

                } else if( (openDoorText) && (!cutElectricText)) {
                    openCutText = openDoorText;
                } else if ((!openDoorText) && (cutElectricText)) {
                    openCutText = cutElectricText;
                }

                res += `<span>${translate.instant('test.criteriaAcceptace.time.thirdSubtitle.text', {equipment: equipmentWithElLa, variable: resVariables, countOpenCutElectric: openCutText})}</span>`;
                res += this.BREAK;
            }


            res += `<span>${translate.instant('test.vacuumDistribution.criteriaAcceptace.thirdSubtitle.text', {client: protocol.nameClient})}</span>`;
        } else {
            res = this.remplaceVariableInText(protocol, criteria, translate);
        }
        return res;
    }

    public static getVariablesFromEssayQualification(essay: ProtocolQTestEssayGroup, translate: TranslateService): string {
		return essay.essayValues.map(i => translate.instant('variable.' + i.translationVariable) as string).join(', ')?.toLowerCase();
	}

    public static getVariablesFromEssayQualificationJoin(essay: ProtocolQTest, translate: TranslateService, isTempHum: boolean): string {
        let translatedValues: string[] = [];
    
        for (let index = 0; index < essay.protocolQTestEssayGroup.length; index++) {
            const element = essay.protocolQTestEssayGroup[index];
            if (isTempHum) {
                translatedValues = element.essayValues
                    .filter(i => i.idVariable === VariableTypeEnum.TEMPERATURE || i.idVariable === VariableTypeEnum.HUMIDITY)
                    .map(i => translate.instant('variable.' + i.translationVariable));
            } else {
                translatedValues = element.essayValues
                    .map(i => translate.instant('variable.' + i.translationVariable));
            }
        
            translatedValues = Array.from(new Set(translatedValues));
        
            translatedValues = translatedValues.map(e => e.toLowerCase());
        
            if (translatedValues.length === 1) {
                return translatedValues[0];
            }
        
            if (translatedValues.length === 2) {
                return translatedValues.join(' y ');
            }
        }
        const lastElement = translatedValues.pop();
        return `${translatedValues.join(', ')} y ${lastElement}`;
    }

    public static getVariablesFromEssayQualificationTempHumCo(test: ProtocolQTest, translate: TranslateService): string {
        let translatedValues: string[] = [];
    
        for (let index = 0; index < test.protocolQTestEssayGroup?.length; index++) {
            const essay = test.protocolQTestEssayGroup[index];

            
            for (let indexValue = 0; indexValue < essay.essayValues.length; indexValue++) {
                const elementValue =  essay.essayValues[indexValue];

                if (!translatedValues.includes(translate.instant('variable.' + elementValue?.translationVariable))) {
                    translatedValues.push(translate.instant('variable.' + elementValue?.translationVariable));
                }
            }
        }
    
        translatedValues = Array.from(new Set(translatedValues));
    
        translatedValues = translatedValues.map(e => e.toLowerCase());
    
        if (translatedValues.length === 1) {
            return translatedValues[0];
        }
    
        if (translatedValues.length === 2) {
            return translatedValues.join(' y ');
        }
    
        const lastElement = translatedValues.pop();
        return `${translatedValues.join(', ')} y ${lastElement}`;
    }

    public static getVariablesEssayByGroupJoin(group: ProtocolQTestEssayGroup, translate: TranslateService, isTempHum: boolean): string {
        let translatedValues: string[] = [];
    
        if (isTempHum) {
            translatedValues = group.essayValues
                .filter(i => i.idVariable === VariableTypeEnum.TEMPERATURE || i.idVariable === VariableTypeEnum.HUMIDITY)
                .map(i => translate.instant('variable.' + i.translationVariable));
        } else {
            translatedValues = group.essayValues
                .map(i => translate.instant('variable.' + i.translationVariable));
        }
    
        translatedValues = Array.from(new Set(translatedValues));
    
        translatedValues = translatedValues.map(e => e.toLowerCase());
    
        if (translatedValues.length === 1) {
            return translatedValues[0];
        }
    
        if (translatedValues.length === 2) {
            return translatedValues.join(` ${translate.instant('test.essay.y')} `);
        }
        const lastElement = translatedValues.pop();
        return `${translatedValues.join(', ')} ${translate.instant('test.essay.y')} ${lastElement}`;
    }
      
      
    public static existsPressureProbe(essay: ProtocolQTestEssayGroup): boolean {
        return essay.essayValues.some(element => element.idVariable === VariableTypeEnum.PRESSURE);
    }

    public static existsPressureCo2(essay: ProtocolQTestEssayGroup): boolean {
        return essay.essayValues.some(element => element.idVariable === VariableTypeEnum.CO2);
    }

    public static existsTempHumd(essay: ProtocolQTestEssayGroup): boolean {
        return essay.essayValues.some(element => element.idVariable === VariableTypeEnum.TEMPERATURE || element.idVariable === VariableTypeEnum.LETHALITY);
    }

    public static existsInstDataLogger(test: ProtocolQTest): boolean {
        return test.idInstrumentation == InstrumentationEnum.DATA_LOGGER;
    }

    public static existsInstRegisterData(test: ProtocolQTest): boolean {
        return test.idInstrumentation == InstrumentationEnum.REGISTRADOR_DATOS;
    }

    public static existsInstValidator(test: ProtocolQTest): boolean {
        return test.idInstrumentation == InstrumentationEnum.VALIDATOR;
    }

    public static existsInstToDefine(test: ProtocolQTest): boolean {
        return test.idInstrumentation == InstrumentationEnum.A_DEFINIR;
    }

    public static isCentrifuges(protocol: ProtocolQ): boolean {
        return protocol.equipments.some(element => element.idType === EquipmentTypeQualificationEnum.CENTRIFUGE);
    }

    public static isN2Liquid(protocol: ProtocolQ): boolean {
        return false; //protocol.equipments.some(element => element.idType === QualificationUtils.EQUIPMENT_TYPE);
    }

    public static isTankN2(protocol: ProtocolQ): boolean {
        return protocol.equipments.some(element => element.idType === EquipmentTypeQualificationEnum.TANK_N2);
    }

    public static isWarehouses(protocol: ProtocolQ): boolean {
        return protocol.equipments.some(element => element.idType === EquipmentTypeQualificationEnum.WAREHOUSE);
    }

    public static getFrecuency(test: ProtocolQTest, translate: TranslateService): string {
        let frequency = 0;
        for (let index = 0; index < test.protocolQTestEssayGroup?.length; index++) {
            const element = test.protocolQTestEssayGroup[index];

            const highestFrequency = element.essayValues?.[0]?.fields?.filter(field => field.idField === FieldEnum.FREQUENCY).map(field => field.value).reduce((max, current) => Math.max(max, current), 0);
            if (highestFrequency) {
               frequency += Number(highestFrequency);
            }
        }
    
        // Convertir la frecuencia a un formato adecuado
        return this.getFreqMinSecs(frequency.toString(), translate);
    }
    

    public static getNumCycleTextIndividual(essay: ProtocolQTestEssayGroup, translate: TranslateService): string {
        let cycleText = '';        
        const totalCycles = essay.essayValues[0].fields?.find(field => field.idField === FieldEnum.NUM_CYCLES)?.value; 

        if (totalCycles) {
            const res = Number(totalCycles);
            if (res === 1) {
                cycleText = translate.instant('test.essay.cycle.singular', {cycle: totalCycles});
            } else {
                cycleText = translate.instant('test.essay.cycle.plural', {cycle: totalCycles});
            }
        } else {
            cycleText = translate.instant('test.contracted.items.elcycle', {cycle: totalCycles});
        }
        return cycleText;
    }


    public static getNumCycleIndividual(essay: ProtocolQTestEssayGroup): number {
        const res = essay.essayValues[0].fields?.find(field => field.idField === FieldEnum.NUM_CYCLES);
        return res ? Number(res.value) : 0;
    }

    public static getNumCycleByGroup(group: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let totalCycles = 0;
        let cycleText = '';
        for (let index = 0; index < group.length; index++) {
            const element = group[index].essayValues[0].fields?.find(field => field.idField === FieldEnum.NUM_CYCLES); 

            if (element) { 
                totalCycles += Number(element.value);
            }
        }

        if (totalCycles) {
            const res = Number(totalCycles);
            if (res === 1) {
                cycleText = translate.instant('test.essay.cycle.singular', {cycle: totalCycles});
            } else {
                cycleText = translate.instant('test.essay.cycle.plural', {cycle: totalCycles});
            }
        } else {
            cycleText = translate.instant('test.contracted.items.elcycle', {cycle: totalCycles});
        }

        return cycleText;
    }

    public static getNumCycle(group: ProtocolQTestEssayGroup[]): number {
        let totalCycles = 0;
        for (let index = 0; index < group.length; index++) {
            const element = group[index].essayValues[0].fields?.find(field => field.idField === FieldEnum.NUM_CYCLES); 

            if (element) { 
                totalCycles += Number(element.value);
            }
        }
        return totalCycles;
    }

    public static getEquipmentName(prot: ProtocolQ,): string {
        return prot.equipments?.[0]?.equipment;
    }

    public static getEquipmentType(prot: ProtocolQ, translate: TranslateService, isDelDela: boolean): string {
        if (!prot.equipments || prot.equipments.length === 0) {
            return "";
        }
    
        const equipment = prot.equipments[0];
        if (!equipment || !equipment.equipment) {
            return "";
        }
    
        const normalizedName = translate.instant(`equipmentType.${equipment.nameType}`).toLowerCase();
    
        if (normalizedName.endsWith('a')) {
            let translated;
            if (isDelDela) {
                translated = translate.instant('test.vacuumDistribution.contracted.items.deLa');
            } else {
                translated = translate.instant('test.vacuumDistribution.contracted.items.la');
            }
            return `${translated} ${normalizedName}`;
        } else if (normalizedName.endsWith('o')) {
            let translated;
            if (isDelDela) {
                translated = translate.instant('test.vacuumDistribution.contracted.items.del');
            } else {
                translated = translate.instant('test.vacuumDistribution.contracted.items.el');

            }
            return `${translated} ${normalizedName}`;
        }
        return "";
    }
    
    
    
    public static isLiofilizador(prot: ProtocolQ,): boolean {
        return false;
    }

    public static getFreqMinSecs(value: string, translate: TranslateService): string {
        if (!value) {
            return '';
        }
    
        const d = Number(value);
        const rIn = Math.floor(d);
    
        if (d === 0) {
            return 'X';
        }
    
        const timeParts: string[] = [];
    
        if (rIn > 0) {
            let minutesMessage = `${rIn} ${translate.instant('generic.time.minute')}`;
            if (rIn > 1) {
                minutesMessage += 's';
            }
            timeParts.push(minutesMessage);
        }
    
        const seconds = Math.round((d - rIn) * 60);
        if (seconds > 0) {
            let secondsMessage = `${seconds} ${translate.instant('generic.time.second')}`;
            if (seconds > 1) {
                secondsMessage += 's';
            }
            timeParts.push(secondsMessage);
        }
        return timeParts.length > 0 ? timeParts.join(', ') : '';
    }

    public static getNumberSensorTemperatureHumd(group: ProtocolQTestEssayGroup): number {
        const totalSensors = group.essayValues.filter(eve => eve.idVariable === VariableTypeEnum.TEMPERATURE || eve.idVariable === VariableTypeEnum.LETHALITY);
        return totalSensors != null ? totalSensors[0].sensors.length : 0;
    }

    public static getNumberSensorCo2(group: ProtocolQTestEssayGroup): number {
        const totalSensors = group.essayValues.filter(eve => eve.idVariable === VariableTypeEnum.CO2);
        return totalSensors != null ? totalSensors.length : 0;
    }

    public static getNumberSensorPressure(group: ProtocolQTestEssayGroup): number {
        const totalSensors = group.essayValues.filter(eve => eve.idVariable === VariableTypeEnum.PRESSURE);
        return totalSensors != null ? totalSensors.length : 0;
    }

    public static getNumberSensor(group: ProtocolQTestEssayGroup): number {
        return group.essayValues.reduce((sum, ev) => sum + ev.sensors.length, 0);
    }

    public static getEssayType(group: ProtocolQTestEssayGroup, translate: TranslateService): string {
        return (translate.instant('essayType.' + group.translationType) as string).toLowerCase();
    }

    public static getCycleText(group: ProtocolQTestEssayGroup, translate: TranslateService): string {
        const cycles = this.getCycleNumber(group);

        if (cycles > 1) {
            return translate.instant('test.vacuumDistribution.contracted.items.loscycle');
        } else {
            return translate.instant('test.vacuumDistribution.contracted.items.elcycle');
        }

    }

    public static getCycleNumber(essay: ProtocolQTestEssayGroup) {
        const frequency = essay.essayValues?.[0]?.fields?.find(field => field.idField === FieldEnum.FREQUENCY);

        if (frequency?.value) {
            return Number(frequency.value)
        } else {
            return 0;
        }
    }

    public static getCriteriaFromEssayComplete(essay: ProtocolQTestEssayGroup, translate: TranslateService, cycle: number): string[] {
		let res = [];
        if (!essay || !essay.essayValues) {
			return null;
		}

        for (let index = 0; index < essay.essayValues.length; index++) {
            const element = essay.essayValues[index];
            res.push(EssayUtils.getCriteriasToShowQualificatesWithSpace(element, translate, cycle));
        }

		return res;
	}

    public static existsOpenDoor(essay: ProtocolQTestEssayGroup): boolean {
        return essay.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA;
    }

    public static frequencyEssay(essay: ProtocolQTestEssayGroup, translate: TranslateService): string {
        let frequency = '';
        const highestFrequency = essay.essayValues?.[0]?.fields?.filter(field => field.idField === FieldEnum.FREQUENCY).map(field => field.value).reduce((max, current) => Math.max(max, current), 0);
        if (highestFrequency) {
            frequency += Number(highestFrequency);
        }
        return this.getFreqMinSecs(frequency.toString(), translate);
    }

    public static existsCutElectric(essay: ProtocolQTestEssayGroup): boolean {
        return essay.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO;    
    }

    public static getCycleFields(essay: ProtocolQTestEssayGroup) {
        let totalCycles = 1;
        if (essay.essayValues != null && essay.essayValues.length > 0) {
            const field = essay.essayValues[0].fields.find(field => field.idField === FieldEnum.NUM_CYCLES);
            if (field?.value != null) {
                totalCycles = Number(field.value);
            }
        }
        return totalCycles;
        
    }

    public static getValueDayHourMin(value: string | string[], translate: TranslateService): string {
		let res = '';

		if (value != null) {
            let values = [];
            if (typeof value === 'string') {
                values = value.split(";");
            } else {
                if (Array.isArray(value[0])) {
                    values = value[0];
                } else {
                    values = value[0]?.split(";");
                }
            }

			let vals = [];

            if (values?.length > 0) {
                let val = values[0];
                if (val && val !== "0") {
                    let str = val + " " + translate.instant('generic.time.day');
                    if (val !== "1") {
                        str = str.concat("s");
                    }
                    vals.push(str);
                }
                
                val = values[1];
                if (val && val !== "0") {
                    let str = val + " " + translate.instant('generic.time.hour');
                    if (val !== "1") {
                        str = str.concat("s");
                    }
                    vals.push(str);
                }
    
                val = values[2];
                if (val && val !== "0") {
                    let str = val + " " + translate.instant('generic.time.minute');
                    if (val !== "1") {
                        str = str.concat("s");
                    }
                    vals.push(str);
                }
            }

            if (vals) {
                res = vals.join(", ");
            }
            
		}

		return res;
    }

    public static differenceInSensory(protocol: ProtocolQTest): boolean {
        const group = protocol.protocolQTestEssayGroup;
        let allValuesAreEqual: boolean = false;
        for (let index = 0; index < group?.length; index++) {
            const element = group[index];
    
            if (!element.essayValues.every(e => e.sensors === element.essayValues[0].sensors)) {
                allValuesAreEqual = true;
                break;
            }
        }

        return allValuesAreEqual;
    }

    public static getProgramaByEssay(essay: ProtocolQTestEssay): string {
        if (essay != null) {
            const field = essay?.fields?.find(field => field.idField === FieldEnum.PROGRAM);
            if (field?.value != null) {
                return field.value;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public static getFrequencyByEssay(essay: ProtocolQTestEssay): string {
        if (essay != null) {
            const field = essay[0].fields?.find(field => field.idField === FieldEnum.FREQUENCY);
            if (field?.value != null) {
                return field.value;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public static differenceInFrequency(protocol: ProtocolQTest): boolean {
        const group = protocol.protocolQTestEssayGroup;
        let allValuesAreEqual: boolean = false;
        for (let i = 0; i < group.length; i++) {
            const element = group[i];
            for (let j = 0; j < element.essayValues.length; j++) {
                const essayValues = element.essayValues[j];
                const frequencyField = essayValues.fields?.find(field => field.idField === FieldEnum.FREQUENCY);
        
                if (frequencyField) {
                    const firstFieldValue = frequencyField.value;

                    if (!essayValues.fields.every(field => 
                        field.idField !== FieldEnum.FREQUENCY || field.value === firstFieldValue
                    )) {
                        allValuesAreEqual = false;
                        break;
                    }
                }
            }
            
            if (!allValuesAreEqual) {
                break;
            }
        }
    
        return allValuesAreEqual;
    }

    public static differenceInCycle(protocol: ProtocolQTest): boolean {
        const group = protocol.protocolQTestEssayGroup;
        let allValuesAreEqual: boolean = false;
        for (let i = 0; i < group.length; i++) {
            const element = group[i];
            for (let j = 0; j < element.essayValues.length; j++) {
                const essayValues = element.essayValues[j];
                const cyclesField = essayValues.fields?.find(field => field.idField === FieldEnum.NUM_CYCLES);

                if (cyclesField) {
                    const firstFieldValue = cyclesField.value;

                    if (!essayValues.fields.every(field => 
                        field.idField !== FieldEnum.NUM_CYCLES || field.value === firstFieldValue
                    )) {
                        allValuesAreEqual = false;
                        break;
                    }
                }
        
            }
            
            if (!allValuesAreEqual) {
                break;
            }
        }
    
        return allValuesAreEqual;
    }

    public static getDifferenceFrequency(group: ProtocolQTestEssayGroup, translate: TranslateService): string {
        let res: string = '';

        const values = group.essayValues;
        const existsTmpHum = this.existsTempHumdByEssay(values);
        const essayName = (translate.instant(`essayType.${group.translationType}`) as string).toLowerCase();
        let isCompleteHumTemp: boolean = false;
        for (let index = 0; index < values.length; index++) {
            const element = values[index];

            let resFrequency = element.fields?.find(e => e.idField === FieldEnum.FREQUENCY)?.value;

            if (resFrequency) {
                resFrequency = this.getFreqMinSecs(resFrequency, translate);

                if (existsTmpHum && (element.idVariable === VariableTypeEnum.TEMPERATURE || element.idVariable === VariableTypeEnum.LETHALITY)) {
                    if (!isCompleteHumTemp) {
                        res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.frequency.item', {variable: this.getVariablesTempHumdByEssay(values, translate, true), time: resFrequency, essayName: essayName })}</span>`;
                        isCompleteHumTemp = true;
                    }
                } else {
                    res += this.BREAK;
                    res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.frequency.item', {variable: translate.instant(`variable.${element.translationVariable}`), time: resFrequency, essayName: essayName })}</span>`;
                }
            }
        }
        return res;
    }

    public static getDifferenceSensor(group: ProtocolQTestEssayGroup, translate: TranslateService): string {
        let res: string = '';
        const values = group.essayValues;
        const existsTmpHum = this.existsTempHumdByEssay(values);
        const existsTmpLeth = this.existsTempLethalityByEssay(values);
        const essayName = translate.instant(`essayType.${group.translationType}`) as string;
        let isCompleteHumTemp: boolean = false;
        let isCompleteHumLeth: boolean = false;
        for (let index = 0; index < values.length; index++) {
            const element = values[index];
            
            const program = this.getProgramaByEssay(element);
            let programText = '';
            if (program != null) {
                programText += ` ${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.differenceSensor.item.program', {programText: program.toLowerCase() })}`
            }
            const probeNumber = element.sensors != null ? element.sensors.length : 0;

            if ((element.idVariable === VariableTypeEnum.TEMPERATURE || element.idVariable === VariableTypeEnum.HUMIDITY) && existsTmpHum) {
                if (!isCompleteHumTemp) {
                    res += `<span>${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.differenceSensor.item', {probeNumber: probeNumber, variable: this.getVariablesTempHumdByEssay(values, translate, true), nameEssay: essayName?.toLowerCase(), programText: programText })}<br/></span>`;
                    isCompleteHumTemp = true;
                }
            } else if ((element.idVariable === VariableTypeEnum.TEMPERATURE ||  element.idVariable === VariableTypeEnum.LETHALITY) && existsTmpLeth) {
                if(!isCompleteHumLeth) {
                    res += `<span>${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.differenceSensor.item', {probeNumber: probeNumber, variable: this.getVariablesTempLetalidadByEssay(values, translate, true), nameEssay: essayName?.toLowerCase(), programText: programText })}<br/></span>`;
                    isCompleteHumLeth = true;
                }
            } else {
                res += `<span>${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.differenceSensor.item', {probeNumber: probeNumber, variable: translate.instant(`variable.${element.translationVariable}`), nameEssay: essayName?.toLowerCase(), programText: programText })}<br/></span>`;
            }  
        }
        return res;
    }

    public static getDifferenceCycle(group: ProtocolQTestEssayGroup, translate: TranslateService): string {
        let res: string = '';
        const values = group.essayValues;
        let cycleEssayText = this.getNumCycleTextIndividual(group, translate);
        const essayName = (translate.instant(`essayType.${group.translationType}`) as string).toLowerCase();
        const existsTmpHum = this.existsTempHumdByEssay(values);
        const existsTmpLeth = this.existsTempLethalityByEssay(values);
        let isCompleteHumTemp: boolean = false;
        if (values && values[0]) {
            const element = values[0];

            const program = this.getProgramaByEssay(element);
            let programText = '';
            if (program != null && program !== '') {
                programText += ` ${translate.instant('test.vacuumDistribution.methodology.secondSubtitle.cycle.item.program', {programText: program.toLowerCase() })}`;
            }

            if (existsTmpHum) {
                if (!isCompleteHumTemp) {
                    res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.cycle.item', {cycleText: cycleEssayText, essayName: essayName, programText: programText })}<br/></span>`;
                    isCompleteHumTemp = true;
                }
            } else {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.cycle.item', {cycleText: cycleEssayText, essayName: essayName, programText: programText })}<br/></span>`;
            }
        }
        return res;
    }

    public static getNumberSensorTemperatureHumdByEssay(essayValues: ProtocolQTestEssay[]): number {
        const totalSensors = essayValues.filter(eve => eve.idVariable === VariableTypeEnum.TEMPERATURE || eve.idVariable === VariableTypeEnum.LETHALITY);
        return totalSensors != null ? totalSensors[0].sensors.length : 0;
    }

    public static getNumberSensorCo2ByEssay(essayValues: ProtocolQTestEssay[]): number {
        const totalSensors = essayValues.filter(eve => eve.idVariable === VariableTypeEnum.CO2);
        return totalSensors != null ? totalSensors.length : 0;
    }

    public static existsPressureProbeByEssay(essayValues: ProtocolQTestEssay[]): boolean {
        return essayValues.some(element => element.idVariable === VariableTypeEnum.PRESSURE);
    }

    public static existsTempHumdByEssay(essayValues: ProtocolQTestEssay[]): boolean {
        return essayValues.some(eve => eve.idVariable === VariableTypeEnum.TEMPERATURE) && essayValues.some(eve => eve.idVariable === VariableTypeEnum.HUMIDITY);
    }

    public static existsTempLethalityByEssay(essayValues: ProtocolQTestEssay[]): boolean {
        return essayValues.some(eve => eve.idVariable === VariableTypeEnum.TEMPERATURE) && essayValues.some(eve => eve.idVariable === VariableTypeEnum.LETHALITY);
    }

    public static existsPressureCo2ByEssay(essayValues: ProtocolQTestEssay[]): boolean {
        return essayValues.some(element => element.idVariable === VariableTypeEnum.CO2);
    }

    public static getVariablesTempHumdByEssay(essayValues: ProtocolQTestEssay[], translate: TranslateService, isTempHum: boolean): string {
        let translatedValues: string[] = [];
    
        translatedValues = essayValues
            .filter(i => i.idVariable === VariableTypeEnum.TEMPERATURE || i.idVariable === VariableTypeEnum.HUMIDITY)
            .map(i => translate.instant('variable.' + i.translationVariable));

        translatedValues = Array.from(new Set(translatedValues));
    
        translatedValues = translatedValues.map(e => e.toLowerCase());
    
        if (translatedValues.length === 1) {
            return translatedValues[0];
        }
    
        if (translatedValues.length === 2) {
            return translatedValues.join(' y ');
        }
        const lastElement = translatedValues.pop();
        return `${translatedValues.join(', ')} y ${lastElement}`;
    }

    public static getVariablesTempLetalidadByEssay(essayValues: ProtocolQTestEssay[], translate: TranslateService, isTempHum: boolean): string {
        let translatedValues: string[] = [];
    
        translatedValues = essayValues
            .filter(i => i.idVariable === VariableTypeEnum.TEMPERATURE || i.idVariable === VariableTypeEnum.LETHALITY)
            .map(i => translate.instant('variable.' + i.translationVariable));

        translatedValues = Array.from(new Set(translatedValues));
    
        translatedValues = translatedValues.map(e => e.toLowerCase());
    
        if (translatedValues.length === 1) {
            return translatedValues[0];
        }
    
        if (translatedValues.length === 2) {
            return translatedValues.join(' y ');
        }
        const lastElement = translatedValues.pop();
        return `${translatedValues.join(', ')} y ${lastElement}`;
    }

    public static getVariablesByEssay(essayValues: ProtocolQTestEssay[], translate: TranslateService, isTempHum: boolean): string {
        let translatedValues: string[] = [];
    
        translatedValues = essayValues.map(i => translate.instant('variable.' + i.translationVariable));

        translatedValues = Array.from(new Set(translatedValues));
    
        translatedValues = translatedValues.map(e => e.toLowerCase());
    
        if (translatedValues.length === 1) {
            return translatedValues[0];
        }
    
        if (translatedValues.length === 2) {
            return translatedValues.join(' y ');
        }
        const lastElement = translatedValues.pop();
        return `${translatedValues.join(', ')} y ${lastElement}`;
    }

    public static getTotalCycleNumber(test: ProtocolQTestEssayGroup[]): number {
        for (let index = 0; index < test.length; index++) {
            const element = test[index];
            const frequency = element.essayValues[0]?.fields.find(field => field.idField === FieldEnum.FREQUENCY);

            if (frequency._value) {
                return Number(frequency._value)
            } else {
                return 0;
            } 
        }
       
    }

    public static getTotalEssayText(groups: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let totalEssay = 0;
        let allowedEssay = [];
        let resText = '';

        for (let index = 0; index < groups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssay += 1;
            }
        }

        if (totalEssay > 1) {
            resText = translate.instant('test.essay.losessay', {numberEssay: totalEssay})
        } else {
            resText = translate.instant('test.essay.elessay')
        }
        return resText;
    }

    public static getTotalEssay(groups: ProtocolQTestEssayGroup[]): number {
        let totalEssay = 0;
        let allowedEssay = [];

        for (let index = 0; index < groups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssay += 1;
            }
        }
        return totalEssay;
    }

    public static getDescriptionLoadWithTitle(test: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let res = '';
        for (let index = 0; index < test.length; index++) {
            const element = test[index];
            if (element.title && element.load) {
                res += `<span>${translate.instant('test.loadDistribution.methodology.secondSubtitle.load.item', {titleEssay: element.title, descriptionLoad: element.load})}<br/></span>`;
            }
        }

        return res;
       
    }

    public static existsOutsideProbe(essay: ProtocolQTestEssayGroup): boolean {
        return essay.essayValues.some(element => {
           return element.sensors.some(s => s.outer);
        });
    }

    public static getVariableOutsideProbe(essay: ProtocolQTestEssayGroup, translante: TranslateService): string {
        let variable = [];
        for (let index = 0; index < essay.essayValues.length; index++) {
            const element = essay.essayValues[index];
            
            for (let indexSensor = 0; indexSensor < element.sensors.length; indexSensor++) {
                const sensor = element.sensors[indexSensor];

                if (sensor.outer && !variable.includes(translante.instant(`variable.${element.translationVariable}`))) {
                    variable.push(translante.instant(`variable.${element.translationVariable}`));
                }
            }
        }

        return variable.join(', ');
    }

    public static getLethalityAccumulated(group: ProtocolQTestEssayGroup[]): boolean{
        let res = false;
        for (let index = 0; index < group.length; index++) {
            const element = group[index].essayValues[0].fields?.find(field => field.idField === FieldEnum.LETHALITY_F0); 

            if (element &&  element.value === 'accumulated') { 
                res = true;
            }
        }

        return res;
    }

    public static getTotalOpenDoorText(groups: ProtocolQTestEssayGroup[], equipment: string, translate: TranslateService): string {
        let totalEssay = 0;
        let allowedEssay = [];
        let resText = '';

        let resGroups = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA);
        for (let index = 0; index < resGroups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssay += 1;
            }
        }

        if (totalEssay === 1) {
            resText = translate.instant('test.essay.openDoor.singular');
        } else if (totalEssay > 1) {
            resText = translate.instant('test.essay.openDoor.plural', {count: totalEssay});
        } else {
            return null;
        }

        let openDoorTime = this.getTimeExpositionOpenDoor(groups, translate);

        for (let index = 0; index < groups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid) && element.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA) {
                this.getFrecuency
            }
        }

        return translate.instant('test.methodology.time.openDoor.thirdSubtitle.text', {countEssay: resText, equipment: equipment, duration: openDoorTime})
    }

    public static getTotalCutElectricText(groups: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let totalEssay = 0;
        let allowedEssay = [];
        let resText = '';

        let resGroups = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO);
        for (let index = 0; index < resGroups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssay += 1;
            }
        }

        if (totalEssay === 1) {
            resText = translate.instant('test.essay.cutElectric.singular');
        } else if (totalEssay > 1) {
            resText = translate.instant('test.essay.cutElectric.plural', {count: totalEssay});
        } else {
            return null;
        }

        let openDoorTime = this.getTimeExpositionCutElectric(groups, translate);

        for (let index = 0; index < groups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid) && element.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO) {
                this.getFrecuency
            }
        }

        return translate.instant('test.methodology.time.cutElectric.thirdSubtitle.text', {countEssay: resText, duration: openDoorTime})
    }

    public static getTimeExpositionOpenDoor(groups: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let highestFrequency: Field;
        let res = [];
        let resTime = [];
        let essayGroup = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA);
        for (let index = 0; index < essayGroup?.length; index++) {
            const element = essayGroup[index];

            highestFrequency = element.essayValues?.[0]?.fields.find(field => field.idField === FieldEnum.REHEARSAL_TIME);
            res.push(highestFrequency.value)
        }

        for (let index = 0; index < res.length; index++) {
            const element = res[index];
            
            resTime.push(this.getValueDayHourMin(element, translate));
        }

        if (resTime.length === 1) {
            return resTime[0];
        }
    
        if (resTime.length === 2) {
            return resTime.join(` ${translate.instant('test.essay.y')} `);
        }
        const lastElement = resTime.pop();
        return `${resTime.join(', ')} ${translate.instant('test.essay.y')} ${lastElement}`; 
    }

    public static getTimeExpositionCutElectric(groups: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let highestFrequency: Field;
        let res = [];
        let resTime = [];
        let essayGroup = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO);
        for (let index = 0; index < essayGroup?.length; index++) {
            const element = essayGroup[index];

            highestFrequency = element.essayValues?.[0]?.fields.find(field => field.idField === FieldEnum.REHEARSAL_TIME);
            res.push(highestFrequency.value)
        }

        for (let index = 0; index < res.length; index++) {
            const element = res[index];
            
            resTime.push(this.getValueDayHourMin(element, translate));
        }

        if (resTime.length === 1) {
            return resTime[0];
        }
    
        if (resTime.length === 2) {
            return resTime.join(` ${translate.instant('test.essay.y')} `);
        }
        const lastElement = resTime.pop();
        return `${resTime.join(', ')} ${translate.instant('test.essay.y')} ${lastElement}`; 
    }

    public static getTotalOpenDoorCutElectric(groups: ProtocolQTestEssayGroup[], translate: TranslateService): string {
        let totalEssayOpen = 0;
        let totalEssayCut = 0;

        let allowedEssay = [];
        let resTextOpenDoor = '';
        let resTextCutElectric = '';

        let resGroupOpenDoor = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA);
        for (let index = 0; index < resGroupOpenDoor.length; index++) {
            const element = resGroupOpenDoor[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssayOpen += 1;
            }
        }

        let resGroupCutElectric = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO);
        for (let index = 0; index < resGroupCutElectric.length; index++) {
            const element = resGroupCutElectric[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssayCut += 1;
            }
        }

        if (totalEssayOpen === 1) {
            resTextOpenDoor = translate.instant('test.essay.openDoor.la');
        } else if (totalEssayOpen > 1) {
            resTextOpenDoor = translate.instant('test.essay.openDoor.las', {count: totalEssayOpen});
        } else {
            resTextOpenDoor = null;
        }


        if (totalEssayCut === 1) {
            resTextCutElectric = translate.instant('test.essay.cutElectric.el');
        } else if (totalEssayCut > 1) {
            resTextCutElectric = translate.instant('test.essay.cutElectric.los', {count: totalEssayCut});
        } else {
            resTextCutElectric = null;
        }

        if (resTextOpenDoor && resTextCutElectric) {
            return `${resTextOpenDoor} ${translate.instant('test.essay.y')} ${resTextCutElectric}`;
        } else if (resTextOpenDoor && !resTextCutElectric) {
            return `${resTextOpenDoor}`;
        } else if (!resTextOpenDoor && resTextCutElectric) {
            return `${resTextCutElectric}`;
        }
    }

    public static getTotalCutElectric(groups: ProtocolQTestEssayGroup[]): number {
        let totalEssay = 0;
        let allowedEssay = [];
        let resText = '';

        let resGroups = groups.filter(e => e.idType === EssayTypeEnum.ENSAYO_CORTE_ELECTRICO);
        for (let index = 0; index < resGroups.length; index++) {
            const element = groups[index];
            
            if (!allowedEssay.includes(element.uuid)) {
                totalEssay += 1;
            }
        }
        return totalEssay;
    }

    public static remplaceVariableInText(protocol: ProtocolQ, text: string, translate: TranslateService): string {
		const client = text.includes('[CLIENTE]');
		if (client) {
			if (protocol.nameClient) {
				text = text.replace(/\[CLIENTE\]/g, protocol.nameClient);
			} else {
				text = text.replace(/\[CLIENTE\]/g, 'XXXX');
			}
		}

		const qType = text.includes('[QUALIFICATION_TYPE]');
		if (qType) {
			if (protocol.testPerformed) {
				text = text.replace(/\[QUALIFICATION_TYPE\]/g, protocol.testPerformed?.map(e => translate.instant('testQualification.variable.' + e)).join('/'));
			} else {
				text = text.replace(/\[QUALIFICATION_TYPE\]/g, 'XXXX');
			}
		}

		const qTypeDescription = text.includes('[QUALIFICATION_TYPE_DESCRIPTION]');
		if (qTypeDescription) {
			if (protocol.testPerformed) {
				const resDes = protocol.testPerformed?.map(e => translate.instant('testQualification.variable.description.' + e));
				text = text.replace(/\[QUALIFICATION_TYPE_DESCRIPTION\]/g, StringUtils.concatenateText(resDes, translate));
			} else {
				text = text.replace(/\[QUALIFICATION_TYPE_DESCRIPTION\]/g, 'XXXX');
			}
		}
	
		/*const titleTest = text.includes('[TITLE_TEST]');
		if (titleTest) {
			text = text.replace('[TITLE_TEST]', protocol.testDescription?.toLowerCase());
		}*/

		return text;
	}
}
