export class SignatureDocumentUsers {
    id: number;
    username: string;
    active: boolean;
    position: string;
    idStatus: number;
    internal: boolean;
    regDate: Date;
    signatureDate: Date;
}

export class SignersInfo {
    username: number;
    active: boolean;
    position: string;
    idStatus: number;
    email: string;
    internal: boolean;
    fullName: string;
    onlyCopy: boolean;
}

