import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';

export interface DialogDataFoldersSelected {
  folders: InternalEquipmentFolder[];
}

@Component({
  selector: 'app-internal-equipment-select-folder',
  templateUrl: './internal-equipment-select-folder.component.html'
})
export class InternalEquipmentSelectFolderComponent implements OnInit {

  idGroupSelected: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataFoldersSelected,
  public dialogRef: MatDialogRef<InternalEquipmentSelectFolderComponent>) { }

  ngOnInit(): void {
  }

  onOkClick(): void {
    this.dialogRef.close(this.idGroupSelected);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

}
