import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { EssayProtocolAir } from 'src/app/model/essayProtocolAir';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EssayAirService } from 'src/app/services/essayAir.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { EssayAirTypeEnum, EssayType } from 'src/app/model/essayType';
import { EssayConfigAirService } from 'src/app/services/essayConfigAir.service';
import { EssayConfigAir } from 'src/app/model/essayConfigAir';
import { CriteriaTypeService } from 'src/app/services/criteriaType.service';
import { CriteriaType, CriteriaTypeEnum, SpeedTypeCriteria } from 'src/app/model/criteriaType';
import { Field } from 'src/app/model/field';
import { Criteria } from 'src/app/model/criteria';
import { ProtocolEditEssayCriteriaAirComponent } from './protocol-edit-essay-criteria-air.component';
import { MatTable } from '@angular/material/table';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionListChange, MatListOption } from '@angular/material/list';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import * as _ from 'lodash-es';
import { AccessoryEquipment, AccessoryEquipmentEnum } from 'src/app/model/accessoryEquipment';
import { FilterAir, FilterAirEnum } from 'src/app/model/filterAir';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { MatSelectChange } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { DefaultUnit, VariableUnit } from 'src/app/model/variable';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { EquipmentTypeAirEnum } from 'src/app/model/equipmentType';
import { AirUtils } from 'src/app/utils/airUtils';
import { v4 as uuid } from 'uuid';

export interface DialogDataArea {
  essay: EssayProtocolAir;
  idsEssaysToRemove: number[];
  idEquipment: number;
  isEdit: boolean;
  surfaceRoom: number;
  volumeRoom: number;
  accesories: AccessoryEquipment[];
  filters: FilterAir[];
  isEquipment: boolean;
  isExecution: boolean;
  entityType: string;
}

@Component({
  selector: 'app-protocol-edit-dialog-essay-air',
  templateUrl: './protocol-edit-dialog-essay-air.component.html'
})
export class ProtocolEditDialogEssayAirComponent implements OnInit {

  @ViewChildren('criteriaTable') criteriaTable: QueryList<MatTable<any>>;

  essays: EssayType[];
  essaysFiltered: EssayType[];

  units: VariableUnit[] = [];

  config: EssayConfigAir;
  criteriaTypes: CriteriaType[] = [];
  idConfig: number;
  hepafilters = [FilterAirEnum.HEPA_EXTRACTION_1, FilterAirEnum.HEPA_EXTRACTION_2, FilterAirEnum.HEPA_IMPULSION];

  public displayedColsCriteria = ['criteria', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private essayService: EssayAirService,
    private essayConfigService: EssayConfigAirService,
    private criteriaTypeService: CriteriaTypeService,
    private variableTypeService: VariableTypeService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProtocolEditDialogEssayAirComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataArea,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    if (this.data.isExecution == null) {
      this.data.isExecution = false;
    }

    if (this.data.entityType == null) {
      this.data.entityType = 'equipment';
    }
  }

  ngOnInit(): void {
    let call: Observable<any>;

    if (this.data.entityType === 'equipment') {
      call = this.essayService.findByEquipmentId(this.data.idEquipment);
    } else if (this.data.entityType === 'room') {
      call = this.essayService.findByRoomId(this.data.idEquipment);
    } else if (this.data.entityType === 'compressedGas') {
      call = this.essayService.findByCompressedGasId(this.data.idEquipment);
    }

    call.pipe(takeUntil(this.destroy$)).subscribe((res: EssayType[]) => {
      const filtered = res.filter(e => !this.data.idsEssaysToRemove.includes(e.id));

      filtered.forEach(f => f.translated = this.translate.instant('essayAir.' + f.translation) as string);

      const sortMap = AirUtils.getMapOrderEssay();

      this.essays = filtered.sort((e1, e2) => {
        const sort1 = sortMap.get(e1.id) || 99;
        const sort2 = sortMap.get(e2.id) || 99;

        return sort1 - sort2;
      });
      this.essaysFiltered = this.essays.slice();

      this.onTypeChange(this.data.essay?.idEssayType, true);

      if (!ArrayUtils.isEmpty(this.data.essay.essayValues)) {
        this.data.essay.essayValues = this.data.essay.essayValues.map(i => FieldUtils.objectToField(i));
      }
    });

  }

  onTypeChange(event: number | MatSelectChange, isLoading = false): void {
    let idType: number;

    const errors = [];
    let hasHepa = false;
    let hasActiveCarbon = false;
    // this.data.essay.idUnit = null;

    this.units = [];

    if (typeof event === 'number') {
      idType = event;
    } else if (event instanceof MatSelectChange) {
      idType = event.source?.value as number;
    } else {
      idType = null;
    }

    if (idType == null) {
      this.criteriaTypes = [];
      this.data.essay.essayValues = [];

      return;
    }

    this.idConfig = idType;
    //this.data.essay.idUnit = null;

    let call: Observable<any>;

    if (this.data.entityType === 'equipment') {
      call = this.essayConfigService.findConfigByEssayIdAndEquipmentId(this.data.idEquipment, this.data.essay.idEssayType)
    } else if (this.data.entityType === 'room') {
      call = this.essayConfigService.findConfigByEssayIdAndRoomId(1, this.data.essay.idEssayType)
    } else if (this.data.entityType === 'compressedGas') {
      call = this.essayConfigService.findConfigByEssayIdAndCompressedGasId(1, this.data.essay.idEssayType)
    }

    call.pipe(takeUntil(this.destroy$)).subscribe((config: EssayConfigAir) => {

      const essay = this.essays.find(e => e.id === idType);
      if (essay.idVariable != null) {
        this.variableTypeService.findUnits(essay.idVariable).subscribe((res: VariableUnit[]) => {
          this.units = res;

          if (this.data.essay.idUnit == null) {
            this.data.essay.idUnit = DefaultUnit.getDefaultUnitForEssayAir(essay.id);
          }

          if (this.data.essay.idUnit == null) {
            this.data.essay.idUnit = DefaultUnit.getDefaultValues().get(essay.idVariable) || res[0]?.id;
          }
        });
      }

      this.criteriaTypeService.findByIdEssayAir(config.id).pipe(takeUntil(this.destroy$)).subscribe((crits: CriteriaType[]) => {

        this.criteriaTypes = crits;

        let crit: CriteriaType = null;

        const criteriasEmpty = ArrayUtils.isEmpty(this.data.essay.criterias);

        const extractions = [EquipmentTypeAirEnum.EXTRACTOR_DISPLAY_CASE, EquipmentTypeAirEnum.EXTRACTION_CABIN];
        const biologicalSecurityTypeII = [EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA, EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB];

        if (this.data.essay.idEssayType === EssayAirTypeEnum.RECOVERY_ESSAY && criteriasEmpty) {
          crit = crits.find(c => c.id === CriteriaTypeEnum.LOWER_THAN);
        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.WASTE_OF_LOAD && criteriasEmpty) {
          crit = crits.find(c => c.id === CriteriaTypeEnum.INFORMATIVE);
        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.HEPA_FILTER_INTEGRITY && criteriasEmpty) {
          crit = crits.find(c => c.id === CriteriaTypeEnum.LOWER_EQUAL);
        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED && criteriasEmpty) {
          const idTypeRange = CriteriaTypeEnum.RANGE;
          const idTypeInformative = CriteriaTypeEnum.INFORMATIVE;
          const idTypeGreaterEq = CriteriaTypeEnum.GREATER_EQUAL;

          if (extractions.includes(this.data.idEquipment)) {
            const crit1 = crits.find(c => c.id === idTypeGreaterEq);

            this.addCriteriaCharge(crit1);

            this.data.essay.criterias.find(c => c.idType === idTypeGreaterEq).customValue1 = SpeedTypeCriteria.FRONTAL_SPEED;
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.AIR_DRIVE_UNIT) {
            crit = crits.find(c => c.id === CriteriaTypeEnum.INFORMATIVE);
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.AIR_EXTRACTION_UNIT) {
            crit = crits.find(c => c.id === CriteriaTypeEnum.INFORMATIVE);
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.CARVING_TABLE) {
            crit = crits.find(c => c.id === CriteriaTypeEnum.GREATER_EQUAL);
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.PROTECTION_HEAVY_CABIN) {
            const crit1 = crits.find(c => c.id === idTypeRange);
            const crit2 = crits.find(c => c.id === idTypeInformative);

            this.addCriteriaCharge(crit1);
            this.addCriteriaCharge(crit2);

            this.data.essay.criterias.find(c => c.idType === idTypeRange).customValue1 = SpeedTypeCriteria.AVERAGE_SPEED;
            this.data.essay.criterias.find(c => c.idType === idTypeInformative).customValue1 = SpeedTypeCriteria.EXTRACTION_SPEED;
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_I) {
            const crit1 = crits.find(c => c.id === idTypeRange);

            this.addCriteriaCharge(crit1);

            this.data.essay.criterias.find(c => c.idType === idTypeRange).customValue1 = SpeedTypeCriteria.FRONTAL_SPEED;
          } else if (biologicalSecurityTypeII.includes(this.data.idEquipment)) {
            const crit1 = crits.find(c => c.id === idTypeRange);
            const crit2 = crits.find(c => c.id === idTypeGreaterEq);

            this.addCriteriaCharge(crit1);
            this.addCriteriaCharge(crit2);

            this.data.essay.criterias.find(c => c.idType === idTypeRange).customValue1 = SpeedTypeCriteria.AVERAGE_SPEED;
            this.data.essay.criterias.find(c => c.idType === idTypeGreaterEq).customValue1 = SpeedTypeCriteria.FRONTAL_SPEED;
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_III) {
            const crit1 = crits.find(c => c.id === idTypeGreaterEq);

            this.addCriteriaCharge(crit1);

            this.data.essay.criterias.find(c => c.idType === idTypeGreaterEq).customValue1 = SpeedTypeCriteria.GLOVE_SPEED;
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.AISLATOR ||
            this.data.idEquipment === EquipmentTypeAirEnum.CELL ||
            this.data.idEquipment === EquipmentTypeAirEnum.TRANSFER) {
            // Vacío queriendo
          } else if (this.data.idEquipment === EquipmentTypeAirEnum.FFU) {
            const crit1 = crits.find(c => c.id === idTypeInformative);

            this.addCriteriaCharge(crit1);

            this.data.essay.criterias.find(c => c.idType === idTypeInformative).customValue1 = SpeedTypeCriteria.AVERAGE_SPEED;
          } else {
            crit = crits.find(c => c.id === CriteriaTypeEnum.RANGE);
          }

        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.INDUCTION_ESSAY && criteriasEmpty) {
          crit = crits.find(c => c.id === CriteriaTypeEnum.NO_LEAKS);
        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.PARALLELISM_TEST && criteriasEmpty) {
          crit = crits.find(c => c.id === CriteriaTypeEnum.LOWER_THAN);
        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.HYDROCARBON_LEVEL && criteriasEmpty) {
          const c = new Criteria();

          c.idType = CriteriaTypeEnum.CLASS_HYDROCARBURE as number;
          c.customValue1 = 'class2Hydrocarbure';

          this.data.essay.criterias.push(c);
        } else if (this.data.essay.idEssayType === EssayAirTypeEnum.DEW_POINT && criteriasEmpty) {
          const c = new Criteria();

          c.idType = CriteriaTypeEnum.DEW_POINT as number;
          c.customValue1 = 'tdClass3';

          this.data.essay.criterias.push(c);
        }

        if (crit != null) {
          this.addCriteriaCharge(crit);

          const critFound = this.data.essay.criterias.find(c => c.idType === crit.id);

          if (critFound.customValue1 == null && this.data.essay.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
            critFound.customValue1 = SpeedTypeCriteria.AVERAGE_SPEED;
          }
        }
      });

      if (isLoading) {
        this.essayService.findFieldsById(config.id).pipe(takeUntil(this.destroy$)).subscribe((fields: Field[]) => {
          fields = fields.map(i => FieldUtils.objectToField(i));

          fields.forEach(f => {
            const originalField = this.data.essay.essayValues?.find(f1 => f1.idField === f.idField);

            if (originalField != null) {
              f.id = originalField.id;
              f._value = originalField._value;
              f.value = originalField.value as string;
            }
          });

          this.data.essay.essayValues = fields;
        });
      } else {

        this.data.essay.criterias = [];
        this.data.essay.essayValues = [];

        if (idType === EssayAirTypeEnum.UV_INTENSITY && this.data.surfaceRoom === null) {
          if (!this.data.accesories.find(a => a.idType === AccessoryEquipmentEnum.UV_LAMP)) {
            errors.push(this.translate.instant('accessoryType.uvLamp.not.present'));
            this.data.essay.idEssayType = null;
          }
        }

        if (idType === EssayAirTypeEnum.REN_FLOW_AND_RATE && ArrayUtils.isEmpty(this.data.filters)) {
          errors.push(this.translate.instant('accessoryType.flowFilter.not.present'));
          this.data.essay.idEssayType = null;
        }

        if (idType === EssayAirTypeEnum.HEPA_FILTER_INTEGRITY) {
          hasHepa = this.data.filters.some(f => f.idTypeFilter !== null && this.hepafilters.find(hf => hf === f.idTypeFilter));

          if (hasHepa === false) {
            errors.push(this.translate.instant('accessoryType.filterHEPA.not.present'));
            this.data.essay.idEssayType = null;
          }
        }

        if (idType === EssayAirTypeEnum.FILTER_SATURATION) {
          hasActiveCarbon = this.data.filters.some(f => f.idTypeFilter !== null && f.idTypeFilter === FilterAirEnum.ACTIVATED_CARBON);

          if (hasActiveCarbon === false) {
            errors.push(this.translate.instant('accessoryType.activatedCarbon.not.present'));
            this.data.essay.idEssayType = null;
          }
        }

        if (errors.length !== 0) {
          this.snackBarService.sendError(errors.join('\n'));
          return;
        } else {
          this.essayService.findFieldsById(config.id).pipe(takeUntil(this.destroy$)).subscribe((fields: Field[]) => {
            this.data.essay.essayValues = fields.map(i => FieldUtils.objectToField(i));
            this.data.essay.essayValues.forEach(i => i.id = null);

            if (!this.data.isEquipment) {
              const idsSmoke = [EssayAirTypeEnum.SMOKE_ESSAY_REPOSE, EssayAirTypeEnum.SMOKE_ESSAY_WORKING];

              if (idsSmoke.includes(idType)) {
                const fieldInfoEvaluate = this.data.essay.essayValues.find(f => f.idField === FieldEnum.INFO_EVALUATE);

                if (fieldInfoEvaluate != null) {
                  fieldInfoEvaluate.value = 'others';
                  fieldInfoEvaluate._value = 'others';
                }
              }

              if (this.data.isExecution) {
                const fieldOther = this.data.essay.essayValues.find(f => f.idField === FieldEnum.OTHER_INFO);

                if (fieldOther) {
                  fieldOther.required = true;
                }
              }

            }

            if (this.data.isExecution) {
              const fieldVolume = this.data.essay.essayValues.find(f => f.idField === FieldEnum.VOLUME);

              if (fieldVolume) {
                fieldVolume.required = true;
              }
            }

            const idsRecoveryMicro = [EssayAirTypeEnum.RECOVERY_ESSAY, EssayAirTypeEnum.MICROBIO_ESSAY];
            const idsParticleCount = [EssayAirTypeEnum.PARTICLE_COUNT_REPOSE, EssayAirTypeEnum.PARTICLE_COUNT_WORKING,
               EssayAirTypeEnum.ANEXOI_COUNT_REPOSE, EssayAirTypeEnum.ANEXOI_COUNT_WORKING];

            this.data.essay.essayValues.forEach(field => {
              if (field.value === '' && this.data.volumeRoom != null) {
                if (field.idField === FieldEnum.VOLUME && this.data.essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
                  field.value = NumberUtils.round(this.data.volumeRoom, 3);
                }

                if (field.idField === FieldEnum.POINTS && StringUtils.isEmpty(field.value as string)) {
                  if (idsRecoveryMicro.includes(this.data.essay.idEssayType)) {
                    field.value = 1;
                  } else if (idsParticleCount.includes(this.data.essay.idEssayType)) {
                    if (this.data.surfaceRoom === null) {
                      field.value = this.calculatePointsImpulsionSurface();
                    } else {
                      field.value = this.calculatePoints(this.data.surfaceRoom);
                    }
                  }
                }

                if (field.idField === FieldEnum.CAUDAL && this.data.essay.idEssayType === EssayAirTypeEnum.MICROBIO_ESSAY) {
                  field.value = 100;
                }
              } else if (StringUtils.isEmpty(field.value as string) && this.data.surfaceRoom == null) {

                if (idsParticleCount.includes(this.data.essay.idEssayType)) {
                  if (this.data.surfaceRoom === null) {
                    field.value = this.calculatePointsImpulsionSurface();
                  } else {
                    field.value = this.calculatePoints(this.data.surfaceRoom);
                  }
                }
              }
            });
          });
        }
      }
    });
  }

  showNewCriteria(): boolean {
    return !ArrayUtils.isEmpty(this.criteriaTypes);
  }

  openNewCriteria(): void {
    const essayValue = this.data.essay.criterias;

    let criteriaRemove = [];

    try {
      criteriaRemove = essayValue.map(crit => crit.idType);
    } catch (e) { }

    const dialogRef = this.dialog.open(ProtocolEditEssayCriteriaAirComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: new Criteria(),
        idEssayType: this.data.essay.idEssayType,
        idEquipmentType: this.data.idEquipment,
        isEdit: false,
        criteriasRemove: criteriaRemove,
        entityType: this.data.entityType
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: Criteria) => {

      if (res != null) {
        this.data.essay.criterias.push(res);
        this.updateTables();
      }
    });
  }

  editCriteria(index: number): void {
    const essayValue = this.data.essay.criterias;

    let criteriaRemove = [];

    const criteria = this.data.essay.criterias[index];

    try {
      criteriaRemove = essayValue.map(crit => crit.idType).filter(crit => crit !== criteria.idType);
    } catch (e) { }

    const dialogRef = this.dialog.open(ProtocolEditEssayCriteriaAirComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: _.cloneDeep(criteria),
        idEssayType: this.data.essay.idEssayType,
        idEquipmentType: this.data.idEquipment,
        isEdit: false,
        criteriasRemove: criteriaRemove,
        entityType: this.data.entityType
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: Criteria) => {

      if (res != null) {
        this.data.essay.criterias[index] = res;
        this.updateTables();
      }
    });
  }

  removeCriteria(criteriaIndex: number): void {
    this.data.essay.criterias.splice(criteriaIndex, 1);

    this.updateTables();
  }

  isSelectedMulti(valuesStr: string | string[], valueCheck: string): boolean {
    if (valuesStr == null || valueCheck == null) {
      return false;
    }

    let values = [];
    if (typeof valuesStr === 'string') {
      values = valuesStr.split(';');
    } else {
      values = valuesStr;
    }

    return values.includes(valueCheck);
  }

  showCriteria(crit: Criteria): string {
    return EssayUtils.getCriteriaToShowAir(crit, this.data.essay.idEssayType, this.translate);
  }

  updateTables(): void {
    this.criteriaTable.forEach(i => i.renderRows());
  }

  onOkClick(): void {
    const essay = this.data.essay;
    this.spinnerService.show();

    const errors = [];

    if (essay.idEssayType == null) {
      errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.type'));
    } else {
      const valuesErr = this.checkValues(essay);

      if (!ArrayUtils.isEmpty(valuesErr)) {
        valuesErr.forEach(i => errors.push(i));
      }
    }

    if (this.showNewCriteria() && ArrayUtils.isEmpty(essay.criterias)) {
      errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.anyCriteria.noVariable'));
    }

    this.spinnerService.hide();

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      return;
    }
    /* if (essay.id == null && essay.idOffline == null) {
      essay.idOffline = uuid();
    } */

    this.dialogRef.close(essay);
  }

  delete(): void {
    const message = this.translate.instant('protocolEdit.dialog.essay.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        this.dialogRef.close('delete');
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public setValue(item: Field, index: number, value: string | MatCheckboxChange | MatSelectionListChange | MatRadioChange): void {
    const tab = this.data.essay;

    const field = tab.essayValues.find(i => i.idField === item.idField);
    const fieldIndex = tab.essayValues.findIndex(i => i.idField === item.idField);

    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map((i: MatListOption) => i.value as string).join(';');
    } else if (value instanceof MatRadioChange) {
      value = value.source.value as string;
    }

    field.setValue(index, value);

    tab.essayValues.splice(fieldIndex, 1, field);
  }

  calculatePoints(volume: number): number {
    let points = 0;

    if (volume <= 2) {
      points = 1;
    } else if (volume <= 4) {
      points = 2;
    } else if (volume <= 6) {
      points = 3;
    } else if (volume <= 8) {
      points = 4;
    } else if (volume <= 10) {
      points = 5;
    } else if (volume <= 24) {
      points = 6;
    } else if (volume <= 28) {
      points = 7;
    } else if (volume <= 32) {
      points = 8;
    } else if (volume <= 36) {
      points = 9;
    } else if (volume <= 52) {
      points = 10;
    } else if (volume <= 56) {
      points = 11;
    } else if (volume <= 64) {
      points = 12;
    } else if (volume <= 68) {
      points = 13;
    } else if (volume <= 72) {
      points = 14;
    } else if (volume <= 76) {
      points = 15;
    } else if (volume <= 104) {
      points = 16;
    } else if (volume <= 108) {
      points = 17;
    } else if (volume <= 116) {
      points = 18;
    } else if (volume <= 148) {
      points = 19;
    } else if (volume <= 156) {
      points = 20;
    } else if (volume <= 192) {
      points = 21;
    } else if (volume <= 232) {
      points = 22;
    } else if (volume <= 276) {
      points = 23;
    } else if (volume <= 352) {
      points = 24;
    } else if (volume <= 436) {
      points = 25;
    } else if (volume <= 636) {
      points = 26;
    } else if (volume <= 1000) {
      points = 27;
    }

    return points;
  }

  private checkValues(item: EssayProtocolAir): string[] {
    const errors: string[] = [];

    item.essayValues.forEach(field => {
      if (field.required) {
        const valid = FieldUtils.validateField(field);

        if (!valid) {
          const fieldName = this.translate.instant('protocolEdit.dialog.essay.' + field.translationField + '.label') as string;
          errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.field.noVariable', { fieldName }) as string);
        }
      }
    });

    const othersFields = [FieldEnum.OTHER_INFO];
    const othersField = item.essayValues.find(f => othersFields.includes(f.idField));

    if (othersField != null) {
      const infoEvaluateField = item.essayValues.find(f => f.idField === FieldEnum.INFO_EVALUATE);

      if (infoEvaluateField != null && (infoEvaluateField.value as string[]).includes('others')
        && StringUtils.isEmpty(othersField.value as string)) {
        const fieldName = this.translate.instant('protocolEdit.dialog.essay.' + othersField.translationField + '.label') as string;
        errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.field.noVariable', { fieldName }) as string);
      }
    }

    return errors;
  }

  private addCriteriaCharge(criteriaType: CriteriaType): void {
    if (criteriaType == null) {
      return;
    }

    const criteria = new Criteria();
    criteria.idType = criteriaType.id;
    criteria.criteriaValue1 = criteriaType.defaultValue1;
    criteria.criteriaValue2 = criteriaType.defaultValue2;
    criteria.criteriaTranslation = criteriaType.translation;
    this.data.essay.criterias.push(criteria);

    this.updateTables();
  }

  private calculatePointsImpulsionSurface(): number {

    let surface = 0;
    let radio = 0;

    this.data.filters.forEach(f => {
      if (f.idTypeFilter !== null && f.idTypeFilter === FilterAirEnum.HEPA_IMPULSION) {
        if (f.length > 0) {
          surface += NumberUtils.round(f.length / 1000, 2) * NumberUtils.round(f.width / 1000, 2);

        } else {
          radio = f.diameter / 2000;
          surface += NumberUtils.round(Math.PI * Math.pow(radio, 2), 2);
        }
      }
    });

    return this.calculatePoints(surface);
  }
}
