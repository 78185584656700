<form>

  <mat-card appearance="outlined" class="card">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.equipment.data.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.name.label' | translate }}</mat-label>
            <input matInput name="nameEq" [(ngModel)]="equipment.name" required>
            <mat-error>
              {{ 'internalEquipmentEdit.general.name.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.group.label' | translate }}</mat-label>
            <input matInput name="group" [(ngModel)]="equipment.group">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="!isInternal"></div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.equipment.label'  | translate }}</mat-label>
            <input matInput name="equipment" [(ngModel)]="equipment.equipment" required>
            <mat-error>
              {{ 'internalEquipmentEdit.general.equipment.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.maker.label' | translate }}</mat-label>
            <input matInput name="maker" [(ngModel)]="equipment.maker" required>
            <mat-error>
              {{ 'internalEquipmentEdit.general.maker.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.model.label' | translate }}</mat-label>
            <input matInput name="model" [(ngModel)]="equipment.model" required>
            <mat-error>
              {{ 'internalEquipmentEdit.general.model.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.serialNum.label' | translate }}</mat-label>
            <input matInput name="serialNum" [(ngModel)]="equipment.serialNum" required>
            <mat-error>
              {{ 'internalEquipmentEdit.general.serialNum.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isExternal">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'internalEquipmentEdit.general.client.label' | translate }}</mat-label>
            <mat-select #clientSelect name="idClient" [(ngModel)]="equipment.idClient" [required]="isExternal">
              <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
              <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                {{client.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          {{ 'internalEquipmentEdit.general.appTypes.label' | translate }}:
          <mat-select multiple [(ngModel)]="equipment.idsApps" name="idsApps">
            <mat-option *ngFor="let item of appTypes" checkboxPosition="before" [value]="item.id">
              {{ item.name | translate }}
            </mat-option>
          </mat-select>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          {{ 'internalEquipmentEdit.general.variables.label' | translate }}:
          <mat-select multiple [(ngModel)]="equipment.idsVariables" name="idsVariables">
            <mat-option *ngFor="let item of variables" checkboxPosition="before" [value]="item.id">
              {{ 'variable.' + item.translation | translate }}
            </mat-option>
          </mat-select>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          {{ 'internalEquipmentEdit.general.folders.label' | translate }}:
          <mat-select multiple [(ngModel)]="equipment.idsFolder" name="idsFolder">
            <mat-option *ngFor="let item of folders" checkboxPosition="before" [value]="item.id">
              {{ getFolderName(item) }}
            </mat-option>
          </mat-select>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isManager && !pendingReview">
          <div fxFlex="60" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ 'internalEquipmentEdit.general.resposible.equipment.label' | translate }}</mat-label>
              <mat-select #responsableSelect name="idResponsibleEquipment" [(ngModel)]="equipment.responsibleEquipment">
                <mat-select-filter *ngIf="responsableSelect.focused" [displayMember]="'fullName'" [array]="users"
                  (filteredReturn)="usersFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                  [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                <mat-option *ngFor="let user of usersFiltered" [value]="user.username">
                  {{user.fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="isManager && equipment.responsibleEquipment">
            <mat-checkbox [value]="equipment.notificationResposibleEq" [checked]="equipment.notificationResposibleEq"
              (change)="onChangeNotificationResponsible($event)">
              {{ 'internalEquipmentEdit.general.notification.resposible.equipment.label' | translate }}
            </mat-checkbox>
          </div>
        </div>

        
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="card" *ngIf="showCalibrationPlan()">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.other.data.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap" [hidden]="equipment.customFields == null">
        <div fxFlex="50" fxFlex.lt-md="100%" *ngFor="let field of equipment.customFields; let indexField = index">

          <!-- STRING -->
          <div *ngIf="field.idType == 1">
            <mat-form-field class="campoFormulario" appearance="fill">
              <mat-label>{{ field.name }}</mat-label>
              <input matInput [name]="field.name" [(ngModel)]="field.value"
                [errorStateMatcher]="matcher" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>

          <!-- NUMÉRICO -->
          <div *ngIf="field.idType == 2">
            <mat-form-field class="campoFormulario" appearance="fill">
              <mat-label>{{ field.name }}</mat-label>
              <input matInput [name]="field.name" [(ngModel)]="field.value"
                [ngModelOptions]="{standalone: true}" [errorStateMatcher]="matcher"
                (ngModelChange)="setValue(field, -1, $event)" type="number" [required]="field.required">
            </mat-form-field>
          </div>

          <!-- TOLERANCIA -->
          <div *ngIf="field.idType == 3">
            <div fxFlex="40%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario" appearance="fill">
                <mat-label>{{ field.name }}</mat-label>
                <input matInput [name]="field.name" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="setValue(field, 0, $event)" [errorStateMatcher]="matcher" type="number">
              </mat-form-field>
            </div>
            <div fxFlex="40%" fxFlex.lt-md="100%">
              ±
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}</mat-label>
                <input matInput
                  [name]="field.name + 'Tolerance'" (ngModelChange)="setValue(field, 1, $event)"
                  [errorStateMatcher]="matcher" type="number" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </div>
          </div>

          <!-- BOOLEAN -->
          <div *ngIf="field.idType == 8">
            <mat-checkbox [value]="field.value" [checked]="field.value == 'true'"
              (change)="setValue(field, -1, $event)">
              {{ field.name }}
            </mat-checkbox>
          </div>

          <!-- SELECT ONE -->
          <div *ngIf="field.idType == 6">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ field.name }}</mat-label>
              <mat-select [name]="'idSelectCustom'+field.id" [(ngModel)]="field.value">
                <mat-option *ngFor="let sel of field.valueSelect" [value]="sel.value">
                  {{sel.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="equipment.customFields?.length == 0"
          style="margin-top: 2em;margin-bottom: 2em;">
          {{ 'protocolEdit.dialog.essay.fields.empty' | translate }}
        </div>
      </div>
    </mat-card-content>

  </mat-card>

  <mat-card appearance="outlined" class="card" *ngIf="isEdit || isInternal">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.calibrationData.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.equipmentRange.label' | translate }}</mat-label>
            <input matInput name="equipmentRange" [(ngModel)]="equipment.equipmentRange"
              [disabled]="!hasCalibrationPlan()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.useRange.label'| translate }}</mat-label>
            <input matInput name="useRange" [(ngModel)]="equipment.useRange"
              [disabled]="!hasCalibrationPlan()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.resolution.label'| translate }}</mat-label>
            <input matInput name="resolution" [(ngModel)]="equipment.resolution"
              [disabled]="!hasCalibrationPlan()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.tolerance.label' | translate }}</mat-label>
            <input matInput name="tolerance" [(ngModel)]="equipment.tolerance"
              [disabled]="!hasCalibrationPlan()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.calibrationFrequency.label' | translate }}</mat-label>
            <input type="number" matInput name="calibrationFrequency" [(ngModel)]="equipment.calibrationFrequency"
              [disabled]="!hasCalibrationPlan()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
          <mat-form-field style="width: 95%;" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.acquisitionDate.label' | translate }}</mat-label>
            <input matInput [matDatepicker]="acquisitionDate" name="acquisitionDate"
              [(ngModel)]="equipment.acquisitionDate" (change)="saveDate('acquisitionDate', $event)"
              [disabled]="!hasCalibrationPlan()">
            <mat-datepicker-toggle matSuffix [for]="acquisitionDate"></mat-datepicker-toggle>
            <mat-datepicker #acquisitionDate >
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.calibrationResponsible.label' | translate }}</mat-label>
            <mat-select name="calibrationResponsibles" [(ngModel)]="equipment.responsible" [required]="isInternal"
              (selectionChange)="onChangeResponsibleCheck($event)" [disabled]="!hasCalibrationPlan()">
              <mat-option *ngFor="let item of calibrationResponsibles" [value]="item.id">
                {{ 'calibrationResponsibleType.' + item.translation | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
            [matTooltipDisabled]="hasCalibrationPlan()" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.supplier.label' | translate }}</mat-label>
            <input matInput name="supplier" [(ngModel)]="equipment.supplier" [disabled]="disablesSupplier()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.fee.label' | translate }}</mat-label>
            <input matInput name="fee" [(ngModel)]="equipment.fee" type="number" min="0"
              matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}"
              [matTooltipDisabled]="hasCalibrationPlan()" [disabled]="!hasCalibrationPlan()">
          </mat-form-field>
        </div>

      </div>

    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="card" *ngIf="isEdit || isInternal">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.calibration.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
          <mat-form-field style="width: 95%;" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.calibrateDate.label' | translate }}</mat-label>
            <input matInput [matDatepicker]="calibrateDate" name="calibrateDate" [(ngModel)]="equipment.calibrateDate"
              (change)="saveDate('calibrateDate', $event)">
            <mat-datepicker-toggle matSuffix [for]="calibrateDate"></mat-datepicker-toggle>
            <mat-datepicker #calibrateDate >
            </mat-datepicker>
            <mat-error>
              {{ 'internalEquipmentEdit.general.calibrateDate.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
          <mat-form-field style="width: 95%;" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.expirationDate.label' | translate }}</mat-label>
            <input matInput [matDatepicker]="expirationDate" name="expirationDate"
              [(ngModel)]="equipment.expirationDate" (change)="saveDate('expirationDate', $event)"
              (dateChange)="onChangeDateExpanded();">
            <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
            <mat-datepicker #expirationDate >
            </mat-datepicker>
            <mat-error>
              {{ 'internalEquipmentEdit.general.expirationDate.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isEdit">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.certificateNum.label' | translate }}</mat-label>
            <input matInput placeholder="{{ 'internalEquipmentEdit.general.certificateNum.label' | translate }}"
              name="certificateNum" [(ngModel)]="equipment.certificateNum">
            <mat-error>
              {{ 'internalEquipmentEdit.general.certificateNum.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="isInternal">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'internalEquipmentEdit.general.status.label' | translate }}</mat-label>
            <mat-select #statusSelect name="idStatus" [(ngModel)]="equipment.idStatus" [required]="isInternal">
              <mat-option *ngFor="let item of statuses" [value]="item.id">
                {{ 'internalEquipmentStatus.' + item.translation | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'internalEquipmentEdit.general.status.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-checkbox [(ngModel)]="extendedDay" (change)="onChangeDateExpandedCheck($event)"
            [ngModelOptions]="{standalone: true}">
            {{ 'internalEquipmentEdit.general.extendedCalibration.label' | translate }}
          </mat-checkbox>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="extendedDay || equipment.expansionDay">
          <mat-form-field class="campoFormulario" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.extendedDayCalibration.label' | translate }}</mat-label>
            <input type="number" min="0" matInput name="extendedDayCalibration" [(ngModel)]="equipment.expansionDay"
              (blur)="onChangeDateExpanded()">
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="equipment.extendedExpirationDate">
          <mat-form-field class="campoFormulario" matTooltip="{{'calibrationPlan.contactUs.tooltip'| translate }}" appearance="fill">
            <mat-label>{{ 'internalEquipmentEdit.general.extendedDateCalibration.label' | translate }}</mat-label>
            <input matInput name="extendedDateCalibration" [ngModel]="equipment.extendedExpirationDate | fixDate: 2"
              placeholder="{{ 'internalEquipmentEdit.general.extendedDateCalibration.label' | translate }}"
              [disabled]="true">
          </mat-form-field>

        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="card">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.calibrations.title' | translate }}

        <button mat-icon-button color="accent" (click)="newExternalCalibration()"
          matTooltip="{{ 'internalEquipmentEdit.calibrations.body.title.create' | translate }}"
          *ngIf="equipment.id != null && canSave()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>

      <div *ngIf="calibrations?.length == 0">
        {{ 'common.noResults' | translate }}
      </div>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let calibration of calibrations; let indexCalibration = index"
          (opened)="setCalibrationOpened(indexCalibration, true)" [expanded]="isCalibrationOpened(indexCalibration)"
          (closed)="setCalibrationOpened(indexCalibration, false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon color="warn" *ngIf="isNotValid(calibration)">close</mat-icon>
              {{ 'internalEquipmentEdit.calibrations.title.title' | translate : { date: (calibration.calibrationDate |
              fixDate) } }}
              ({{calibration.internal ? ('common.internal' | translate) : ('common.external' | translate)}})

              <div *ngIf="isCalibrationOpened(indexCalibration)">
                <button mat-icon-button (click)="editExternalCalibration(calibration.id)" color="accent"
                  matTooltip="{{ 'internalEquipmentEdit.calibrations.body.title.edit' | translate }}"
                  class="centerButton" *ngIf="!calibration.internal">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <button mat-icon-button (click)="goToCalibration(calibration.id)" color="accent"
                  matTooltip="{{ 'internalEquipmentEdit.calibrations.body.title.edit' | translate }}"
                  class="centerButton" *ngIf="calibration.internal">
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteExternalCalibration(calibration.id)" color="accent"
                  matTooltip="{{ 'internalEquipmentEdit.calibrations.body.title.delete' | translate }}"
                  class="centerButton" *ngIf="!calibration.internal">
                  <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button *ngIf="calibration.idDocument != null || calibration.idCertificate != null"
                  (click)="downloadCertificateEquipment(indexCalibration)" color="accent"
                  matTooltip="{{ 'button.download-certificate' | translate }}" class="centerButton">
                  <mat-icon>cloud_download</mat-icon>
                </button>
                <button mat-icon-button
                  *ngIf="calibration.internal && calibration.idDocument == null && calibration.idCertificate == null"
                  (click)="downloadCertificateLastCertificateCalibration(indexCalibration)" color="accent"
                  matTooltip="{{ 'button.download-certificate' | translate }}" class="centerButton">
                  <mat-icon>cloud_download</mat-icon>
                </button>

                <button mat-icon-button (click)="uploadCertificateCalibration.click()" color="accent"
                  *ngIf="isInternal && equipment.id != null" matTooltip="{{ 'button.upload-certificate' | translate }}"
                  class="centerButton">
                  <mat-icon>cloud_upload</mat-icon>
                </button>

                <button mat-icon-button (click)="deleteCertificateToCalibration(indexCalibration)" color="accent"
                  *ngIf="isInternal && equipment.id != null && (calibration.idCertificate != null || calibration.idDocument != null)"
                  matTooltip="{{ 'button.delete-certificate' | translate }}" class="centerButton">
                  <mat-icon>cloud_off</mat-icon>
                </button>
              </div>

              <input accept="application/pdf" hidden type="file"
                (change)="uploadCertificateToCalibration(indexCalibration, $event)" #uploadCertificateCalibration>

            </mat-panel-title>
            <mat-panel-description *ngIf="calibration?.regUser">
              {{ 'internalEquipmentEdit.calibrations.title.subtitle' | translate: { certificateNum:
              calibration.certificateNum, regUser: calibration.regUser} }}
            </mat-panel-description>

            <mat-panel-description *ngIf="!calibration?.regUser">
              {{ 'internalEquipmentEdit.calibrations.new.title.subtitle' | translate: { certificateNum:
              calibration.certificateNum} }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div fxLayout="column">

            <span fxFlex>{{ 'internalEquipmentEdit.calibrations.body.calibrationDate' | translate }}:
              {{ calibration.calibrationDate | fixDate }}</span>
            <span fxFlex>{{ 'internalEquipmentEdit.calibrations.body.expirationDate' | translate }}:
              {{ calibration.expirationDate | fixDate }}</span>
            <span fxFlex>{{ 'internalEquipmentEdit.calibrations.body.certificateNum' | translate }}:
              {{ calibration.certificateNum }}</span>

          </div>

          <span *ngIf="calibration.internal">
            <br />
          </span>

          <div fxLayout="column">
            <span fxFlex *ngIf="calibration.internal">
              {{ 'internalEquipmentEdit.calibrations.body.temperature' | translate }}:
              {{ calibration.temperature != null ? calibration.temperature : ('common.notApplicable' | translate) }}
            </span>
            <span fxFlex *ngIf="calibration.internal">
              {{ 'internalEquipmentEdit.calibrations.body.humidity' | translate }}:
              {{ calibration.humidity != null ? calibration.humidity : ('common.notApplicable' | translate) }}
            </span>
            <span fxFlex *ngIf="calibration.internal">
              {{ 'internalEquipmentEdit.calibrations.body.pressure' | translate }}:
              {{ calibration.pressure != null ? calibration.pressure : ('common.notApplicable' | translate) }}
            </span>
          </div>

          <div fxLayout="column">

            <div *ngFor="let variable of calibration.variables">
              <br />
              <strong>
                {{ 'internalEquipmentEdit.calibrations.body.results.title' | translate: { variable: ('variable.' +
                variable.variableTranslation | translate), measurementUnit: variable.unitName } }}
              </strong>

              <div *ngIf="!calibration.multichannel">
                <table #asLeftTable mat-table
                  [dataSource]="calibration.internal ? getAsLeftList(variable) : variable.values" style="width: 100%;">

                  <!-- Number Column -->
                  <ng-container matColumnDef="number">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                  </ng-container>

                  <!-- Point Column -->
                  <ng-container matColumnDef="point">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                  </ng-container>

                  <!-- Pattern Column -->
                  <ng-container matColumnDef="pattern">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.pattern.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ calibration.internal ? element.pattern : null }} </td>
                  </ng-container>

                  <!-- Average Column -->
                  <ng-container matColumnDef="average">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.average">
                        {{ calibration.internal ?
                        ( element.average | number:'1.' + (getDecimalsResolution(variable.resolution) + 1) + '-' +
                        (getDecimalsResolution(variable.resolution) + 1) ) :
                        (element.average) }}
                      </span>
                    </td>
                  </ng-container>

                  <!-- Correction Column -->
                  <ng-container matColumnDef="correction">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      {{ calibration.internal ?
                      ( element.correction | number:'1.' + getDecimalsPattern(element.pattern) + '-' +
                      getDecimalsPattern(element.pattern) ) :
                      (element.correction) }}
                    </td>
                  </ng-container>

                  <!-- U Column -->
                  <ng-container matColumnDef="U">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      {{ calibration.internal ?
                      ( element.U | number:'1.' + getDecimalsUncertainty(element.uncertaintyResolution) + '-' +
                      getDecimalsUncertainty(element.uncertaintyResolution) ) :
                      (element.uncertainty) }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="(calibration.internal ? asLeftColsInt : asLeftColsExt)"></tr>
                  <tr mat-row *matRowDef="let row; columns: (calibration.internal ? asLeftColsInt : asLeftColsExt);">
                  </tr>
                </table>

              </div>
              <div *ngIf="calibration.multichannel">
                <mat-card appearance="outlined">
                  <mat-card-content>

                    <mat-tab-group style="width: 100%;">
  
                      <mat-tab *ngFor="let multichannel of variable.valuesMultichannel; let indexChannel = index"
                        style="min-height: 50vh;" label="{{ multichannel.description }}">
                        <mat-card appearance="outlined" class="card">
                          <mat-card-content>
                            <div fxLayout="column" class="flexwrap">
                              <div fxFlex>
                                <table #asLeftTableMultiChannel mat-table
                                [dataSource]="getAsLeftListMultichannel(variable, indexChannel)"
                                  style="width: 100%;">
  
                                  <!-- Number Column -->
                                  <ng-container matColumnDef="number">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element; let i = index"> {{ (i + 1) }} </td>
                                  </ng-container>
  
                                  <!-- Point Column -->
                                  <ng-container matColumnDef="point">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.point }} </td>
                                  </ng-container>
  
                                  <!-- Pattern Column -->
                                  <ng-container matColumnDef="pattern">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.pattern.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ element.pattern }} </td>
                                  </ng-container>
  
                                  <!-- Values Column -->
                                  <ng-container matColumnDef="value">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.value.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ joinListValues(indexVariable, element) }}
                                    </td>
                                  </ng-container>
  
                                  <!-- Average Column -->
                                  <ng-container matColumnDef="average">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element">
                                      <span [matTooltip]="element.average">
                                        {{ element.average | number:'1.' + (getDecimalsResolution(variable.resolution) + 1) + '-' +
                                        (getDecimalsResolution(variable.resolution) + 1) }}
                                      </span>
                                    </td>
                                  </ng-container>
  
                                  <!-- Correction Column -->
                                  <ng-container matColumnDef="correction">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element">
                                      <span [matTooltip]="element.correction">
                                        {{ element.correction | number:'1.' + getDecimalsPattern(element.pattern) + '-' +
                                        getDecimalsPattern(element.pattern) }}
                                      </span>
                                    </td>
                                  </ng-container>
  
                                  <!-- U Column -->
                                  <ng-container matColumnDef="U">
                                    <th mat-header-cell *matHeaderCellDef>
                                      {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
                                    <td mat-cell *matCellDef="let element">
                                      <span [matTooltip]="element.U">
                                        {{element.U | number:'1.' + getDecimalsUncertainty(element.uncertaintyResolution) + '-' +
                                        getDecimalsUncertainty(element.uncertaintyResolution)}}
                                      </span>
                                    </td>
                                  </ng-container>
  
                                  <tr mat-header-row *matHeaderRowDef="asLeftColsInt"></tr>
                                  <tr mat-row *matRowDef="let row; columns: asLeftColsInt;"></tr>
                                </table>
  
                              </div>
  
                            </div>
  
                          </mat-card-content>
                        </mat-card>
                      </mat-tab>
                    </mat-tab-group>
                  </mat-card-content>
                </mat-card>
              </div>

            </div>
          </div>

        </mat-expansion-panel>

      </mat-accordion>

    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="card">
    <mat-card-content>
      <mat-toolbar color="secondary">
        {{ 'internalEquipmentEdit.observations.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
        <div fxFlex="100" fxFlex.lt-md="100%">
          <textarea matInput cdkTextareaAutosize placeholder="{{ 'internalEquipmentEdit.general.observations.label' | translate }}" #autosizeObs="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
            [(ngModel)]="equipment.observations" name="observations">
          </textarea>
        </div>

      </div>

    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="card">
    <mat-card-actions>
      <div fxFlex></div>
      <button mat-raised-button color="primary" *ngIf="pendingReview" (click)="reviewEquipment()">
        {{ 'button.approve' | translate }}</button>
      <button mat-raised-button color="primary" *ngIf="pendingReview" (click)="decline()">
        {{ 'button.decline' | translate }}</button>
      <button mat-raised-button color="primary" *ngIf="canSave()" (click)="saveEquipment()">
        {{ 'button.save' | translate }}</button>
      <button mat-raised-button color="accent" *ngIf="equipment.idCertificate != null && !pendingReview"
        (click)="downloadPdf()">
        {{ 'button.download-certificate' | translate }}</button>
      <button mat-raised-button color="accent" (click)="downloadAuditPdf()" *ngIf="equipment.id != null">
        {{ 'button.download-audit' | translate }}</button>
      <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
    </mat-card-actions>
  </mat-card>

</form>