import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { FlexLayoutModule } from 'ng-flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ProtocolQEditComponent } from './protocol-q-edit.component';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { SharedModule } from '../../shared/shared.module';
import { ProtocolEditConfirmSaveComponent } from './protocol-edit-confirm-save/protocol-q-edit-confirm-save.component';
import { ProtocolQEditAuditComponent } from './protocol-q-edit-audit/protocol-q-edit-audit.component';
import { ProtocolQEditTestComponent } from './protocol-q-edit-test/protocol-q-edit-test.component';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-equipment-dialog/protocol-q-edit-equipment-dialog.component';
import { ProtocolQEditSectionTemplateComponent } from './protocol-q-edit-section-template/protocol-q-edit-section-template.component';
import { ProtocolQEditGenerateReportComponent } from './protocol-q-edit-generate-report/protocol-q-edit-generate-report.component';
import { ProtocolQEditDialogManualSignComponent } from './protocol-q-edit-dialog-manual-sign/protocol-q-edit-dialog-manual-sign.component';
import { ProtocolEditTestAttachmentsComponent } from './protocol-edit-test-attachments/protocol-q-edit-test-attachments.component';
import { ProtocolQEditDialogAutomaticSignComponent } from './protocol-q-edit-dialog-automatic-sign/protocol-q-edit-dialog-automatic-sign.component';
import { ProtocolQEditAttachmentsComponent } from './protocol-edit-attachments/protocol-q-edit-attachments.component';
import { ProtocolQEditTestEssayComponent } from './protocol-q-edit-test-essay/protocol-q-edit-test-essay.component';
import { ProtocolQEditTestEssayCriteriaComponent } from './protocol-q-edit-test-essay-criteria/protocol-q-edit-test-essay-criteria.component';
import { ProtocolQEditTestBioindicatorsDetailsComponent } from './protocol-q-edit-test-bioindicators-details/protocol-q-edit-test-bioindicators-details.component';
import { ProtocolQEditDialogSignersComponent } from './protocol-q-edit-dialog-signers/protocol-q-edit-dialog-signers.component';

@NgModule({
  declarations: [
    ProtocolQEditComponent,
    ProtocolEditConfirmSaveComponent,
    ProtocolQEditAuditComponent,
    ProtocolQEditTestComponent,
    ProtocolEditEquipmentDialogComponent,
    ProtocolQEditSectionTemplateComponent,
    ProtocolQEditGenerateReportComponent,
    ProtocolEditTestAttachmentsComponent,
    ProtocolQEditDialogAutomaticSignComponent,
    ProtocolQEditDialogManualSignComponent,
    ProtocolQEditAttachmentsComponent,
    ProtocolQEditTestEssayComponent,
    ProtocolQEditTestEssayCriteriaComponent,
    ProtocolQEditTestBioindicatorsDetailsComponent,
    ProtocolQEditDialogSignersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),

    /* Custom modules */
    AngularMaterialModule,
    DevextremeModule,
    SharedModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule
  ]
})
export class ProtocolQEditModule { }
