<div class="main-container">

    <div style="display: grid;">
      <img src="assets/img/logo-home.png" alt="Logo de Qloud" style="max-width: 30%; margin: 0 auto;">
    </div>
  
    <br /><br />
  
    <mat-card appearance="outlined" class="loginBox">
      <mat-card-content>
        <form [formGroup]="changePasswordForm" class="form">
          <h2>{{ 'createCredentials.title' | translate }}</h2>
          <mat-form-field class="full-width">
            <input matInput placeholder="{{ 'login.username.label' | translate }}" formControlName="username"
              [ngModel]="username">
          </mat-form-field>
          <mat-form-field class="campoFormulario">
            <input matInput type="password" placeholder="{{ 'changePassword.newPassword.label' | translate }}"
              formControlName="newPassword" [(ngModel)]="newPassword" autocomplete="new-password" required
              [errorStateMatcher]="matcher">
            <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
              {{ 'changePassword.newPassword.error.required' | translate }}
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('pattern')">
              {{ 'changePassword.newPassword.error.pattern' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="campoFormulario">
            <input matInput type="password" placeholder="{{ 'changePassword.confirmPassword.label' | translate }}"
              formControlName="confirmPassword" [(ngModel)]="confirmPassword" autocomplete="new-password" required
              [errorStateMatcher]="matcher">
            <mat-error>
              {{ 'changePassword.confirmPassword.error.notMatch' | translate }}
            </mat-error>
          </mat-form-field>
          <p *ngIf="message">
            {{ message }}
          </p>
          <p *ngIf="error" class="error">
            {{ error }}
          </p>
  
        </form>
      </mat-card-content>
  
      <mat-card-actions>
        <button mat-raised-button (click)="changePassword()" color="primary">{{ 'button.send' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  
    <a class="under-card-link" (click)="backLogin()">
      {{ 'button.goBack' | translate }}
    </a>
  
  </div>
  