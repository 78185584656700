import * as _ from 'lodash-es';
import * as moment from 'moment';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DialogDataCopyEssayResult, ExecutionEditCopyEssayComponent } from './execution-edit-copy-essay.component';
import { Essay, EventEssayVariableKeys } from 'src/app/model/essay';
import { EssayUtils, HotColdValueEssay, LethalityValueEssay, MinMaxValueEssay } from 'src/app/utils/essayUtils';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import { VariableTypeEnum, VariableUnit } from 'src/app/model/variable';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { AttachmentType } from 'src/app/model/attachment';
import { BioindicatorConfig } from 'src/app/model/bioindicator';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Criteria } from 'src/app/model/criteria';
import { DateUtils } from 'src/app/utils/dateUtils';
import { Defrost } from 'src/app/model/defrost';
import { DefrostService } from 'src/app/services/defrost.service';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { EssayProtocol } from 'src/app/model/essayProtocol';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { ExecutionEditAttachmentDetailsComponent } from './execution-edit-attachment-details.component';
import { ExecutionEditBioindicatorsDetailsComponent } from './execution-edit-bioindicators-details.component';
import { ExecutionEditBottomSheetUploadComponent } from './execution-edit-bottom-sheet-upload.component';
import { ExecutionEditConfigSensorComponent } from './execution-edit-config-sensor.component';
import { ExecutionEditCriteriaEditComponent } from './execution-edit-criteria-edit.component';
import { ExecutionEditDefrostListComponent } from './execution-edit-defrost-list.component';
import { ExecutionEditDialogCorrectSensorsComponent } from './execution-edit-dialog-correct-sensors.component';
import { ExecutionEditDiscardTimeComponent } from './execution-edit-discard-time.component';
import { ExecutionEditFieldEditComponent } from './execution-edit-field-edit.component';
import { ExecutionEditGraphComponent } from './execution-edit-graph.component';
import { ExecutionEditReasonExpositionTimeComponent } from './execution-edit-reason-exposition-time.component';
import { ExecutionService } from 'src/app/services/execution.service';
import { ExecutionStatus } from 'src/app/model/execution';
import { ExecutionUtils } from 'src/app/utils/executionUtils';
import { Field } from 'src/app/model/field';
import { FixDateTime } from 'src/app/pipes/fixDateTime.pipe';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PhotoUtils } from 'src/app/utils/photoUtils';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { Sensor } from 'src/app/model/sensor';
import { SensorData } from 'src/app/model/sensorData';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-execution-edit-essay',
  templateUrl: './execution-edit-essay.component.html'
})
export class ExecutionEditEssayComponent implements OnDestroy {

  @ViewChild(ExecutionEditGraphComponent) graph: ExecutionEditGraphComponent;
  @ViewChild('uploadExcel') uploadExcelInput: ElementRef;
  @ViewChild('uploadPrimaryFile') uploadPrimaryFileInput: ElementRef;

  @Input() idExecution: number;
  @Input() idStatusExecution: number;

  @Output() fcStartEssayEmitter = new EventEmitter<any>();
  @Output() updateSensorEssayEmitter = new EventEmitter<any>();
  @Output() updateAttachmentListEmitter = new EventEmitter<any>();
  @Output() onIdPeakEmitter = new EventEmitter<any>();
  @Output() reloadPageEmitter = new EventEmitter<any>();
  @Output() essayActiveEmitter = new EventEmitter<any>();
  @Output() essayActiveReasonEmitter = new EventEmitter<any>();

  @ViewChildren('criteriaTable') criteriaTable: QueryList<MatTable<any>>;

  public equipmentAutoComplete: Map<number, InternalEquipment[]>;
  _essays: Essay[];
  variableUnits: VariableUnit[];

  _essayIndex = 0;
  _variableIndex = 0;

  public displayedColsCriteria = ['criteria', 'edit', 'delete'];

  graphMap = new Map<string, SensorData[]>();
  copyGraphDateMap = new Map<EventEssayVariableKeys, boolean>();

  private destroy$ = new Subject<void>();

  constructor(
    private attachmentThermalService: AttachmentThermalService,
    private internalEquipmentService: InternalEquipmentService,
    private defrostService: DefrostService,
    private variableTypeService: VariableTypeService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private imageCompress: NgxImageCompressService,
    public snackBarService: SnackBarService,
    private executionService: ExecutionService,
    private bottomSheet: MatBottomSheet,
    private spinnerService: SpinnerService,
    private fixDateTime: FixDateTime) { }

  get reasonModifyExpositionTime(): string {
    const variableSelected = this._essays[this._essayIndex].essayValues[this._variableIndex];

    if (variableSelected == null) {
      return '';
    }

    return variableSelected.reasonModifyExpositionTime;
  }

  set reasonModifyExpositionTime(reason: string) {
    const variableSelected = this._essays[this._essayIndex].essayValues[this._variableIndex];

    if (variableSelected != null) {
      variableSelected.reasonModifyExpositionTime = reason;
    }
  }

  public set essays(essays: Essay[]) {
    essays.forEach(essay => essay.essayValues.sort((a, b) => a.idVariable - b.idVariable));

    this._essays = essays;

    this.equipmentAutoComplete = new Map<number, InternalEquipment[]>();

    this.reloadGraphMap();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadPage(): void {
    const data = { idExecution: this.idExecution };
    this.reloadPageEmitter.emit(data);

    this.onEssayTabChange(0);
  }

  reloadGraphMap(): void {
    this.graphMap = new Map<string, SensorData[]>();

    this._essays.forEach((essay, essayIndex) => {
      essay.essayValues.forEach((essayValue, variableIndex) => {
        let values: SensorData[] = [];
        essayValue.sensors.filter(sensor => sensor.data != null && sensor.data.length !== 0)
          .forEach(sensor => values = [...values, ...sensor.data]);

        essayValue.sensors.forEach(sensor => {
          this.equipmentAutoComplete.set(sensor.id, []);
        });

        if (values.length > 0) {
          const key = new EventEssayVariableKeys();
          key.essayIndex = essay.id;
          key.variableIndex = essayValue.idVariable;

          this.graphMap.set(key.toString(), values);
        }

        if (variableIndex !== 0) {
          const ek = new EventEssayVariableKeys();
          ek.essayIndex = essayIndex;
          ek.variableIndex = variableIndex;

          this.copyGraphDateMap.set(ek, true);
        }

      });
    });

    this.updateGraphByIndex(this._essayIndex, this._variableIndex);
  }

  lookupEquipment(essayIndex: number, variableIndex: number, sensorIndex: number, idSensor: number, event): void {
    let results = this.getResultsLookup(idSensor);

    const filter = new EquipmentAutocompleteFilter();

    filter.query = event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forExecution = true;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {

      const variableSelected = this._essays[essayIndex].essayValues[variableIndex];

      const alreadySelected = variableSelected.sensors.filter((v, index) => index !== sensorIndex).map(v => v.serialNum);

      item.content = item.content.filter((c: InternalEquipment) => !alreadySelected.includes(c.serialNum));

      results = item.content;
      this.equipmentAutoComplete.set(idSensor, results);
    }, () => {
      results = [];

    });
  }

  getResultsLookup(idSensor: number): InternalEquipment[] {
    return this.equipmentAutoComplete.get(idSensor);
  }

  updateSensorDependency(essayIndex: number, variableIndex: number, sensorIndex: number, field: string): void {
    const essay = this._essays[essayIndex];
    const currentVariable = essay.essayValues[variableIndex];

    essay.essayValues.forEach((essayVal, essayValIndex) => {
      if (essayVal === currentVariable || essayVal.idConfigDependsOn == null || essayVal.idConfigDependsOn !== currentVariable.idConfig) {
        return;
      }

      const sensor = currentVariable.sensors[sensorIndex];
      const sensorDepends = essayVal.sensors[sensorIndex];

      if (sensor == null || sensorDepends == null) {
        return;
      }

      if (field === 'serialNum') {
        sensorDepends.equipmentName = sensor.equipmentName;
        sensorDepends.serialNum = sensor.serialNum;
      } else if (field === 'photo') {
        sensorDepends.idPhoto = sensor.idPhoto;
      } else if (field === 'primary') {
        sensorDepends.idPrimaryDataFile = sensor.idPrimaryDataFile;
      } else if (field === 'config') {
        sensorDepends.evaluate = sensor.evaluate;
        sensorDepends.outer = sensor.outer;
        sensorDepends.evaluateReason = sensor.evaluateReason;
        sensorDepends.location = sensor.location;
        sensorDepends.locationChangeReason = sensor.locationChangeReason;
      }

      this.setSensor(essayIndex, essayValIndex, sensorIndex, sensorDepends);

    });
  }

  onSensorChange(
    essayIndex: number, variableIndex: number, sensorIndex: number, event: any, trigger: MatAutocompleteTrigger, confirmEq = false): void {
    const equipmentSelected = event.option.value as InternalEquipment;

    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    if (!confirmEq) {
      const variableSelected = this._essays[essayIndex].essayValues[variableIndex];

      variableSelected.sensors.forEach((s: Sensor, index: number) => {
        if (index === sensorIndex) {
          return;
        }

        if (s.serialNum === equipmentSelected.serialNum) {

          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              message: this.translate.instant('executionEdit.essays.sensor.form.error.alreadyInUse') as string
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
            if (result === true) {
              this.onSensorChange(essayIndex, variableIndex, sensorIndex, event, trigger, true);
            } else {
              sensor.equipmentName = null;
              sensor.serialNum = null;

              this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);
              this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'serialNum');
            }

            event.stopPropagation();
            trigger.closePanel();
          });

          return;
        }
      });
    }

    sensor.equipmentName = null;
    sensor.serialNum = null;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {

      sensor.equipmentName = null;
      sensor.serialNum = null;

      this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);
      this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'serialNum');

      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').toUpperCase().replace('./', '/');

      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        const sen = this.getSensor(essayIndex, variableIndex, sensorIndex);

        if (result === true) {
          sen.equipmentName = equipmentSelected.name;
          sen.serialNum = equipmentSelected.serialNum;

          this.setSensor(essayIndex, variableIndex, sensorIndex, sen);
          this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'serialNum');
        } else {
          sen.equipmentName = null;
          sen.serialNum = null;

          this.setSensor(essayIndex, variableIndex, sensorIndex, sen);
          this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'serialNum');
        }

        event.stopPropagation();
        trigger.closePanel();
      });
    } else {
      sensor.equipmentName = equipmentSelected.name;
      sensor.serialNum = equipmentSelected.serialNum;

      this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);
      this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'serialNum');
    }
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }

  displayEssay(): boolean {
    if (this._essays == null || ArrayUtils.isEmpty(this._essays)) {
      return false;
    }

    return this._essays[this._essayIndex].active;
  }

  displayCycle(essay: Essay): boolean {
    const emptyList: Field[] = [];
    const fs = emptyList.concat(...essay.essayValues.map(essayVal => essayVal.fields.filter(f => f.idField === FieldEnum.NUM_CYCLES)));

    const values = fs.map((f: Field) => f.value as string);

    return !values.includes('1');
  }

  onEssayActiveChange(essay: Essay, event: MatCheckboxChange): void {
    const value = event.checked;

    essay.active = value;

    const data = {
      essayId: essay.id,
      active: value
    };

    this.essayActiveEmitter.emit(data);
  }

  onEssayActiveReasonChange(essay: Essay, event): void {
    const data = {
      essayId: essay.id,
      reason: essay.reasonActive
    };

    this.essayActiveReasonEmitter.emit(data);
  }

  showSetpoint(essayIndex: number, essayValueIndex: number): boolean {
    return ExecutionUtils.showSetpoint(this._essays, essayIndex, essayValueIndex);
  }

  showRealLoad(essayIndex: number, essayValueIndex: number): boolean {
    return ExecutionUtils.showRealLoad(this._essays, essayIndex, essayValueIndex);
  }

  showCriteria(crit: Criteria): string {
    return EssayUtils.getCriteriaToShowThermal(crit, this.translate);
  }

  onEssayTabChange(event: MatTabChangeEvent | number, changeVariableIndex = true): void {
    let essayIndex: number;

    if (event instanceof MatTabChangeEvent) {
      essayIndex = event.index;
    } else {
      essayIndex = event;
    }

    this._essayIndex = essayIndex;

    if (changeVariableIndex) {
      this._variableIndex = 0;
    }

    this.updateGraphByIndex(this._essayIndex, this._variableIndex);

    window.setTimeout(() => {
      if (changeVariableIndex) {
        this._variableIndex = 0;
      }

      this.cd.markForCheck();
    });

    const tab = this._essays[this._essayIndex].essayValues[this._variableIndex];

    this.variableUnits = [];

    if (tab != null && tab.idVariable != null) {
      this.variableTypeService.findUnits(tab.idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
        this.variableUnits = res;

        if (tab.idUnit == null || !this.variableUnits.map(v => v.id).includes(tab.idUnit)) {
          this.onUnitChange(this._variableIndex, res[0]);
        }
      });
    }
  }

  onUnitChange(indexVariable: number, event: MatSelectChange | VariableUnit): void {

    const essay = this._essays[this._essayIndex].essayValues[indexVariable];

    let unit: VariableUnit;

    if (event instanceof MatSelectChange) {
      unit = this.variableUnits.find(v => v.id === event.value);
    } else {
      unit = event;
    }

    if (essay != null && unit != null) {
      essay.idUnit = unit.id;
      essay.unitName = unit.unit;

      this._essays[this._essayIndex].essayValues[indexVariable] = essay;
    }
  }

  onVariableTabChange(event: MatTabChangeEvent | number): void {
    let essayIndex: number;

    if (event instanceof MatTabChangeEvent) {
      essayIndex = event.index;
    } else {
      essayIndex = event;
    }

    this._variableIndex = essayIndex;

    this.updateGraphByIndex(this._essayIndex, this._variableIndex);
  }

  public showRestartGraph(): boolean {
    if (!this.graph) {
      return false;
    }

    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return this.graph.isFixedRange() && allowedStatuses.includes(this.idStatusExecution);
  }

  public restartGraph(): void {
    this.graph.restartGraph();
  }

  updateGraphByIndex(essayIndex: number, variableIndex: number): void {
    if (this.graph == null || ArrayUtils.isEmpty(this._essays)) {
      return;
    }

    const essaySelected = this._essays[essayIndex];
    const variableSelected = essaySelected.essayValues[variableIndex];

    this.graph.reasonModifyExpositionTime = variableSelected.reasonModifyExpositionTime;
    this.graph.essayIndex = essayIndex;
    this.graph.variableIndex = variableIndex;
    this.graph.idEssay = essaySelected.id;
    this.graph.idTypeEssay = essaySelected.idType;
    this.graph.idVariable = variableSelected.id;
    this.graph.idTypeVariable = variableSelected.idVariable;
    this.graph.idUnit = variableSelected.idUnit;
    this.graph.idPeak = essaySelected.idPeakEventType;
    this.graph.idStatus = this.idStatusExecution;

    this.graph.fcStartEssay = variableSelected.fcStartEssay as Date;
    this.graph.fcEndEssay = variableSelected.fcEndEssay as Date;

    this.graph.originalSensors = variableSelected.sensors;

    this.executionService.getLethalityValue(variableSelected).subscribe((res: LethalityValueEssay[]) => {

      res.forEach(lethal => {
        lethal.outer = variableSelected.sensors.find(s => s.serialNum === lethal.serialNum)?.outer;
      });

      this.graph.lethalityValue = res;
    });

    this.graph.hotColdSpotValue = new HotColdValueEssay();
    this.executionService.getHotColdSpotValue(variableSelected).subscribe((res: HotColdValueEssay) => {
      this.graph.hotColdSpotValue = res;
    });

    void this.calculateMinMax(variableSelected).then(minMax => this.graph.minMaxValue = minMax);
    void this.checkEssayDiffErrors(variableSelected.id).then(rSensor => this.graph.avgSensors = rSensor);
    void this.checkEssayDiffAveragesErrors(variableSelected.id).then(rSensor => this.graph.diffAvarages = rSensor);
    void this.checkDiffCalculateProbeWithRespectMeanErrors(variableSelected.id).then(rSensor => this.graph.diffProbeRespectAvg = rSensor);

    this.graph.criterias = variableSelected.criterias;
    /* void this.calculateDiff(variableSelected).then(res => {
      this.graph.diffAvgValue = res;
      console.log(res);
    }); */

    this.graph.changeTimezone = false;

    this.graph.showMkt = variableSelected.criterias.some(c => c.showMkt);

    // Buscamos en la variable seleccionada
    let rehearsalTime = variableSelected.fields.find(item => item.idField === FieldEnum.REHEARSAL_TIME);

    // Si la variable seleccionada no tiene, buscamos en todo el ensayo
    if (rehearsalTime == null) {

      essaySelected.essayValues.forEach(essayValues => {
        if (rehearsalTime == null) {
          rehearsalTime = essayValues.fields.find(item => item.idField === FieldEnum.REHEARSAL_TIME);
        }
      });
    }

    if (rehearsalTime != null) {
      this.graph.range = (rehearsalTime.value as string).toString();
      this.graph.fullEssay = false;
    } else {
      this.graph.fullEssay = true;
    }

    if (variableIndex !== 0) {
      const value = this.getValueFromCopyGraphDateMap(essayIndex, variableIndex);

      this.graph.copyDateFirstSensor = value;
    }

    const key = new EventEssayVariableKeys();
    key.essayIndex = essaySelected.id;
    key.variableIndex = variableSelected.idVariable;

    let data = this.graphMap.get(key.toString());

    if (!data) {
      let mapValue: SensorData[] = [];
      variableSelected.sensors.filter(sen => sen.data != null && sen.data.length !== 0).forEach(sen => {
        mapValue = [...mapValue, ...sen.data];
      });

      this.graphMap.set(key.toString(), mapValue);

      data = this.graphMap.get(key.toString());
    }

    if (variableSelected.fcStartEssay == null) {

      if (data != null && data.length !== 0) {
        let dates: Date[] = data.map(d => d.date);

        dates = dates.filter((n, i) => dates.indexOf(n) === i).sort((d1, d2) => d1.getTime() - d2.getTime());

        const minDate = dates[0];
        variableSelected.fcStartEssay = minDate;
        this.onFcStartEssayEmit({
          essayIndex,
          variableIndex,
          fcStartEssay: variableSelected.fcStartEssay as Date,
          fcEndEssay: variableSelected.fcEndEssay as Date,
          changeTimezone: false
        });
      }
    }

    this.defrostService.findAllFromEssay(this._essays[essayIndex].id).pipe(takeUntil(this.destroy$)).subscribe((item: Defrost[]) => {
      item.forEach(i => {
        i.startDate = DateUtils.anyToDate(i.startDate);
        i.endDate = DateUtils.anyToDate(i.endDate);
      });

      this.graph.defrosts = item;
      this.graph.data = data;
    }, () => {
      this.graph.defrosts = [];
      this.graph.data = data;
    });

    this.variableUnits = [];

    if (variableSelected != null && variableSelected.idVariable != null) {
      this.variableTypeService.findUnits(variableSelected.idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
        this.variableUnits = res;

        if (variableSelected.idUnit == null || !this.variableUnits.map(v => v.id).includes(variableSelected.idUnit)) {
          this.onUnitChange(this._variableIndex, res[0]);
        }
      });
    }
    this.graph.recalculateDateFilterOnChangeTab();
  }

  onCopyTimeGraphChange(event: { essayIndex: number, variableIndex: number, value: boolean }): void {
    const essayIndex = event.essayIndex;
    const variableIndex = event.variableIndex;
    const value = event.value;

    const ek = new EventEssayVariableKeys();
    ek.essayIndex = essayIndex;
    ek.variableIndex = variableIndex;

    this.copyGraphDateMap.set(ek, value);
  }

  onPhotoUploadBowieDick(event): void {
    const file = event.target.files[0] as File;

    PhotoUtils.fixImage(file, this.imageCompress, (res: File) => {
      this.spinnerService.show();

      this.attachmentThermalService.uploadAttachmentToExecution(this.idExecution, res, AttachmentType.RESULTADO_BOWIE_DICK)
        .pipe(takeUntil(this.destroy$)).subscribe(() => {

          this.updateAttachmentListEmitter.emit({});

          this.spinnerService.hide();

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadImageBowieDick.ok') as string);
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadImageBowieDick.error.generic') as string);
        });
    });

  }

  onGeneralPhotoUpload(essayIndex: number, event): void {
    const essay = this._essays[essayIndex];

    const file = event.target.files[0] as File;

    this.spinnerService.show();

    PhotoUtils.fixImage(file, this.imageCompress, (res) => {
      this.attachmentThermalService.uploadGeneralPhoto(this.idExecution, essay.id, res).pipe(takeUntil(this.destroy$))
        .subscribe((item: number) => {
          essay.idPhoto = item;

          this.spinnerService.hide();

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadImage.ok') as string);
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadImage.error.generic') as string);
        });
    });
  }

  onPhotoUploadSensor(essayIndex: number, variableIndex: number, sensorIndex: number, event): void {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const idSensor = sensor.id;

    if (idSensor == null) {
      this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.sensor.notUpdated') as string);

      return;
    }

    const file = event.target.files[0] as File;

    this.spinnerService.show();

    PhotoUtils.fixImage(file, this.imageCompress, (res) => {
      this.attachmentThermalService.uploadPhotoToSensor(sensor.id, res).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
        sensor.idPhoto = item;

        this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);
        this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'photo');

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadImage.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadImage.error.generic') as string);
      });
    });
  }

  onExcelUploadSensor(essayIndex: number, variableIndex: number, sensorIndex: number, event): void {
    const essaySelected = this._essays[essayIndex];
    const variableSelected = essaySelected.essayValues[variableIndex];
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const idSensor = sensor.id;

    if (idSensor == null) {
      this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.sensor.notUpdated') as string);

      return;
    }

    const cond = sensor.idEquipment != null;

    if (cond === false) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.idEquipmentMissing') as string);

      return;
    }

    const file: File = event.target.files[0] as File;

    if (file.name.endsWith('.pdf')) {
      this.spinnerService.show();

      this.attachmentThermalService.convertPdfToCsv(file, variableSelected.idVariable).pipe(takeUntil(this.destroy$)).subscribe(csv => {
        csv.name = file.name;
        this.uploadDataExcel(essayIndex, variableIndex, sensorIndex, csv as File);
      });
    } else {
      this.uploadDataExcel(essayIndex, variableIndex, sensorIndex, file);
    }

  }

  onPrimaryDataUploadSensor(essayIndex: number, variableIndex: number, sensorIndex: number, event): void {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const res = sensor.idEquipment != null;

    if (res === false) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.idEquipmentMissing') as string);

      return;
    }

    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadPrimaryFileToSensor(sensor.id, file).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
      sensor.idPrimaryDataFile = item;

      this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);
      this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'primary');

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadPrimaryData.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadPrimaryData.error.generic') as string);
    });
  }

  openUploadGeneric(essayIndex: number, variableIndex: number): void {
    const essaySelected = this._essays[essayIndex];
    const variableSelected = essaySelected.essayValues[variableIndex];

    const bottomRef = this.bottomSheet.open(ExecutionEditBottomSheetUploadComponent, {
      data: {
        idStatusExecution: this.idStatusExecution,
        essay: variableSelected
      }
    });

    bottomRef.afterDismissed().pipe(takeUntil(this.destroy$)).subscribe((res: { action: string, data: any }) => {
      if (res != null) {
        if (res.action === 'updateGraphByIndex') {
          this.updateGraphByIndex(essayIndex, variableIndex);
        } else if (res.action === 'updateSensor') {
          const sensorIndex = res.data.sensorIndex as number;
          const sensor = res.data.sensor as Sensor;
          const itsFirtsCall = res.data.itsFirtsCall as boolean;

          this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);

          let mapValue: SensorData[] = [];
          variableSelected.sensors.filter(sen => sen.data != null && sen.data.length !== 0).forEach(sen => {
            mapValue = [...mapValue, ...sen.data];
          });

          const key = new EventEssayVariableKeys();
          key.essayIndex = essaySelected.id;
          key.variableIndex = variableSelected.idVariable;

          this.graphMap.set(key.toString(), mapValue);

          if (itsFirtsCall) {
            this.checkFrequency(essayIndex, variableIndex);
          }
          this.updateGraphByIndex(essayIndex, variableIndex);
        } else if (res.action === 'updateSensorPhoto' || res.action === 'updateSensorPrimary') {
          const sensor = res.data.sensor as Sensor;

          const indexSensor = this._essays[essayIndex].essayValues[variableIndex].sensors.findIndex(s => s.id === sensor.id
            && s.location === sensor.location);

          const copyType = (res.action === 'updateSensorPhoto') ? 'photo' : ' primary';

          if (indexSensor > -1) {
            this.setSensor(essayIndex, variableIndex, indexSensor, sensor);
            this.updateSensorDependency(essayIndex, variableIndex, indexSensor, copyType);
          }
        } else if (res.action === 'reloadPage') {
          this.reloadPage();
        }
      }
    });
  }

  copySensorToAll(): void {
    const sensors = this._essays[0].essayValues[0].sensors;

    this._essays.forEach((essay, essayIndex) => {
      essay.essayValues.forEach((variables, variableIndex) => {
        if (essayIndex === 0 && variableIndex === 0) {
          return;
        }

        variables.sensors.forEach((sensorToCopy, sensorToCopyIndex) => {
          let sensorFromCopy: Sensor = null;

          if (sensorToCopy.location === this.translate.instant('protocolEdit.dialog.essay.sensors.defaultLocation')) {
            sensorFromCopy = sensors[sensorToCopyIndex];
          } else {
            sensorFromCopy = sensors.find((sensor, sensorIndex) => sensor.location === sensorToCopy.location
              && sensorIndex === sensorToCopyIndex);
          }

          if (sensorFromCopy != null) {
            if (!this.disableSerialNumLabel()) {
              sensorToCopy.serialNum = sensorFromCopy.serialNum;
              sensorToCopy.equipmentName = sensorFromCopy.equipmentName;
            }

            if (!this.disableUploadPhotoButton()) {
              sensorToCopy.idPhoto = sensorFromCopy.idPhoto;
            }

            this._essays[essayIndex].essayValues[variableIndex].sensors[sensorToCopyIndex] = sensorToCopy;

          }
        });

      });
    });

    this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.copySensors.ok') as string);
  }

  enableReasonModifyExpositionTime(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.idStatusExecution);
  }

  openReasonModifyExpositionTime(event: MatCheckboxChange): void {

    if (event.checked === false) {
      this.reasonModifyExpositionTime = null;
      this.graph.reasonModifyExpositionTime = null;
      return;
    }

    const dialogRef = this.dialog.open(ExecutionEditReasonExpositionTimeComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        reason: _.cloneDeep(this.reasonModifyExpositionTime)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((reason: string) => {

      if (reason != null) {
        this.reasonModifyExpositionTime = reason;
        this.graph.reasonModifyExpositionTime = reason;
      }
    });
  }

  enableEditField(field: Field): boolean {
    if (!field.editableInExecution) {
      return false;
    }

    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.idStatusExecution);
  }

  enableEditCriteria(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.idStatusExecution);
  }

  enableEditSensor(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.idStatusExecution);
  }

  openEditField(essayIndex: number, variableIndex: number, fieldIndex: number): void {
    const essay = this._essays[essayIndex];
    const variableSelected = essay.essayValues[variableIndex];

    const field = variableSelected.fields[fieldIndex];

    const dialogRef = this.dialog.open(ExecutionEditFieldEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        field: _.cloneDeep(field)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Field) => {

      if (result != null) {
        variableSelected.fields[fieldIndex] = result;

        if (result.idField === FieldEnum.REHEARSAL_TIME) {
          this.reloadGraphMap();
        }
      }
    });
  }

  openNewCriteria(essayIndex: number, variableIndex: number): void {
    const essay = this._essays[essayIndex];
    const variableSelected = essay.essayValues[variableIndex];
    const criteriasRemove = variableSelected.criterias.map(c => c.idType);

    const criteria = new Criteria();
    criteria.idTypeProtocol = null;
    criteria.criteriaValueProtocol1 = null;
    criteria.criteriaValueProtocol2 = null;
    criteria.criteriaValueChangeReason = null;
    criteria.showDiffInDeviations = false;
    criteria.showDiffInObservations = false;
    criteria.fromProtocol = false;

    const dialogRef = this.dialog.open(ExecutionEditCriteriaEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria,
        idEssay: essay.idType,
        idEssayConfig: variableSelected.idConfig,
        criteriasRemove,
        isNew: true,
        showMkt: variableSelected.idVariable === VariableTypeEnum.TEMPERATURE
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Criteria) => {
      if (result != null) {

        variableSelected.criterias.push(result);
        this.criteriaTable.forEach(i => i.renderRows());

        void this.calculateMinMax(variableSelected).then(minMax => this.graph.minMaxValue = minMax);
        void this.checkEssayDiffErrors(variableSelected.id).then(rSensor => this.graph.avgSensors = rSensor);
        void this.checkEssayDiffAveragesErrors(variableSelected.id).then(rSensor => this.graph.diffAvarages = rSensor);
        this.graph.showMkt = variableSelected.criterias.some(c => c.showMkt);
        this.graph.recalculateDateFilter();
      }
    });
  }

  openEditCriteria(essayIndex: number, variableIndex: number, criteriaIndex: number): void {
    const essay = this._essays[essayIndex];
    const variableSelected = essay.essayValues[variableIndex];
    const criteria = variableSelected.criterias[criteriaIndex];
    const criteriasRemove = variableSelected.criterias.map(c => c.idType).filter(c => c !== criteria.idType);

    const dialogRef = this.dialog.open(ExecutionEditCriteriaEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: _.cloneDeep(criteria),
        idEssay: essay.idType,
        idEssayConfig: variableSelected.idConfig,
        criteriasRemove,
        isNew: false,
        showMkt: variableSelected.idVariable === VariableTypeEnum.TEMPERATURE
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Criteria) => {
      if (result != null) {
        variableSelected.criterias[criteriaIndex] = result;
        this.criteriaTable.forEach(i => i.renderRows());
        this.graph.criterias[criteriaIndex] = result;
        void this.calculateMinMax(variableSelected).then(minMax => this.graph.minMaxValue = minMax);
        void this.checkEssayDiffErrors(variableSelected.id).then(rSensor => this.graph.avgSensors = rSensor);
        void this.checkEssayDiffAveragesErrors(variableSelected.id).then(minMaxSensor => this.graph.diffAvarages = minMaxSensor)
        this.graph.showMkt = variableSelected.criterias.some(c => c.showMkt);
        this.graph.recalculateDateFilter();
      }
    });
  }

  removeCriteria(essayIndex: number, variableIndex: number, criteriaIndex: number): void {
    const essay = this._essays[essayIndex];
    const variableSelected = essay.essayValues[variableIndex];
    // const criterias = variableSelected.criterias;

    if (criteriaIndex > -1) {
      variableSelected.criterias.splice(criteriaIndex, 1);
    }

    this.criteriaTable.forEach(i => i.renderRows());
  }

  disableSerialNumLabel(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.idStatusExecution);
  }

  disableUploadPhotoButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.idStatusExecution);
  }

  disableUploadExcelButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.idStatusExecution);
  }

  disableUploadOriginalButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.idStatusExecution);
  }

  styleUploadPhotoButton(essayIndex: number, variableIndex: number, sensorIndex: number): string {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    return (!this.disableUploadPhotoButton() && sensor.idPhoto != null) ? '' : 'opacity50';
  }

  styleUploadExcelButton(essayIndex: number, variableIndex: number, sensorIndex: number): string {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    return (!this.disableUploadExcelButton() && sensor.idExcel != null) ? '' : 'opacity50';
  }

  styleUploadOriginalButton(essayIndex: number, variableIndex: number, sensorIndex: number): string {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    return (!this.disableUploadOriginalButton() && sensor.idPrimaryDataFile != null) ? '' : 'opacity50';
  }

  showPhotoButton(essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
    return ExecutionUtils.showPhotoButton(this._essays, essayIndex, variableIndex, sensorIndex);
  }

  showExcelButton(essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
    return ExecutionUtils.showExcelButton(this._essays, essayIndex, variableIndex, sensorIndex);
  }

  showPrimaryButton(essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
    return ExecutionUtils.showPrimaryButton(this._essays, essayIndex, variableIndex, sensorIndex);
  }

  photoAlreadyUploaded(essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    return sensor.idPhoto != null;
  }

  excelAlreadyUploaded(essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    return sensor.idExcel != null;
  }

  primaryAlreadyUploaded(essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    return sensor.idPrimaryDataFile != null;
  }

  openDialogGeneralPhoto(essayIndex: number): void {
    const essay = this._essays[essayIndex];

    this.dialog.open(ExecutionEditAttachmentDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idExecution: this.idExecution,
        idSensor: essay.id,
        idAttachment: essay.idPhoto,
        accept: 'image/*',
        isPhoto: true,
        isEssay: true,
        disableUpload: this.disableUploadPhotoButton(),
        type: 'generalPhoto'
      }
    });
  }

  openDialogCorrectSensors(essayIndex: number, indexVariable: number): void {
    const essay = this._essays[essayIndex];
    const variableSelected = essay.essayValues[indexVariable];

    const dialogRef = this.dialog.open(ExecutionEditDialogCorrectSensorsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idExecution: this.idExecution,
        idEssay: essay.id,
        idEssayVariable: variableSelected.id,
        sensors: variableSelected.sensors
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.reloadPage();
      }
    });
  }

  openDialogSensorPhoto(essayIndex: number, variableIndex: number, sensorIndex: number): void {
    const variableSelected = this._essays[essayIndex].essayValues[variableIndex];
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const idSensor = sensor.id;

    if (idSensor == null) {
      this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.sensor.notUpdated') as string);

      return;
    }

    const dialogRef = this.dialog.open(ExecutionEditAttachmentDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idSensor,
        idAttachment: sensor.idPhoto,
        accept: 'image/*',
        isPhoto: true,
        disableUpload: this.disableUploadPhotoButton(),
        sensor,
        idVariable: variableSelected.idVariable,
        type: 'photo'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor) => {
      if (result != null) {
        this.setSensor(essayIndex, variableIndex, sensorIndex, result);
      }
    });
  }

  openDialogSensorExcel(essayIndex: number, variableIndex: number, sensorIndex: number): void {
    const variableSelected = this._essays[essayIndex].essayValues[variableIndex];
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const idSensor = sensor.id;

    if (idSensor == null) {
      this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.sensor.notUpdated') as string);

      return;
    }

    const dialogRef = this.dialog.open(ExecutionEditAttachmentDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idSensor,
        idAttachment: sensor.idExcel,
        accept: '.xlsx, .xls, .csv, .pdf',
        isPhoto: false,
        disableUpload: this.disableUploadExcelButton(),
        sensor,
        idVariable: variableSelected.idVariable,
        type: 'excel'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor) => {
      if (result != null) {
        this.setSensor(essayIndex, variableIndex, sensorIndex, result);

        this.checkFrequency(essayIndex, variableIndex);
      }
    });
  }

  openDialogSensorPrimary(essayIndex: number, variableIndex: number, sensorIndex: number): void {
    const variableSelected = this._essays[essayIndex].essayValues[variableIndex];
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const idSensor = sensor.id;

    if (idSensor == null) {
      this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.sensor.notUpdated') as string);

      return;
    }

    const dialogRef = this.dialog.open(ExecutionEditAttachmentDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idSensor,
        idAttachment: sensor.idPrimaryDataFile,
        accept: '*',
        isPhoto: false,
        disableUpload: this.disableUploadOriginalButton(),
        sensor,
        idVariable: variableSelected.idVariable,
        type: 'primary'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor) => {
      if (result != null) {
        this.setSensor(essayIndex, variableIndex, sensorIndex, result);
      }
    });
  }

  onFcStartEssayEmit(
    event: { essayIndex: number, variableIndex: number, fcStartEssay: Date, fcEndEssay: Date, changeTimezone: boolean }): void {
    this.fcStartEssayEmitter.next(event);

    const init = _.cloneDeep(event.fcStartEssay);
    const end = _.cloneDeep(event.fcEndEssay);

    if (event.essayIndex != null && event.variableIndex != null) {
      this._essays[event.essayIndex].essayValues[event.variableIndex].fcStartEssay = init;
      this._essays[event.essayIndex].essayValues[event.variableIndex].fcEndEssay = end;
      this._essays[event.essayIndex].essayValues[event.variableIndex].changeEssayDate = true;

      if (!this.graph.showCopyDateFirstSensor()) {
        this.copyGraphDateMap.forEach((value: boolean, key: EventEssayVariableKeys) => {
          if (key.essayIndex === event.essayIndex && key.variableIndex !== event.variableIndex && value) {
            this._essays[key.essayIndex].essayValues[key.variableIndex].fcStartEssay = init;
            this._essays[key.essayIndex].essayValues[key.variableIndex].fcEndEssay = end;
            this._essays[key.essayIndex].essayValues[key.variableIndex].changeEssayDate = true;
          }
        });
      }
    }
  }

  onHotColdSpotChange(event: any): void {
    if (event.essayIndex != null && event.variableIndex != null) {
      const sensors = this._essays[event.essayIndex].essayValues[event.variableIndex].sensors;

      const sensor = sensors.find(s => s.equipmentName === event.equipmentName);

      if (sensor != null) {
        sensor.hotColdSpot = event.value as boolean;
      }
    }
  }

  onIdPeakChange(event: any): void {
    this.onIdPeakEmitter.next(event);
  }

  disableDefrostsButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION, ExecutionStatus.PENDIENTE_FIRMA];

    return !allowedStatuses.includes(this.idStatusExecution);
  }

  showDialogDefrost(): void {
    const essay = this._essays[this._essayIndex];
    const essayValue = essay.essayValues[this._variableIndex];

    let values: SensorData[] = [];
    essayValue.sensors.filter(sensor => sensor.data != null && sensor.data.length !== 0)
      .forEach(sensor => values = [...values, ...sensor.data]);

    const initDate = this.graph.minDate as Date;
    const endDate = this.graph.maxDate as Date;

    if (this.graph.isFixedRange() && values) {
      values = values.filter(item => item.date > initDate && item.date < endDate);
    }

    const dialogRef = this.dialog.open(ExecutionEditDefrostListComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idEssay: essay.id,
        data: values
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.defrostService.findAllFromEssay(essay.id).pipe(takeUntil(this.destroy$)).subscribe((item: Defrost[]) => {
        item.forEach(i => {
          i.startDate = DateUtils.anyToDate(i.startDate);
          i.endDate = DateUtils.anyToDate(i.endDate);
        });

        this.graph.defrosts = item;
        this.graph.recalculateData();
      }, () => {
        this.graph.recalculateData();
      });
    });

  }

  showDialogDiscardTime(essay: Essay): void {
    const variableSelected = essay.essayValues[0];

    const key = new EventEssayVariableKeys();
    key.essayIndex = essay.id;
    key.variableIndex = variableSelected.idVariable;

    const dats = this.graphMap.get(key.toString());

    const dialogRef = this.dialog.open(ExecutionEditDiscardTimeComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idEssay: essay.id,
        idExecution: this.idExecution,
        data: dats
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: { valid: boolean, data: SensorData[] }) => {
      if (res.valid === true) {
        this.graphMap.set(key.toString(), res.data);
        this.graph.data = res.data;
      }
    });

  }

  openNewSensor(essayIndex: number, variableIndex: number): void {
    const variableSelected = this._essays[essayIndex].essayValues[variableIndex];

    const sensor = new Sensor();
    sensor.fromProtocol = false;
    sensor.idVariable = variableSelected.idVariable;
    sensor.evaluate = true;
    sensor.showDiffInDeviations = true;

    const dialogRef = this.dialog.open(ExecutionEditConfigSensorComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        sensor,
        isLastSensor: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor) => {
      if (result != null) {
        variableSelected.sensors.push(result);
        this.graph.originalSensors = variableSelected.sensors;
      }
    });
  }

  openEditSensor(essayIndex: number, variableIndex: number, sensorIndex: number): void {
    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const variableSelected = this._essays[essayIndex].essayValues[variableIndex];

    const isLastSensor = variableSelected.sensors.filter(s => s.id !== sensor.id && s.evaluate).length < 1;

    const dialogRef = this.dialog.open(ExecutionEditConfigSensorComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        sensor: _.cloneDeep(sensor),
        isLastSensor
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor) => {
      if (result != null) {
        this.setSensor(essayIndex, variableIndex, sensorIndex, result);

        variableSelected.sensors[sensorIndex] = result;

        this.graph.originalSensors = variableSelected.sensors;
        this.updateSensorDependency(essayIndex, variableIndex, sensorIndex, 'config');
        this.graph.recalculateGraph();
      }
    });

  }

  openDeleteSensor(essayIndex: number, variableIndex: number, sensorIndex: number): void {
    const message = this.translate.instant('executionEdit.essays.sensor.form.delete.message') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        const variableSelected = this._essays[essayIndex].essayValues[variableIndex];
        variableSelected.sensors.splice(sensorIndex, 1);
        this.graph.originalSensors = variableSelected.sensors;
        this.graph.recalculateGraph();
      }
    });
  }

  showDialogConfigBioindicator(essayIndex: number, variableIndex: number): void {
    const bio = this._essays[essayIndex].essayValues[variableIndex].bioindicatorsConfig;

    const dialogRef = this.dialog.open(ExecutionEditBioindicatorsDetailsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        bioindicator: _.cloneDeep(bio),
        idExecution: this.idExecution,
        type: 'bioindicator'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: BioindicatorConfig) => {
      if (result != null) {
        this._essays[essayIndex].essayValues[variableIndex].bioindicatorsConfig = result;
      }
    });

  }

  showDialogConfigEndotoxin(essayIndex: number, variableIndex: number): void {
    const bio = this._essays[essayIndex].essayValues[variableIndex].endotoxinsConfig;

    const dialogRef = this.dialog.open(ExecutionEditBioindicatorsDetailsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        bioindicator: _.cloneDeep(bio),
        idExecution: this.idExecution,
        type: 'endotoxin'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: BioindicatorConfig) => {
      if (result != null) {
        this._essays[essayIndex].essayValues[variableIndex].endotoxinsConfig = result;
      }
    });

  }

  isSelectedMulti(valuesStr: string | string[], valueCheck: string): boolean {
    if (valuesStr == null || valueCheck == null) {
      return false;
    }

    let values = [];
    if (typeof valuesStr === 'string') {
      values = valuesStr.split(';');
    } else {
      values = valuesStr;
    }

    return values.includes(valueCheck);
  }

  copyToEssay(): void {
    const essaySelected = this._essays[this._essayIndex];
    let essaysToShow = this._essays.filter(e => e.id !== essaySelected.id);

    essaysToShow = essaysToShow.filter(essay => {
      const emptyList: SensorData[] = [];
      const data = emptyList.concat(...essay.essayValues[0].sensors.map(s => s.data));

      return !ArrayUtils.isEmpty(data);
    });

    if (ArrayUtils.isEmpty(essaysToShow)) {
      this.snackBarService.sendError('No hay ensayos desde el que copiar');
      return;
    }

    const dialogRef = this.dialog.open(ExecutionEditCopyEssayComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        essays: essaysToShow
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataCopyEssayResult) => {
      if (result != null) {
        this.spinnerService.show();

        this.executionService.copyToEssay(this.idExecution, result.idEssay, essaySelected.id, result.dateFrom, result.dateTo)
          .pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.spinnerService.hide();

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.events.form.copyEssay.ok') as string);

            this.reloadPage();
            this.onEssayTabChange(this._essayIndex);
          }, () => {
            this.spinnerService.hide();

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.events.form.copyEssay.error.generic') as string);
          });
      }
    });
  }

  showCopyToOtherEssay(): boolean {
    return this._essays.length > 1;
  }

  private getSensor(essayIndex: number, variableIndex: number, sensorIndex: number): Sensor {
    return this._essays[essayIndex].essayValues[variableIndex].sensors[sensorIndex];
  }

  private setSensor(essayIndex: number, variableIndex: number, sensorIndex: number, sensor: Sensor): void {
    this._essays[essayIndex].essayValues[variableIndex].sensors[sensorIndex] = sensor;

    const value = { essayIndex, variableIndex, sensorIndex, sensor };

    this.updateSensorEssayEmitter.emit(value);
  }

  private uploadDataExcel(essayIndex: number, variableIndex: number, sensorIndex: number, file: File): void {
    const essaySelected = this._essays[essayIndex];
    const variableSelected = essaySelected.essayValues[variableIndex];

    const sensor = this.getSensor(essayIndex, variableIndex, sensorIndex);

    const idSensor = sensor.id;

    if (idSensor == null) {
      this.snackBarService.sendWarn(this.translate.instant('executionEdit.essays.form.sensor.notUpdated') as string);

      return;
    }

    this.spinnerService.show();

    ExcelUtils.excelToSensorData(file, this.attachmentThermalService, variableSelected.idVariable, false, 1).then((data: SensorData[]) => {

      data.forEach(item => {
        item.serialNum = sensor.serialNum;
        item.equipmentName = sensor.equipmentName;
        item.idEquipment = sensor.idEquipment;
      });

      const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

      if (map.size > 1) {
        data = map.get(variableSelected.idVariable);
      }

      data.forEach(item => delete item.posEquipment);

      sensor.data = data;
      this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);

      data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

      this.attachmentThermalService.uploadExcelToSensor(idSensor, file, data).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
        sensor.idExcel = item;

        sensor.data = data;

        this.setSensor(essayIndex, variableIndex, sensorIndex, sensor);

        let mapValue: SensorData[] = [];
        variableSelected.sensors.filter(sen => sen.data != null && sen.data.length !== 0)
          .forEach(sen => mapValue = [...mapValue, ...sen.data]);

        const key = new EventEssayVariableKeys();
        key.essayIndex = essaySelected.id;
        key.variableIndex = variableSelected.idVariable;

        this.graphMap.set(key.toString(), mapValue);

        this.updateGraphByIndex(essayIndex, variableIndex);

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadExcel.ok') as string);

        this.checkFrequency(essayIndex, variableIndex);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.generic') as string);
      });

    }).catch(err => {
      console.error(err);
      this.snackBarService.sendError(err as string);
      this.spinnerService.hide();
    });
  }

  private checkFrequency(essayIndex: number, variableIndex: number): void {
    const essay = this._essays[essayIndex];
    const currentVariable = essay.essayValues[variableIndex];

    const fieldFreq = currentVariable.fields.find(f => f.idField === FieldEnum.FREQUENCY);

    const dates = currentVariable.sensors.find(s => !ArrayUtils.isEmpty(s.data)).data.map(d => d.date)
      .sort((d1, d2) => d1.getTime() - d2.getTime());

    const lastDate = dates[dates.length - 1];
    const lastDate2 = dates[dates.length - 2];

    const diff = DateUtils.getMinutesBetween(lastDate, lastDate2);

    const value: number = +fieldFreq?.value;

    if (value !== diff) {

      const diffStr = diff > 0 ? FieldUtils.minutesToHHMMSS(diff, this.translate) : null;
      const valueStr = value > 0 ? FieldUtils.minutesToHHMMSS(value, this.translate) : null;

      if (diffStr == null || valueStr == null) {
        return;
      }

      const message = this.translate.instant('executionEdit.essays.form.sensor.diffFrequency',
        { diff: diffStr, value: valueStr }) as string;

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {
          this.requestReason((reason) => {
            fieldFreq.value = diff;
            fieldFreq.valueChangeReason = reason;
          });
        }
      });

    }
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private getValueFromCopyGraphDateMap(essayIndex: number, variableIndex: number): boolean {
    let res: boolean = null;

    this.copyGraphDateMap.forEach((value, key) => {
      if (key.essayIndex === essayIndex && key.variableIndex === variableIndex) {
        res = value;
        return;
      }
    });

    return res;
  }

  private calculateMinMax(variableSelected: EssayProtocol): Promise<MinMaxValueEssay> {
    this.graph.minMaxValue = new MinMaxValueEssay();

    return new Promise((resolve) => {
      this.executionService.getMinMaxValue(variableSelected).subscribe((res: MinMaxValueEssay) => {
        resolve(res);
      });
    });
  }

  // Diff between sonda
  private checkEssayDiffErrors(idEssay: number): Promise<string[]> {
    return new Promise((resolve) => {
      this.executionService.checkEssayDiffErrors(idEssay).subscribe((res: string[]) => {
        resolve(res);
      });
    });
  }

  private checkEssayDiffAveragesErrors(idEssay: number): Promise<string[]> {
    return new Promise((resolve) => {
      this.executionService.checkEssayDiffAveragesErrors(idEssay).subscribe((res: string[]) => {
        resolve(res);
      });
    });
  }

  // Diff. of each probe with respect to the mean
  private checkDiffCalculateProbeWithRespectMeanErrors(idEssay: number): Promise<string[]> {
    return new Promise((resolve) => {
      this.executionService.checkDiffCalculateProbeWithRespectMeanErrors(idEssay).subscribe((res: string[]) => {
        resolve(res);
      });
    });
  }
}
