<form>
    <div class="qualificates-theme" style="display: block;">
        <div fxLayout="column">

            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" fxLayout="row" class="table-title">
                    <div fxFlex="100">
                        <div fxFlex="3" class="number-title">
                            <span>{{data.testQType.id}}</span>
                        </div>
                        <div fxFlex="97" class="title-essay-dialog">
                            <mat-form-field class="campoFormulario">
                                <input class="input-title-essay" matInput name="title-essay"
                                    [(ngModel)]="data.data.testDescription">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div fxFlex="100" fxLayout="column" *ngIf="allowedEssay.includes(data.testQType?.id)">
                    <div fxLayout="row" class="table-item title-table-test title-table-withpadding" *ngIf="showTestEssay()">
                        <div fxFlex="100">
                            <div fxFlex="95" class="center-text">
                                <span>{{ 'protocolEdit.instrumentationUse.title' | translate }}</span>
                            </div>
                            <div fxFlex="5">

                            </div>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayout="column" class="container-verification-data">
                        <mat-radio-group required aria-labelledby="example-radio-group-label"
                            class="example-radio-group" [(ngModel)]="dataSource.idInstrumentation"
                            name="idInstrumentation">
                            <mat-radio-button class="example-radio-button" *ngFor="let item of instrumentationUse"
                                [value]="item.id" (change)="onInstrumentationChange($event)">
                                {{ 'instrumentation.' + item.translation | translate }}
                            </mat-radio-button>
                            <div class="error" *ngIf="dataSource.idInstrumentation == null">
                                {{ 'protocolEdit.instrumentationUse.error' | translate }}
                            </div>
                        </mat-radio-group>

                        <table style="width:100%;" class="tablaConBordes" *ngIf="showValidatorSpecifics">
                            <tr>
                                <th>{{ 'protocolEdit.instrumentationUse.validator.calibrationPoint' | translate }}</th>
                                <th>{{ 'protocolEdit.instrumentationUse.validator.valueTemp' | translate }}</th>
                                <th>{{ 'protocolEdit.instrumentationUse.validator.maxDeviation' | translate }}</th>
                                <th>{{ 'protocolEdit.instrumentationUse.validator.minStability' | translate }}</th>
                            </tr>
                            <tr>
                                <td>{{ 'protocolEdit.instrumentationUse.validator.lowTemp' | translate }}</td>
                                <td>
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.lowTemp" name="lowTemp"
                                            required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                </td>
                                <td>
                                    ≤
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.lowDesv" name="lowDesv"
                                            required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                    {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
                                </td>
                                <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'protocolEdit.instrumentationUse.validator.highTemp' | translate }}</td>
                                <td>
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.highTemp" name="highTemp"
                                            required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                </td>
                                <td>
                                    ≤
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.highDesv" name="highDesv"
                                            required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                    {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
                                </td>
                                <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'protocolEdit.instrumentationUse.validator.verifTemp' | translate }}</td>
                                <td>
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.verifTemp" name="verifTemp"
                                            required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                </td>
                                <td>
                                    ±
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.verifDesv" name="verifDesv"
                                            required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                    {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
                                </td>

                                <td>
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.minStability"
                                            name="minStability" required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                    {{ 'protocolEdit.instrumentationUse.validator.tempIn' | translate }}
                                    <mat-form-field class="campoFormulario" style="width: 3em">
                                        <input matInput [(ngModel)]="dataSource.validator.minStabilityTime"
                                            name="minStabilityTime" required [errorStateMatcher]="matcher" size="3">
                                    </mat-form-field>
                                    {{ 'protocolEdit.instrumentationUse.validator.minutes' | translate }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div fxLayout="row" class="table-item title-table-test" *ngIf="showTestEssay()">
                    <div fxFlex="100">
                        <div fxFlex="95" class="center-text">
                            <span> {{ 'protocolEdit.essays.title.generic' | translate }}</span>
                        </div>
                        <div fxFlex="5">
                            <button mat-icon-button class="btn-add without-border" (click)="newEssayOpenDialog()">
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="column" class="container-verification-data"
                    *ngIf="dataSource.protocolQTestEssayGroup?.length > 0">
                    <table mat-table [dataSource]="dataSource.protocolQTestEssayGroup" style="min-width: 95%"
                        cdkDropListData="dataSource.protocolQTestEssayGroup" [cdkDropListDisabled]="dragDisabled"
                        cdkDropList class="verification-drop-list" (cdkDropListDropped)="dropEssay($event)"
                        #essaysTable>

                        <ng-container matColumnDef="order" sticky>
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon class="dragCursor" (mousedown)="dragDisabled = false;">reorder</mat-icon>
                            </td>
                        </ng-container>

                        <!-- Type Column -->
                        <ng-container matColumnDef="essayType">
                            <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.essay.label' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{ 'essayType.' + element.translationType |
                                translate }} </td>
                        </ng-container>

                        <!-- Variables Column -->
                        <ng-container matColumnDef="variables">
                            <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.variables.label' | translate
                                }} </th>
                            <td mat-cell *matCellDef="let element"> {{ getVariablesFromEssay(element) }} </td>
                        </ng-container>

                        <!-- Criteria Column -->
                        <ng-container matColumnDef="criteria">
                            <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.criteria.label' | translate
                                }} </th>
                            <td mat-cell *matCellDef="let element"> {{ getCriteriaFromEssay(element) }} </td>
                        </ng-container>

                        <!-- Load Column -->
                        <ng-container matColumnDef="load">
                            <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.load.label' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{ getLoadFromEssay(element) }} </td>
                        </ng-container>

                        <!-- Button see Column -->
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <button mat-icon-button (click)="editEssayOpenDialog(i)">
                                    <mat-icon>mode_edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!-- Button delete Column -->
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                                    [matTooltipDisabled]="!disableOfflineButton">
                                    <button mat-icon-button (click)="deleteEssayRow(i, element.uuid)"
                                        [disabled]="disableOfflineButton">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="essaysCols"></tr>
                        <tr mat-row *matRowDef="let row; columns: essaysCols;" cdkDrag [cdkDragData]="row"></tr>
                    </table>
                </div>

                <div fxLayout="row" class="table-item title-table-test title-table-withpadding"
                    *ngIf="isObjetiveEmpty() || isMetodologyEmpty() || isCriteriaEmpty()">
                    <div fxFlex="100">
                        <div fxFlex="100" class="center-text">
                            <span> {{ 'protocolEdit.test.dataGeneral.title' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="column" class="container-verification-data">
                    <div fxFlex="100" fxLayout="column">
                        <div *ngIf="testConfig?.testGeneralData">
                            <div fxLayout="column" *ngIf="isObjetiveEmpty()">
                                <mat-accordion>
                                    <mat-expansion-panel class="overflowX" hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div fxFlex="100" class="table-item title-table-test">
                                                    <div fxFlex="100">
                                                        <div fxFlex="95" class="center-text">
                                                            <span>{{'protocolEdit.test.objetive.title' |
                                                                translate}}</span>
                                                        </div>
                                                        <div fxFlex="5">
                                                            <button mat-icon-button (click)="refreshObjetive()"
                                                                matTooltip="{{ 'button.refresh' | translate }}">
                                                                <mat-icon>refresh</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div fxLayout="row"
                                            *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                                            <div fxFlex="100" fxLayout="column">
                                                <div fxFlex="100" class="table-item"
                                                    style="display: grid; margin-bottom: 50px">
                                                    <quill-editor #editor class="quill-container"
                                                        [(ngModel)]="value.objective"
                                                        (onTextChange)="onModelChange($event)"
                                                        [name]="'objective' + indexTest" [id]="'objective' + indexTest"
                                                        [modules]="quillConfig"
                                                        style="height: auto; font-weight: normal;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>

                            <div fxLayout="column" *ngIf="isMetodologyEmpty()">
                                <mat-accordion>
                                    <mat-expansion-panel class="overflowX" hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div fxFlex="100" class="table-item title-table-test">
                                                    <div fxFlex="100">
                                                        <div fxFlex="95" class="center-text">
                                                            <span>{{'protocolEdit.test.methodology.title' |
                                                                translate}}</span>
                                                        </div>
                                                        <div fxFlex="5">
                                                            <button mat-icon-button (click)="refreshMethodology()"
                                                                matTooltip="{{ 'button.refresh' | translate }}">
                                                                <mat-icon>refresh</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div fxLayout="row"
                                            *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                                            <div fxFlex="100" fxLayout="column" *ngIf="value.methodology">
                                                <div fxLayout="row" fxFlex="100" class="table-item"
                                                    style="display: grid; margin-bottom: 50px">
                                                    <quill-editor class="quill-container"
                                                        [(ngModel)]="value.methodology"
                                                        [name]="'methodology' + indexTest"
                                                        [id]="'methodology' + indexTest" [modules]="quillConfig"
                                                        style="height: auto; font-weight: normal;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div fxLayout="column" *ngIf="isCriteriaEmpty()">

                                <div fxLayout="row"
                                    *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                                    <mat-accordion>
                                        <mat-expansion-panel class="overflowX" hideToggle>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <div fxFlex="100" class="table-item title-table-test">
                                                        <div fxFlex="100">
                                                            <div fxFlex="95" class="center-text">
                                                                <span>{{'protocolTest.test.criteria' | translate
                                                                    }}</span>
                                                            </div>
                                                            <div fxFlex="5">
                                                                <button mat-icon-button (click)="refreshCriteria()"
                                                                    matTooltip="{{ 'button.refresh' | translate }}">
                                                                    <mat-icon>refresh</mat-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex="100" fxLayout="column" *ngIf="value.criteria">
                                                <div fxLayout="row" fxFlex="100" class="table-item"
                                                    style="display: grid; margin-bottom: 50px">
                                                    <quill-editor class="quill-container" [(ngModel)]="value.criteria"
                                                        [name]="'criteria' + indexTest" [id]="'criteria' + indexTest"
                                                        [modules]="quillConfig"
                                                        style="height: auto; font-weight: normal;"></quill-editor>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="testConfig?.testVerification">
                    <div fxLayout="column">
                        <div fxLayout="row" class="table-item title-table-test">
                            <div fxFlex="100">
                                <div fxFlex="90" class="center-text">
                                    <span style="margin-right: 5px;">{{'protocolTest.test.verification.test.tittle'
                                        |
                                        translate}}</span>
                                </div>
                                <div fxFlex="10">
                                    <button class="btn-add" mat-raised-button (click)="newTestVerification()">
                                        {{ 'protocolTest.test.verification.new.btn' | translate }}
                                        <mat-icon fxHide.lt-sm>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" class="container-verification-data">
                            <div fxLayout="row"
                                *ngFor="let value of dataSource.protocolQTestVerification; let indexTest = index">
                                <div fxFlex="35" class="table-item " class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.verification.test.item.test' | translate
                                            }}</mat-label>
                                        <input matInput [name]="'test' + indexTest" [(ngModel)]="value.test">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30" class="table-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.verification.test.item.description' |
                                            translate
                                            }}</mat-label>
                                        <textarea matInput [name]="'descriptionTest' + indexTest"
                                            [(ngModel)]="value.descriptionTest" rows="5"></textarea>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30" class="table-item" class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.verification.test.item.result' | translate
                                            }}</mat-label>
                                        <mat-select multiple [name]="'vResultVerification'+indexTest"
                                            [(ngModel)]="value.resultDesc">
                                            <mat-option *ngFor="let sel of resultQTypes" checkboxPosition="before"
                                                [value]="sel.translation">
                                                {{'protocolTest.result.'+sel.translation | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="5">
                                    <button class="btn-delete-verification" mat-icon-button
                                        (click)="deleteTestVerification(indexTest)"
                                        matTooltip="{{ 'button.delete' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex="100" fxLayout="column">
                    <div fxLayout="row" class="title-table-withpadding" class="table-item title-table-test">
                        <div fxFlex="100">
                            <div fxFlex="100" class="center-text">
                                <span>{{'protocolTest.test.verification.title' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <mat-tab-group [selectedIndex]="_groupItem" (focusChange)="onEssayTabChange($event.index)">
                        <mat-tab *ngFor="let group of dataSource.protocolQTestItemGroup; let indexGroup = index"
                            style="min-height: 50vh;">
                            <ng-template mat-tab-label>
                                <span class="tab-text"> {{ group.translationType ? ('essayType.' +
                                    group.translationType | translate) + ' ' : ''}} </span>
                                <span class="tab-text" *ngIf="group.title" style="margin-left: 5px">{{ ' ('+
                                    group.title +') '}}</span>
                                <span class="tab-text" *ngIf="displayCycle(group)" style="margin-left: 5px"> {{
                                    'testQualification.cicle' | translate }} {{group.numCycle}} </span>
                            </ng-template>
                            <div *ngIf="group?.protocolQTestItems?.length > 0">
                                <div fxLayout="column">
                                    <div fxFlex="100" fxLayout="column" class="container-verification-data">
                                        <div fxFlex="100"
                                            *ngFor="let item of group?.protocolQTestItems; let indexItem = index"
                                            fxLayout="column">
                                            <div fxFlex="100">
                                                <div fxFlex="15" *ngIf="testConfig.item">
                                                    <button class="btn-add" mat-raised-button
                                                        (click)="newItemVeritication(indexGroup)">
                                                        {{ 'Nuevo item' | translate }}
                                                        <mat-icon fxHide.lt-sm>add</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div fxFlex="100" fxLayout="row" class="table-item">
                                                <div fxFlex="80" *ngIf="testConfig?.item">
                                                    <mat-form-field class="campoFormulario">
                                                        <mat-label>{{ 'Item' | translate }}</mat-label>
                                                        <input matInput [name]="'item'+indexGroup+indexItem"
                                                            [(ngModel)]="item.description">
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex="5" *ngIf="testConfig?.item" [fxHide]="indexItem === 0">
                                                    <button class="btn-delete-verification" mat-icon-button
                                                        (click)="deleteRowItem(indexGroup, indexItem)"
                                                        matTooltip="{{ 'button.delete' | translate }}">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                                <div fxFlex="20">
                                                    <button class="btn-add" mat-raised-button
                                                        (click)="newItemVerification(indexGroup, indexItem)">
                                                        {{ 'Nueva verificación' | translate }}
                                                        <mat-icon fxHide.lt-sm>add</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div cdkDropList [cdkDropListData]="item.protocolQTestDatas"
                                                class="verification-drop-list"
                                                (cdkDropListDropped)="drop($event, indexGroup, indexItem)">
                                                <div *ngFor="let valueItemV of item.protocolQTestItemVerification; let indexItemV = index"
                                                    cdkDrag class="verification-drop-box">

                                                    <div fxFlex="100" fxLayout="column" class="table-item">
                                                        <div fxFlex="100" fxLayout="row">
                                                            <div fxFlex="85">
                                                                <!-- Icono de arrastre (handler) -->
                                                                <div fxFlex="5" cdkDragHandle>
                                                                    <span>
                                                                        <mat-icon
                                                                            class="move-drag-cursorr">reorder</mat-icon>
                                                                    </span>
                                                                </div>
                                                                <div fxFlex="95">
                                                                    <mat-form-field class="campoFormulario">
                                                                        <mat-label>{{
                                                                            'protocolTest.test.item.verification.item'
                                                                            |
                                                                            translate }}</mat-label>
                                                                        <textarea matInput
                                                                            [name]="'verificationTypeDesc'+indexGroup+indexItem+indexItemV"
                                                                            [(ngModel)]="valueItemV.verification"
                                                                            rows="1"></textarea>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                            <div fxFlex="10">
                                                                <button class="btn-add" mat-raised-button
                                                                    (click)="newDataVerification(indexGroup,indexItem,indexItemV)">
                                                                    <mat-icon fxHide.lt-sm>add</mat-icon>
                                                                </button>
                                                            </div>
                                                            <div fxFlex="5">
                                                                <button class="btn-delete-verification" mat-icon-button
                                                                    (click)="deleteRowItemVerification(indexGroup,indexItem,indexItemV)"
                                                                    matTooltip="{{ 'button.delete' | translate }}">
                                                                    <mat-icon fxHide.lt-sm>delete</mat-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngFor="let value of valueItemV.protocolQTestDataMultiple; let indexValue = index">
                                                            <div fxFlex="100" fxLayout="row" class="table-item">
                                                                <div fxFlex="45" class="input-dinamyc-item"
                                                                    *ngIf="testConfig?.expectedResult">
                                                                    <mat-form-field class="campoFormulario">
                                                                        <mat-label
                                                                            *ngIf="!value.expectedResultRhDesc">{{
                                                                            'protocolTest.test.result.expected.multiple.item'
                                                                            |
                                                                            translate }}</mat-label>
                                                                        <mat-label *ngIf="value.expectedResultRhDesc">{{
                                                                            value.expectedResultRhDesc
                                                                            }}</mat-label>

                                                                        <input matInput
                                                                            [name]="'expectedResultDesc'+indexGroup+indexItem+indexItemV+indexValue"
                                                                            [(ngModel)]="value.expectedResultDesc">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div fxFlex="40" class="input-dinamyc-item">
                                                                    <mat-form-field class="campoFormulario">
                                                                        <mat-label>{{
                                                                            'protocolTest.test.multiple.result.item'
                                                                            |
                                                                            translate }}</mat-label>
                                                                        <mat-select multiple
                                                                            [name]="'sResultDesc'+indexGroup+indexItem+indexItemV+indexValue"
                                                                            [(ngModel)]="value.resultDesc">
                                                                            <mat-option *ngFor="let sel of resultQTypes"
                                                                                checkboxPosition="before"
                                                                                [value]="sel.translation">
                                                                                {{'protocolTest.result.'+sel.translation
                                                                                |
                                                                                translate}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="valueItemV.id" fxFlex="10">
                                                                    <button mat-raised-button
                                                                        (click)="openVisualizerImage(value)"
                                                                        class="stroked">
                                                                        <mat-icon
                                                                            fxHide.lt-sm>add_photo_alternate</mat-icon>
                                                                    </button>
                                                                </div>
                                                                <div fxFlex="5">
                                                                    <button class="btn-delete-verification"
                                                                        mat-icon-button
                                                                        (click)="deleteRowData(indexGroup,indexItem,indexItemV,indexValue)"
                                                                        matTooltip="{{ 'button.delete' | translate }}">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div fxFlex="100" fxLayout="column" *ngIf="testConfig?.probePlacement">
                <div fxLayout="row" class="table-item title-table-test title-table-withpadding">
                    <div fxFlex="100">
                        <div fxFlex="100" class="center-text">
                            <span> {{ 'protocolTest.test.location.probe' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="column" class="container-verification-data">
                    <div fxLayout="row" fxFlex="100" class="table-item" style="display: grid; margin-bottom: 50px">
                        <quill-editor class="quill-container" [(ngModel)]="dataSource.imageTest"
                            [name]="'imageTest'" [id]="'imageTest'" [modules]="quillConfig"
                            style="height: auto; font-weight: normal;"></quill-editor>
                    </div>
                </div>
            </div>


            <div fxFlex>
                <app-protocol-q-edit-test-attachments #attachmentsTestComponent *ngIf="data.data.id !=null">
                </app-protocol-q-edit-test-attachments>
            </div>


            <div mat-dialog-actions class="qualificates-theme">
                <div fxFlex></div>
                <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
                <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
                    }}</button>
            </div>

        </div>
    </div>
</form>