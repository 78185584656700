import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { Field } from 'src/app/model/field';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { FieldEnum } from '../../../utils/fieldUtils';
import { EssayTypeEnum } from '../../../utils/essayUtils';
import { EssayAirTypeEnum } from 'src/app/model/essayType';

export interface DialogDataFieldEdit {
  field: Field;
  fromProtocol: boolean;
  isAnexI: boolean;
  essayType: number;
}

@Component({
  selector: 'app-execution-edit-field-edit',
  templateUrl: './execution-edit-field-edit.component.html'
})
export class ExecutionEditFieldEditComponent {

  valueOriginal: number;
  constructor(
    private dialogRef: MatDialogRef<ExecutionEditFieldEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataFieldEdit,
    private translate: TranslateService,
    public snackBarService: SnackBarService) {

    if (this.data.fromProtocol == null) {
      this.data.fromProtocol = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.valueOriginal = this.data.field ? this.data.field?.value : 0;
  }

  public setValue(index: number, value: string | MatCheckboxChange | MatSelectionListChange): void {

    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map(i => i.value as string).join(';');
    }

    this.data.field.setValue(index, value);
  }

  isSelectedMulti(valuesStr: string | string[], valueCheck: string): boolean {
    if (valuesStr == null || valueCheck == null) {
      return false;
    }

    let values = [];
    if (typeof valuesStr === 'string') {
      values = valuesStr.split(';');
    } else {
      values = valuesStr;
    }

    return values.includes(valueCheck);
  }

  showReason(): boolean {
    if (!this.data.isAnexI) {
      if (!this.data.fromProtocol) {
        return false;
      }
    }

    const field = this.data.field;
    let currentValue = field.value;
    if (!this.data.isAnexI) {
      let protocolValue = field.isMultifield() ? field.valueProtocolArray : field.valueProtocol;

      if (Array.isArray(currentValue)) {
        currentValue = currentValue.join(';');
      }

      if (Array.isArray(protocolValue)) {
        protocolValue = protocolValue.join(';');
      }

      return currentValue !== protocolValue;
    }

    return currentValue !== this.valueOriginal;
  }

  onOkClick(): void {
    const errs = [];

    const field = this.data.field;
    const currentValue = field.value;

    if ((currentValue == null || currentValue === '') && field.required) {
      errs.push(this.translate.instant('executionEdit.essays.editField.form.error.value'));
    }

    if (!this.showReason()) {
      field.valueChangeReason = null;
    } else if (field.valueChangeReason == null || field.valueChangeReason === '') {
      errs.push(this.translate.instant('common.reasonChangeError'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(field);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showTextVolumenRoom(): boolean {
    if (FieldEnum.VOLUME === this.data.field?.idField && (this.data?.essayType === EssayAirTypeEnum.REN_FLOW_AND_RATE)) {
      return true;
    } else {
      return false;
    }
  }
}
