import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { FlexLayoutModule } from 'ng-flex-layout';
import { IsothermalCharacterizationEditAttachmentDetailsComponent } from './isothermal-characterization-edit-attachment-details.component';
import { IsothermalCharacterizationEditAuditComponent } from './isothermal-characterization-edit-audit.component';
import { IsothermalCharacterizationEditComponent } from './isothermal-characterization-edit.component';
import { IsothermalCharacterizationEditConfigSensorComponent } from './isothermal-characterization-edit-config-sensor.component';
import { IsothermalCharacterizationEditConfigureSensorsComponent } from './isothermal-characterization-edit-configure-sensors.component';
import { IsothermalCharacterizationEditConfirmSaveComponent } from './isothermal-characterization-edit-dialog-confirmSave.component';
import { IsothermalCharacterizationEditCriteriaEditComponent } from './isothermal-characterization-edit-criteria-edit.component';
import {
  IsothermalCharacterizationEditDialogCorrectSensorsComponent
} from './isothermal-characterization-edit-dialog-correct-sensors.component';
import { IsothermalCharacterizationEditDiscardTimeComponent } from './isothermal-characterization-edit-discard-time.component';
import { IsothermalCharacterizationEditEquipmentDialogComponent } from './isothermal-characterization-edit-dialog-equipment.component';
import { IsothermalCharacterizationEditEssayComponent } from './isothermal-characterization-edit-essay.component';
import { IsothermalCharacterizationEditGenerateReportComponent } from './isothermal-characterization-edit-generate-report.component';
import { IsothermalCharacterizationEditGraphComponent } from './isothermal-characterization-edit-graph.component';
import { IsothermalCharacterizationEditMassiveUploadComponent } from './isothermal-characterization-edit-massive-upload.component';
import { IsothermalCharacterizationEditPhotoDetailsComponent } from './isothermal-characterization-edit-photo-details.component';
import {
  IsothermalCharacterizationEditReasonExpositionTimeComponent
} from './isothermal-characterization-edit-reason-exposition-time.component';
import { IsothermalCharacterizationEditSignReportComponent } from './isothermal-characterization-edit-sign-report.component';
import {
  IsothermalCharacterizationEditValidateIsothermalCharacterizationComponent
} from './isothermal-characterization-edit-validate-isothermal-characterization.component';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [IsothermalCharacterizationEditComponent, IsothermalCharacterizationEditGenerateReportComponent,
    IsothermalCharacterizationEditValidateIsothermalCharacterizationComponent, IsothermalCharacterizationEditGraphComponent,
    IsothermalCharacterizationEditMassiveUploadComponent, IsothermalCharacterizationEditEssayComponent,
    IsothermalCharacterizationEditDiscardTimeComponent, IsothermalCharacterizationEditConfigSensorComponent,
    IsothermalCharacterizationEditAuditComponent, IsothermalCharacterizationEditAttachmentDetailsComponent,
    IsothermalCharacterizationEditConfigureSensorsComponent, IsothermalCharacterizationEditEquipmentDialogComponent,
    IsothermalCharacterizationEditCriteriaEditComponent, IsothermalCharacterizationEditConfirmSaveComponent,
    IsothermalCharacterizationEditReasonExpositionTimeComponent, IsothermalCharacterizationEditSignReportComponent,
    IsothermalCharacterizationEditPhotoDetailsComponent, IsothermalCharacterizationEditDialogCorrectSensorsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /* Custom modules */
    AngularMaterialModule,
    DevextremeModule,
    SharedModule,
    PipesModule,
    /* Otros */
    FlexLayoutModule
  ]
})
export class IsothermalCharacterizationEditModule { }
