<form>
    <h1 mat-dialog-title class="calibrates-theme">
        {{ 'calibrateEquipmentEdit.ambientConditions.readParticles.title' | translate }}
    </h1>
    <div mat-dialog-content class="calibrates-theme">
        <!-- Contaje de partículas -->
        <div fxFlex>

            <button mat-icon-button color="accent" (click)="uploadExcelParticleCalibratesCountInput.click()"
                matTooltip="{{ 'button.upload-excel' | translate }}" [disabled]="!enableEditCriteria()">
                <mat-icon>cloud_upload</mat-icon>
            </button>
            <input accept=".xlsx, .xls, .csv" hidden type="file" (change)="uploadExcelParticleCount($event)"
                #uploadExcelParticleCalibratesCountInput>

            <button mat-icon-button color="accent" (click)="downloadExcelParticleCount()"
                matTooltip="{{ 'button.download-excel' | translate }}" *ngIf="data.variable?.ambientConditions?.id != null && data.variable?.ambientConditions?.idParticlesExcel">
                <mat-icon>cloud_download</mat-icon>
            </button>

            <table #valuesParticles mat-table matSort [dataSource]="data.variable.valuesParticles">

                <!-- date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{ item.date | fixDateTime }} </td>
                </ng-container>

                <!-- location Column -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.location' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{ item.location }} </td>
                </ng-container>

                <!-- volume Column -->
                <ng-container matColumnDef="volume">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.volume' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{ item.volume + ' ' + item.unit }} </td>
                </ng-container>

                <!-- zeroOne Column -->
                <ng-container matColumnDef="zeroOne">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroOne' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        {{ showParticleNum(item.zeroOne) }}
                    </td>
                </ng-container>

                <!-- zeroTwo Column -->
                <ng-container matColumnDef="zeroTwo">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroTwo' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        {{ showParticleNum(item.zeroTwo) }}
                    </td>
                </ng-container>

                <!-- zeroThree Column -->
                <ng-container matColumnDef="zeroThree">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroThree' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        {{ showParticleNum(item.zeroThree) }}
                    </td>
                </ng-container>

                <!-- zeroFive Column -->
                <ng-container matColumnDef="zeroFive">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroFive' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        {{ showParticleNum(item.zeroFive) }}
                    </td>
                </ng-container>

                <!-- oneZero Column -->
                <ng-container matColumnDef="oneZero">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.oneZero' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        {{ showParticleNum(item.oneZero) }}
                    </td>
                </ng-container>

                <!-- fiveZero Column -->
                <ng-container matColumnDef="fiveZero">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'executionEdit.dialog.essay.valuesParticles.cols.fiveZero' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item">
                        {{ showParticleNum(item.fiveZero) }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsParticleCount"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsParticleCount;"></tr>

            </table>
        </div>
    </div>
    <div mat-dialog-actions class="calibrates-theme">
        <!--<div fxFlex="50" align="start">
            <button mat-raised-button color="secondary" (click)="deleteBtnDataAmbientParticles()">
                {{ 'calibrateEquipmentEdit.ambientConditions.deleteData.btn' | translate }} </button>
        </div>-->
        <div  fxFlex="100" align="end">
            <button mat-raised-button color="primary" (click)="onOkClick()">
                {{ 'button.save' | translate }} </button>
            <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
        </div>
    </div>
</form>