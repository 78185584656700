import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineService } from '../../../services/online.service';
import { ThemeService } from '../../../services/theme.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../services/snackBar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { GlobalStateService } from '../../../services/globalState.service';
import { Subject, takeUntil } from 'rxjs';
import { RoleUser } from '../../../model/roleUser';
import { User } from '../../../model/user';
import { environment } from 'src/environments/environment';
import { AppTypeEnum } from '../../../model/appType';

@Component({
  selector: 'app-home-calibrates',
  templateUrl: './home-calibrates.component.html',
  styleUrls: ['./home-calibrates.component.scss']
})
export class HomeCalibratesComponent implements OnInit, OnDestroy {

  timestampt: string;

  user = new User();

  selectedGroup: number;
  selectedApp: number;
  roles: RoleUser[];

  selectedComponent: string;
  airState: any;
  roouterPath: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private onlineService: OnlineService,
    private themeService: ThemeService,
    private userService: UserService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private globalStateService: GlobalStateService) {
      const currentProfile = userService.currentProfile;

      this.roles = currentProfile.profiles;
      this.selectedGroup = currentProfile.profiles
        .find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;
    }

  ngOnInit(): void {
    localStorage.removeItem('filter');
    localStorage.removeItem('filter-equipment');
    localStorage.removeItem('customFields');
    this.timestampt = environment.timeStamp;

    this.user = this.userService.currentProfile;
    this.roouterPath = false;

    this.globalStateService.calibratesState$.subscribe((newState) => {
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToPage(page: string): void {
    this.roouterPath = true;
    void this.router.navigateByUrl(page);
  }

  downloadData(): void {
    this.spinnerService.show();

    this.onlineService.downloadCacheableData().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('home.form.downloadDataOffline.ok') as string);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('home.form.downloadDataOffline.error') as string);

      this.spinnerService.hide();
    });
  }

  showExternalEquipments(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.showExternalEquipments;
  }

  showCharacterization(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser === null || currentUser === undefined) {
      return false;
    }

    return currentUser.showCharacterization;
  }

  getLogo(): string {
    return 'assets/img/qloud_calibrates.png';
  }

  getText(): string {
    return 'landing.main.calibrates';
  }

  getThemeIconPath(): string {
    return 'assets/icons/calibrates/';
  }

  newProtocol(): void {
    void this.router.navigateByUrl('/thermal/protocol?id=0');
  }

  newExecutions(): void {
    void this.router.navigateByUrl('/thermal/execution?id=0');
  }

  newInternalEquipment(): void {
    void this.router.navigateByUrl('calibrates/internalEquipment?id=0');
  }

  newExternalEquipment(): void {
    void this.router.navigateByUrl('calibrates/externalEquipment?id=0');
  }

  newCalibration(): void {
    void this.router.navigateByUrl('calibrates/calibration?id=0');
  }

  newIsothermalCharacterization(): void {
    void this.router.navigateByUrl('calibrates/isothermalCharacterization?id=0');
  }

  navigateToCalibratesOptions(url: string): void {
    this.roouterPath = true;
    const newState = { selectedOption: 'calibrates-'+url };
    this.globalStateService.updateTabState('main-tab', newState);
    void this.router.navigate(['calibrates',url]);
  }

  getTheme(): string {
    return this.themeService.getThemeHome(AppTypeEnum.CALIBRATES);
  }
}