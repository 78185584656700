<h1 mat-dialog-title>{{ 'executionEdit.' + data.type + 's.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="thermal-theme" style="min-height: 10vh;">

    <div fxLayout="column" fxLayout.xs="column" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <mat-label>{{ 'executionEdit.' + data.type + 's.responsible.label' | translate }}</mat-label>
              <mat-select name="responsible" [(ngModel)]="data.bioindicator.responsible">
                <mat-option *ngFor="let responsible of bioindicatorResponsibles" [value]="responsible.id">
                  {{ 'bioindicatorResponsibleType.' + responsible.translation | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.lt-md="100%">
        <mat-radio-group class="vertical-radio-group" [ngModel]="optionSelected" [ngModelOptions]="{standalone: true}">
          <mat-radio-button class="vertical-radio-button" *ngFor="let item of options" [value]="item"
            (change)="onOptionChange($event)">
            {{ 'executionEdit.' + data.type + 's.options.' + item + '.label' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex="100" fxFlex.lt-md="100%">

        <div *ngIf="optionSelected == 'cert'">
          <button mat-raised-button color="primary" (click)="uploadCertificate.click()">
            {{ 'button.upload-certificate' | translate }}
          </button>
          <input accept="application/pdf" hidden type="file" (change)="uploadCertificateBioindicator($event)"
            #uploadCertificate>

          <div *ngFor="let cert of data.bioindicator.certificates; let indexCert = index">
            {{ cert.filename }}

            <button mat-icon-button *ngIf="cert.id != null" (click)="downloadAttachment(cert)">
              <mat-icon>cloud_download</mat-icon>
            </button>

            <button mat-icon-button *ngIf="cert.id == null" (click)="downloadAttachmentLocal(cert)">
              <mat-icon>cloud_download</mat-icon>
            </button>

            <button mat-icon-button (click)="removeAttachment(indexCert)">
              <mat-icon>delete</mat-icon>
            </button>

          </div>

        </div>

        <div *ngIf="optionSelected == 'batch'">
          <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
            *ngFor="let batch of data.bioindicator.batchs; let indexBatch = index">

            <div fxFlex="5" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'executionEdit.' + data.type + 's.number.label' | translate }}"
                  name="batchNumber{{indexBatch}}" [ngModel]="(indexBatch + 1)" disabled="true">
              </mat-form-field>
            </div>

            <div fxFlex="45" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'executionEdit.' + data.type + 's.batch.label' | translate }}"
                  name="batchId{{indexBatch}}" [(ngModel)]="batch.batchId">
                <mat-error>
                  {{ 'executionEdit.' + data.type + 's.batch.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="45" fxFlex.lt-md="100%">
              <mat-form-field>
                <input matInput [matDatepicker]="expiryDate" name="expiryDate{{indexBatch}}"
                  placeholder="{{ 'executionEdit.' + data.type + 's.expiryDate.label' | translate }}"
                  [(ngModel)]="batch.expiryDate">
                <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                <mat-datepicker #expiryDate >
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="5" fxFlex.lt-md="100%">
              <button mat-icon-button color="accent" (click)="removeBatch(indexBatch)"
                *ngIf="data.bioindicator.batchs.length > 1">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>

          <div>
            <button mat-raised-button color="primary" (click)="newBatch()">
              {{ 'button.new' | translate }}
            </button>
          </div>

        </div>

        <div *ngIf="optionSelected == 'reason'">
          <textarea rows="5" matAutosizeMinRows="5" matInput
            placeholder="{{ 'executionEdit.' + data.type + 's.reason.label' | translate }}"
            [(ngModel)]="data.bioindicator.reason" name="reason" required style="resize: none;"></textarea>
        </div>

      </div>

      <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="data.type == 'bioindicator'">
        <br />

        <span>{{'executionEdit.' + data.type + 's.equipments.title' | translate}}</span>
        <br />
        <mat-radio-group [(ngModel)]="hasEquipmentData" name="hasEquipmentData">
          <mat-radio-button [value]="true" (change)="onHasEquipmentData($event)">
            {{'common.yes' | translate}}
          </mat-radio-button>
          <mat-radio-button [value]="false" (change)="onHasEquipmentData($event)">
            {{'common.no' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="!hasEquipmentData">
        <br /><br />
      </div>

      <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="hasEquipmentData">
        <br />

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput
                placeholder="{{ 'executionEdit.' + data.type + 's.equipments.table.equipment.label' | translate }}"
                name="equipment" [(ngModel)]="data.bioindicator.equipments[0].equipment" [errorStateMatcher]="matcher">
              <mat-error>
                {{ 'executionEdit.' + data.type + 's.equipments.table.equipment.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput
                placeholder="{{ 'executionEdit.' + data.type + 's.equipments.table.serialNum.label' | translate }}"
                name="serialNum" [(ngModel)]="data.bioindicator.equipments[0].serialNum" [errorStateMatcher]="matcher">
              <mat-error>
                {{ 'executionEdit.' + data.type + 's.equipments.table.serialNum.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput
                placeholder="{{ 'executionEdit.' + data.type + 's.equipments.table.internalId.label' | translate }}"
                name="internalId" [(ngModel)]="data.bioindicator.equipments[0].internalId"
                [errorStateMatcher]="matcher">
              <mat-error>
                {{ 'executionEdit.' + data.type + 's.equipments.table.internalId.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput
                placeholder="{{ 'executionEdit.' + data.type + 's.equipments.table.certificateNum.label' | translate }}"
                name="certificateNum" [(ngModel)]="data.bioindicator.equipments[0].certificateNum"
                [errorStateMatcher]="matcher">
              <mat-error>
                {{ 'executionEdit.' + data.type + 's.equipments.table.certificateNum.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field style="width: 95%;">
              <input
                placeholder="{{ 'executionEdit.' + data.type + 's.equipments.table.calibrateDate.label' | translate }}"
                matInput [matDatepicker]="calibrateDate" name="calibrateDate"
                [ngModel]="data.bioindicator.equipments[0].calibrateDate"
                (dateInput)="saveDate('calibrateDate', $event)" (dateChange)="saveDate('calibrateDate', $event)">
              <mat-datepicker-toggle matSuffix [for]="calibrateDate"></mat-datepicker-toggle>
              <mat-datepicker #calibrateDate >
              </mat-datepicker>
              <mat-error>
                {{ 'executionEdit.' + data.type + 's.equipments.table.calibrateDate.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
            <button mat-raised-button (click)="uploadCertificate.click()" color="accent">{{ 'button.upload-certificate'
              | translate }}</button>

            <div fxFlex="5px"></div>
            <button mat-raised-button *ngIf="showDownloadCertificateEquipment()"
              (click)="downloadCertificateEquipment()" color="accent">{{ 'button.download-certificate' | translate
              }}</button>

            <input accept="application/pdf" hidden type="file" (change)="uploadCertificateToEquipment($event)"
              #uploadCertificate>
          </div>

        </div>

      </div>

    </div>
  </div>

  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <div fxFlex="5px"></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>