<h1>
  Seleccione plan de pago:
</h1>

<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="30" fxFlex.lt-md="100%" *ngFor="let plan of plans" style="padding: 1em;">
    <mat-card appearance="outlined" class="card" appMaterialElevation>
      <mat-card-content>
        <span class="planType">Plan {{plan.name}}</span>

        <p>{{ 'signup.plan.' + (plan.name | lowercase ) + '.short' | translate }}</p>

        <span innerHtml="{{ 'signup.plan.' + (plan.name | lowercase ) + '.description' | translate }}"> </span>

        <p>Coste inicial: {{plan?.initialPayment}} €</p>

        <p>Coste mensual: {{plan?.monthlyPayment}} €</p>
      </mat-card-content>
    </mat-card>

    <br />

    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex></div>
      <button mat-raised-button color="primary" (click)="selectPlan(plan)">{{ 'button.select' | translate }}</button>
      <div fxFlex></div>
    </div>

  </div>
</div>

<br />

<div fxLayout="row" class="block-highlights see-all" fxLayoutAlign="center center">
  <div fxFlex="90" style="padding: 1em;">
    <mat-card appearance="outlined" appMaterialElevation class="card">
      <mat-card-content class="pointerHand" (click)="contactUs()">
        <button mat-button color="accent">{{ 'button.contactUs' | translate }}</button>
      </mat-card-content>
    </mat-card>
  </div>
</div>