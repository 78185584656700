import { GenericClass } from './genericClass';

export class InstrumentationUse extends GenericClass {
    hasValidatorSpecifics: boolean;

    // validatorSpecifics: ValidatorSpecifics; // TODO: A implementar

}

export enum InstrumentationEnum {
    DATA_LOGGER = 1,
    REGISTRADOR_DATOS = 2,
    VALIDATOR = 3,
    A_DEFINIR = 4
}
