<div class="main-container">

  <div class="appImageContainer">
    <img src="assets/img/qloud_logo_claim.svg" class="appImage" alt="Logo de Qloud">
  </div>

  <mat-card appearance="outlined" class="loginBox">
    <mat-card-content>

      <p *ngIf="!isOnline"> {{ 'login.offline.disclaimer1' | translate }} </p>

      <p *ngIf="!isOnline && canConnectOffline">
        {{ 'login.offline.disclaimer2' | translate: {username: userConnectedOffline} }}
      </p>

      <form #userlogin="ngForm" class="form" *ngIf="isOnline">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{ 'login.username.label' | translate }}</mat-label>
          <input matInput name="username" autocomplete="username"
            [(ngModel)]="username" required [errorStateMatcher]="matcher" (keydown.enter)="login()"
            [disabled]="!isOnline">
          <mat-error>
            {{ 'login.username.error' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{ 'login.password.label' | translate }}</mat-label>
          <input matInput name="password"
            autocomplete="current-password" [(ngModel)]="password" required [type]="showPassword ? 'text' : 'password'"
            [errorStateMatcher]="matcher" (keydown.enter)="login()">
          <mat-error>
            {{ 'login.password.error' | translate }}
          </mat-error>

          <button mat-icon-button mat-small-icon-button matSuffix (click)="showPassword = !showPassword">
            <mat-icon>{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
        </mat-form-field>
        <p *ngIf="error" class="error">
          {{ error }}
        </p>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button name="loginButton" type="button" (click)="login()" color="primary"
        [disabled]="!isOnline && !canConnectOffline">
        {{ 'button.login' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>

  <a class="under-card-link" style="cursor: pointer;" (click)="forgotPassword()">
    {{ 'login.forgottenPassword.label' | translate }}
  </a>

  <br /><br />

  <a class="under-card-link" style="cursor: pointer;" (click)="createAccount()" *ngIf="false">
    {{ 'login.createAccount.label' | translate }}
  </a>

</div>